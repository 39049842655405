import { SelectConfigurationEditorComponent } from './select-configuration.component';
import { head, append, lens, reject, whereEq, compose, set, lensProp, over, findIndex, find, nth, update } from 'ramda';
import { v4 } from 'uuid';
import * as React from 'react';
import { Translations } from '../../../components/variable-editor-dialog/translations.interface';
const optionNameLens = (id: string) =>
  compose(
    lensProp('options'),
    lensWhere(whereEq({ id })),
    lensProp('name'),
  );
const optionValueLens = (id: string) =>
  compose(
    lensProp('options'),
    lensWhere(whereEq({ id })),
    lensProp('value'),
  );

interface SelectOption {
  id: string;
  value: string;
  name: string;
}

interface SelectTypeConfig {
  options: SelectOption[];
}

interface VariableValueFields {
  value: string;
  type_config: SelectTypeConfig;
}
export interface SelectConfigurationEditorProp extends VariableValueFields {
  translations: Translations['defaultValue'];
  onChange: (value: string, type_config: SelectTypeConfig) => void;
}

export const SelectConfigurationEditor = (props: SelectConfigurationEditorProp): any => (
  <SelectConfigurationEditorComponent
    value={props.value}
    type_config={props.type_config}
    translations={props.translations}
    defaultValueId={props.type_config.options[getDefaultValueIndex(props.value, props.type_config)].id}
    isRemoveDisabled={props.type_config.options.length <= 1}
    addNewOption={() => {
      const newOption = { id: v4(), value: '', name: '' };
      const withNewOption = over(lensProp('options'), append(newOption), props.type_config);
      props.onChange(props.value, withNewOption);
    }}
    remove={(id: string) => {
      const withRemovedOption = over(lensProp('options'), reject(whereEq({ id })), props.type_config);
      props.onChange(removeDefaultValue(props.value, props.type_config, id), withRemovedOption);
    }}
    valueChange={(id: string, value: string) => {
      const withUpdatedOptionValue = set(optionValueLens(id), value, props.type_config);
      const defaultValueIndex = getDefaultValueIndex(props.value, props.type_config);
      const changedIndex = findIndex(option => option.id === id, props.type_config.options);
      const valueToEmit = defaultValueIndex === changedIndex ? value : props.value;
      props.onChange(valueToEmit, withUpdatedOptionValue);
    }}
    nameChange={(id: string, value: string) => {
      const withUpdatedOptionName = set(optionNameLens(id), value, props.type_config);
      props.onChange(props.value, withUpdatedOptionName);
    }}
    defaultChange={(id: string) =>
      props.onChange(find(option => option.id === id, props.type_config.options)!.value, props.type_config)
    }
  />
);

// tslint:disable-next-line:typedef
function lensWhere<T>(pred: (t: T) => boolean) {
  return lens(
    (array: T[]) => nth(findIndex(pred, array), array),
    (value: T, array: T[]) => update(findIndex(pred, array), value, array),
  );
}

function removeDefaultValue(value: string, typeConfig: SelectTypeConfig, id: string): string {
  const defaultValueIndex = getDefaultValueIndex(value, typeConfig);
  const removedIndex = findIndex(whereEq({ id }), typeConfig.options);
  return defaultValueIndex === removedIndex ? head(typeConfig.options)!.value : value;
}

function getDefaultValueIndex(value: string, type_config: SelectTypeConfig): number {
  return Math.max(0, findIndex(whereEq({ value }), type_config.options));
}
