import * as React from 'react';
import classnames from 'classnames';
import { Translations } from '../../components/variable-editor-dialog/translations.interface';

export type StringEditorProp = {
  value: string;
  translations: Translations['defaultValue'];
  onChange: (value: string) => void;
  invalid?: boolean;
};
export const StringEditor = ({ onChange, value, translations, invalid }: StringEditorProp): any => (
  <input
    type="text"
    value={value}
    onChange={(event: any) => onChange(event.target.value)}
    placeholder={translations.placeholder}
    className={classnames(['e-input variables-editor-input variable-value', { 'e-input-error': invalid }])}
  />
);
