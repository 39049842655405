import { State, ValidationResult } from '../state.interface';
import * as Actions from '../actions';
import { assocPath, pipe, pathOr } from 'ramda';
import { validateId } from '../validations';

export const setIdReducer = (state: State, action: Actions.SetId) =>
  pipe(
    assocPath<string, State>(['variableConfiguration', 'id'], action.payload.id),
    assocPath<ValidationResult, State>(
      ['validation', 'id'],
      validateId(
        action.payload.id,
        state.reservedIds,
        pathOr(undefined, ['originalVariableConfiguration', 'id'], state),
        state.translations.validationErrors,
      ),
    ),
  )(state);
