import { LinkSelection, SelectedLink, LinkAttributes, SelectionType } from '../editable-text/models';
import { defaultTo } from 'ramda';
import { findParent } from './find-parent';

const DEFAULT_HREF = '';
const DEFAULT_TITLE = '';
const DEFAULT_ATTRIBUTES = {
  href: DEFAULT_HREF,
  title: DEFAULT_TITLE,
};

export const getLinkSelection = (editor): LinkSelection => {
  const linkElement = _checkElementLink(editor);
  return {
    type: _getSelectionType(linkElement),
    link: _getSelectedLinkData(linkElement),
  };
};

function _checkElementLink(editor): HTMLAnchorElement {
  if (editor.selection.getNode().nodeName === 'A') return editor.selection.getNode();
  return _getLinkAncestorOfSelection(editor);
}

function _getLinkAncestorOfSelection(editor): HTMLAnchorElement {
  return findParent(editor.selection.getNode(), 'a') as HTMLAnchorElement;
}

function _getSelectionType(linkElement: HTMLAnchorElement): SelectionType {
  const isLinkSelected = linkElement !== null;
  if (isLinkSelected) return SelectionType.linkSelected;
  return SelectionType.textSelected;
}

function _getSelectedLinkData(linkElement: HTMLAnchorElement): SelectedLink {
  if (!linkElement) return { element: linkElement, attributes: DEFAULT_ATTRIBUTES };
  return {
    element: linkElement,
    attributes: _getLinkAttributes(linkElement),
  };
}

function _getLinkAttributes(linkElement: HTMLAnchorElement): LinkAttributes {
  return {
    href: defaultTo(DEFAULT_HREF, linkElement.getAttribute('href')) as string,
    title: defaultTo(DEFAULT_TITLE, linkElement.getAttribute('title')) as string,
  };
}
