import { CustomElement } from '../../../../lib/custom-element-decorators';
import { HTMLCustomElement } from '../../../../lib/html-custom-element';
import { StringAttribute, JsonAttribute, Callback } from '../../../../lib/reactive-decorators';
import { safeGet } from '../image-properties/libs/safe-get';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { switchMap, takeUntil, mapTo } from 'rxjs/operators';
import { fromEvent } from 'rxjs/observable/fromEvent';
import { ToolbarButton } from '../toolbar';

const getTargetId = (element: HTMLElement, attributeName: string) => element.getAttribute(attributeName);

type CurrentButton = {
  button: HTMLElement;
  target: HTMLElement;
};
export interface VcePluginCustomButtonTranslations {
  tooltip: string;
}
export interface VcePluginToolbarCustomButton extends HTMLElement, ToolbarButton {
  translations: VcePluginCustomButtonTranslations;
  buttonName: string;
  idAttributeName: string;
  icon: string;
}
export function createVcePluginToolbarCustomButton(): { new (): VcePluginToolbarCustomButton } {
  class VcePluginCustomButtonClass extends HTMLCustomElement implements VcePluginToolbarCustomButton {
    @JsonAttribute('translations') translations: VcePluginCustomButtonTranslations;
    @StringAttribute('button-name') buttonName: string;
    @StringAttribute('id-attribute-name') idAttributeName: string;
    @StringAttribute('icon') icon: string;
    @Callback('disconnectedCallback') _disconnect$: Observable<void>;

    private _currentButton$: Subject<CurrentButton>;

    init(): void {
      this._currentButton$ = new Subject<CurrentButton>();
    }

    connectedCallback(): void {
      this._currentButton$
        .pipe(
          switchMap(({ button, target }: CurrentButton) => fromEvent(button, 'click').pipe(mapTo(target))),
          takeUntil(this._disconnect$),
        )
        .subscribe(this._onClickCallback.bind(this));
    }

    getButton(target: HTMLElement): HTMLElement {
      const container = this._getContainer();
      this._currentButton$.next({ target, button: container.querySelector('button') as HTMLElement });
      return container.querySelector('e-tooltip') as HTMLElement;
    }

    private _getContainer(): HTMLElement {
      const container = document.createElement('div');
      container.innerHTML = `
        <e-tooltip placement="bottom" content="${this._getTooltip()}">
          <button class="e-btn e-btn-onlyicon e-svgclickfix">
            <e-icon icon="${this.icon}"></e-icon>
          </button>
        </e-tooltip>
      `;
      return container;
    }

    private _getTooltip(): string {
      return safeGet(() => this.translations.tooltip)!;
    }

    private _onClickCallback(target: HTMLElement): void {
      this.dispatchEvent(
        new CustomEvent('vce-plugin-toolbar-custom-button.click', {
          detail: { id: getTargetId(target, this.idAttributeName), button: this.buttonName },
        }),
      );
    }
  }

  return VcePluginCustomButtonClass;
}

const name = 'vce-plugin-toolbar-custom-button';
CustomElement(name)(createVcePluginToolbarCustomButton());
