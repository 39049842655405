import * as cheerio from 'cheerio';
const loadCheerio = html => cheerio.load(html, { decodeEntities: false });

export function createElementFromHtmlContent(document: Document, content: string): HTMLElement {
  const container = getContainer(document, content);
  container.innerHTML = content;
  return container.firstChild as HTMLElement;
}

function getContainer(document: Document, content: string): HTMLElement {
  switch (getMainElementTagName(content)) {
    case 'tr': {
      return document.createElement('tbody') as HTMLElement;
    }
    case 'td': {
      return document.createElement('tr') as HTMLElement;
    }
    case 'tbody': {
      return document.createElement('table') as HTMLElement;
    }
    default: {
      return document.createElement('div') as HTMLElement;
    }
  }
}

function getMainElementTagName(content: string): string {
  const $ = loadCheerio('');
  return $(content).first()[0].tagName;
}
