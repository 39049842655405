import { isFontSelectorEvent, isRemoveFormatEvent, isToggleFormatEvent } from './format-events';
import { TokenFormatRemover, TokenFontSelector, TokenFormatToggler, SelectionFormatter } from './format-handlers';
import { isTokensSelected } from './utils';
import { complement, isEmpty, isNil, anyPass, includes } from 'ramda';

const isNotEmpty = complement(anyPass([isEmpty, isNil]));
const isAlreadyDefined = complement(isNil);
const tinymce = require('tinymce/tinymce');

export const initializeTokenFormatterPlugin = () => {
  if (isAlreadyDefined(tinymce.PluginManager.get('tokenFormatterPlugin'))) return;

  tinymce.PluginManager.add('tokenFormatterPlugin', function(editor: any): void {
    editor.on(
      'BeforeExecCommand',
      event => {
        const selectionAncestor: HTMLElement = editor.selection.getNode();
        const tokens: HTMLSpanElement[] = editor.dom.select('span.cbNonEditable');
        const oneTokenSelected = isNotEmpty(tokens) && includes(selectionAncestor, tokens);
        const multipleTokenSelected = !oneTokenSelected && isTokensSelected(editor);

        if (oneTokenSelected) {
          if (isToggleFormatEvent(selectionAncestor, event)) {
            TokenFormatToggler.create(editor)
              .wrapElement(selectionAncestor)
              .toggleFormat(event)
              .restoreSelection()
              .removeWrapperElement();
          }

          if (isFontSelectorEvent(selectionAncestor, event)) {
            TokenFontSelector.create(editor)
              .wrapElement(selectionAncestor)
              .toggleFormat(event)
              .restoreSelection()
              .clearElementWrapper();
          }

          if (isRemoveFormatEvent(selectionAncestor, event)) {
            TokenFormatRemover.create(editor)
              .wrapElement(selectionAncestor)
              .removeFormats();
          }
        }

        if (multipleTokenSelected) {
          SelectionFormatter.create(editor)
            .wrapSelection(editor.selection.getContent({ format: 'raw' }))
            .formatTokens(token => {
              if (isToggleFormatEvent(token, event)) {
                TokenFormatToggler.create(editor)
                  .wrapElement(token)
                  .toggleFormat(event)
                  .restoreSelection()
                  .removeWrapperElement();
              }

              if (isFontSelectorEvent(token, event)) {
                TokenFontSelector.create(editor)
                  .wrapElement(token)
                  .toggleFormat(event)
                  .restoreSelection()
                  .clearElementWrapper();
              }
            })
            .restoreSelection()
            .removeBookmarks();
        }
      },
      true,
    );
  });
};
