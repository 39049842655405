import { CustomElement } from '../../../../lib/custom-element-decorators';
import { IVcePlugin } from '../../../../lib/vce-plugin.interface';
import { StringAttribute, HTMLCustomElement } from '../../../../lib/index';
import { ReplaceBrokenImageWithFallback, replaceBrokenImageWithFallback } from './replace-broken-image-with-fallback';
import { Subscription } from 'rxjs';
import { isNil } from 'ramda';

export interface VcePluginReplaceBrokenImageWithFallback extends HTMLElement, IVcePlugin {}
export function createVcePluginReplaceBrokenImageWithFallback(
  replaceBrokenImageWithFallback: ReplaceBrokenImageWithFallback,
): { new (): VcePluginReplaceBrokenImageWithFallback } {
  class VcePluginReplaceBrokenImageWithFallbackClass extends HTMLCustomElement
    implements VcePluginReplaceBrokenImageWithFallback {
    @StringAttribute('placeholder') placeholder: string;
    @StringAttribute('data-path') dataPath: string;
    @StringAttribute('fallback-data-path') fallbackDataPath: string;
    private _errorEvents: Subscription;

    connectedCallback(): void {
      this.dispatchEvent(new CustomEvent('plugin.connected', { bubbles: true }));
    }

    disconnectedCallback(): void {
      this.dispatchEvent(new CustomEvent('plugin.disconnected'));
    }

    renderedCallback(iframe: HTMLIFrameElement): void {
      if (!isNil(this._errorEvents)) {
        this._errorEvents.unsubscribe();
      }
      this._errorEvents = replaceBrokenImageWithFallback(
        { placeholder: this.placeholder, dataPath: this.dataPath, fallbackDataPath: this.fallbackDataPath },
        iframe.contentDocument!,
      );
    }
  }
  return VcePluginReplaceBrokenImageWithFallbackClass;
}

CustomElement('vce-plugin-replace-broken-image-with-fallback')(
  createVcePluginReplaceBrokenImageWithFallback(replaceBrokenImageWithFallback),
);
