import { CustomElement, ReactiveAttribute } from '../../../../lib';
import {
  CodeEditorValidationStatusEvents,
  CodeEditorTwigValidatorPluginValidationStatus,
  CodeEditorTwigValidatorStatus,
} from '../../interface';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { mergeDeepRight } from 'ramda';
import { combineLatest } from 'rxjs/observable/combineLatest';
import { ValidationStatusDisplayInputs, ValidationStatusTranslation } from './interface';

export const getDefaultTranslation = (): ValidationStatusTranslation => ({
  success: 'ESL code validation passed',
  fail: 'Invalid ESL',
  loading: 'Waiting for ESL validation',
  unknown: 'The ESL code cannot be validated right now. Try again later.',
});

export const createCodeEditorValidationStatusDisplay = () => {
  class CodeEditorValidationStatusDisplay extends HTMLElement {
    @ReactiveAttribute('validationStatus', 'validationStatus')
    private _validationStatus: Observable<CodeEditorTwigValidatorPluginValidationStatus>;

    @ReactiveAttribute('translations', 'translations', JSON.parse)
    private _translations$: Observable<Partial<ValidationStatusTranslation>>;

    private _validator: HTMLElement;

    connectedCallback(): Element | void {
      this._validator = document.createElement('e-validator');
      this.appendChild(this._validator);

      this._inputs$.subscribe(({ validationStatus, translations }) => {
        const { header, status } = this.getStatusDisplayContent(validationStatus, translations);

        this._validator.setAttribute('header', header);
        this._validator.setAttribute('status', status);
      });

      this.dispatchEvent(new CustomEvent(CodeEditorValidationStatusEvents.Connected, { bubbles: true }));
    }

    disconnectedCallback(): void {
      this.innerHTML = '';
      this.dispatchEvent(new CustomEvent(CodeEditorValidationStatusEvents.Disconnected, { bubbles: true }));
    }

    private get _inputs$(): Observable<ValidationStatusDisplayInputs> {
      return combineLatest(
        this._validationStatus,
        this._translations$.pipe(
          startWith(getDefaultTranslation()),
          map(mergeDeepRight<ValidationStatusTranslation>(getDefaultTranslation())),
        ),
      ).pipe(
        map(([validationStatus, translations]) => ({
          validationStatus,
          translations: { ...getDefaultTranslation(), ...(translations as {}) },
        })),
      );
    }
    private getStatusDisplayContent(
      validationStatus: CodeEditorTwigValidatorPluginValidationStatus,
      translations: ValidationStatusTranslation,
    ): any {
      if (this._isSuccessStatus(validationStatus)) {
        return { header: translations.success, status: 'success' };
      }

      if (this._isFailedStatus(validationStatus)) {
        return { header: `*${translations.fail}:* ${validationStatus.errors.join(', ')}`, status: 'danger' };
      }

      if (this._isLoadingStatus(validationStatus)) {
        return { header: translations.loading, status: 'loading' };
      }

      if (this._isUnknownStatus(validationStatus)) {
        return { header: translations.unknown, status: 'warning' };
      }
    }

    private _isLoadingStatus(validationStatus: CodeEditorTwigValidatorPluginValidationStatus): boolean {
      return validationStatus.status === CodeEditorTwigValidatorStatus.Loading;
    }

    private _isUnknownStatus(validationStatus: CodeEditorTwigValidatorPluginValidationStatus): boolean {
      return validationStatus.status === CodeEditorTwigValidatorStatus.Unknown;
    }

    private _isSuccessStatus(validationStatus: CodeEditorTwigValidatorPluginValidationStatus): boolean {
      return validationStatus.status === CodeEditorTwigValidatorStatus.Success;
    }

    private _isFailedStatus(validationStatus: CodeEditorTwigValidatorPluginValidationStatus): boolean {
      return validationStatus.status === CodeEditorTwigValidatorStatus.Fail;
    }
  }

  return CodeEditorValidationStatusDisplay;
};

const name = 'vce-code-editor-validation-status-display';
CustomElement(name)(createCodeEditorValidationStatusDisplay());
