import * as React from 'react';
import { VariableConfiguration } from '@emartech/vce-domain';
import { VariableFieldForConfiguration } from '../../../react-components/variable-field';
import { ValidationResult } from '../state';
import { Translations } from '../translations.interface';

type DefaultValueProps = {
  variableConfiguration: VariableConfiguration;
  validation: ValidationResult;
  translations: Translations['defaultValue'];
  setDefaultValue: (name: string, typeConfig: any) => void;
  useEditableImage: boolean;
};
export const DefaultValue = (props: DefaultValueProps): JSX.Element => (
  <div className="e-field" variable-editor-form-field="default_value">
    {props.variableConfiguration.type !== 'select' && (
      <label className="e-field__label">{props.translations.title}</label>
    )}
    <VariableFieldForConfiguration
      id={props.variableConfiguration.id}
      type_config={'type_config' in props.variableConfiguration ? props.variableConfiguration.type_config : undefined}
      type={props.variableConfiguration.type}
      value={props.variableConfiguration.default_value}
      onChange={(value, type_config?) => props.setDefaultValue(value, type_config)}
      invalid={props.validation.isInvalid}
      translations={props.translations}
      useEditableImage={props.useEditableImage}
    />
    {props.validation.isInvalid && (
      <span className="e-field__message e-field__message-error">{props.validation.errorMessage}</span>
    )}
  </div>
);
