import { CustomElement } from '../../../../lib/custom-element-decorators';
import { HTMLCustomElement } from '../../../../lib/html-custom-element';
import { ExternalTinyMcePlugin } from '../editable-text/editable-text.component';
import { commandTrackerFactory, CommandTrackerFactory, CommandTracker } from './command-tracker';
import { TinymceEditor } from '../editable-text/models/tinymce-editor/index';

export interface VcePluginEditableTextTracker extends HTMLElement, ExternalTinyMcePlugin {}

export function createVcePluginEditableTextTracker(
  commandTrackerFactory: CommandTrackerFactory,
): { new (): VcePluginEditableTextTracker } {
  class VcePluginEditableTextTrackerClass extends HTMLCustomElement implements VcePluginEditableTextTracker {
    private _commandTracker: CommandTracker;
    init(): void {
      this._commandTracker = commandTrackerFactory((action, label) => {
        this.dispatchEvent(
          new CustomEvent('trackEvent', {
            detail: {
              action,
              label,
            },
          }),
        );
      });
    }

    setupTinyMceEditor(editor: TinymceEditor): void {
      this._commandTracker(editor);
    }
  }

  return VcePluginEditableTextTrackerClass;
}

const name = 'vce-plugin-editable-text-tracker';
CustomElement(name)(createVcePluginEditableTextTracker(commandTrackerFactory));
