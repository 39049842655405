import { CustomElement } from '../../../../lib/custom-element-decorators';
import { takeUntil } from 'rxjs/operators';
import { combineLatest } from 'rxjs/observable/combineLatest';
import { Observable } from 'rxjs/Observable';
import { ReactiveAttribute, Callback, HTMLCustomElement } from '../../../../lib';
import { RenderToken } from '../../interface';

export type TokenComponent = HTMLElement & {
  name: string;
  data: string;
};

export const createToken = (renderToken: RenderToken) => {
  class TokenImplementation extends HTMLCustomElement {
    @ReactiveAttribute('data', 'data')
    private _data$: Observable<string>;

    @ReactiveAttribute('name', 'name')
    private _name$: Observable<string>;

    @Callback('disconnectedCallback') private _disconnect$: Observable<void>;

    connectedCallback(): void {
      combineLatest(this._data$, this._name$)
        .pipe(takeUntil(this._disconnect$))
        .subscribe(([data, name]) => {
          const newContent = renderToken(name);
          this._replaceContent(newContent);
          newContent.addEventListener('dragstart', event => {
            event.dataTransfer!.setData('text', data);
            if (typeof event.dataTransfer!.setDragImage !== 'undefined') {
              event.dataTransfer!.setDragImage(event.target as Element, 0, 0);
            }
          });
        });
    }

    private _replaceContent(content: HTMLElement): void {
      this.innerHTML = '';
      this.appendChild(content);
    }
  }
  return TokenImplementation;
};

function renderToken(name: string): HTMLSpanElement {
  const element = document.createElement('span');
  element.classList.add('e-label', 'e-label-primary');
  element.style.cursor = 'move';
  element.draggable = true;
  element.innerText = name;
  return element;
}

const name = 'vce-token';
CustomElement(name)(createToken(renderToken));
