import { map, filter, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { ofType } from 'redux-observable';
import { Store } from 'redux';
import { EpicDependencies } from '../../dependencies.interface';
import { validateDefaultValue } from '../../validations';
import { of } from 'rxjs/observable/of';
import { fromPromise } from 'rxjs/observable/fromPromise';
import {
  DefaultValue,
  VariablesEditorDialogAction,
  SET_DEFAULT_VALUE,
  createSetDefaultValueValidation,
} from '../../actions';
import { State } from '../../state.interface';
import { ValidateEditableImage } from '../../validations/validate-editable-image/validate-editable-image';

export const createSetDefaultValueValidationOnChange = (validateEditableImage: ValidateEditableImage) => (
  actions$: Observable<VariablesEditorDialogAction>,
  store: Store<State>,
  _dependencies: EpicDependencies,
): Observable<any> =>
  actions$.pipe(
    filter(() => store.getState().useEditableImage),
    ofType(SET_DEFAULT_VALUE),
    switchMap((action: DefaultValue) => {
      const state = store.getState();
      if (state.variableConfiguration.type === 'image') {
        return fromPromise(validateEditableImage(action.payload.defaultValue, state.translations.validationErrors));
      }
      return of(validateDefaultValue(action.payload.defaultValue, state.translations.validationErrors));
    }),
    map(({ isInvalid, errorMessage }) => createSetDefaultValueValidation(isInvalid, errorMessage)),
  );
