import { Subscription } from 'rxjs/Subscription';
import { fromEvent } from 'rxjs/observable/fromEvent';
import { includes } from 'ramda';
const isChrome = require('is-chrome');

export type ReplaceBrokenImageWithFallbackOptions = { placeholder: string; dataPath: string; fallbackDataPath: string };

export interface ReplaceBrokenImageWithFallback {
  (opts: ReplaceBrokenImageWithFallbackOptions, document: Document): Subscription;
}
export const replaceBrokenImageWithFallback: ReplaceBrokenImageWithFallback = (
  opts: { placeholder: string; dataPath: string; fallbackDataPath: string },
  document: Document,
) => {
  Array.from(document.querySelectorAll('img')).forEach(
    (element: HTMLImageElement): void => {
      if (element.complete) {
        if (element.naturalWidth === 0 || element.naturalHeight === 0) {
          setPlaceholder(element, opts);
        }
      }
    },
  );

  return fromEvent(document.querySelectorAll('img'), 'error').subscribe(($event: ErrorEvent) => {
    const element = $event.target as HTMLImageElement;
    if (element.hasAttribute('data-placeholder')) {
      return;
    }
    setPlaceholder(element, opts);
  });
};

function setPlaceholder(element: HTMLImageElement, opts: ReplaceBrokenImageWithFallbackOptions): void {
  const httpsDataPath = forceHttps(opts.dataPath);
  const httpsSrc = forceHttps(element.src);
  if (isChrome() && includes(httpsDataPath, httpsSrc) && opts.dataPath !== opts.fallbackDataPath) {
    element.setAttribute('data-broken-src', element.src);
    element.src = httpsSrc.replace(httpsDataPath, opts.fallbackDataPath);
    return;
  }

  element.setAttribute('data-placeholder', 'true');
  if (!element.hasAttribute('data-broken-src')) {
    element.setAttribute('data-broken-src', element.src);
  }
  element.src = opts.placeholder;
}

function forceHttps(url: string): string {
  return url.replace(/http:\/\//g, 'https://');
}
