import {
  CHANGE_VALUE,
  RESET,
  createApplyStateFromInput,
  State,
  VariablesEditorAction,
  createSetUseEditableImage,
} from './';
import { map, tap, ignoreElements } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { ofType } from 'redux-observable';
import { Store } from 'redux';
import { Variables } from '@emartech/vce-domain';

export type EpicDependencies = {
  inputChange$: Observable<State>;
  emitStateChange: (variables: Variables) => void;
  useEditableImage$: Observable<boolean>;
};

export const delegateChangesViaApi = (
  actions$: Observable<VariablesEditorAction>,
  store: Store<State>,
  dependencies: EpicDependencies,
) =>
  actions$.pipe(
    ofType(CHANGE_VALUE, RESET),
    tap(() => dependencies.emitStateChange(store.getState().variables)),
    ignoreElements(),
  );

export const applyChangesFromApi = (
  actions$: Observable<VariablesEditorAction>,
  store: Store<State>,
  dependencies: EpicDependencies,
) => dependencies.inputChange$.pipe(map(createApplyStateFromInput));

export const setUseEditableImageFromApi = (
  _actions$: Observable<VariablesEditorAction>,
  _store: Store<State>,
  dependencies: EpicDependencies,
): Observable<VariablesEditorAction> => dependencies.useEditableImage$.pipe(map(createSetUseEditableImage));
