import * as DOMPurify from 'dompurify';
import { assoc } from 'ramda';
import { sanitizeRefreshMeta, sanitizeDataSVGImages } from './hooks';

const SANITIZE_CONFIG_PARTIAL_DOCUMENT = {
  ADD_ATTR: [
    'e-editable',
    'e-link-id',
    'e-conditional',
    'e-personalization',
    'e-link',
    'e-block-id',
    'e-block',
    'e-blocks-container',
    'e-block-roots',
    'e-block-util',
    'e-button-id',
    'e-token',
    'ems:notrack',
    'ems:category',
    'ems:name',
    'ems:preheader',
    'data',
    'min',
    'max',
    'optional',
    'profile',
    'http-equiv',
    'charset',
    'content',
    'target',
    'position',
    'label',
    'active',
    'placement',
    'inmost',
    'sticky',
    'highlight',
    'step',
    'icon',
    'blocktemplatename',
    'block-toolbar-button',
    'blockposition',
    'draggable',
    'image-toolbar-button',
    'data-editable-attributes',
    'data-link-attributes',
    'token-template',
    'token-content',
    'token-meta',
  ],

  ADD_TAGS: [
    '!doctype',
    '#comment',
    'meta',
    'link',
    'e-icon',
    'e-tooltip',
    'e-vce-dropline',
    'e-vce-positioner-block',
    'e-vce-borderer',
  ],
  SANITIZE_DOM: false,
};

DOMPurify.addHook('uponSanitizeAttribute', sanitizeRefreshMeta);
sanitizeDataSVGImages(DOMPurify, SANITIZE_CONFIG_PARTIAL_DOCUMENT);

const SANITIZE_CONFIG_WHOLE_DOCUMENT = assoc('WHOLE_DOCUMENT', true, SANITIZE_CONFIG_PARTIAL_DOCUMENT);

export function sanitizeDocument(inputHtml: string): string {
  return DOMPurify.sanitize(inputHtml, SANITIZE_CONFIG_WHOLE_DOCUMENT);
}

export function sanitizePartialDocument(inputHtml: string): string {
  return DOMPurify.sanitize(inputHtml, SANITIZE_CONFIG_PARTIAL_DOCUMENT);
}
