import * as React from 'react';
import { reactCustomElementWrapper } from '../../../../lib/react-custom-element-wrapper';
import { Translations } from '../../components/variable-editor-dialog/translations.interface';

export type ImageEditorProp = {
  value: string;
  onChange: (value: string) => void;
  invalid: boolean;
  translations: { image: Translations['defaultValue']['image'] };
  hideThumbnailButton?: boolean;
};

const EditableImageSelectorComponent = reactCustomElementWrapper('vce-editable-image-input');

export const EditableImageEditor = ({
  onChange,
  value,
  invalid,
  translations,
  hideThumbnailButton,
}: ImageEditorProp): any => (
  <EditableImageSelectorComponent
    value={value}
    on-update={event => onChange(event.detail.value)}
    invalid={invalid}
    prop-translations={translations}
    prop-hideThumbnailButton={hideThumbnailButton}
  />
);
