import { HTMLCustomElement } from '../../../../lib/html-custom-element';
import { CustomElement } from '../../../../lib/custom-element-decorators';
import { ReactiveAttribute, Callback } from '../../../../lib/reactive-decorators';
import { Observable } from 'rxjs/Observable';
import { render } from 'react-dom';
import * as React from 'react';
import { combineLatest } from 'rxjs/observable/combineLatest';
import { takeUntil } from 'rxjs/operators';
import { locales } from '../../lib/locales';
import { GetLocaleDisplayName } from '../../interface';
import { createGetLocaleDisplayName } from '../../lib/get-locale-display-name';

export const createLanguageName = (
  getLocaleName: GetLocaleDisplayName = createGetLocaleDisplayName(locales),
): { new (): HTMLElement } => {
  class LanguageName extends HTMLCustomElement {
    @ReactiveAttribute('ietf-code', 'ietfCode')
    private _ietfCode$: Observable<string>;

    @Callback('disconnectedCallback') private _disconnect$: Observable<void>;

    connectedCallback(): void {
      combineLatest(this._ietfCode$)
        .pipe(takeUntil(this._disconnect$))
        .subscribe(([ietfCode]) => render(<>{getLocaleName(ietfCode)}</>, this));
      this.dispatchEvent(new CustomEvent('connected', { bubbles: true }));
    }
  }
  return LanguageName;
};

export const languageNameTagName = 'vce-language-name';
CustomElement(languageNameTagName)(createLanguageName());
