import { CustomElement } from '../../../../lib/custom-element-decorators';
import { HTMLCustomElement } from '../../../../lib/html-custom-element';
import { Link, LinkEditorDialog } from '../../components/link-editor-dialog';
import { ViewChild, JsonAttribute } from '../../../../lib/reactive-decorators';
import { translate, Translate } from '../image-properties/translate';
import { getSetupInnerLinkEditorHook, GetSetupInnerLinkEditorHook } from './tinymce-plugin';
import { ExternalTinyMcePlugin } from '../editable-text/editable-text.component';
import { TinymceEditor } from '../editable-text/models/tinymce-editor/index';

export type TextInnerLinkEditorData = {
  [key: string]: Link;
};

export interface VcePluginTextInnerLinkEditor extends HTMLElement, ExternalTinyMcePlugin {}

export function createVcePluginTextInnerLinkEditor(
  translate: Translate,
  getSetupInnerLinkEditorHook: GetSetupInnerLinkEditorHook,
): { new (): VcePluginTextInnerLinkEditor } {
  class VcePluginTextInnerLinkEditorClass extends HTMLCustomElement implements VcePluginTextInnerLinkEditor {
    @JsonAttribute('translations') translations?: Object;
    @ViewChild('[link-editor-dialog]') _dialog?: LinkEditorDialog;

    connectedCallback(): void {}

    setupTinyMceEditor(editor: TinymceEditor): void {
      getSetupInnerLinkEditorHook(editor, {
        tooltip: this._translate('text-inner-link-editor-toolbar-button'),
        openDialog: (link: Link) => this._dialog!.open(link).apply,
      });
    }

    private _translate(key: string, parameters?: any[]): string {
      return translate(this.translations || {})(key, parameters);
    }
  }

  return VcePluginTextInnerLinkEditorClass;
}

const name = 'vce-plugin-text-inner-link-editor';
CustomElement(name)(createVcePluginTextInnerLinkEditor(translate, getSetupInnerLinkEditorHook));
