import { Observable } from 'rxjs/Observable';
import { DomBordererRecalculateAction, DomBordererActionType } from './models';
import { map as map$, withLatestFrom } from 'rxjs/operators';
import { map } from 'ramda';

const selectFromIframe = (selector: string) => (iframe: HTMLIFrameElement): HTMLElement[] =>
  Array.from(iframe.contentWindow!.document.body.querySelectorAll(selector));

export type Recalculate = (
  iframe$: Observable<HTMLIFrameElement>,
  recalculate$: Observable<Event>,
  selector: string,
) => Observable<DomBordererRecalculateAction[]>;
export const onRecalculate: Recalculate = (iframe$, recalculate$, selector) =>
  recalculate$.pipe(
    withLatestFrom(iframe$),
    map$(([_, iframe]) => iframe),
    map$(selectFromIframe(selector)),
    map$(
      map(
        editable =>
          ({
            element: editable,
            actionType: DomBordererActionType.recalculate,
          } as DomBordererRecalculateAction),
      ),
    ),
  );
