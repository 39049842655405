import { head } from 'ramda';

const WRAPPER_CLASS = 'elementWrapper';
const WRAPPER_ELEMENT_SELECTOR = `span.${WRAPPER_CLASS}`;

export class ElementWrapper {
  private _editor;
  private _element: HTMLElement;
  private _elementTagName: string;

  static create(editor): ElementWrapper {
    return new ElementWrapper(editor);
  }

  constructor(editor) {
    this._editor = editor;
  }

  wrap(element: HTMLElement): void {
    this._element = element;
    this._elementTagName = element.tagName.toLowerCase();
    this._editor.selection.setNode(this._createWrapperElement());
    this._editor.selection.select(this._getWrapperElement());
  }

  restoreSelection(): void {
    this._editor.selection.select(this._getElement());
  }

  clearWrapperElement({ forcedRemove = false } = {}): void {
    const wrapperElement = this._getWrapperElement();
    wrapperElement.removeAttribute('class');
    if (forcedRemove || !wrapperElement.hasAttribute('data-mce-style')) {
      this._editor.dom.remove(wrapperElement, true);
    }
  }

  private _createWrapperElement(): HTMLSpanElement {
    return this._editor.dom.create('span', { class: WRAPPER_CLASS }, this._element.outerHTML);
  }

  private _getWrapperElement(): HTMLSpanElement {
    const wrapperElements: HTMLSpanElement[] = this._editor.dom.select(`${WRAPPER_ELEMENT_SELECTOR}`);
    return head(wrapperElements) as HTMLSpanElement;
  }

  private _getElement(): HTMLElement {
    const elements: HTMLElement[] = this._editor.dom.select(`${WRAPPER_ELEMENT_SELECTOR} > ${this._elementTagName}`);
    return head(elements) as HTMLElement;
  }
}
