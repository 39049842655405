import { TinyMCEEvent } from '../format-events/event.interface';
import { ElementWrapper } from '../utils';

export class TokenFormatToggler {
  private _editor;
  private _wrapper: ElementWrapper;

  static create(editor): TokenFormatToggler {
    return new TokenFormatToggler(editor);
  }

  constructor(editor) {
    this._editor = editor;
    this._wrapper = ElementWrapper.create(editor);
  }

  wrapElement(element: HTMLSpanElement): TokenFormatToggler {
    this._wrapper.wrap(element);
    return this;
  }

  toggleFormat(event: TinyMCEEvent): TokenFormatToggler {
    this._editor.formatter.toggle(event.value);
    return this;
  }

  restoreSelection(): TokenFormatToggler {
    this._wrapper.restoreSelection();
    return this;
  }

  removeWrapperElement(): void {
    this._wrapper.clearWrapperElement({ forcedRemove: true });
  }
}
