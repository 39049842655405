/* tslint:disable */

const setCss = (element: HTMLElement, styles) => Object.assign(element.style, styles);
const innerBorderStyle = { borderStyle: 'dashed', borderWidth: '3' };
const borderStyle = { position: 'absolute', display: 'block', zIndex: 600 };
const defaultBorderColor = getComputedStyle(document.documentElement).getPropertyValue('--token-box-default-border');
const warningBorderColor = getComputedStyle(document.documentElement).getPropertyValue('--token-box-warning-border');

export type Render = {
  createBorder: CreateBorder;
  getBorder: GetBorder;
  getBorders: GetBorders;
  hasBorders: HasBorders;
  updateBorder: UpdateBorder;
  hideOutline: HideOutline;
  restoreOutline: RestoreOutline;
};
export type CreateBorder = (editable: HTMLElement, type: string) => HTMLElement;
export const createBorder: CreateBorder = (editable, type) => {
  const warning = editable.getAttribute('e-warning') !== null;
  const { top, left, elementDimensions } = calculatePos(editable);
  const borderElement = createBorderElement(
    editable.ownerDocument,
    editable.getAttribute('e-editable')!,
    type,
    left,
    top,
  );
  borderElement.innerHTML = render({
    ...innerBorderStyle,
    borderColor: warning ? warningBorderColor : defaultBorderColor,
    elementWidth: elementDimensions.width,
    elementHeight: elementDimensions.height,
  });
  return borderElement;
};

export type GetBorder = (editable: HTMLElement, type: string) => HTMLElement | null;
export const getBorder: GetBorder = (editable, type) => {
  const editableId = editable.getAttribute('e-editable')!;
  const querySelector = `[e-borderer-type="${type}"][e-borderer-editable-id="${editableId}"]`;
  return editable.ownerDocument.querySelector(querySelector);
};

export type GetBorders = (editable: HTMLElement) => HTMLElement[];
export const getBorders: GetBorders = editable => {
  const editableId = editable.getAttribute('e-editable')!;
  const querySelector = `[e-borderer-editable-id="${editableId}"]`;
  return Array.from(editable.ownerDocument.querySelectorAll(querySelector));
};

export type HasBorders = (editable: HTMLElement) => boolean;
export const hasBorders: HasBorders = editable => {
  const editableId = editable.getAttribute('e-editable')!;
  const querySelector = `[e-borderer-editable-id="${editableId}"]`;
  return !!editable.ownerDocument.querySelector(querySelector);
};

export type HideOutline = (editable: HTMLElement) => void;
export const hideOutline: HideOutline = editable => {
  if (!editable['__outline']) {
    editable['__outline'] = {
      outline: editable.style.outline,
      outlineStyle: editable.style.outlineStyle,
      outlineColor: editable.style.outlineColor,
      outlineOffset: editable.style.outlineOffset,
      outlineWidth: editable.style.outlineWidth,
    };
    editable.style.outline = 'none';
  }
};

export type RestoreOutline = (editable: HTMLElement) => void;
export const restoreOutline: RestoreOutline = editable => {
  if (editable['__outline']) {
    Object.assign(editable.style, editable['__outline']);
    delete editable['__outline'];
  }
};

export type UpdateBorder = (borderElement: HTMLElement) => void;
export const updateBorder: UpdateBorder = border => {
  const editableId = border.getAttribute('e-borderer-editable-id')!;
  const editableQuerySelector = `[e-editable="${editableId}"]`;
  const editable = border.ownerDocument.querySelector(editableQuerySelector)! as HTMLElement;
  const warning = editable.getAttribute('e-warning') !== null;
  const { top, left, elementDimensions } = calculatePos(editable);
  setCss(border, { left, top, ...borderStyle });
  border.innerHTML = render({
    ...innerBorderStyle,
    borderColor: warning ? warningBorderColor : defaultBorderColor,
    elementWidth: elementDimensions.width,
    elementHeight: elementDimensions.height,
  });
};

function calculatePos(editable: HTMLElement) {
  const elementDimensions = getElementDimensions(editable);
  const containerOffset = {
    scrollLeft: editable.ownerDocument.defaultView!.pageXOffset || editable.ownerDocument.documentElement.scrollLeft,
    scrollTop: editable.ownerDocument.defaultView!.pageYOffset || editable.ownerDocument.documentElement.scrollTop,
  };
  const left = elementDimensions.left + containerOffset.scrollLeft + 'px';
  const top = elementDimensions.top + containerOffset.scrollTop + 'px';
  return { top, left, elementDimensions };
}

function getElementDimensions(element: HTMLElement): ClientRect {
  let elementDimensions;
  const targetIsInline = window.getComputedStyle(element).getPropertyValue('display') === 'inline';
  if (targetIsInline) {
    const originalStyle = element.getAttribute('style');
    setTargetToInlineBlock(element);
    elementDimensions = element.getBoundingClientRect();
    resetTargetStyle(element, originalStyle);
  } else {
    elementDimensions = element.getBoundingClientRect();
  }
  element.getBoundingClientRect();
  return elementDimensions;
}

function setTargetToInlineBlock(element) {
  element.style.display = 'inline-block';
}

function resetTargetStyle(element, originalStyle) {
  if (originalStyle) {
    element.setAttribute('style', originalStyle);
  } else {
    element.getAttribute('style'); // chrome render fix :(
    element.removeAttribute('style');
  }
}

function createBorderElement(
  document: Document,
  editableId: string,
  type: string,
  left: string,
  top: string,
): HTMLElement {
  const borderElement = document.createElement('vce-borderer');
  setCss(borderElement, { left, top, ...borderStyle });
  borderElement.setAttribute('e-borderer-editable-id', editableId);
  borderElement.setAttribute('e-borderer-type', type);
  return borderElement;
}

function render({ borderWidth, borderStyle, borderColor, elementWidth, elementHeight }): string {
  return `
  <vce-borderer-border vce-borderer-top style="display: block; pointer-events: none; border-top: ${borderWidth}px ${borderStyle} ${borderColor}; width: ${elementWidth}px; height: 0; left: 0; top: 0; position: absolute"></vce-borderer-border>
  <vce-borderer-border vce-borderer-right style="display: block; pointer-events: none; border-right: ${borderWidth}px ${borderStyle} ${borderColor}; width: 0; height: ${elementHeight -
    borderWidth * 2}px; left: ${elementWidth -
    borderWidth}px; top: ${borderWidth}px; position: absolute"></vce-borderer-border>
  <vce-borderer-border vce-borderer-bottom style="display: block; pointer-events: none; border-bottom: ${borderWidth}px ${borderStyle} ${borderColor}; width: ${elementWidth}px; height: 0; left: 0; top: ${elementHeight -
    borderWidth}px; position: absolute"></vce-borderer-border>
  <vce-borderer-border vce-borderer-left style="display: block; pointer-events: none; border-left: ${borderWidth}px ${borderStyle} ${borderColor}; width: 0; height: ${elementHeight -
    borderWidth * 2}px; left: 0; top: ${borderWidth}px; position: absolute"></vce-borderer-border>
  `;
}
