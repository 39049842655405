export type DomBordererAction = DomBordererRecalculateAction | DomBordererRemoveAction | DomBordererCreateAction;

export enum DomBordererActionType {
  create = 'create',
  remove = 'remove',
  recalculate = 'recalculate',
}

export type DomBordererRecalculateAction = {
  actionType: DomBordererActionType.recalculate;
  element: HTMLElement;
};

export type DomBordererRemoveAction = {
  actionType: DomBordererActionType.remove;
  element: HTMLElement;
  type: DomBordererType;
};
export type DomBordererCreateAction = {
  actionType: DomBordererActionType.create;
  element: HTMLElement;
  type: DomBordererType;
};

export enum DomBordererType {
  force = 'force',
  focus = 'focus',
  hover = 'hover',
}
