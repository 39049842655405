import { Observable } from 'rxjs/Observable';
import { DomBordererType, DomBordererCreateAction, DomBordererRemoveAction, DomBordererActionType } from './models';
import { map } from 'rxjs/operators';
import { combineLatest } from 'rxjs/observable/combineLatest';

const selectFromIframe = (iframe: HTMLIFrameElement, selector: string): HTMLElement[] =>
  Array.from(iframe.contentWindow!.document.body.querySelectorAll(selector));

export type OnForce = (
  force$: Observable<boolean>,
  iframe$: Observable<HTMLIFrameElement>,
  selector: string,
) => Observable<(DomBordererCreateAction | DomBordererRemoveAction)[]>;
export const onForce: OnForce = (force$, iframe$, selector) =>
  combineLatest(iframe$, force$).pipe(
    map(([iframe, force]) =>
      selectFromIframe(iframe, selector).map(
        editable =>
          ({
            element: editable as HTMLElement,
            type: DomBordererType.force,
            actionType: force ? DomBordererActionType.create : DomBordererActionType.remove,
          } as DomBordererCreateAction | DomBordererRemoveAction),
      ),
    ),
  );
