import * as React from 'react';
import { Translations, EditableVariable } from '../state';
import { DisplayName } from './variable-editor.display-name';
import { ResetButton } from './variable-editor.reset-button';
import { VariableField } from '../../../react-components/variable-field';

const enableHideReset = false;

export type VariableProps = {
  editableVariable: EditableVariable;
  translations: Translations;
  onInputChange: (id: string, value: string) => void;
  onResetClick: (id: string) => void;
  useEditableImage: boolean;
  showVariableIDString?: boolean;
};
export const VariableEditor = ({
  editableVariable,
  translations,
  onInputChange,
  onResetClick,
  useEditableImage,
  showVariableIDString = false,
}: VariableProps): any => (
  <div key={editableVariable.id}>
    <div className="e-field variable-editor-row" style={{ marginBottom: '12px' }}>
      <div className="e-grid e-grid-between e-grid-vertical_center">
        <div className="e-cell text-overflow">
          <DisplayName editableVariable={editableVariable} />
        </div>
      </div>
      <div className="e-grid">
        <div className="e-cell e-cell-auto">
          <VariableField
            id={`variables-editor-input-${editableVariable.id}`}
            type={editableVariable.type}
            value={editableVariable.value}
            type_config={editableVariable.type_config}
            onChange={value => onInputChange(editableVariable.id, value)}
            translations={translations}
            useEditableImage={useEditableImage}
          />
        </div>
        <div className="e-cell">
          <div className="reset-button-container e-cell e-cell-sticky" style={{visibility: (enableHideReset && editableVariable.default_value === editableVariable.value)? "hidden": "visible" }}>
            <ResetButton tooltip={translations.resetButtonTooltip} onClick={() => onResetClick(editableVariable.id)} />
          </div>
        </div>
      </div>
      {showVariableIDString && 
        <div>
          &#123;variables.{editableVariable.id}&#125;
        </div>
      }
    </div>
  </div>
);
