import { CustomElement } from '../../../../lib/custom-element-decorators';
import { ExternalTinyMcePlugin } from '../editable-text/editable-text.component';
import { TinymceEditor } from '../editable-text/models/tinymce-editor/index';
import { transformToToken } from '../personalization/transform-to-token';
import { v2TokenDataToToken } from '../personalization/token-data-to-token-v2';
import { tokenDataToToken } from '../personalization/token-data-to-token';
import { findPlaceholderPositions } from '@emartech/personalization-twig-token';
import { HTMLCustomElement } from '../../../../lib/html-custom-element';

export interface VcePluginPersonalizationDrop extends HTMLElement, ExternalTinyMcePlugin {}

export function createVcePluginPersonalizationDrop(): { new (): VcePluginPersonalizationDrop } {
  class VcePluginPersonalizationDropClass extends HTMLCustomElement implements VcePluginPersonalizationDrop {
    setupTinyMceEditor(editor: TinymceEditor): void {
      editor.on('drop', event => {
        if (event.dataTransfer) {
          event.preventDefault();
          const droppedData = event.dataTransfer.getData('text');
          const dropRange = this._createRangeFromPoint(event.clientX, event.clientY, event.target.ownerDocument);
          editor.selection.setRng(dropRange);
          editor.insertContent(droppedData);
        }
      });

      editor.on('BeforeSetContent', event => {
        event.content = transformToToken(v2TokenDataToToken, tokenDataToToken, findPlaceholderPositions)(event.content);
      });
    }

    _createRangeFromPoint(x, y, document): any {
      let range;
      if ('caretPositionFromPoint' in document) {
        const position = document['caretPositionFromPoint'](x, y);
        range = document.createRange();
        range.setStart(position.offsetNode, position.offset);
        range.collapse(true);
      } else if ('caretRangeFromPoint' in document) {
        range = document.caretRangeFromPoint(x, y);
      } else if ('createTextRange' in document) {
        range = document.body['createTextRange']();
        range.moveToPoint(x, y);
      }
      return range;
    }
  }

  return VcePluginPersonalizationDropClass;
}

const name = 'vce-plugin-personalization-drop';
CustomElement(name)(createVcePluginPersonalizationDrop());
