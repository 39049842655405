export function createReactiveProperty({
  target,
  propertyKey,
  createObservable,
}): { subject: string; observable: string } {
  const subject = `__callback_${propertyKey}_subject`;
  const observable = `__callback_${propertyKey}_observable`;
  Object.defineProperty(target.constructor.prototype, subject, {
    get: function(): any {
      const memoizedSubject = '__memoized' + subject;
      if (!this[memoizedSubject]) {
        this[memoizedSubject] = createObservable();
      }
      return this[memoizedSubject];
    },
  });
  Object.defineProperty(target.constructor.prototype, observable, {
    get: function(): any {
      const memoizedObservable = '__memoized' + observable;
      if (!this[memoizedObservable]) {
        this[memoizedObservable] = this[subject].asObservable ? this[subject].asObservable() : this[subject];
      }
      return this[memoizedObservable];
    },
  });
  Object.defineProperty(target.constructor.prototype, propertyKey, {
    get: function(): any {
      return this[observable];
    },
  });
  return { subject, observable };
}
