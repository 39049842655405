import { Observable } from 'rxjs/Observable';
import { map, filter } from 'rxjs/operators';
import { combineLatest } from 'rxjs/observable/combineLatest';
import { isUIElement } from '../is-ui-element';
import { isNil, not } from 'ramda';
import { TextEditorEventData } from '../../models';
import { TinymceEditor } from '../../models/tinymce-editor/index';

type ClickTargets = Observable<EventTarget>;
type EditorBlurs = Observable<TextEditorEventData>;
type ValidatorParams = {
  target: EventTarget;
  editor: TinymceEditor;
  event: { focusedEditor: boolean };
};

export const getValidBlurEvents = (clickTargets$: ClickTargets, editorBlurs$: EditorBlurs): Observable<boolean> => {
  return combineLatest(clickTargets$, editorBlurs$).pipe(
    map(toValidatorParams),
    filter(byValidBlurEvents),
    map(() => true),
  );
};

function toValidatorParams([clickTarget, editorBlur]): { target: any; editor: any; event: any } {
  return {
    target: clickTarget,
    editor: editorBlur.editor.editor,
    event: editorBlur.event,
  };
}

function byValidBlurEvents({ editor, target, event }: ValidatorParams): boolean {
  return isNil(event.focusedEditor) && not(isUIElement(editor, target as Element));
}
