import { HTMLCustomElement } from '../../../../lib';
import { CustomElement } from '../../../../lib/custom-element-decorators';
import { V2PersonalizationState, PersonalizationApi } from '../../../../lib/personalization.interface';
import { PersonalizationDialogProxy, InsertTemplate } from '../../interface';

export function createPersonalizationDialogProxy(global: { personalization: PersonalizationApi }) {
  class PersonalizationDialogPromptProxyClass extends HTMLCustomElement implements PersonalizationDialogProxy {
    openDialog(insertTemplate: InsertTemplate, personalizationState?: V2PersonalizationState): void {
      const personalizationApi: PersonalizationApi = global.personalization;

      personalizationApi.openDialog({
        state: JSON.stringify(personalizationState || {}),
        insertTemplate: personalizationState => insertTemplate(personalizationState),
      });
    }
  }

  return PersonalizationDialogPromptProxyClass;
}

const name = 'vce-personalization-dialog-proxy';
CustomElement(name)(createPersonalizationDialogProxy(window as any));
