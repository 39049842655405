import * as React from 'react';
import { Translations, EditableVariable } from '../state';
import { VariableEditor } from './variable-editor';

export type VariableListActions = {
  onInputChange: (id: string, value: string) => void;
  onResetClick: (id: string) => void;
  onSearch: (search: string) => void;
};

export type VariableListProps = {
  translations: Translations;
  editableVariables: EditableVariable[];
  useEditableImage: boolean;
  search: string;
  useSearch?: boolean;
  showVariableIDString?: boolean;
};

export const VariableList = (props: VariableListProps & VariableListActions) => (
  <div>
    {props.useSearch && (
      <div className="e-margin-bottom-s">
        <input
          id="variable-search-input"
          className="e-input e-input-search"
          placeholder={props.translations.searchInputPlaceholder}
          type="search"
          onInput={e => props.onSearch(e.currentTarget.value)}
        />
      </div>
    )}
    {props.editableVariables.map(editableVariable => {
      if (props.search && !editableVariable.name.toLowerCase().includes(props.search.toLowerCase())) {
        return null;
      }
      return (
        <VariableEditor
          key={editableVariable.id}
          editableVariable={editableVariable}
          translations={props.translations}
          onResetClick={props.onResetClick}
          onInputChange={props.onInputChange}
          useEditableImage={props.useEditableImage}
          showVariableIDString={props.showVariableIDString}
        />
      );
    })}
  </div>
);
