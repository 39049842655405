import * as React from 'react';
import { reactCustomElementWrapper } from '../../../../lib/react-custom-element-wrapper';
import { Translations } from '../../components/variable-editor-dialog/translations.interface';

export type ImageEditorProp = {
  value: string;
  onChange: (value: string) => void;
  invalid: boolean;
  translations: Translations;
};

const EditableImageListItemComponent = reactCustomElementWrapper('vce-editable-image-list-item');

export const EditableImageListItemEditor = ({ onChange, value, invalid, translations }: ImageEditorProp): any => (
  <EditableImageListItemComponent
    value={value}
    on-update={event => onChange(event.detail.value)}
    prop-translations={translations}
  />
);
