import * as React from 'react';
import classnames from 'classnames';
import { ValidationResult } from '../state';
import { Translations } from '../translations.interface';

type NameProps = {
  name: string;
  validation: ValidationResult;
  translations: Translations['name'];
  setId: (name: string) => void;
};
export const Name = (props: NameProps): JSX.Element => (
  <div className="e-field" variable-editor-form-field="id">
    <label className="e-field__label">
      {props.translations.title}
      <e-tooltip type="helper" content={props.translations.helper} />
    </label>
    <input
      type="text"
      className={classnames(['e-input', { 'e-input-error': props.validation.isInvalid }])}
      value={props.name}
      onChange={event => props.setId(event.target.value)}
      placeholder={props.translations.placeholder}
    />
    {props.validation.isInvalid && (
      <span className="e-field__message e-field__message-error">{props.validation.errorMessage}</span>
    )}
  </div>
);
