import { State, StateType } from '../interface';
import { BLOKS_LEAVED, Action, BLOCK_SWITCHED, BLOCK_FORCED, BLOCK_ENTERED_FROM_EMPTY_SPACE } from '../actions';

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case BLOKS_LEAVED:
      return { type: StateType.off };

    case BLOCK_ENTERED_FROM_EMPTY_SPACE:
    case BLOCK_SWITCHED:
      return { type: StateType.on, block: action.payload.block };

    case BLOCK_FORCED:
      return { type: StateType.force, block: action.payload.block };

    default:
      return state;
  }
};
