import { VariablesEditorDialogAction, State, createOpenWithCreate } from '..';
import { Store } from 'react-redux';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { EpicDependencies } from '../dependencies.interface';

export const fillNewDataFromApi = (
  actions$: Observable<VariablesEditorDialogAction>,
  store: Store<State>,
  dependencies: EpicDependencies,
): Observable<VariablesEditorDialogAction> => dependencies.create$.pipe(map(createOpenWithCreate));
