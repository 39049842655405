import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { isNil } from 'ramda';

type GetIframeLeft = (mouseEnter: Observable<MouseEvent>) => Observable<any>;
export const getIframeLeft: GetIframeLeft = mouseenter =>
  mouseenter.pipe(
    filter(event => isNotToolbar(event.target as HTMLElement)),
    filter(event => isNotPreviewIframe(event.target as HTMLElement)),
  );

function isNotToolbar(element: Element | null): boolean {
  if (element == null || isNil(element)) {
    return true;
  }
  return element.id === 'toolbar' ? false : isNotToolbar(element.parentElement);
}

function isNotPreviewIframe(element: HTMLElement): boolean {
  return !element.classList.contains('e-contentblocks-preview__iframe');
}
