import { State, ValidationResults } from '../state.interface';
import * as Actions from '../actions';
import { assocPath, pipe } from 'ramda';
import { VariableConfiguration } from '@emartech/vce-domain';
import { createInitialState } from '..';
import { OpenType } from '../epics/lib/open-type';

export const openWithCreateReducer = (state: State, action: Actions.OpenWithCreate) => {
  const initialState = createInitialState();
  return pipe(
    assocPath<VariableConfiguration, State>(['variableConfiguration'], initialState.variableConfiguration),
    assocPath(['originalVariableConfiguration'], null),
    assocPath<ValidationResults, State>(['validation'], initialState.validation),
    assocPath(['openType'], OpenType.Create),
  )(state);
};
