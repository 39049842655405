import { CustomElement } from '../../../../lib/custom-element-decorators';
import { HTMLCustomElement } from '../../../../lib/html-custom-element';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { map, takeUntil, switchMap } from 'rxjs/operators';
import { fromEvent } from 'rxjs/observable/fromEvent';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { merge } from 'rxjs/observable/merge';
import { Link, LinkEditorDialog, DialogEvent, DialogWithRemoveEvent } from '../../components/link-editor-dialog';
import { ViewChild, JsonAttribute, Callback } from '../../../../lib/reactive-decorators';
import { safeGet } from '../image-properties/libs/safe-get';
import { ToolbarButton } from '../toolbar/toolbar.component';
import { translate, Translate } from '../image-properties/translate';

const getEditableId = (element: HTMLElement) => element.getAttribute('e-editable');

const getEditableClicks = pair =>
  fromEvent(pair.button, 'click').pipe(map(() => getEditableId(pair.element) as string));

type ElementWithButton = {
  element: HTMLImageElement;
  button: HTMLButtonElement;
};

export type ImageLinkEditorData = {
  [key: string]: ImageLink;
};

type ImageLink = {
  data: Link;
  readonly: boolean;
  removable: boolean;
};

type LinkDataWithEditableId = {
  linkData: Link | null;
  editableId: string;
};

export interface VcePluginImageLinkEditor extends HTMLElement, ToolbarButton {
  translations?: Object;
  linkData: ImageLinkEditorData;
}

export function createVcePluginImageLinkEditor(translate: Translate): { new (): VcePluginImageLinkEditor } {
  class VcePluginLinkEditorClass extends HTMLCustomElement {
    @JsonAttribute('translations') translations?: Object;
    @Callback('disconnectedCallback') _disconnect$: Observable<void>;
    @JsonAttribute('link-data') linkData: ImageLinkEditorData;
    @ViewChild('[link-editor-dialog]') _dialog?: LinkEditorDialog;
    private _currentButton$: Subject<ElementWithButton>;

    init(): void {
      this._currentButton$ = new Subject();
    }

    connectedCallback(): void {
      this._currentButton$
        .pipe(
          switchMap(getEditableClicks),
          switchMap(editableId => this._handleDialogClose(editableId)),
          takeUntil(this._disconnect$),
        )
        .subscribe(({ editableId, linkData }) => {
          if (linkData) {
            this.dispatchEvent(new CustomEvent('change', { detail: { editableId, linkData } }));
          } else {
            this.dispatchEvent(new CustomEvent('remove', { detail: { editableId } }));
          }
        });
    }

    getButton(image: HTMLImageElement): HTMLElement {
      const editableId = getEditableId(image);
      const buttonContainer = this._createButtonContainer(editableId, this._getLinkState(editableId));
      const button = buttonContainer.querySelector('button') as HTMLButtonElement;
      this._currentButton$.next({ element: image, button });
      return buttonContainer;
    }

    private _createButtonContainer(editableId: string | null, isDisabled: boolean | undefined): HTMLElement {
      const container = document.createElement('div');
      container.innerHTML = `
        <e-tooltip placement="bottom" content="${this._translate('link-editor-toolbar-button')}">
          <button e-btn-type="image-link-editor" class="e-btn e-btn-onlyicon e-svgclickfix"${
            isDisabled ? ' disabled="disabled"' : ''
          }>
            <e-icon icon="link"></e-icon>
          </button>
        </e-tooltip>
      `;

      return container.querySelector('e-tooltip') as HTMLElement;
    }

    private _handleDialogClose(editableId: string): Observable<LinkDataWithEditableId> {
      if (!safeGet(() => this.linkData![editableId].removable)) {
        return this._openDialogPromise(editableId);
      }
      return this._openDialogWithRemovePromise(editableId);
    }

    private _openDialogPromise(editableId: string): Observable<LinkDataWithEditableId> {
      return fromPromise(
        this._openDialog(editableId).apply.then(linkData => ({
          editableId,
          linkData,
        })),
      );
    }

    private _openDialogWithRemovePromise(editableId: string): Observable<LinkDataWithEditableId> {
      const dialogWithRemove = this._openDialogWithRemove(editableId);
      return merge(
        dialogWithRemove.remove.then(() => ({
          editableId,
          linkData: null,
        })),
        dialogWithRemove.apply.then(linkData => ({
          editableId,
          linkData,
        })),
      );
    }

    private _openDialog(editableId: string): DialogEvent {
      return this._dialog!.open(this._getLinkData(editableId));
    }

    private _openDialogWithRemove(editableId: string): DialogWithRemoveEvent {
      return this._dialog!.openWithRemove(this._getLinkData(editableId));
    }

    private _translate(key: string, parameters?: any[]): string {
      return translate(this.translations || {})(key, parameters);
    }

    private _getLinkData(editableId: string): Link {
      return (
        safeGet(() => this.linkData![editableId]!.data) || {
          title: '',
          href: '',
        }
      );
    }

    private _getLinkState(editableId: string | null): boolean | undefined {
      return safeGet(() => this.linkData[editableId!].readonly);
    }
  }

  return VcePluginLinkEditorClass;
}

const name = 'vce-plugin-image-link-editor';
CustomElement(name)(createVcePluginImageLinkEditor(translate));
