import { HTMLCustomElement } from '../../../../lib';
import { CustomElement } from '../../../../lib/custom-element-decorators';
import { V2PersonalizationState } from '../../../../lib/personalization.interface';
import { InsertTemplate, PersonalizationDialogProxy } from '../../interface';

export function createPersonalizationDialogPromptProxy(
  prompt: (message?: string | undefined, _default?: string | undefined) => string | null,
) {
  class PersonalizationDialogPromptProxyClass extends HTMLCustomElement implements PersonalizationDialogProxy {
    openDialog(insertTemplate: InsertTemplate, personalizationState?: V2PersonalizationState): void {
      const originalName = (personalizationState && personalizationState.tokenName) || '';
      const originalPreview = (personalizationState && personalizationState.preview) || '';
      const newName = prompt('Token name', originalName);
      const newPreview = prompt('Script', originalPreview);
      insertTemplate({
        ...(personalizationState || {}),
        tokenName: newName || originalName,
        preview: newPreview || originalPreview,
      });
    }
  }

  return PersonalizationDialogPromptProxyClass;
}

const name = 'vce-personalization-dialog-prompt-proxy';
CustomElement(name)(createPersonalizationDialogPromptProxy(prompt));
