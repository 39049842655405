import { fromEvent } from 'rxjs/observable/fromEvent';
import { merge } from 'rxjs/observable/merge';
import { map, first } from 'rxjs/operators';

export type Confirm = (title: string, content: string, applyLabel?: string) => Promise<boolean>;

export const confirm: Confirm = (title, content, applyLabel?) => {
  const dialog = document.createElement('e-dialog');
  dialog.setAttribute('headline', title);
  dialog.setAttribute('width', '590px');
  dialog.innerHTML = createDialogContent(content, applyLabel);
  const confirmButton = dialog.querySelector('.e-btn-primary')!;
  dialog['open']();
  return merge(
    fromEvent(dialog, 'dialog.close').pipe(map(() => false)),
    fromEvent(confirmButton, 'click').pipe(map(() => true)),
  )
    .pipe(first())
    .toPromise()
    .then(isConfirmed => {
      dialog['close']();
      return isConfirmed;
    });
};

function createDialogContent(message: string, applyLabel: string = 'Apply'): string {
  return `<p>${message}<p>
    <div class="e-dialog__footer">
      <div class="e-grid e-grid-small">
        <div class="e-cell e-cell-small">
          <button class="e-btn" data-action="close">
            Cancel
          </button>
        </div>
        <div class="e-cell e-cell-small">
          <button class="e-btn e-btn-primary">
            ${applyLabel}
          </button>
        </div>
      </div>
    </div>`;
}
