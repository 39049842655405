import { HTMLCustomElement } from '../../../../lib/html-custom-element';
import { CustomElement } from '../../../../lib/custom-element-decorators';
import { render } from 'react-dom';
import * as React from 'react';
import { ReactiveAttribute, Callback } from '../../../../lib';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IOpenMediaDB } from '../../../preview/interface';
import { fromPromise } from 'rxjs/observable/fromPromise';

export const createImageInput = (): { new (): HTMLElement } => {
  class ImageInput extends HTMLCustomElement {
    @ReactiveAttribute('value', 'value')
    private _value$: Observable<string>;
    @Callback('disconnectedCallback') private _disconnect$: Observable<void>;
    private _mediaDb: IOpenMediaDB;

    connectedCallback(): void {
      this._value$.pipe(takeUntil(this._disconnect$)).subscribe(value => render(this._render(value), this));
    }

    private _openMediaDb(): void {
      fromPromise(this._mediaDb.open())
        .pipe(takeUntil(this._disconnect$))
        .subscribe(selected => {
          this.dispatchEvent(
            new CustomEvent('update', { detail: { value: selected.basePath + selected.relativePath } }),
          );
        });
    }

    private _render(value: string): JSX.Element {
      const img = document.createElement('img');
      // Let the broswer escape the value to prevent xss
      img.setAttribute('src', value);
      img.setAttribute('style', 'max-width:200px;display:block;margin:5px 0;');
      return (
        <div>
          <div className="e-grid">
            <div className="e-cell">
              <e-tooltip disabled={!value} content={`${img.outerHTML}`} placement="top-start">
                <button className="e-btn e-btn-onlyicon">
                  <e-icon icon="picture-o" />
                </button>
              </e-tooltip>
            </div>
            <div className="e-cell e-cell-auto e-cell-sticky">
              <input className="e-input" onClick={this._openMediaDb.bind(this)} value={value} readOnly />
            </div>
          </div>
          <open-media-db ref={mediaDb => (this._mediaDb = mediaDb)} />
        </div>
      );
    }
  }
  return ImageInput;
};

const name = 'vce-image-input';
CustomElement(name)(createImageInput());
