import { Observable } from 'rxjs/Observable';
import { DomBordererAction } from './models';
import { merge } from 'rxjs/observable/merge';
import { onFocus } from './on-focus';
import { onHover } from './on-hover';
import { onForce } from './on-force';
import { onRecalculate } from './on-recalculate';

export type Actions = (
  force$: Observable<boolean>,
  blur$: Observable<HTMLElement>,
  focus$: Observable<HTMLElement>,
  iframe$: Observable<HTMLIFrameElement>,
  recalculate$: Observable<Event>,
  selector: string,
) => Observable<DomBordererAction[]>;
export const actions: Actions = (force$, blur$, focus$, iframe$, recalculate$, selector) =>
  merge(
    onFocus(focus$, blur$, selector),
    onHover(iframe$, selector),
    onForce(force$, iframe$, selector),
    onRecalculate(iframe$, recalculate$, selector),
  );
