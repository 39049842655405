import * as React from 'react';
import { reactCustomElementWrapper } from '../../../../lib/react-custom-element-wrapper';

export type DecimalEditorProp = {
  value: string;
  onChange: (value: string) => void;
};

const DecimalComponent = reactCustomElementWrapper('vce-number-input');

export const DecimalEditor = ({ onChange, value }: DecimalEditorProp): any => (
  <DecimalComponent value={value} decimal step="0.01" on-update={event => onChange(event.detail.value)} />
);
