import { CustomElement } from '../../../../lib/custom-element-decorators';
import { HtmlEditorStrategy, EditorPluginEvents } from '../../interface';
import { findPlaceholderPositions } from '@emartech/personalization-twig-token';
import { FindPlaceholders } from '../../../preview/plugins/personalization/transform-to-token';
import { HTMLCustomElement } from '../../../../lib';

export type HtmlEditorPersonalizationPlugin = HTMLElement & {
  getStrategy(): HtmlEditorStrategy;
};

export const createHtmlEditorPersonalizationPlugin = (findTokens: FindPlaceholders) => {
  class HtmlEditorPersonalizationPluginImplementation extends HTMLCustomElement
    implements HtmlEditorPersonalizationPlugin {
    connectedCallback(): void {
      this.dispatchEvent(new CustomEvent(EditorPluginEvents.Connected, { bubbles: true }));
    }

    disconnectedCallback(): void {
      this.dispatchEvent(new CustomEvent(EditorPluginEvents.Disconnected));
    }

    getStrategy(): HtmlEditorStrategy {
      return {
        findTokens: (html: string) => {
          return findTokens(html).map(token => ({
            position: {
              from: token.start,
              to: token.end,
            },
            tokenName: token.data.tokenName,
            type: 'personalization',
            tokenData: token.data,
          }));
        },
      };
    }
  }

  return HtmlEditorPersonalizationPluginImplementation;
};

const name = 'vce-html-editor-personalization-plugin';
CustomElement(name)(createHtmlEditorPersonalizationPlugin(findPlaceholderPositions));
