import { CustomElement } from '../../../../lib/custom-element-decorators';
import { HTMLCustomElement } from '../../../../lib/html-custom-element';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { map, takeUntil, switchMap } from 'rxjs/operators';
import { fromEvent } from 'rxjs/observable/fromEvent';
import { ViewChild, StringAttribute, JsonAttribute, Callback } from '../../../../lib/reactive-decorators';
import { ImagePropertiesViewsData } from './image-properties.interface';
import { safeGet } from './libs/safe-get';
import { parseTemplateImageProperties } from './parse-template-image-properties';
import { ToolbarButton } from '../toolbar/toolbar.component';
import { translate, Translate } from './translate';

const getEditableClicks = pair =>
  fromEvent(pair.button, 'click').pipe(map(() => pair.element.getAttribute('e-editable')));

export type ImagePropertiesDialog = HTMLElement & {
  open(data: ImagePropertiesViewsData, editableId?: string): Promise<ImagePropertiesViewsData>;
};

type ElementWithButton = {
  element: HTMLImageElement;
  button: HTMLButtonElement;
};
type ViewsDataWithEditableId = {
  editableId: string;
  imagePropertiesViewsData: ImagePropertiesViewsData;
};

export interface VcePluginImageProperties extends HTMLElement, ToolbarButton {
  imagesData: any;
}

export function createVcePluginImageProperties(
  getOriginalImageProperties: typeof parseTemplateImageProperties,
  translate: Translate,
): { new (): VcePluginImageProperties } {
  class VcePluginImagePropertiesClass extends HTMLCustomElement implements VcePluginImageProperties {
    @JsonAttribute('translations') translations?: Object;
    @Callback('disconnectedCallback') _disconnect$: Observable<void>;
    @StringAttribute('template') template: string;
    @JsonAttribute('images-data') imagesData: any;
    @ViewChild('[image-properties-dialog]') _dialog: ImagePropertiesDialog | null;
    private _currentButton$: Subject<ElementWithButton>;

    init(): void {
      this._currentButton$ = new Subject();
    }

    connectedCallback(): void {
      this._currentButton$
        .pipe(
          switchMap(getEditableClicks),
          switchMap(this._getModifiedImageProperties.bind(this)),
          takeUntil(this._disconnect$),
        )
        .subscribe(({ imagePropertiesViewsData, editableId }) => {
          const imageData = {
            default: safeGet(() => imagePropertiesViewsData.default.active),
          };
          this.dispatchEvent(new CustomEvent('change', { detail: { editableId, imageData } }));
        });
    }

    getButton(image: HTMLImageElement): HTMLElement {
      const container = document.createElement('div');
      container.innerHTML = `
        <e-tooltip placement="bottom" content="${this._translate('image-properties-toolbar-button')}">
          <button class="e-btn e-btn-onlyicon e-svgclickfix">
            <e-icon icon="e-image-settings"></e-icon>
          </button>
        </e-tooltip>
      `;

      const toolbarButton = container.querySelector('e-tooltip') as HTMLElement;
      const button = container.querySelector('button') as HTMLButtonElement;

      this._currentButton$.next({ element: image, button });

      return toolbarButton;
    }

    private _getImagePropertiesViewsData(editableId: string): ImagePropertiesViewsData {
      const original = getOriginalImageProperties(this.template, editableId);
      return {
        default: {
          original,
          active: safeGet(() => this.imagesData![editableId]!.default),
        },
      };
    }

    private _getModifiedImageProperties(editableId: string): Promise<ViewsDataWithEditableId> {
      return this._dialog!.open(this._getImagePropertiesViewsData(editableId), editableId).then(
        imagePropertiesViewsData => ({
          editableId,
          imagePropertiesViewsData,
        }),
      );
    }

    private _translate(key: string, parameters?: any[]): string {
      return translate(this.translations || {})(key, parameters);
    }
  }

  return VcePluginImagePropertiesClass;
}

const name = 'vce-plugin-image-properties';
CustomElement(name)(createVcePluginImageProperties(parseTemplateImageProperties, translate));
