import { createReactiveProperty } from './lib/create-reactive-property';
import { ReplaySubject } from 'rxjs/ReplaySubject';

export function ReplayCallback(callbackName: string): PropertyDecorator {
  return (target: Object, propertyKey: string): void => {
    const { subject } = createReactiveProperty({
      target: target.constructor.prototype,
      propertyKey,
      createObservable: () => new ReplaySubject(),
    });
    const oldCallback = target.constructor.prototype[callbackName] || (() => {});
    target.constructor.prototype[callbackName] = function(arg): void {
      if (this[propertyKey]) {
        this[subject].next(arg);
      }
      return oldCallback.call(this, arg);
    };
  };
}
