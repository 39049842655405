import { CustomElement } from '../../../../lib/custom-element-decorators';
import { IVcePlugin } from '../../../../lib/vce-plugin.interface';
import { JsonAttribute, Callback, StringAttribute, ViewChildren, fromEventSafe } from '../../../../lib';
import { Observable } from 'rxjs/Observable';
import { HTMLCustomElement } from '../../../../lib/html-custom-element';
import { TextEditorEventData, TextEditorProviders } from './models';
import { TextEditorsService, CreateEditor } from './services';
import { createEditorFactory, getTextEditorConfig } from './lib/editor-factory';
import { map, takeUntil, buffer, switchMap, share, withLatestFrom, filter, delay } from 'rxjs/operators';
import { fromEvent } from 'rxjs/observable/fromEvent';
import { v4 } from 'uuid';
import { initializeCleanUpPlugin, initializeTokenFormatterPlugin } from './lib/editor-factory/plugins';
import { flatten, groupBy, map as mapArray, last, toPairs, forEach, pipe } from 'ramda';
import { ChangeBlurSyncService } from './lib/change-blur-sync-service';
import { TinymceEditor } from './models/tinymce-editor';
import { ExternalTinyMcePlugin, EditableTextPlugin, ToolbarContainer } from './interfaces';
import { of } from 'rxjs/observable/of';

export * from './interfaces';
const tinymce = require('tinymce/tinymce');
const CORE_PLUGINS = [
  'paste',
  'textcolor',
  'colorpicker',
  'noneditable',
  'foreColorPatch',
  'tokenFormatterPlugin',
  'cleanUpPlugin',
];

type TinyMceEvents = Observable<TextEditorProviders>;

export interface VcePluginEditableText extends HTMLElement, IVcePlugin {
  renderedCallback: (iframe: HTMLIFrameElement) => void;
}
export function createVcePluginEditableText(positionerTagName: string): { new (): VcePluginEditableText } {
  class VcePluginEditableText extends HTMLCustomElement implements IVcePlugin {
    @StringAttribute('editable-selector') editableSelector?: string;
    @JsonAttribute('fonts') fonts?: string[];
    @JsonAttribute('font-sizes') fontSizes?: string[];
    @JsonAttribute('toolbar') toolbar?: string[];
    @ViewChildren('[external-plugin]') externalPlugins: ExternalTinyMcePlugin[];
    @ViewChildren('[editable-text-plugin]') private _editableTextPlugin: EditableTextPlugin[];
    @Callback('readyCallback') private _ready$: Observable<HTMLIFrameElement>;
    @Callback('disconnectedCallback') private _disconnect$: Observable<void>;
    private _toolbarPositioner: ToolbarContainer;
    private _toolbarContainerSelector: string;

    private _editors: TinymceEditor[] = [];

    connectedCallback(): void {
      this._iframeUnload$.pipe(takeUntil(this._disconnect$)).subscribe(() => {
        this._editors.forEach(editor => {
          editor.remove();
        });
        this._editors = [];
      });
      this._initializeToolbarPositioner();
      this._onReadyClearToolbars();
      const events$ = this._ready$.pipe(
        delay(1),
        filter(iframe => iframe.contentWindow !== null),
        switchMap(iframe => this._initializeTinyMce(iframe)),
        share(),
      ) as TinyMceEvents;
      this._onFocusOpenToolbar(events$);
      this._onBlurCloseToolbar(events$);
      this._onChangeEmitChange(events$);
      this._onContinuousChangeEmitContinuousChange(events$);
      this.dispatchEvent(new CustomEvent('plugin.connected', { bubbles: true }));
    }

    renderedCallback(iframe: HTMLIFrameElement): void {
      const style = document.createElement('style');
      style.innerHTML = '.mce-offscreen-selection { display: none; }';
      iframe.contentDocument!.head.appendChild(style);
    }

    disconnectedCallback(): void {
      this.dispatchEvent(new CustomEvent('plugin.disconnected'));
      if (this._toolbarPositioner) this._toolbarPositioner.close();
      this._editors.forEach(editor => editor.remove());
      this._editors = [];
    }

    private _onReadyClearToolbars(): void {
      this._ready$.pipe(takeUntil(this._disconnect$)).subscribe(() => {
        this._editors.forEach(editor => editor.remove());
        this._editors = [];
      });
    }

    private _initializeToolbarPositioner(): void {
      this._toolbarPositioner = document.createElement(positionerTagName) as ToolbarContainer;
      this._toolbarPositioner.preventClickOutside = true;
      const toolbarContainer = document.createElement('div');
      const id = 'toolbar-container-' + v4();
      toolbarContainer.setAttribute('id', id);
      this._toolbarContainerSelector = `#${id}`;
      this._toolbarPositioner.appendChild(toolbarContainer);
      this.appendChild(this._toolbarPositioner);
    }

    private _onFocusOpenToolbar(events$: TinyMceEvents): void {
      events$
        .pipe(
          switchMap(textEditorEvents => textEditorEvents.focuses$),
          withLatestFrom(this._ready$),
          takeUntil(this._disconnect$),
        )
        .subscribe(([{ editable }, iframeElement]) => this._toolbarPositioner.open(editable, iframeElement));
    }

    private _onBlurCloseToolbar(events$: TinyMceEvents): void {
      events$
        .pipe(
          switchMap(textEditorEvents => textEditorEvents.blurs$),
          takeUntil(this._disconnect$),
        )
        .subscribe(() => this._toolbarPositioner.close());
    }

    private _onChangeEmitChange(events$: TinyMceEvents): void {
      this._iframeClick$
        .pipe(takeUntil(this._disconnect$))
        .subscribe(e => ChangeBlurSyncService.instance.onIframeClick(e));

      events$
        .pipe(
          switchMap(events => events.blurs$),
          takeUntil(this._disconnect$),
        )
        .subscribe(e => ChangeBlurSyncService.instance.onBlur(e));

      events$
        .pipe(
          switchMap(textEditorEvents => textEditorEvents.valueChanges$),
          buffer(ChangeBlurSyncService.instance.changeBlur$),
          map(groupBy((event: TextEditorEventData) => event.editable.getAttribute('e-editable')!)),
          map(<any>mapArray(last)),
          takeUntil(this._disconnect$),
        )
        .subscribe(
          pipe(
            toPairs,
            mapArray(this._mapToCustomEventDetail()),
            forEach(detail => this.dispatchEvent(new CustomEvent('change', { detail }))),
          ),
        );
    }

    private _mapToCustomEventDetail(): any {
      return ([editableId, event]) => ({
        editableId,
        data: this._runPreUpdateCallbacks(event.editor.getContent()),
      });
    }

    private _onContinuousChangeEmitContinuousChange(events$: TinyMceEvents): void {
      events$
        .pipe(
          switchMap(textEditorEvents => textEditorEvents.continuousChanges$),
          withLatestFrom(this._ready$),
          takeUntil(this._disconnect$),
        )
        .subscribe(([{ editor }, iframeElement]) => {
          this.dispatchEvent(
            new CustomEvent('continuousChange', {
              bubbles: true,
              detail: {
                editableId: editor.editableId,
                data: this._runPreUpdateCallbacks(editor.getContent()),
                window: iframeElement.contentWindow,
              },
            }),
          );
        });
    }

    private _initializeTinyMce(iframe: HTMLIFrameElement): Promise<TextEditorProviders> {
      return this._createTextEditorsService().initialize({
        previewDocument: iframe.contentWindow!.document,
        previewWindow: iframe.contentWindow!,
        editables: this._getEditables(iframe),
      }) as Promise<TextEditorProviders>;
    }

    private _createTextEditorsService(): TextEditorsService {
      return new TextEditorsService(this._getCreateEditor(), editor => {
        this._editors.push(editor);
        this.externalPlugins.forEach(plugin => {
          if (isFunction(plugin.setupTinyMceEditor)) {
            plugin.setupTinyMceEditor(editor);
          }
        });
      });
    }

    private _getCreateEditor(): CreateEditor {
      const tinymcePlugins = this._concatTinyMcePlugins();
      return createEditorFactory(
        getTextEditorConfig(this.fonts || [], this.fontSizes || [], tinymcePlugins, this._toolbarContainerSelector),
        this.toolbar || [],
      );
    }

    private _concatTinyMcePlugins(): string[] {
      return [...CORE_PLUGINS, ...this._getInternalPlugins(), ...this._getExternalPlugins()];
    }

    private _getInternalPlugins(): string[] {
      [initializeCleanUpPlugin, initializeTokenFormatterPlugin].forEach(plugin => plugin());
      return ['cleanUpPlugin', 'tokenFormatterPlugin'];
    }

    private _getExternalPlugins(): string[] {
      return flatten<string>(
        this.externalPlugins.map(
          plugin => (isFunction(plugin.getTinyMcePlugin) ? plugin.getTinyMcePlugin(tinymce) : []),
        ),
      );
    }

    private _getEditables(iframe: HTMLIFrameElement): Element[] {
      return Array.from(iframe.contentWindow!.document.body.querySelectorAll(this.editableSelector || '')).filter(
        element => !element.classList.contains('mce-content-body'),
      );
    }

    private _runPreUpdateCallbacks(content: string): string {
      return this._editableTextPlugin.reduce(
        (decoratedContent, plugin) => plugin.preUpdateCallback(decoratedContent),
        content,
      );
    }

    private get _iframeClick$(): Observable<MouseEvent> {
      return this._ready$.pipe(switchMap(iframe => fromEvent<MouseEvent>(iframe.contentWindow!.document, 'click')));
    }

    private get _iframeUnload$(): Observable<BeforeUnloadEvent> {
      return this._ready$.pipe(
        filter(iframe => iframe.contentWindow !== null),
        switchMap(iframe => (iframe.contentWindow !== null ? fromEventSafe(iframe.contentWindow, 'unload') : of({}))),
      ) as Observable<BeforeUnloadEvent>;
    }
  }
  return VcePluginEditableText;
}

function isFunction(object: any): object is Function {
  return !!(object && object.constructor && object.call && object.apply);
}

CustomElement('vce-plugin-editable-text')(createVcePluginEditableText('e-vce-positioner-editable'));
