import { HTMLCustomElement } from '../../../../lib/html-custom-element';
import { CustomElement } from '../../../../lib/custom-element-decorators';
import { render } from 'react-dom';
import * as React from 'react';
import { ReactiveAttribute, Callback } from '../../../../lib';
import { Observable, Subject, ReplaySubject, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  EditableImagePopup,
  EditableImagePopupTranslations,
} from '../../react-components/editable-image-popup/editable-image-popup';
import { combineLatest } from 'rxjs/observable/combineLatest';

export type Translation = EditableImagePopupTranslations;

export const createEditableImageListItem = (): { new (): HTMLElement } => {
  class EditableImageListItem extends HTMLCustomElement {
    @ReactiveAttribute('value', 'value')
    private _value$: Observable<string>;
    private _isPopoverOpen$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private _popover$: Subject<HTMLElement> = new Subject();
    private _popoverHandler$: ReplaySubject<HTMLElement> = new ReplaySubject(1);
    @ReactiveAttribute('translations', 'translations', JSON.parse)
    private _translations$: Observable<Translation>;

    @Callback('disconnectedCallback') private _disconnect$: Observable<void>;

    connectedCallback(): void {
      combineLatest(this._value$, this._isPopoverOpen$, this._translations$)
        .pipe(takeUntil(this._disconnect$))
        .subscribe(([value, isPopoverOpen, translations]) =>
          render(this._render(value, isPopoverOpen, translations), this),
        );
      this._initPopover();
    }

    private _onApply = (value: string) => {
      this.dispatchEvent(new CustomEvent('update', { detail: { value } }));
      this._closePopover();
    };

    private _onCancel = () => {
      this._closePopover();
    };

    private _initPopover = () => {
      combineLatest(this._popover$, this._popoverHandler$)
        .pipe(takeUntil(this._disconnect$))
        .subscribe(([popover, popoverHandler]) => {
          popoverHandler['removeOnDestroy'] = true;
          popoverHandler['adjustWidth'] = true;
          // @ts-ignore because popover is a property that design systems overwrote.  Also popover is deprecated in design systems
          popoverHandler['popover'] = popover;
          popoverHandler.addEventListener('open', (event: any) => {
            event.stopPropagation();
            this._isPopoverOpen$.next(true);
          });
        });
      this.addEventListener('popover.connect', (event: any) => {
        event.stopPropagation();
      });
    };

    private _closePopover = () => {
      this._popoverHandler$.subscribe(popoverHandler => {
        popoverHandler['opened'] = false;
        this._isPopoverOpen$.next(false);
      });
    };

    private _refPopover = popover => this._popover$.next(popover);

    private _refPopoverHandler = popoverHandler => this._popoverHandler$.next(popoverHandler);

    private _render(value: string, isPopoverOpen: boolean, translations: Translation): JSX.Element {
      const img = document.createElement('img');
      // Let the broswer escape the value to prevent xss
      img.setAttribute('src', value);
      img.setAttribute('style', 'max-width:200px;display:block;margin:5px 0;');

      return (
        <div>
          <e-popover-handler ref={this._refPopoverHandler}>
            <div className="e-inputgroup">
              <e-tooltip disabled={!value} content={`${img.outerHTML}`} placement="top-start">
                <button className="e-btn e-btn-onlyicon e-inputgroup__item e-inputgroup__item-first">
                  <e-icon icon="picture-o" />
                </button>
              </e-tooltip>
              <input
                className="e-input e-inputgroup__item e-inputgroup__item-fluid e-inputgroup__item-last"
                value={value}
                readOnly
              />
            </div>
          </e-popover-handler>
          <EditableImagePopup
            value={value}
            isOpen={isPopoverOpen}
            onApply={this._onApply}
            onCancel={this._onCancel}
            translations={translations}
            popoverRef={this._refPopover}
          />
        </div>
      );
    }
  }
  return EditableImageListItem;
};

const name = 'vce-editable-image-list-item';
CustomElement(name)(createEditableImageListItem());
