import { DomBordererRemoveAction, DomBordererCreateAction, DomBordererActionType, DomBordererType } from './models';
import { Observable } from 'rxjs/Observable';
import { merge } from 'rxjs/observable/merge';
import { map } from 'rxjs/operators';

export type OnFocus = (
  focus$: Observable<HTMLElement>,
  blur$: Observable<HTMLElement>,
  selector: string,
) => Observable<(DomBordererRemoveAction | DomBordererCreateAction)[]>;
export const onFocus: OnFocus = (focus$, blur$, selector) =>
  merge(
    focus$.pipe(
      map(editable => [
        {
          element: editable as HTMLElement,
          type: DomBordererType.focus,
          actionType: DomBordererActionType.create,
        } as DomBordererCreateAction,
      ]),
    ),
    blur$.pipe(
      map(editable => [
        {
          element: editable as HTMLElement,
          type: DomBordererType.focus,
          actionType: DomBordererActionType.remove,
        } as DomBordererRemoveAction,
      ]),
    ),
  );
