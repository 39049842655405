import { VariableConfiguration } from '@emartech/vce-domain';
import { Translations } from '../translations.interface';
import { ValidationResult } from './state.interface';
import { Action } from 'redux';

export const OPEN_WITH_EDIT = 'VARIABLE_EDITOR_DIALOG_OPEN_WITH_EDIT';
export interface OpenWithEdit extends Action {
  type: 'VARIABLE_EDITOR_DIALOG_OPEN_WITH_EDIT';
  payload: { variableConfiguration: VariableConfiguration };
}

export const createOpenWithEdit = (variableConfiguration: VariableConfiguration): OpenWithEdit => ({
  type: OPEN_WITH_EDIT,
  payload: { variableConfiguration },
});

export const OPEN_WITH_COPY = 'VARIABLE_EDITOR_DIALOG_OPEN_WITH_COPY';
export interface OpenWithCopy extends Action {
  type: 'VARIABLE_EDITOR_DIALOG_OPEN_WITH_COPY';
  payload: { variableConfiguration: VariableConfiguration };
}
export const createOpenWithCopy = (variableConfiguration: VariableConfiguration): OpenWithCopy => ({
  type: OPEN_WITH_COPY,
  payload: { variableConfiguration },
});

export const OPEN_WITH_CREATE = 'VARIABLE_EDITOR_DIALOG_OPEN_WITH_CREATE';
export interface OpenWithCreate extends Action {
  type: 'VARIABLE_EDITOR_DIALOG_OPEN_WITH_CREATE';
}
export const createOpenWithCreate = (): OpenWithCreate => ({
  type: OPEN_WITH_CREATE,
});

export const CONFIRM = 'VARIABLE_EDITOR_DIALOG_CONFIRM';
export interface Confirm extends Action {
  type: 'VARIABLE_EDITOR_DIALOG_CONFIRM';
  payload: { changedFieldNames: string[] };
}
export const createConfirm = (changedFieldNames: string[]): Confirm => ({
  type: CONFIRM,
  payload: { changedFieldNames },
});

export const SUBMIT_FORM = 'VARIABLE_EDITOR_DIALOG_SUBMIT_FORM';
export interface SubmitForm extends Action {
  type: 'VARIABLE_EDITOR_DIALOG_SUBMIT_FORM';
}
export const createSubmitForm = (): SubmitForm => ({
  type: SUBMIT_FORM,
});

export const CONFIRMED_SUBMIT_FORM = 'VARIABLE_EDITOR_DIALOG_CONFIRMED_SUBMIT_FORM';
export interface ConfirmedSubmitForm extends Action {
  type: 'VARIABLE_EDITOR_DIALOG_CONFIRMED_SUBMIT_FORM';
}
export const createConfirmedSubmitForm = (): ConfirmedSubmitForm => ({
  type: CONFIRMED_SUBMIT_FORM,
});

export const SET_DISPLAY_NAME = 'VARIABLE_EDITOR_DIALOG_SET_DISPLAY_NAME';
export interface SetDisplayName extends Action {
  type: 'VARIABLE_EDITOR_DIALOG_SET_DISPLAY_NAME';
  payload: {
    displayName: string;
  };
}
export const createSetDisplayName = (displayName: string): SetDisplayName => ({
  type: SET_DISPLAY_NAME,
  payload: { displayName },
});

export const SET_ID = 'VARIABLE_EDITOR_DIALOG_SET_ID';
export interface SetId extends Action {
  type: 'VARIABLE_EDITOR_DIALOG_SET_ID';
  payload: {
    id: string;
  };
}
export const createSetId = (id: string): SetId => ({
  type: SET_ID,
  payload: { id },
});

export const SET_TRANSLATIONS = 'VARIABLE_EDITOR_DIALOG_SET_TRANSLATIONS';
export interface SetTranslations extends Action {
  type: 'VARIABLE_EDITOR_DIALOG_SET_TRANSLATIONS';
  payload: {
    translations: Translations;
  };
}
export const createSetTranslations = (translations: Translations): SetTranslations => ({
  type: SET_TRANSLATIONS,
  payload: { translations },
});

export const SET_TYPE = 'VARIABLE_EDITOR_DIALOG_SET_TYPE';
export interface SetType extends Action {
  type: 'VARIABLE_EDITOR_DIALOG_SET_TYPE';
  payload: {
    type: string;
  };
}
export const createSetType = (type: string): SetType => ({
  type: SET_TYPE,
  payload: { type },
});

export const SET_DEFAULT_VALUE = 'VARIABLE_EDITOR_DIALOG_SET_DEFAULT_VALUE';
export interface DefaultValue extends Action {
  type: 'VARIABLE_EDITOR_DIALOG_SET_DEFAULT_VALUE';
  payload: {
    defaultValue: string;
    typeConfig?: any;
  };
}
export const createSetDefaultValue = (defaultValue: string, typeConfig?: any): DefaultValue => ({
  type: SET_DEFAULT_VALUE,
  payload: { defaultValue, typeConfig },
});

export const SET_IS_OVERRIDABLE = 'VARIABLE_EDITOR_DIALOG_SET_IS_OVERRIDABLE';
export interface IsOverridable extends Action {
  type: 'VARIABLE_EDITOR_DIALOG_SET_IS_OVERRIDABLE';
  payload: {
    isOverridable: boolean;
  };
}
export const createSetIsOverridable = (isOverridable: boolean): IsOverridable => ({
  type: SET_IS_OVERRIDABLE,
  payload: { isOverridable },
});

export const SET_RESERVED_IDS = 'VARIABLE_EDITOR_DIALOG_SET_RESERVED_IDS';
export interface SetReservedIds extends Action {
  type: 'VARIABLE_EDITOR_DIALOG_SET_RESERVED_IDS';
  payload: {
    ids: string[];
  };
}
export const createFillReservedIds = (ids: string[]): SetReservedIds => ({
  type: SET_RESERVED_IDS,
  payload: { ids },
});

export const SET_DEFAULT_VALUE_VALIDATION = 'VARIABLE_EDITOR_DIALOG_SET_DEFAULT_VALUE_VALIDATION';
export interface SetDefaultValueValidation extends Action {
  type: 'VARIABLE_EDITOR_DIALOG_SET_DEFAULT_VALUE_VALIDATION';
  payload: ValidationResult;
}
export const createSetDefaultValueValidation = (
  isInvalid: boolean,
  errorMessage: string,
): SetDefaultValueValidation => ({
  type: SET_DEFAULT_VALUE_VALIDATION,
  payload: { isInvalid, errorMessage },
});

export const SET_USE_EDITABLE_IMAGE = 'VARIABLE_EDITOR_DIALOG_SET_USE_EDITABLE_IMAGE';
export interface SetUseEditableImage extends Action {
  type: 'VARIABLE_EDITOR_DIALOG_SET_USE_EDITABLE_IMAGE';
  payload: { useEditableImage: boolean };
}
export const createSetUseEditableImage = (useEditableImage: boolean): SetUseEditableImage => ({
  type: SET_USE_EDITABLE_IMAGE,
  payload: { useEditableImage },
});

export const SET_STRING_EDITABLE = 'VARIABLE_EDITOR_DIALOG_SET_STRING_EDIITABLE';
export interface SetStringEditable extends Action {
  type: 'VARIABLE_EDITOR_DIALOG_SET_STRING_EDIITABLE';
  payload: { stringEditable: boolean };
}
export const createSetStringEditable = (stringEditable: boolean): SetStringEditable => {
  return {
    type: SET_STRING_EDITABLE,
    payload: { stringEditable },
  };
};

export type VariablesEditorDialogAction =
  | OpenWithEdit
  | OpenWithCopy
  | OpenWithCreate
  | SubmitForm
  | ConfirmedSubmitForm
  | SetDisplayName
  | SetId
  | SetType
  | DefaultValue
  | SetReservedIds
  | IsOverridable
  | Confirm
  | SetTranslations
  | SetDefaultValueValidation
  | SetUseEditableImage
  | SetStringEditable;
