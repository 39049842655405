import { head, last } from 'ramda';
const tinymce = require('tinymce/tinymce');

const WRAPPER_CLASS = 'selectionWrapper';
const WRAPPER_ELEMENT_SELECTOR = `span.${WRAPPER_CLASS}`;

export class SelectionFormatter {
  private _editor;
  private _selectionWrapper: HTMLSpanElement;

  static create(editor): SelectionFormatter {
    return new SelectionFormatter(editor);
  }

  constructor(editor) {
    this._editor = editor;
  }

  wrapSelection(selectedContent: string): SelectionFormatter {
    this._editor.selection.getBookmark();
    this._editor.selection.setNode(this._editor.dom.create('span', { class: WRAPPER_CLASS }, selectedContent));
    this._selectionWrapper = head(<HTMLSpanElement[]>this._editor.dom.select(WRAPPER_ELEMENT_SELECTOR))!;
    this._editor.selection.select(this._selectionWrapper);
    return this;
  }

  formatTokens(callback): SelectionFormatter {
    Array.from(this._selectionWrapper.querySelectorAll('span.cbNonEditable')).forEach((token: HTMLSpanElement) => {
      token.setAttribute('contenteditable', 'false');
      this._editor.selection.select(token);
      callback(token);
    });

    return this;
  }

  restoreSelection(): SelectionFormatter {
    const selectionWrappers: HTMLSpanElement[] = this._editor.dom.select(WRAPPER_ELEMENT_SELECTOR);
    const range = document.createRange();
    range.setStartBefore(head(selectionWrappers)!);
    range.setEndAfter(last(selectionWrappers)!);
    this._editor.selection.setRng(range);
    selectionWrappers.forEach(selectionWrapper => selectionWrapper.removeAttribute('class'));
    return this;
  }

  removeBookmarks(): void {
    const rootElement: HTMLElement = this._editor.getBody();
    const children: Node[] = Array.from(rootElement.querySelectorAll('*'));
    children.forEach(element => {
      if (tinymce.dom.BookmarkManager.isBookmarkNode(element)) {
        this._editor.dom.remove(element, false);
      }
    });
  }
}
