import { cleanUpBookmarks } from './bookmark-cleanup';
import { getLinkSelection } from './get-link-selection';
import { findParent } from './find-parent';
import { Link } from '../../components/link-editor-dialog';
import { SelectionType, LinkSelection } from '../editable-text/models';
import { TinymceEditor } from '../editable-text/models';

export type GetSetupInnerLinkEditorHookOptions = {
  tooltip: string;
  openDialog: (link: Link) => Promise<Link>;
};

export type GetSetupInnerLinkEditorHook = (editor: TinymceEditor, options: GetSetupInnerLinkEditorHookOptions) => void;

export const getSetupInnerLinkEditorHook: GetSetupInnerLinkEditorHook = (editor, options) => {
  editor.addButton('linkEditor', {
    tooltip: options.tooltip,
    stateSelector: 'a',
    onclick: () => {
      cleanUpBookmarks(editor);
      const linkSelection = getLinkSelection(editor);
      options
        .openDialog({ href: linkSelection.link.attributes.href, title: linkSelection.link.attributes.title })
        .then(updateContent(editor, linkSelection));
    },
    onpostrender: handleButtonStateOnChange(editor),
  });
};

function handleButtonStateOnChange(editor: TinymceEditor): Function {
  return function(this: any): void {
    const button: any = this;
    editor.on('NodeChange', () => {
      if (!button) return;
      button!.disabled(isDisabled(editor));
    });
  };
}

function isDisabled(editor: TinymceEditor): boolean {
  const selectedNode = editor.selection.getNode();
  const cursorIsOnLink = selectedNode.nodeName === 'A' || findParent(selectedNode, 'a') !== null;
  const nothingIsSelected = editor.selection.isCollapsed();
  return nothingIsSelected && !cursorIsOnLink;
}

function updateContent(editor: TinymceEditor, linkSelection: LinkSelection): (linkData: Link) => void {
  return linkData => {
    editor.focus(false);
    editor.selection.moveToBookmark(editor.selection.getBookmark());
    if (linkSelection.type === SelectionType.linkSelected) {
      if (linkData.href === '') {
        removeLink(editor);
      } else {
        updateLink(editor, linkSelection, linkData);
      }
    }
    if (linkSelection.type === SelectionType.textSelected) {
      insertLink(editor, linkData);
    }
  };
}
function insertLink(editor: TinymceEditor, linkData: Link): void {
  editor.execCommand('mceInsertLink', false, linkData);
}

function removeLink(editor: TinymceEditor): void {
  (editor.execCommand as any)('Unlink');
}

function updateLink(editor: TinymceEditor, linkSelection: LinkSelection, linkData: Link): void {
  (editor.focus as any)();
  editor.dom.setAttribs(linkSelection.link.element, linkData);
  editor.selection.select(linkSelection.link.element);
}
