import { fromEventSafe as fromEvent, CustomElement } from '../../../../lib/index';
import { HTMLCustomElement } from '../../../../lib/html-custom-element';
import { appendBody as defaultAppendBody } from '../../../../lib/append-body';
import { map, takeUntil, switchMap, filter } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { Callback, StringAttribute } from '../../../../lib/reactive-decorators';
import { getScrollTop } from '../../../../lib/get-scroll-top';
import { setScrollTop } from '../../../../lib/set-scroll-top';
import { equals } from 'ramda';
import * as defaultDomCommands from '../../lib/dom-commands';
import * as defaultDomPositions from '../../lib/dom-positions';

export interface VceIframe extends HTMLElement {
  content: string;
  loaded: boolean | undefined;
  iframeWidth: string | undefined;
  extraClass: string | undefined;
  iframeElement: HTMLIFrameElement | null;
  reorderNodesInContainer: (selector: string, order: string[]) => void;
  insertNodeBefore: (selector: string, content: string) => void;
  insertNodeInContainer: (selector: string, content: string) => void;
  updateNode: (selector: string, content: string) => void;
  deleteNode: (selector: string) => void;
  setContainerHtml: (selector: string, content: string) => void;
}

export interface VceIframeCustomEvent {
  detail: VceIframe;
}

export function createVcePluginIframe(
  appendBody = defaultAppendBody,
  domCommands = defaultDomCommands,
  domPositions = defaultDomPositions,
): { new (): VceIframe } {
  class VceIframe extends HTMLCustomElement implements VceIframe {
    @Callback('disconnectedCallback') _disconnect$: Observable<void>;
    content: string;
    loaded: boolean | undefined;

    private _iframeWidth?: string;
    private _extraClass?: string;

    @StringAttribute('iframe-width')
    set iframeWidth(value: string | undefined) {
      this._iframeWidth = value;
      if (this.iframeElement) this._setIframeWidth();
    }

    @StringAttribute('extra-class')
    set extraClass(value: string | undefined) {
      this._extraClass = value;
      if (this.iframeElement) this._setExtraClass();
    }

    get iframeElement(): HTMLIFrameElement | null {
      return this.children.length > 0 ? (this.children[0] as HTMLIFrameElement) : null;
    }

    get scrollTop(): number {
      return getScrollTop(this.iframeElement!);
    }

    set scrollTop(value: number) {
      setScrollTop(this.iframeElement!, value);
    }

    connectedCallback(): void {
      this._createIframe();
      const iframeLoad$ = appendBody(this.iframeElement!, this.content).pipe(takeUntil(this._disconnect$));

      iframeLoad$.pipe(filter(equals('interactive'))).subscribe(() => {
        this.dispatchEvent(new CustomEvent('iframe.create', { detail: this, bubbles: true }));
      });

      iframeLoad$
        .pipe(filter(equals('complete')))
        .pipe(switchMap(() => this._iframeElementError$))
        .subscribe(error => this.dispatchEvent(new CustomEvent('iframe.error', { detail: error, bubbles: true })));

      iframeLoad$.pipe(filter(equals('complete'))).subscribe(() => {
        this.loaded = true;
        this.dispatchEvent(new CustomEvent('iframe.load', { detail: this, bubbles: true }));
      });
    }

    deleteNode(selector: string): void {
      if (this.iframeElement) {
        domCommands.deleteNode(this.iframeElement!.contentWindow!.document, selector);
      }
    }

    updateNode(selector: string, content: string): void {
      if (this.iframeElement) {
        domCommands.updateNode(this.iframeElement!.contentWindow!.document, selector, content);
      }
    }

    reorderNodesInContainer(selector: string, order: string[]): void {
      if (this.iframeElement) {
        domPositions.keepWindowPosition(this.iframeElement!.contentWindow!, () =>
          domCommands.reorderNodesInContainer(this.iframeElement!.contentWindow!.document, selector, order),
        );
      }
    }

    insertNodeInContainer(selector: string, content: string): void {
      if (this.iframeElement) {
        domCommands.insertNodeInContainer(this.iframeElement!.contentWindow!.document, selector, content);
      }
    }

    insertNodeBefore(selector: string, content: string): void {
      if (this.iframeElement) {
        domCommands.insertNodeBefore(this.iframeElement.contentWindow!.document, selector, content);
      }
    }

    setContainerHtml(selector: string, content: string): void {
      if (this.iframeElement) {
        domCommands.setContainerHtml(this.iframeElement.contentWindow!.document, selector, content);
      }
    }

    private _createIframe(): void {
      const iframe = document.createElement('iframe') as HTMLIFrameElement;
      iframe.src = 'about:blank';
      iframe.style.overflowX = 'hidden';
      this.appendChild(iframe);
      this._setIframeWidth();
      this._setExtraClass();
    }

    private _setIframeWidth(): void {
      this.iframeElement!.style.width = this._iframeWidth === undefined ? '100%' : this._iframeWidth;
    }

    private _setExtraClass(): void {
      this.iframeElement!.className = this._extraClass || '';
    }

    private get _iframeElementError$(): Observable<{ iframe: HTMLIFrameElement; exception: Error }> {
      return fromEvent(this.iframeElement!.contentWindow!, 'error').pipe(
        map((exception: Error) => ({
          iframe: this.iframeElement!,
          exception: exception,
        })),
      );
    }
  }
  return VceIframe;
}

export const vceIframeName = 'vce-iframe';
CustomElement(vceIframeName)(createVcePluginIframe(defaultAppendBody, defaultDomCommands, defaultDomPositions));
