import { TinymceEditor } from '../editable-text/models';

export type TrackEvent = (action: string, label?: string) => void;
export type CommandTracker = (editor: TinymceEditor) => TinymceEditor;
export type CommandTrackerFactory = (trackEvent: TrackEvent) => CommandTracker;

export const commandTrackerFactory: CommandTrackerFactory = trackEvent => editor => {
  editor.on('BeforeExecCommand', event => {
    const trackData = _transformCommandEventToTrackerData(event.command, event.value);
    if (trackData) trackEvent(trackData.action, trackData.label);
  });

  return editor;
};

function _transformCommandEventToTrackerData(command: string, value: string): any {
  let trackId = _mapCommandToTrackId(command, value);

  return trackId
    ? {
        action: 'editedText',
        label: trackId,
      }
    : null;
}

function _mapCommandToTrackId(command: string, value: string): string {
  let trackId: string | null = null;

  switch (command) {
    case 'RemoveFormat':
      trackId = 'removeformat';
      break;
    case 'ForeColor':
      trackId = 'forecolor';
      break;
    case 'FontName':
      trackId = 'fontfamily';
      break;
    case 'FontSize':
      trackId = 'fontsize';
      break;
    case 'mceToggleFormat':
      trackId = value;
      break;
    case 'mceInsertContent':
      trackId = _getTokenType(value);
      break;
    case 'mceInsertLink':
      trackId = 'linkinsert';
      break;
  }

  return trackId!;
}

function _getTokenType(value: string): string {
  const node$ = new DOMParser().parseFromString(value, 'text/html');
  let trackId: string | null = null;

  switch (true) {
    case !!node$.querySelector('[e-personalization]'):
      trackId = 'personalization';
      break;
    case !!node$.querySelector('[e-conditional]'):
      trackId = 'conditional';
      break;
  }

  return trackId!;
}
