import { State, ValidationResult } from '../state.interface';
import * as Actions from '../actions';
import { assocPath, pipe } from 'ramda';
import { validateDefaultValue, validateId } from '../validations';
import { VariableConfiguration } from '@emartech/vce-domain';
import { OpenType } from '../epics/lib/open-type';

export const openWithEditReducer = (state: State, action: Actions.OpenWithEdit) =>
  pipe(
    assocPath<VariableConfiguration, State>(['variableConfiguration'], action.payload.variableConfiguration),
    assocPath<VariableConfiguration, State>(['originalVariableConfiguration'], action.payload.variableConfiguration),
    assocPath<ValidationResult, State>(
      ['validation', 'id'],
      validateId(
        action.payload.variableConfiguration.id,
        state.reservedIds,
        action.payload.variableConfiguration.id,
        state.translations.validationErrors,
      ),
    ),
    state =>
      state.useEditableImage
        ? { ...state }
        : assocPath<ValidationResult, State>(
            ['validation', 'default_value'],
            validateDefaultValue(
              action.payload.variableConfiguration.default_value,
              state.translations.validationErrors,
            ),
            state,
          ),
    assocPath<OpenType, State>(['openType'], OpenType.Edit),
  )(state);
