import * as React from 'react';
import { CustomElement } from '../../../../lib/custom-element-decorators';
import { ViewChild, ReactiveAttribute, Callback } from '../../../../lib';
import { HTMLCustomElement } from '../../../../lib/html-custom-element';
import { render } from 'react-dom';
import { reactCustomElementWrapper } from '../../../../lib/react-custom-element-wrapper';
import { Observable, Subject } from 'rxjs';
import { EDialog, Locale, ChangeMasterDialogReturnData } from '../../interface';
import { map, startWith, takeUntil, withLatestFrom } from 'rxjs/operators';
import { combineLatest } from 'rxjs/observable/combineLatest';
import { createAnalyticsEvent } from '../../lib/create-analytics-event';
import { Translations } from './translations.interface';
import { Memoize } from 'typescript-memoize';
import { defaultTranslations } from './translations.default';
import { mergeDeepRight } from 'ramda';

const Select = reactCustomElementWrapper('e-select');
type State = {
  locales: Locale[];
  targetMaster: Locale | null;
  master: string;
};
type DialogProps = {
  applyBtnDisabled: boolean;
};

const VceLanguageName = reactCustomElementWrapper('vce-language-name');

export const createLanguagesChangeMasterDialog = (dialogName: string) => {
  const EDialog = reactCustomElementWrapper(dialogName);

  class LanguagesChangeMasterDialog extends HTMLCustomElement {
    @ViewChild(dialogName) eDialog: EDialog;
    @ReactiveAttribute('locales', 'locales', JSON.parse)
    private _locales$: Observable<Locale[]>;
    @ReactiveAttribute('master', 'master')
    private _master$: Observable<string>;
    @ReactiveAttribute('translations', 'translations', JSON.parse)
    private _translations$: Observable<Translations>;
    private _openMasterChangeDialog$ = new Subject();
    private _changeMaster$ = new Subject();
    private _apply: (masterLocale: Locale) => void;
    @Callback('disconnectedCallback') private _disconnect$: Observable<void>;

    private _state: State = {
      locales: [],
      targetMaster: null,
      master: '',
    };

    @Memoize()
    private get _getTranslations$(): Observable<Translations> {
      return this._translations$.pipe(
        startWith(defaultTranslations),
        map(mergeDeepRight<Translations>(defaultTranslations)),
      );
    }

    connectedCallback(): void {
      combineLatest(this._locales$, this._master$)
        .pipe(takeUntil(this._disconnect$))
        .subscribe(([locales, master]) => this._setState({ locales, master }));

      this._changeMaster$
        .pipe(
          takeUntil(this._disconnect$),
          withLatestFrom(this._getTranslations$),
        )
        .subscribe(([_, translations]) => render(this._render({ applyBtnDisabled: false }, translations), this));

      this._openMasterChangeDialog$
        .pipe(
          takeUntil(this._disconnect$),
          withLatestFrom(this._getTranslations$),
        )
        .subscribe(([_, translations]) => render(this._render({ applyBtnDisabled: true }, translations), this));

      this.dispatchEvent(new CustomEvent('connected', { bubbles: true }));
    }

    change(): ChangeMasterDialogReturnData {
      this._openMasterChangeDialog$.next();
      this.eDialog.open();
      return { apply: new Promise(resolve => (this._apply = resolve)) };
    }

    private _render(props: DialogProps, translations: Translations): JSX.Element {
      return (
        <EDialog headline={translations.dialogHeadline}>
          <div className="e-field">
            <label className="e-field__label">{translations.languageSelectLabel}</label>
            <Select placeholder={translations.languageSelectPlaceholder} on-change={this._changeMaster.bind(this)}>
              {this._localeOptions}
            </Select>
          </div>
          <div className="e-notice e-notice-withicon">
            <span className="e-notice__icon">
              <e-icon icon="e-info-circle" />
            </span>
            {translations.dialogNotice}
          </div>
          <div className="e-dialog__footer">
            <div className="e-grid e-grid-small">
              <div className="e-cell e-cell-small">
                <button className="e-btn" data-action="close">
                  {translations.dialogButtonCancel}
                </button>
              </div>
              <div className="e-cell e-cell-small">
                <button
                  disabled={props.applyBtnDisabled}
                  type="submit"
                  className="e-btn e-btn-primary"
                  onClick={this._applyDialog.bind(this)}
                >
                  {translations.dialogButtonOk}
                </button>
              </div>
            </div>
          </div>
        </EDialog>
      );
    }

    private _applyDialog(): void {
      this._apply(this._state.targetMaster as Locale);
      this.dispatchEvent(
        createAnalyticsEvent(
          'Changed_MasterLanguage',
          `ChangedMasterLanguage_${this._state.master}_${this._state.targetMaster!.key}`,
        ),
      );
      this.eDialog.close();
    }

    private _changeMaster(event): void {
      this._setState({
        targetMaster: this._state.locales.find(locale => locale.key === event.detail.value),
      });
      this._changeMaster$.next();
    }

    private get _localeOptions(): any {
      return this._state.locales.map(locale => (
        <e-select-option value={locale.key} key={locale.key} selected={this._isMaster(locale.key)}>
          <VceLanguageName prop-ietfCode={locale.key} />
        </e-select-option>
      ));
    }

    private _setState(args: Partial<State>): void {
      this._state = { ...this._state, ...args };
    }

    private _isMaster(locale: string): boolean {
      return locale === this._state.master;
    }
  }
  return LanguagesChangeMasterDialog;
};

export const languagesChangeMasterDialogTagName = 'vce-languages-change-master-dialog';
CustomElement(languagesChangeMasterDialogTagName)(createLanguagesChangeMasterDialog('e-dialog'));
