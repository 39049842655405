import { TextEditorEventData } from '../../models';
import { TextEditorEvents } from '../text-editor-events';
import { Observable } from 'rxjs/Observable';
import { merge } from 'rxjs/observable/merge';
import { TextEditorProviders } from '../../models';

export const createTextEditorProviders = (textEditorEvents: TextEditorEvents[]): TextEditorProviders => ({
  externalButtonClicks$: provideExternalButtonClicks(textEditorEvents),
  valueChanges$: provideValueChanges(textEditorEvents),
  continuousChanges$: provideContinuousChanges(textEditorEvents),
  blurs$: provideBlurs(textEditorEvents),
  focuses$: provideFocuses(textEditorEvents),
  dragEnter$: provideDragEnter(textEditorEvents),
  dragLeave$: provideDragLeave(textEditorEvents),
  dragOver$: provideDragOver(textEditorEvents),
  drop$: provideDrop(textEditorEvents),
});

function provideExternalButtonClicks(textEditorEvents: TextEditorEvents[]): Observable<TextEditorEventData> {
  return merge(...textEditorEvents.map(textEditorEvents => textEditorEvents.externalButtonClick$));
}

function provideValueChanges(textEditorEvents: TextEditorEvents[]): Observable<TextEditorEventData> {
  return merge(...textEditorEvents.map(textEditorEvents => textEditorEvents.valueChange$));
}

function provideContinuousChanges(textEditorEvents: TextEditorEvents[]): Observable<TextEditorEventData> {
  return merge(...textEditorEvents.map(textEditorEvents => textEditorEvents.continuousChange$));
}

function provideBlurs(textEditorEvents: TextEditorEvents[]): Observable<TextEditorEventData> {
  return merge(...textEditorEvents.map(textEditorEvents => textEditorEvents.blur$));
}

function provideFocuses(textEditorEvents: TextEditorEvents[]): Observable<TextEditorEventData> {
  return merge(...textEditorEvents.map(textEditorEvents => textEditorEvents.focus$));
}

function provideDragEnter(textEditorEvents: TextEditorEvents[]): Observable<TextEditorEventData> {
  return merge(...textEditorEvents.map(textEditorEvents => textEditorEvents.dragEnter$));
}

function provideDragLeave(textEditorEvents: TextEditorEvents[]): Observable<TextEditorEventData> {
  return merge(...textEditorEvents.map(textEditorEvents => textEditorEvents.dragLeave$));
}

function provideDragOver(textEditorEvents: TextEditorEvents[]): Observable<TextEditorEventData> {
  return merge(...textEditorEvents.map(textEditorEvents => textEditorEvents.dragOver$));
}

function provideDrop(textEditorEvents: TextEditorEvents[]): Observable<TextEditorEventData> {
  return merge(...textEditorEvents.map(textEditorEvents => textEditorEvents.drop$));
}
