import * as React from 'react';
import { Translations } from '../translations.interface';

type TypeProps = {
  type: string;
  translations: Translations['types'];
  setType: (name: string) => void;
};
export const Type = (props: TypeProps): JSX.Element => (
  <div className="e-field" variable-editor-form-field="type">
    <label className="e-field__label">{props.translations.title}</label>
    <e-select>
      <select className="e-select" onChange={event => props.setType(event.target.value)} value={props.type}>
        <option value="color">{props.translations.color}</option>
        <option value="image">{props.translations.image}</option>
        <option value="decimal">{props.translations.decimal}</option>
        <option value="integer">{props.translations.integer}</option>
        <option value="string">{props.translations.string}</option>
        <option value="select">{props.translations.select}</option>
      </select>
    </e-select>
  </div>
);
