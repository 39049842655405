import { HTMLCustomElement } from '../../../../lib/html-custom-element';
import { CustomElement } from '../../../../lib/custom-element-decorators';
import { ReactiveAttribute, ViewChild, Callback } from '../../../../lib/index';
import { Observable } from 'rxjs/Observable';
import { takeUntil, map, startWith, withLatestFrom } from 'rxjs/operators';
import { fromEvent } from 'rxjs/observable/fromEvent';
import { merge } from 'rxjs/observable/merge';

export const createNumberInput = () => {
  class NumberInput extends HTMLCustomElement {
    @ReactiveAttribute('value', 'value')
    private _value$: Observable<string>;

    @ReactiveAttribute('step', 'step', Number)
    private _step$: Observable<number>;

    @ViewChild('input') private _input: HTMLInputElement;
    @Callback('disconnectedCallback') private _disconnect$: Observable<void>;

    connectedCallback(): void {
      this._createContent();
      this._preventIllegalCharacters();

      this._value$.pipe(takeUntil(this._disconnect$)).subscribe(value => {
        if (this._input.value !== value) {
          this._input.value = value;
        }
      });

      this._step$
        .pipe(
          startWith(1),
          takeUntil(this._disconnect$),
        )
        .subscribe(step => this._input.setAttribute('step', step.toString()));

      this._onInternalValueChange$.pipe(takeUntil(this._disconnect$)).subscribe(currentValue => {
        this.dispatchEvent(new CustomEvent('update', { detail: { value: currentValue } }));
      });
    }

    private _preventIllegalCharacters(): void {
      merge(fromEvent(this._input, 'input'), fromEvent(this._input, 'keyup'))
        .pipe(
          takeUntil(this._disconnect$),
          withLatestFrom(this._value$.pipe(startWith(''))),
        )
        .subscribe(([event, lastValidValue]: [any, string]) => {
          if (!event.target.validity.valid) {
            event.target.value = lastValidValue;
          }
        });
    }

    private get _onInternalValueChange$(): Observable<string> {
      return fromEvent(this._input, 'input').pipe(map((event: any) => event.target.value));
    }

    private _createContent(): void {
      this.innerHTML = '';
      const input = window.document.createElement('input');
      input.setAttribute('type', 'number');
      input.setAttribute('step', '1');
      input.classList.add('e-input');
      input.classList.add('variables-editor-input');
      this.appendChild(input);
    }
  }
  return NumberInput;
};

const name = 'vce-number-input';
CustomElement(name)(createNumberInput());
