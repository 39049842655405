import * as React from 'react';
import { reactCustomElementWrapper } from '../../../../lib/react-custom-element-wrapper';

export type ColorEditorProp = {
  value: string;
  onChange: (value: string) => void;
};

const ColorPickerComponent = reactCustomElementWrapper('vce-color-input');

export const ColorEditor = ({ onChange, value }: ColorEditorProp): any => (
  <ColorPickerComponent value={value} on-update={event => onChange(event.detail.value)} />
);
