import { Memoize } from 'typescript-memoize';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { getValidBlurEvents } from '../valid-blur-events/index';
import { fromEvent } from 'rxjs/observable/fromEvent';
import { map } from 'rxjs/operators/map';
import { TextEditorEventData } from '../../models/text-editor-event-data/index';
import { merge } from 'rxjs/observable/merge';

export class ChangeBlurSyncService {
  @Memoize()
  static get instance(): ChangeBlurSyncService {
    return new ChangeBlurSyncService();
  }

  private _iframeClick$ = new Subject<MouseEvent>();
  private _blur$ = new Subject<TextEditorEventData>();

  @Memoize()
  get changeBlur$(): Observable<boolean> {
    return getValidBlurEvents(this._everyClick$, this._blur$);
  }

  onIframeClick(obj: MouseEvent): void {
    this._iframeClick$.next(obj);
  }

  onBlur(obj: TextEditorEventData): void {
    this._blur$.next(obj);
  }

  private get _everyClick$(): Observable<EventTarget> {
    return merge(this._iframeClick$, fromEvent<MouseEvent>(window.document, 'click')).pipe(
      map(({ target }) => target!),
    );
  }
}
