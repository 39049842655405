import { State, ValidationResult } from '../state.interface';
import * as Actions from '../actions';
import { assocPath, pipe } from 'ramda';
import { validateDefaultValue } from '../validations';

export const setDefaultValueReducer = (state: State, action: Actions.DefaultValue) =>
  pipe(
    assocPath<string, State>(['variableConfiguration', 'default_value'], action.payload.defaultValue),
    state =>
      state.useEditableImage
        ? { ...state }
        : assocPath<ValidationResult, State>(
            ['validation', 'default_value'],
            validateDefaultValue(action.payload.defaultValue, state.translations.validationErrors),
            state,
          ),
    assocPath<any, State>(['variableConfiguration', 'type_config'], action.payload.typeConfig),
  )(state);
