export function sanitizeDataSVGImages(DOMPurify, config) {
  const sdsvgic = new sanitizeDataSVGImagesClass(DOMPurify, config);
  DOMPurify.addHook('uponSanitizeElement', sdsvgic.uponSanitizeElement);
  DOMPurify.addHook('afterSanitizeElements', sdsvgic.afterSanitizeElements);
  DOMPurify.addHook('uponSanitizeAttribute', sdsvgic.uponSanitizeAttribute);
}

export class sanitizeDataSVGImagesClass {
  private replaceArray: string[] = [];
  private cssRegex = new RegExp(/url\(["']data:image\/svg\+xml;utf8,<svg.*\);/);
  private attrRegex = new RegExp(/data:image\/svg\+xml;utf8,<svg.*/);
  private DOMPurify: any;
  private config: any;

  constructor(DOMPurify, config) {
    this.DOMPurify = DOMPurify;
    this.config = config;
  }

  uponSanitizeElement = (node: Element, data) => {
    if (data.tagName === 'style' && data.allowedTags[data.tagName]) {
      do {
        node.innerHTML = node.innerHTML.replace(this.cssRegex, match => {
          const total = this.replaceArray.push(this.DOMPurify.sanitize(match, this.config));
          return `|||${total - 1}|||`;
        });
      } while (this.cssRegex.test(node.innerHTML));
    }
  };

  afterSanitizeElements = (node: Element) => {
    if (node.tagName === 'STYLE') {
      const length = this.replaceArray.length;
      for (let x = 0; x < length; x++) {
        const element = this.replaceArray.pop() as string;
        node.innerHTML = node.innerHTML.replace(`|||${x}|||`, element);
      }
    }
  };

  uponSanitizeAttribute = (currentNode: Element, hookEvent) => {
    const match = hookEvent.attrValue.match(this.attrRegex);
    if ((hookEvent.attrName === 'src' || hookEvent.attrName === 'style') && match) {
      currentNode.setAttribute(hookEvent.attrName, this.DOMPurify.sanitize(hookEvent.attrValue, this.config));
      hookEvent.forceKeepAttr = true;
    }
  };
}
