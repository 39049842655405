import * as React from 'react';
import { CustomElement } from '../../../../lib/custom-element-decorators';
import App from './components/app';
import { Subject } from 'rxjs/Subject';
import { State } from './state';
import { Provider } from 'react-redux';
import { VariableConfiguration } from '@emartech/vce-domain';
import { ViewChild, ReactiveAttribute, Callback, attributeToBoolean } from '../../../../lib';
import { Observable } from 'rxjs/Observable';
import { merge } from 'rxjs/observable/merge';
import { combineLatest } from 'rxjs/observable/combineLatest';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { createDialogStore } from './state/create-store';
import { Translations } from './translations.interface';
import { defaultTranslations } from './translations.default';
import { confirm } from '../../../../lib/confirm';
import { HTMLCustomElement } from '../../../../lib/html-custom-element';
import { render } from 'react-dom';
import { Store } from 'redux';
import { Memoize } from 'typescript-memoize';
import { mergeDeepRight } from 'ramda';

type EDialog = HTMLElement & { open: () => void; close: () => void; headline: string };

export type ReturnData = {
  apply: Promise<VariableConfiguration>;
};

export const createVariableEditorDialog = () => {
  class VariableEditorDialog extends HTMLCustomElement {
    @ViewChild('e-dialog') eDialog: EDialog;
    @ReactiveAttribute('reserved-ids', 'reservedIds', JSON.parse)
    private _reservedIds$: Observable<string[]>;
    @ReactiveAttribute('use-editable-image', 'useEditableImage', attributeToBoolean)
    private _useEditableImage$: Observable<boolean>;
    @ReactiveAttribute('translations', 'translations', JSON.parse)
    private _translations$: Observable<Translations>;

    @ReactiveAttribute('string-editable', 'stringEditable', attributeToBoolean)
    private _stringEditable$: Observable<boolean>;

    @Callback('disconnectedCallback') private _disconnect$: Observable<void>;
    private _edit$: Subject<VariableConfiguration>;
    private _copy$: Subject<any>;
    private _create$: Subject<any>;
    private _apply: (value: VariableConfiguration) => void;

    init(): void {
      this._edit$ = new Subject<VariableConfiguration>();
      this._copy$ = new Subject<VariableConfiguration>();
      this._create$ = new Subject<VariableConfiguration>();
    }

    connectedCallback(): void {
      const store = createDialogStore({
        edit$: this._edit$.asObservable(),
        copy$: this._copy$.asObservable(),
        create$: this._create$.asObservable(),
        reservedIds$: this._reservedIds$.pipe(startWith([])),
        useEditableImage$: this._useEditableImage$.pipe(startWith(false)),
        translations$: this._getTranslations$,
        emitStateChange: (variableConfiguration: VariableConfiguration) => {
          this.eDialog.close();
          this._emitUpdate(variableConfiguration);
        },
        trackEvent: async function trackPlugin(action): Promise<void> {},
        confirm,
        stringEditable$: this._stringEditable$,
      });

      combineLatest(this._getTranslations$, this._edit$).subscribe(
        ([translations]) => (this.eDialog.headline = translations.dialogTitleEdit),
      );
      combineLatest(this._getTranslations$, this._copy$).subscribe(
        ([translations]) => (this.eDialog.headline = translations.dialogTitleCopy),
      );
      combineLatest(this._getTranslations$, this._create$).subscribe(
        ([translations]) => (this.eDialog.headline = translations.dialogTitleCreate),
      );

      merge(this._edit$, this._copy$, this._create$, this._reservedIds$, this._getTranslations$)
        .pipe(takeUntil(this._disconnect$))
        .subscribe(() => render(this._render(store), this));

      this.dispatchEvent(new CustomEvent('connected', { bubbles: true }));
    }

    edit(variableConfiguration: VariableConfiguration): ReturnData {
      this._edit$.next(variableConfiguration);
      this.eDialog.open();
      return { apply: new Promise<VariableConfiguration>(resolve => (this._apply = resolve)) };
    }

    copy(variableConfiguration: VariableConfiguration): ReturnData {
      this._copy$.next(variableConfiguration);
      this.eDialog.open();
      return { apply: new Promise<VariableConfiguration>(resolve => (this._apply = resolve)) };
    }

    create(): ReturnData {
      this._create$.next();
      this.eDialog.open();
      return { apply: new Promise<VariableConfiguration>(resolve => (this._apply = resolve)) };
    }

    @Memoize()
    private get _getTranslations$(): Observable<Translations> {
      return this._translations$.pipe(
        startWith(defaultTranslations),
        map(mergeDeepRight<Translations>(defaultTranslations)),
      );
    }

    private _render(store: Store<State>): JSX.Element {
      return (
        <div>
          <e-dialog>
            <Provider store={store}>
              <App />
            </Provider>
          </e-dialog>
        </div>
      );
    }

    private _emitUpdate(variableConfiguration: VariableConfiguration): void {
      this._apply(variableConfiguration);
    }
  }
  return VariableEditorDialog;
};

export const variableEditorDialogTagName = 'vce-variable-editor-dialog';
CustomElement(variableEditorDialogTagName)(createVariableEditorDialog());
