import * as React from 'react';
import { CustomElement } from '../../../../lib/custom-element-decorators';
import { Observable } from 'rxjs/Observable';
import { takeUntil, map, startWith } from 'rxjs/operators';
import { Callback, ReactiveAttribute } from '../../../../lib/reactive-decorators';
import { render } from 'react-dom';
import { combineLatest } from 'rxjs/observable/combineLatest';
import { Contact, ContactEventDetail, RecentContactsEvents } from '../../interface';
import { HTMLCustomElement } from '../../../../lib';

export type RecentContactsTranslations = {
  label: string;
  preview: string;
  delete: string;
};

const getDefaultTranslation = (): RecentContactsTranslations => ({
  label: 'Recent contacts',
  preview: 'Preview',
  delete: 'Delete',
});

export type RecentContacts = HTMLElement & {
  contacts: Contact[];
  translations: RecentContactsTranslations;
};

type RenderProps = {
  contacts: Contact[];
  translations: RecentContactsTranslations;
};

export const createRecentContacts = () => {
  class RecentContactsClass extends HTMLCustomElement implements RecentContacts {
    contacts: Contact[];
    translations: RecentContactsTranslations;

    @Callback('disconnectedCallback') private _disconnect$: Observable<void>;
    @ReactiveAttribute('contacts', 'contacts', JSON.parse)
    private _contacts$: Observable<Contact[]>;

    @ReactiveAttribute('translations', 'translations', JSON.parse)
    private _translations$: Observable<Object>;

    connectedCallback(): void {
      this._inputs$.pipe(takeUntil(this._disconnect$)).subscribe(props => render(this._render(props), this));
      this.dispatchEvent(new CustomEvent(RecentContactsEvents.Connected, { bubbles: true }));
    }

    disconnectedCallback(): void {
      this.dispatchEvent(new CustomEvent(RecentContactsEvents.Disconnected));
    }

    private _render(props: RenderProps): JSX.Element {
      return (
        <div className="e-field">
          <table className="e-table" data-e-version="2">
            <thead>
              <tr>
                <th className="e-table__sort e-table__sort-desc">{props.translations.label}</th>
                <th className="e-table__col e-table__col-actions" />
              </tr>
            </thead>
            <tbody>
              {props.contacts.map((contact, index) => (
                <tr key={contact.email}>
                  <td>{contact.email}</td>

                  <td className="e-table__col e-table__col-actions">
                    <e-tooltip valign="baseline" content={props.translations.preview}>
                      <div className="e-svgclickfix" onClick={event => this._onContactSelect(contact)}>
                        <e-icon icon="eye" type="table" />
                      </div>
                    </e-tooltip>

                    <e-tooltip valign="baseline" content={props.translations.delete}>
                      <div className="e-svgclickfix" onClick={event => this._onContactRemove(contact)}>
                        <e-icon icon="trash-o" type="table" />
                      </div>
                    </e-tooltip>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }

    private get _inputs$(): Observable<RenderProps> {
      return combineLatest(
        this._contacts$.pipe(startWith([])),
        this._translations$.pipe(startWith(getDefaultTranslation())),
      ).pipe(
        map(([contacts, translations]) => ({
          contacts,
          translations: { ...getDefaultTranslation(), ...translations },
        })),
      );
    }

    private _onContactSelect(contact: Contact): void {
      this._emitSelect(contact);
    }

    private _onContactRemove(contact: Contact): void {
      this._emitRemove(contact);
    }

    private _emitSelect(contact: Contact): void {
      const type = 'select';
      const detail: ContactEventDetail = { type, contact };
      this.dispatchEvent(new CustomEvent(type, { detail }));
    }

    private _emitRemove(contact: Contact): void {
      const type = 'remove';
      const detail: ContactEventDetail = { type, contact };
      this.dispatchEvent(new CustomEvent(type, { detail }));
    }
  }
  return RecentContactsClass;
};

const name = 'vce-recent-contacts';
CustomElement(name)(createRecentContacts());
