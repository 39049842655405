import * as React from 'react';

export type SelectEditorProp = {
  value: string;
  onChange: (value: string) => void;
  type_config: { options: { value: string; name: string }[] };
};
export const SelectEditor = ({ onChange, value, type_config }: SelectEditorProp): any => (
  <e-select>
    <select className="e-select" value={value} onChange={event => onChange(event.target.value)}>
      {type_config!.options.map(option => (
        <option key={option.value} value={option.value}>
          {option.name}
        </option>
      ))}
    </select>
  </e-select>
);
