import * as React from 'react';
import { CustomElement } from '../../../../lib/custom-element-decorators';
import { ViewChild, ReactiveAttribute, Callback } from '../../../../lib';
import { HTMLCustomElement } from '../../../../lib/html-custom-element';
import { render } from 'react-dom';
import { reactCustomElementWrapper } from '../../../../lib/react-custom-element-wrapper';
import { Observable, Subject } from 'rxjs';
import { EDialog, Locale, ReturnData as DialogReturnData } from '../../interface';
import { isEmpty } from 'ramda';
import { Translations } from './translations.interface';
import { Memoize } from 'typescript-memoize';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { defaultTranslations } from './translations.default';
import { mergeDeepRight } from 'ramda';
import { combineLatest } from 'rxjs/observable/combineLatest';
import { merge } from 'rxjs/observable/merge';

const MultiSelect = reactCustomElementWrapper('e-multiselect');

type State = {
  locales: Locale[];
  targets: Locale[];
  from: Locale;
};

export const createLanguagesDialog = (dialogName: string) => {
  const EDialog = reactCustomElementWrapper(dialogName);

  class LanguagesDialog extends HTMLCustomElement {
    @ViewChild(dialogName) eDialog: EDialog;
    @ReactiveAttribute('locales', 'locales', JSON.parse)
    private _locales$: Observable<Locale[]>;
    @ReactiveAttribute('translations', 'translations', JSON.parse)
    private _translations$: Observable<Translations>;
    private _apply: (value: Locale[]) => void;
    private _targetChange$ = new Subject();
    private _copy$ = new Subject();

    private _state: State = {
      locales: [],
      targets: [],
      from: { name: '', key: '' },
    };

    @Callback('disconnectedCallback') private _disconnect$: Observable<void>;

    @Memoize()
    private get _getTranslations$(): Observable<Translations> {
      return this._translations$.pipe(
        startWith(defaultTranslations),
        map(mergeDeepRight<Translations>(defaultTranslations)),
      );
    }

    connectedCallback(): void {
      this._locales$.subscribe(locales => this._setState({ locales }));
      combineLatest([this._getTranslations$, merge(this._copy$, this._targetChange$)] as any)
        .pipe(takeUntil(this._disconnect$))
        .subscribe(([translations]) => {
          render(this._render(translations as Translations), this);
        });
      this.dispatchEvent(new CustomEvent('connected', { bubbles: true }));
    }

    copy(from: Locale): DialogReturnData {
      this._setState({ from, targets: [] });
      this._copy$.next();
      this.eDialog.open();
      return { apply: new Promise(resolve => (this._apply = resolve)) };
    }

    private _render(translations: Translations): JSX.Element {
      return (
        <EDialog headline={translations.dialogHeadline}>
          <div className="e-field">{translations.dialogText.replace('%s', this._state.from.name)}</div>
          <div className="e-field">
            <label className="e-field__label">{translations.languageSelectLabel}</label>
            <MultiSelect
              placeholder={translations.languageSelectPlaceholder}
              on-change={this._changeTargets.bind(this)}
            >
              {this._localeOptions}
            </MultiSelect>
          </div>
          <div className="e-notice e-notice-withicon">
            <span className="e-notice__icon">
              <e-icon icon="e-info-circle" />
            </span>
            {translations.dialogNotice}
          </div>
          <div className="e-dialog__footer">
            <div className="e-grid e-grid-small">
              <div className="e-cell e-cell-small">
                <button className="e-btn" data-action="close">
                  {translations.dialogButtonCancel}
                </button>
              </div>
              <div className="e-cell e-cell-small">
                <button
                  disabled={isEmpty(this._state.targets)}
                  type="submit"
                  className="e-btn e-btn-primary"
                  onClick={this._applyDialog.bind(this)}
                >
                  {translations.dialogButtonCopy}
                </button>
              </div>
            </div>
          </div>
        </EDialog>
      );
    }

    private _applyDialog(): void {
      this._apply(this._state.targets);
      this.eDialog.close();
    }

    private _changeTargets(event): void {
      this._setState({
        targets: event.target.value.map(evtVal => this._state.locales.find(locale => locale.key === evtVal.value)),
      });
      this._targetChange$.next();
    }

    private get _localeOptions(): any {
      return this._state.locales.map(locale => (
        <e-select-option value={locale.key} key={locale.key}>
          {locale.name} - {locale.key}
        </e-select-option>
      ));
    }

    private _setState(args: Partial<State>): void {
      this._state = { ...this._state, ...args };
    }
  }
  return LanguagesDialog;
};

export const languagesDialogTagName = 'vce-languages-dialog';
CustomElement(languagesDialogTagName)(createLanguagesDialog('e-dialog'));
