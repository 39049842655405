import { Translations } from './translations.interface';

export const defaultTranslations: Translations = {
  dialogHeadline: 'Copy Language Versions',
  dialogText: `Making copy from %s language version`,
  dialogNotice: 'When copying a language version, the original content will be automatically filled in.',
  languageSelectLabel: 'Select languages for the copy',
  languageSelectPlaceholder: 'Select language',
  dialogButtonCopy: 'Copy',
  dialogButtonCancel: 'Cancel',
};
