import { V2TokenDataToToken } from './token-data-to-token-v2';
import { TokenDataToToken } from './token-data-to-token';
import { reduceRight } from 'ramda';
import { V2PersonalizationState, PersonalizationState } from '../../../../lib/personalization.interface';

export type PlaceholderPosition = {
  start: number;
  end: number;
  data: V2PersonalizationState | PersonalizationState;
};

export type FindPlaceholders = (html: string) => PlaceholderPosition[];

export type TransformToToken = (html: string) => string;
export const transformToToken = (
  v2tokenDataToToken: V2TokenDataToToken,
  tokenDataToToken: TokenDataToToken,
  findplaceholders: FindPlaceholders,
): TransformToToken => html => {
  return reduceRight(replaceToToken, html, findplaceholders(html));

  function replaceToToken(placeholder: PlaceholderPosition, html: string): string {
    const beforeTwig = html.slice(0, placeholder.start);
    const afterTwig = html.slice(placeholder.end);
    const tokenHtml = placeholder.data['preview']
      ? v2tokenDataToToken(placeholder.data as V2PersonalizationState)
      : tokenDataToToken(placeholder.data as PersonalizationState);
    return beforeTwig + tokenHtml + afterTwig;
  }
};
