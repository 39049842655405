import { Variables, VariableConfiguration } from '@emartech/vce-domain';
import { State, Translations } from './state.interface';
import { ascend, prop, sortWith } from 'ramda';
import { createSelector } from 'reselect';

export type EditableVariable = {
  id: string;
  value: string;
  name: string;
  type: string;
  type_config: { options: { value: string; name: string }[] } | null;
  default_value: string;
};

export const getTranslations = (state: State): Translations => state.translations;

export const getSearch = (state: State): string => state.search;

export const getUseSearch = (state: State): boolean => state.useSearch;

export const getshowVariableIDString = (state: State): boolean => state.showVariableIDString;

export const getVariables = (state: State): Variables => state.variables;

export const getVariableConfigurations = (state: State): VariableConfiguration[] => state.variableConfigurations;

const getEditableVariables = (variables: Variables, configurations: VariableConfiguration[]): EditableVariable[] =>
  configurations.filter(configuration => configuration.is_overridable).map(configuration => ({
    id: configuration.id,
    value: configuration.id in variables ? variables[configuration.id] : configuration.default_value,
    name: configuration.display_name || configuration.id,
    type: configuration.type,
    type_config: 'type_config' in configuration ? configuration.type_config : null,
    default_value: configuration.default_value,
  }));

export const getEditableVariablesSelector = createSelector(
  [getVariables, getVariableConfigurations],
  getEditableVariables,
);

const sortByName = ascend(prop('name'));
const sortById = ascend(prop('id'));

const sortEditableVariables = sortWith<EditableVariable>([sortByName, sortById] as any);

export const getSortedEditableVariablesSelector = createSelector(
  getEditableVariablesSelector,
  (editableVariables: EditableVariable[]) => sortEditableVariables(editableVariables),
);

export const getUseEditableImage = (state: State): boolean => state.useEditableImage;
