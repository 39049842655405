import { CustomElement } from '../../../../lib/custom-element-decorators';
import { HTMLCustomElement } from '../../../../lib/html-custom-element';
import { StringAttribute, JsonAttribute } from '../../../../lib/reactive-decorators';
import { GetSetupHook, getSetupHook } from './tinymce-plugin';
import { ExternalTinyMcePlugin } from '../editable-text';
import { TinymceEditor } from '../editable-text/models/tinymce-editor/index';
import { safeGet } from '../image-properties/libs/safe-get';

const getEditableId = (element: HTMLElement, attributeName: string) => element.getAttribute(attributeName);

export interface VcePluginEditableTextCustomButtonTranslations {
  tooltip: string;
}
export interface VcePluginEditableTextCustomButton extends HTMLElement, ExternalTinyMcePlugin {
  translations?: VcePluginEditableTextCustomButtonTranslations;
  buttonName?: string;
  idAttributeName?: string;
}
export function createVcePluginEditableTextCustomButton(
  getSetupHook: GetSetupHook,
): { new (): VcePluginEditableTextCustomButton } {
  class VcePluginEditableTextCustomButtonClass extends HTMLCustomElement implements VcePluginEditableTextCustomButton {
    @JsonAttribute('translations') translations?: VcePluginEditableTextCustomButtonTranslations;
    @StringAttribute('button-name') buttonName?: string;
    @StringAttribute('id-attribute-name') idAttributeName?: string;

    connectedCallback(): void {}

    setupTinyMceEditor(editor: TinymceEditor): void {
      getSetupHook(editor, {
        buttonName: this.buttonName || '',
        tooltip: safeGet(() => this.translations!.tooltip) || 'tooltip',
        onClickCallback: (editableElement: HTMLElement) => {
          this.dispatchEvent(
            new CustomEvent('vce-plugin-editable-text-custom-button.click', {
              detail: { id: getEditableId(editableElement, this.idAttributeName || ''), button: this.buttonName },
            }),
          );
        },
      });
    }
  }

  return VcePluginEditableTextCustomButtonClass;
}

const name = 'vce-plugin-editable-text-custom-button';
CustomElement(name)(createVcePluginEditableTextCustomButton(getSetupHook));
