import { ElementWrapper } from '../utils';

export class TokenFormatRemover {
  private _editor;
  private _wrapper: ElementWrapper;

  static create(editor): TokenFormatRemover {
    return new TokenFormatRemover(editor);
  }

  constructor(editor) {
    this._editor = editor;
    this._wrapper = ElementWrapper.create(editor);
  }

  wrapElement(element: HTMLSpanElement): TokenFormatRemover {
    this._wrapper.wrap(element);
    return this;
  }

  removeFormats(): void {
    this._editor.formatter.remove('removeformat');
  }
}
