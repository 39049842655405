import { Observable } from 'rxjs';
import {
  Action,
  BlockEnteredFromEmptySpaceAction,
  ToolbarEnteredFromBLockAction,
  BlocksLeavedAction,
  BlockSwitchedAction,
  BlockForcedAction,
} from './actions';

export type Inputs = {
  blocks$: Observable<VcePluginBlockToolbarElement[]>;
  mouseleave$: Observable<void>;
  mouseenter$: Observable<void>;
  mousemove$: Observable<MouseEvent>;
  windowMouseenter$: Observable<MouseEvent>;
  scroll$: Observable<void>;
  resize$: Observable<void>;
  window$: Observable<Window>;
};

export type CreateInputs = (
  window: Window,
  iframe$: Observable<HTMLIFrameElement>,
  block$: Observable<VcePluginBlockToolbarElement[]>,
) => Inputs;

export type Calculated = {
  blocksLeaved$: Observable<BlocksLeavedAction>;
  blockSwitched$: Observable<BlockSwitchedAction>;
  toolbarEnteredFromBlock$: Observable<ToolbarEnteredFromBLockAction>;
  blockEnteredFromEmptySpace$: Observable<BlockEnteredFromEmptySpaceAction>;
  blockForced$: Observable<BlockForcedAction>;
};

export type CreateCalculated = (inputs: Inputs) => Calculated;

export type CreateActions = (calculated: Calculated) => Observable<Action>;

export enum StateType {
  on = 'ON',
  off = 'OFF',
  force = 'FORCE',
}

export type ToolbarOnState = {
  block: VcePluginBlockToolbarElement;
  type: StateType.on;
};

export type ToolbarOffState = {
  type: StateType.off;
};

export type ToolbarForcedOnState = {
  block: VcePluginBlockToolbarElement;
  type: StateType.force;
};

export type State = ToolbarOnState | ToolbarOffState | ToolbarForcedOnState;

export enum BlockToolbarItemEvents {
  Click = 'click.block-toolbar-item',
  Connected = 'connected.block-toolbar-item',
  Disconnected = 'disconnected.block-toolbar-item',
  Updated = 'updated.block-toolbar-item',
}

export enum BlockToolbarElementEvents {
  Connected = 'connected.block-toolbar-element',
  Disconnected = 'disconnected.block-toolbar-element',
  Updated = 'updated.block-toolbar-element',
}

export type CodeEditorToolbarElementInputs = { label: string; selector: string; forced: boolean };
export type CodeEditorToolbarButtonInputs = { label: string; icon: string };
export interface VcePluginBlockToolbarButton extends HTMLElement, CodeEditorToolbarButtonInputs {
  getItem: () => HTMLElement;
}
export interface VcePluginBlockToolbarElement extends HTMLElement, CodeEditorToolbarElementInputs {
  getItem: () => HTMLElement;
}
