import { State, ValidationResult } from '../state.interface';
import * as Actions from '../actions';
import { assocPath, pipe, identity, dissocPath } from 'ramda';
import { getVariableFieldDefaultValue } from '../../../../react-components/variable-field';
import { v4 } from 'uuid';

export const setTypeReducer = (state: State, action: Actions.SetType) =>
  pipe(
    () =>
      state.useEditableImage
        ? assocPath<ValidationResult, State>(
            ['validation', 'default_value'],
            { isInvalid: false, errorMessage: '' },
            state,
          )
        : { ...state },
    assocPath<string, State>(['variableConfiguration', 'type'], action.payload.type),
    assocPath<string, State>(
      ['variableConfiguration', 'default_value'],
      getVariableFieldDefaultValue(action.payload.type),
    ),
    action.payload.type === 'string'
      ? assocPath<boolean, State>(['variableConfiguration', 'is_overridable'], false)
      : identity,
    action.payload.type === 'select'
      ? assocPath<any, State>(['variableConfiguration', 'type_config'], {
          options: [{ id: v4(), name: '', value: '' }],
        })
      : dissocPath<State>(['variableConfiguration', 'type_config']),
  )(state);
