import { cleanUpBookmarks } from '../../../bookmark-cleanup';
import { complement, isNil } from 'ramda';

const tinymce = require('tinymce/tinymce');
const isAlreadyDefined = complement(isNil);

export const initializeCleanUpPlugin = () => {
  if (isAlreadyDefined(tinymce.PluginManager.get('cleanUpPlugin'))) return;

  tinymce.PluginManager.add('cleanUpPlugin', function(editor: any): void {
    editor.on('BeforeExecCommand', () => cleanUpBookmarks(editor));
    editor.on('BeforeGetContent', () => cleanUpBookmarks(editor));
  });
};
