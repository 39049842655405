import * as React from 'react';
import { EditableVariable } from '../state';

export type DisplayNameProps = {
  editableVariable: EditableVariable;
};
export const DisplayName = ({ editableVariable }: DisplayNameProps): any => (
  <label
    className="e-field__label e-field__label-inline"
    htmlFor={`variables-editor-input-${editableVariable.id}`}
    readonly-variable-name={editableVariable.id}
  >
    {editableVariable.name}
  </label>
);
