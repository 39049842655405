import * as React from 'react';
import { Translations } from '../../../components/variable-editor-dialog/translations.interface';

interface SelectOption {
  id: string;
  value: string;
  name: string;
}

interface SelectTypeConfig {
  options: SelectOption[];
}

interface SelectConfigurationComponentProps {
  value: string;
  type_config: SelectTypeConfig;
  isRemoveDisabled: boolean;
  defaultValueId: string;
  translations: Translations['defaultValue'];
  addNewOption: () => void;
  remove: (id: string) => void;
  valueChange: (id: string, value: string) => void;
  nameChange: (id: string, value: string) => void;
  defaultChange: (id: string) => void;
}

export const SelectConfigurationEditorComponent = (props: SelectConfigurationComponentProps): any => (
  <div className="e-box e-box-chart e-box-nopadding">
    <table className="e-table e-table-condensed" data-e-version="2">
      <thead className="e-table__content e-table__content-without_border">
        <tr>
          <th className="e-table__col e-table__col-xsmall">{props.translations.select.defaultColumn}</th>
          <th className="e-table__col">{props.translations.select.nameColumn}</th>
          <th className="e-table__col">{props.translations.select.valueColumn}</th>
          <th className="e-table__col e-table__col-xsmall" />
        </tr>
      </thead>
      <tbody className="e-table__content e-table__content-without_border">
        {props.type_config.options.map((option, index) => (
          <tr className="e-table__row" key={option.id}>
            <td className="e-table__col">
              <div className="text-align-center">
                <input
                  type="radio"
                  id={`SelectConfigurationEditor_${option.id}`}
                  className="e-radio e-radio-onlyradio"
                  name="defaultRadio"
                  checked={props.defaultValueId === option.id}
                  onChange={() => props.defaultChange(option.id)}
                />
                <label htmlFor={`SelectConfigurationEditor_${option.id}`} />
              </div>
            </td>
            <td className="e-table__col">
              <input
                className="e-input"
                type="text"
                defaultValue={option.name}
                placeholder={`Item ${index + 1} name`}
                onBlur={(event: any) => props.nameChange(option.id, event.target.value)}
              />
            </td>
            <td className="e-table__col">
              <input
                className="e-input"
                type="text"
                defaultValue={option.value}
                placeholder={`Item ${index + 1} value`}
                onBlur={(event: any) => props.valueChange(option.id, event.target.value)}
              />
            </td>
            <td className="e-table__col">
              <button
                disabled={props.isRemoveDisabled}
                onClick={() => props.remove(option.id)}
                className="e-btn e-btn-onlyicon"
              >
                <e-icon icon="trash-o" />
              </button>
            </td>
          </tr>
        ))}
        <tr>
          <td className="e-table__col" colSpan={3} />
          <td className="e-table__col">
            <button className="e-btn e-btn-onlyicon" onClick={props.addNewOption}>
              <e-icon icon="plus" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);
