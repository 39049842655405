import { ValidationResult } from '..';
import { ValidationErrorTranslations } from '../../translations.interface';

export const validateId = (
  value: string,
  existingIds: string[],
  originalId: string | undefined,
  translation: ValidationErrorTranslations,
): ValidationResult => {
  const isInvalid = !/^[a-zA-Z]+$/g.test(value);
  const isUniq = existingIds.filter(id => id !== originalId).indexOf(value) < 0;
  if (isInvalid)
    return {
      errorMessage: translation.wrongIdFormat,
      isInvalid: true,
    };
  if (!isUniq) return { errorMessage: translation.idAlreadyUsed, isInvalid: true };
  return { errorMessage: '', isInvalid: false };
};
