import { CustomElement } from '../../../../../lib/custom-element-decorators';
import { HTMLCustomElement, Callback, ReactiveAttribute, attributeToBoolean } from '../../../../../lib';
import { Observable } from 'rxjs/Observable';
import { take, skip, map, takeUntil } from 'rxjs/operators';
import { combineLatest } from 'rxjs/observable/combineLatest';
import {
  BlockToolbarElementEvents,
  CodeEditorToolbarElementInputs,
  VcePluginBlockToolbarButton,
  BlockToolbarItemEvents,
} from '../interface';
import { getComponentPlugins } from '../../../../../lib/component-connections';

export const createVcePluginBlockToolbarElement = () => {
  class VcePluginBlockToolbarElementClass extends HTMLCustomElement {
    @Callback('readyCallback') _ready$: Observable<HTMLIFrameElement>;
    @ReactiveAttribute('label', 'label')
    private _label$: Observable<string>;
    @ReactiveAttribute('selector', 'selector')
    private _selector$: Observable<string>;
    @ReactiveAttribute('forced', 'forced', attributeToBoolean)
    private _forced$: Observable<boolean>;
    @Callback('disconnectedCallback') private _disconnect$: Observable<void>;
    private _items: VcePluginBlockToolbarButton[];

    connectedCallback(): void {
      this._inputs$.pipe(take(1)).subscribe(() => {
        this.dispatchEvent(new CustomEvent(BlockToolbarElementEvents.Connected, { bubbles: true }));
      });
      this._items$.subscribe(_ => (this._items = _));
      combineLatest(this._inputs$.pipe(skip(1)), this._items$).subscribe(() => {
        this.dispatchEvent(new CustomEvent(BlockToolbarElementEvents.Updated, { bubbles: true }));
      });
    }

    disconnectedCallback(): void {
      this.dispatchEvent(new CustomEvent(BlockToolbarElementEvents.Disconnected));
    }

    getItem(): HTMLElement {
      const toolbar = document.createElement('div');
      const title = document.createElement('div');
      const actions = document.createElement('div');
      toolbar.classList.add('e-contentblocks-toolbar');
      toolbar.classList.add('toolbar');
      actions.classList.add('e-contentblocks-toolbar__actions');
      title.classList.add('e-contentblocks-toolbar__title');
      title.innerHTML = this.getAttribute('label')!;
      this._items.map(item => item.getItem()).forEach(button => actions.appendChild(button));
      toolbar.appendChild(title);
      toolbar.appendChild(actions);
      return toolbar;
    }

    private get _inputs$(): Observable<CodeEditorToolbarElementInputs> {
      return combineLatest(this._label$, this._selector$, this._forced$).pipe(
        takeUntil(this._disconnect$),
        map(([label, selector, forced]) => ({ label, selector, forced })),
      );
    }

    private get _items$(): Observable<VcePluginBlockToolbarButton[]> {
      return getComponentPlugins<VcePluginBlockToolbarButton>(
        this,
        this._disconnect$,
        BlockToolbarItemEvents.Connected,
        BlockToolbarItemEvents.Disconnected,
        BlockToolbarItemEvents.Updated,
      );
    }
  }

  return VcePluginBlockToolbarElementClass;
};

const name = 'vce-plugin-block-toolbar-element';
CustomElement(name)(createVcePluginBlockToolbarElement());
