import { CustomElement } from '../../../../lib/custom-element-decorators';
import { ContactPreviewApi, Contact, ApiEvents } from '../../interface';
import { map } from 'ramda';

export type Storage = {
  getItem(key: string): string | null;
  setItem(key: string, value: string): void;
};

export const createContactPreviewFakeContentPersonalizationApi = (storage: Storage) => {
  class ContactPreviewPersonalizationApiClass extends HTMLElement implements ContactPreviewApi {
    connectedCallback(): void {
      this.dispatchEvent(new CustomEvent(ApiEvents.Connected, { bubbles: true }));
    }

    disconnectedCallback(): void {
      this.dispatchEvent(new CustomEvent(ApiEvents.Disconnected));
    }

    async find(term: string): Promise<Contact[]> {
      await new Promise(resolve => setTimeout(resolve, 2000));
      if (term === 'error') {
        throw new Error(`error for ${term}`);
      }

      return [
        { id: Date.now().toString(), email: term + '@freemail.hu', firstName: 'John', lastName: 'Doe' },
        { id: Date.now().toString(), email: term + '@hotmail.com', firstName: 'Jane', lastName: 'Doe' },
        { id: Date.now().toString(), email: term + '@gmail.com', firstName: 'Jimmy', lastName: 'Doe' },
      ];
    }

    render(content: { [key: string]: string }, contact: Contact): Promise<object> {
      if (contact.email === 'error@emarsys.com') {
        return Promise.reject(new Error('322'));
      }
      const rendered = map(html => this._compile(html, contact), content);
      return Promise.resolve(rendered);
    }

    getStoredContacts(): Contact[] {
      try {
        const stored = storage.getItem('vce-contact-preview-box__recentContacts');
        return (stored && JSON.parse(stored)) || [];
      } catch (err) {
        return [];
      }
    }

    setStoredContacts(contacts: Contact[]): void {
      storage.setItem('vce-contact-preview-box__recentContacts', JSON.stringify(contacts));
    }

    private _compile(html: string, contact: Contact): string {
      return html
        .replace('{{ contact.id }}', contact.id)
        .replace('{{ contact.1 }}', contact.firstName)
        .replace('{{ contact.2 }}', contact.lastName)
        .replace('{{ contact.3 }}', contact.email);
    }
  }
  return ContactPreviewPersonalizationApiClass;
};

const name = 'vce-contact-preview-fake-content-api';
CustomElement(name)(createContactPreviewFakeContentPersonalizationApi(localStorage));
