import { CreateCalculated, VcePluginBlockToolbarElement } from '../interface';
import { getBlockUnderMouse$ } from './get-block-under-mouse';
import { filter, map, mapTo, bufferCount, first, share } from 'rxjs/operators';
import { isNil, find, complement } from 'ramda';
import { merge } from 'rxjs/observable/merge';
import {
  BlocksLeavedAction,
  BlockSwitchedAction,
  BlockForcedAction,
  BlockEnteredFromEmptySpaceAction,
  ToolbarEnteredFromBLockAction,
} from '../actions';
import { getIframeLeft } from './get-iframe-left';
import { getToolbarHover } from './get-toolbar-hover';

export const createCalculated: CreateCalculated = inputs => {
  const blocksUnderMouse$ = getBlockUnderMouse$(inputs);
  const mousemoveOutsideOfBlocks$ = merge(
    blocksUnderMouse$.pipe(filter(block => block === undefined)),
    getIframeLeft(inputs.windowMouseenter$),
  ).pipe(mapTo(undefined));
  const blockMovePairs$ = merge(mousemoveOutsideOfBlocks$, blocksUnderMouse$).pipe(
    bufferCount(2, 1),
    share(),
  );
  return {
    blocksLeaved$: blockMovePairs$.pipe(
      filter(([previous, current]) => previous !== undefined && current === undefined),
      mapTo(new BlocksLeavedAction()),
      share(),
    ),
    blockForced$: inputs.blocks$.pipe(
      map(find(block => block.forced)),
      filter<VcePluginBlockToolbarElement>(complement(isNil)),
      map(block => new BlockForcedAction({ block })),
      share(),
    ),
    blockSwitched$: blocksUnderMouse$.pipe(
      bufferCount(2, 1),
      filter(
        ([previous, current]) =>
          previous !== undefined &&
          current !== undefined &&
          previous.getAttribute('selector') !== current.getAttribute('selector'),
      ),
      map(([, block]) => new BlockSwitchedAction({ block: block! })),
      share(),
    ),
    blockEnteredFromEmptySpace$: merge(
      blocksUnderMouse$.pipe(
        first(),
        filter(block => block !== undefined),
        map(block => new BlockEnteredFromEmptySpaceAction({ block: block! })),
        share(),
      ),
      blockMovePairs$.pipe(
        filter(([previous, current]) => previous === undefined && current !== undefined),
        map(([, block]) => new BlockEnteredFromEmptySpaceAction({ block: block! })),
        share(),
      ),
    ),
    toolbarEnteredFromBlock$: getToolbarHover(inputs.windowMouseenter$).pipe(
      mapTo(new ToolbarEnteredFromBLockAction()),
      share(),
    ),
  };
};
