import { CustomElement } from '../../../../lib/custom-element-decorators';
import { ViewChild, JsonAttribute, BooleanAttribute } from '../../../../lib/reactive-decorators';
import { translate, Translate } from '../image-properties/translate';
import { getSetupPersonalizationButtonHook, GetSetupPersonalizationButtonHook } from './tinymce-plugin';
import { getSetupV3PersonalizationButtonHook, GetSetupV3PersonalizationButtonHook } from './tinymce-plugin-v3';
import { ExternalTinyMcePlugin } from '../editable-text/editable-text.component';
import { TinymceEditor } from '../editable-text/models/tinymce-editor/index';
import { PersonalizationDialogProxy, PersonalizationDialogV3Proxy } from '../../../personalization/interface';
import { HTMLCustomElement } from '../../../../lib';

export interface VcePluginPersonalizationButton extends HTMLElement, ExternalTinyMcePlugin {}

export function createVcePluginPersonalizationButton(
  translate: Translate,
  getSetupPersonalizationButtonHook: GetSetupPersonalizationButtonHook,
  getSetupV3PersonalizationButtonHook: GetSetupV3PersonalizationButtonHook,
): { new (): VcePluginPersonalizationButton } {
  class VcePluginPersonalizationButtonClass extends HTMLCustomElement implements VcePluginPersonalizationButton {
    @JsonAttribute('translations') translations?: Object;
    @BooleanAttribute('is-v3') isV3: boolean;
    @ViewChild('[personalization-dialog]') _dialog?: PersonalizationDialogProxy | PersonalizationDialogV3Proxy;

    connectedCallback(): void {}

    setupTinyMceEditor(editor: TinymceEditor): void {
      if (this.isV3) {
        getSetupV3PersonalizationButtonHook(editor, {
          tooltip: this._translate('personalization-toolbar-button'),
          openDialog: ({ callback, state }) =>
            (<PersonalizationDialogV3Proxy>this._dialog)!.openDialog({ callback, state }),
        });
      } else {
        getSetupPersonalizationButtonHook(editor, {
          tooltip: this._translate('personalization-toolbar-button'),
          openDialog: (insertTemplate, personalizationState) =>
            (<PersonalizationDialogProxy>this._dialog)!.openDialog(insertTemplate, personalizationState),
        });
      }
    }

    private _translate(key: string, parameters?: any[]): string {
      return translate(this.translations || {})(key, parameters);
    }
  }

  return VcePluginPersonalizationButtonClass;
}

const name = 'vce-plugin-personalization-button';
CustomElement(name)(
  createVcePluginPersonalizationButton(
    translate,
    getSetupPersonalizationButtonHook,
    getSetupV3PersonalizationButtonHook,
  ),
);
