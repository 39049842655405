import axios from 'axios';
import {
  CodeEditorTwigValidatorPluginValidationResult,
  TwigValidatorClientStatus,
} from '../modules/code-editor/interface';
import { isNil } from 'ramda';

export const validateTwig = async (
  code: string | null | undefined,
  url: string,
): Promise<CodeEditorTwigValidatorPluginValidationResult> => {
  let response;
  try {
    if (code == null || isNil(code) || code.trim() === '' || !code.includes('{')) {
      return createSuccessValidationResult();
    }

    const jwtToken = await window['e'].utils.getAuthenticationToken('personalization-editor');
    const headers = { Authorization: `Bearer ${jwtToken}` };
    const data = { templates: [{ name: 'template1', text: code }] };
    response = await axios.post(url, data, createNoExceptionOn400ResponseConfig(headers));
    if (response.status === 200) {
      return createSuccessValidationResult();
    }

    return createFailValidationResult(response.data.errors);
  } catch (e) {
    return createUnknownValidationResult();
  }
};

function createNoExceptionOn400ResponseConfig(headers: object): object {
  return {
    headers,
    validateStatus(status: number): boolean {
      return (status >= 200 && status < 300) || status === 400;
    },
  };
}

function createUnknownValidationResult(): CodeEditorTwigValidatorPluginValidationResult {
  return {
    status: TwigValidatorClientStatus.Unknown,
    errors: [],
  } as CodeEditorTwigValidatorPluginValidationResult;
}

function createSuccessValidationResult(): CodeEditorTwigValidatorPluginValidationResult {
  return {
    status: TwigValidatorClientStatus.Success,
    errors: [],
  } as CodeEditorTwigValidatorPluginValidationResult;
}

function createFailValidationResult(errors): CodeEditorTwigValidatorPluginValidationResult {
  return {
    status: TwigValidatorClientStatus.Fail,
    errors: errors.map(error => error.reason_text),
  } as CodeEditorTwigValidatorPluginValidationResult;
}
