import { Translations } from './translations.interface';

export const defaultTranslations: Translations = {
  dialogHeadline: 'Change Master Language',
  languageSelectLabel: 'Select',
  languageSelectPlaceholder: 'Select master language',
  dialogNotice:
    "The master version will be sent out to all contacts who do not have any other language assigned. When updating another version, the master's content will be copied over.",
  dialogButtonOk: 'OK',
  dialogButtonCancel: 'Cancel',
};
