import { State, VariablesEditorDialogAction, CONFIRMED_SUBMIT_FORM } from '..';
import { tap, ignoreElements } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { ofType } from 'redux-observable';
import { Store } from 'redux';
import { EpicDependencies } from '../dependencies.interface';

export const emitStateChangeOnConfirmedSubmit = (
  actions$: Observable<VariablesEditorDialogAction>,
  store: Store<State>,
  dependencies: EpicDependencies,
): Observable<any> =>
  actions$.pipe(
    ofType(CONFIRMED_SUBMIT_FORM),
    tap(() => dependencies.emitStateChange(store.getState().variableConfiguration)),
    ignoreElements(),
  );
