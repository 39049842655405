import { TokenDefinition, CodeMirrorTokenPosition } from '../../../interface';
import { last } from 'ramda';

export const multiline = (
  html: string,
  token: TokenDefinition,
): { from: CodeMirrorTokenPosition; to: CodeMirrorTokenPosition } => {
  const fromLines = html.substring(0, token.position.from).split('\n');
  const toLines = html.substring(0, token.position.to).split('\n');

  const from = {
    line: fromLines.length - 1,
    ch: last(fromLines)!.length,
  };

  const to = {
    line: toLines.length - 1,
    ch: last(toLines)!.length,
  };

  return { from, to };
};
