import { fromEventSafe as fromEvent, CustomElement, safariScrollFix } from '../../../../lib/index';
import { VceIframe, vceIframeName, VceIframeCustomEvent } from '../iframe/iframe.component';
import { HTMLCustomElement } from '../../../../lib/html-custom-element';
import { map, switchMap, tap, takeUntil } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { Observable } from 'rxjs/Observable';
import { Callback, StringAttribute, ReactiveAttribute } from '../../../../lib/reactive-decorators';

export interface VceIFramesContainer extends HTMLElement {}

export function createVcePluginIframesContainer(vceIframeTagName, safariScrollFix): { new (): VceIFramesContainer } {
  class VcePluginIframesContainer extends HTMLCustomElement implements VceIFramesContainer {
    @StringAttribute('iframe-width')
    set iframeWidth(value: string | undefined) {
      this._iframeWidth = value;
      this._setIFrameWidth();
    }

    @StringAttribute('extra-class')
    set extraClass(value: string | undefined) {
      this._extraClass = value;
      this._setExtraClass();
    }

    @Callback('disconnectedCallback') private _disconnect$: Observable<void>;

    @ReactiveAttribute('content') private _content$: Observable<string>;

    private _iframeWidth?: string;
    private _extraClass?: string;

    connectedCallback(): void {
      this._appendedIframe$.subscribe(vceIframe => {
        if (this._vceIframes.length > 1) this._setLastIframeScrollPosition(vceIframe);
        vceIframe.style.visibility = 'visible';
        vceIframe.style.zIndex = '';
        this._emitUnloadEventForOldIframe(this._vceIframes, vceIframe);
        this._removeOldIframes(this._vceIframes, vceIframe);
        this.dispatchEvent(new CustomEvent('iframe.change', { detail: vceIframe, bubbles: true }));
        safariScrollFix(vceIframe.iframeElement);
      });
      this._onDisconnectDestroyIframes();
    }

    private get _appendedIframe$(): Observable<VceIframe> {
      return this._content$.pipe(
        map(content => this._createVceIframe(content)),
        tap(vceIframe => this.appendChild(vceIframe)),
        switchMap((vceIframe: VceIframe) => {
          if (vceIframe.loaded) return of(vceIframe);
          return fromEvent(vceIframe, 'iframe.load').pipe(map((event: VceIframeCustomEvent) => event.detail));
        }),
        takeUntil(this._disconnect$),
      );
    }

    private get _vceIframes(): VceIframe[] {
      return Array.from(this.children) as VceIframe[];
    }

    private _onDisconnectDestroyIframes(): void {
      this._disconnect$.subscribe(() => {
        this._emitUnloadEventForOldIframe(this._vceIframes);
        this._removeOldIframes(this._vceIframes);
      });
    }

    private _setIFrameWidth(): void {
      this._vceIframes.forEach((iframe: VceIframe) => (iframe.iframeWidth = this._iframeWidth));
    }

    private _setExtraClass(): void {
      this._vceIframes.forEach((iframe: VceIframe) => (iframe.extraClass = this._extraClass));
    }

    private _createVceIframe(content: string): VceIframe {
      const vceIframe: VceIframe = document.createElement(vceIframeTagName) as VceIframe;
      vceIframe.content = content;
      vceIframe.style.visibility = 'hidden';
      vceIframe.style.zIndex = '-1';
      vceIframe.extraClass = this._extraClass;
      vceIframe.iframeWidth = this._iframeWidth;
      return vceIframe;
    }

    private _setLastIframeScrollPosition(currentIframe: VceIframe): void {
      const scrollTop = this._vceIframes[this._vceIframes.length - 2].scrollTop;
      currentIframe.scrollTop = scrollTop;
    }

    private _removeOldIframes(vceIframes: VceIframe[], currentIframe?: VceIframe): void {
      vceIframes.filter(iframe => iframe !== currentIframe).forEach((iframe: VceIframe) => iframe.remove());
    }

    private _emitUnloadEventForOldIframe(vceIframes: VceIframe[], currentIframe?: VceIframe): void {
      vceIframes
        .filter(iframe => iframe !== currentIframe)
        .forEach((iframe: VceIframe) =>
          this.dispatchEvent(new CustomEvent('iframe.unload', { detail: iframe, bubbles: true })),
        );
    }
  }
  return VcePluginIframesContainer;
}

export const vceIFramesContainerName = 'vce-iframes-container';
CustomElement(vceIFramesContainerName)(createVcePluginIframesContainer(vceIframeName, safariScrollFix));
