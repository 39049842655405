import { Observable } from 'rxjs';
import { State, StateType } from '../interface';
import { Action } from '../actions';
import { scan, distinctUntilChanged } from 'rxjs/operators';
import { reducer } from './reducer';
import { keepToolbarEffect } from '../effects';

const initialState: State = {
  type: StateType.off,
};

export const createState = (action$: Observable<Action>): Observable<State> => {
  return keepToolbarEffect(action$).pipe(
    scan(reducer, initialState),
    distinctUntilChanged(),
  );
};
