import * as React from 'react';
import { Translations } from '../state';

export interface EmptyStateProps {
  translations: Translations;
}

export const EmptyState = (props: EmptyStateProps) => (
  <div className="e-emptystate">
    <e-icon class="e-emptystate__icon" icon="e-style" />
    <div className="e-emptystate__content">
      <div className="e-emptystate__title">{props.translations.emptyStateTitle}</div>
      <div className="e-emptystate__lead">{props.translations.emptyStateLead}</div>
      <a
        className="e-helperlink"
        href="https://help.emarsys.com/hc/en-us/articles/360003076334-Email-Style-Settings"
        target="_blank"
      >
        <e-icon class="e-helperlink__icon" icon="e-helperlink" size="small" />
        <span className="e-helperlink__text">{props.translations.emptyStateLinkButton}</span>
      </a>
    </div>
  </div>
);
