import { assoc } from 'ramda';
import { LinkSelection, SelectedLink, SelectionType } from '../../models';
import { transformTokenObjectToHtml, TokenObject } from '@emartech/content-blocks-token';
import { replaceNbsp } from './replace-nbsp';
import { TinymceEditor } from '../../models/tinymce-editor/index';

export class TextEditor {
  private _editable: Element;
  private _editor: TinymceEditor;

  static create(editable: Element, editor: TinymceEditor): TextEditor {
    return new TextEditor(editable, editor);
  }

  constructor(editable: Element, editor: TinymceEditor) {
    this._editable = editable;
    this._editor = editor;
  }

  get editor(): TinymceEditor {
    return this._editor;
  }

  get blockId(): string {
    return this._editable.getAttribute('e-block-id')!;
  }

  get editableId(): string {
    return this._editable.getAttribute('e-editable')!;
  }

  get editableLinkId(): string {
    return this._editable.getAttribute('e-link-id')!;
  }

  getContent(): string {
    return this._editor
      .getContent()
      .replace(/\{\{%20/g, '{{ ')
      .replace(/{{.*}}/g, replaceNbsp)
      .replace(/{%.*%}/g, replaceNbsp);
  }

  insertContent(content: string): void {
    this._editor.insertContent(content);
  }

  insertToken(tokenObject: TokenObject): void {
    const classes = tokenObject.class ? `cbNonEditable ${tokenObject.class}` : 'cbNonEditable';
    const tokenObjectWithCbNonEditableClass = assoc('class', classes, tokenObject);
    const tokenString = transformTokenObjectToHtml(tokenObjectWithCbNonEditableClass);
    this._editor.insertContent(tokenString);
  }

  focus(skipFocus?: boolean): void {
    this._editor.focus(skipFocus || false);
  }

  moveToBookmark(): void {
    this._editor.selection.moveToBookmark(this._editor.currentBookmark);
  }

  insertLink({ type, link }: LinkSelection): void {
    if (type === SelectionType.linkSelected) {
      if (link.attributes.href === '') {
        this._removeLink();
      } else {
        this._editLink(link);
      }
    }

    if (type === SelectionType.textSelected) {
      this._editor.execCommand('mceInsertLink', false, link.attributes);
    }
  }

  private _editLink(link: SelectedLink): void {
    (this._editor.focus as any)();
    this._editor.dom.setAttribs(link.element, link.attributes);
    this._editor.selection.select(link.element);
  }

  private _removeLink(): void {
    (this._editor.execCommand as any)('Unlink');
  }
}
