import { CustomElement } from '../../../../lib/custom-element-decorators';
import { Observable } from 'rxjs/Observable';
import { Callback, ReactiveAttribute } from '../../../../lib/reactive-decorators';
import { SimpleSearchField } from '../simple-search-field/index';
import { RecentContacts } from '../recent-contacts/index';
import { SearchResults } from '../search-results/index';
import { fromEvent } from 'rxjs/observable/fromEvent';
import {
  takeUntil,
  debounceTime,
  map,
  share,
  switchMap,
  scan,
  withLatestFrom,
  filter,
  startWith,
  shareReplay,
  tap,
} from 'rxjs/operators';
import 'rxjs/add/observable/of';
import { uniqBy } from 'ramda';
import {
  Contact,
  ContactEventDetail,
  ContactPreviewApi,
  ContactElementEventDetail,
  HTMLRenderEvent,
  SimpleSearchFieldEvents,
  ApiEvents,
  RecentContactsEvents,
  SearchResultsEvents,
} from '../../interface';
import { merge } from 'rxjs/observable/merge';
import { Memoize } from 'typescript-memoize';
import { combineLatest } from 'rxjs/observable/combineLatest';
import { getComponentPlugin } from '../../../../lib/component-connections';
import { HTMLCustomElement } from '../../../../lib';

type ContactEvent = CustomEvent & {
  detail: ContactEventDetail;
};

export const createContactPreview = (searchDebounceTime: number, minimumSearchTermLength: number) => {
  class ContactPreviewBox extends HTMLCustomElement {
    @Callback('disconnectedCallback') private _disconnect$: Observable<void>;

    @ReactiveAttribute('html', 'html')
    private _html$: Observable<string>;

    @ReactiveAttribute('content', 'content', JSON.parse)
    private _content$: Observable<object>;

    connectedCallback(): void {
      combineLatest(this._getSearchContactStream(), this._searchResults$)
        .pipe(takeUntil(this._disconnect$))
        .subscribe(([contactList, searchResults]) => {
          searchResults!.contacts = contactList;
        });
      combineLatest(this._addContact$, this._searchResults$)
        .pipe(takeUntil(this._disconnect$))
        .subscribe(([contact, searchResults]) => {
          searchResults!.contacts = [];
        });
      combineLatest(this._contactList$, this._recentContacts$, this._contactPreviewApi$)
        .pipe(takeUntil(this._disconnect$))
        .subscribe(([contactList, recentContacts, contactPreviewApi]) => {
          recentContacts!.contacts = contactList;
          contactPreviewApi.setStoredContacts(contactList);
        });
      combineLatest(this._recentContacts$, this._contactPreviewApi$)
        .pipe(takeUntil(this._disconnect$))
        .subscribe(([recentContacts, contactPreviewApi]) => {
          recentContacts!.contacts = contactPreviewApi.getStoredContacts();
        });
      this._htmlRender$.pipe(takeUntil(this._disconnect$)).subscribe(renderEvent => this._emitRender(renderEvent));
      this._contentRender$.pipe(takeUntil(this._disconnect$)).subscribe(renderEvent => this._emitRender(renderEvent));
    }

    @Memoize()
    private get _contactList$(): Observable<Contact[]> {
      const setContactList$ = this._contactPreviewApi$.pipe(
        map(contactPreviewApi => ({
          type: 'set',
          contactList: contactPreviewApi.getStoredContacts(),
        })),
      );

      return merge(this._addContact$, this._removeContact$, setContactList$).pipe(
        scan((contactList: Contact[], event: ContactEventDetail): Contact[] => {
          switch (event.type) {
            case 'add':
              return uniqBy(contact => contact.email, contactList.concat(event.contact));

            case 'set':
              return event.contactList;

            case 'remove':
              return contactList.filter(contact => contact !== event.contact);
          }

          return contactList;
        }, []),
        startWith([]),
        shareReplay(),
      );
    }

    @Memoize()
    private get _searchField$(): Observable<SimpleSearchField> {
      return getComponentPlugin<SimpleSearchField>(
        this,
        this._disconnect$,
        SimpleSearchFieldEvents.Connected,
        SimpleSearchFieldEvents.Disconnected,
        SimpleSearchFieldEvents.Updated,
      ).pipe(filter(_ => !!_));
    }

    @Memoize()
    private get _searchResults$(): Observable<SearchResults> {
      return getComponentPlugin<SearchResults>(
        this,
        this._disconnect$,
        SearchResultsEvents.Connected,
        SearchResultsEvents.Disconnected,
        SearchResultsEvents.Updated,
      ).pipe(filter(_ => !!_));
    }

    @Memoize()
    private get _recentContacts$(): Observable<RecentContacts> {
      return getComponentPlugin<RecentContacts>(
        this,
        this._disconnect$,
        RecentContactsEvents.Connected,
        RecentContactsEvents.Disconnected,
        RecentContactsEvents.Updated,
      ).pipe(filter(_ => !!_));
    }

    @Memoize()
    private get _contactPreviewApi$(): Observable<ContactPreviewApi> {
      return getComponentPlugin<ContactPreviewApi>(
        this,
        this._disconnect$,
        ApiEvents.Connected,
        ApiEvents.Disconnected,
        ApiEvents.Updated,
      ).pipe(filter(_ => !!_));
    }

    @Memoize()
    private get _htmlRender$(): Observable<HTMLRenderEvent> {
      return this._selectedContact$.pipe(
        withLatestFrom(this._html$, this._contactPreviewApi$),
        switchMap(([contact, html, contactPreviewApi]) => {
          return contactPreviewApi
            .render(html as any, contact)
            .then(compiledTemplate => ({ type: 'success', detail: compiledTemplate as any }))
            .catch(error => ({ type: 'error', detail: error }));
        }),
      );
    }

    @Memoize()
    private get _contentRender$(): Observable<HTMLRenderEvent> {
      return this._selectedContact$.pipe(
        withLatestFrom(this._content$, this._contactPreviewApi$),
        switchMap(([contact, content, contactPreviewApi]) => {
          return contactPreviewApi
            .render(content, contact)
            .then(compiledTemplate => ({ type: 'success', detail: compiledTemplate }))
            .catch(error => ({ type: 'error', detail: error }));
        }),
      );
    }

    @Memoize()
    private get _selectedContact$(): Observable<Contact> {
      return this._selectContact$.pipe(
        withLatestFrom(this._contactList$),
        map(([contactEvent, contactList]) => contactList.find(contact => contact.email === contactEvent.contact.email)),
        filter<Contact>(contact => !!contact),
      );
    }

    @Memoize()
    private get _searchTerm$(): Observable<string> {
      return this._searchField$.pipe(
        switchMap(searchField => fromEvent<CustomEvent>(searchField, 'search')),
        map(event => event.detail),
        filter(eventDetail => eventDetail.length >= minimumSearchTermLength),
        debounceTime(searchDebounceTime),
      );
    }

    @Memoize()
    private get _addContact$(): Observable<ContactElementEventDetail> {
      return this._createObservableFromChildContactEvent(this._searchResults$, 'add');
    }

    @Memoize()
    private get _selectContact$(): Observable<ContactElementEventDetail> {
      return this._createObservableFromChildContactEvent(this._recentContacts$, 'select');
    }

    @Memoize()
    private get _removeContact$(): Observable<ContactElementEventDetail> {
      return this._createObservableFromChildContactEvent(this._recentContacts$, 'remove');
    }

    private _createObservableFromChildContactEvent(
      child$: Observable<HTMLElement>,
      eventName: string,
    ): Observable<ContactElementEventDetail> {
      return child$.pipe(
        switchMap(child => fromEvent<ContactEvent>(child!, eventName)),
        map(event => event.detail),
        share(),
      );
    }

    private _emitRender(renderEvent: HTMLRenderEvent): void {
      const { type, detail } = renderEvent;
      this.dispatchEvent(new CustomEvent(`render.${type}`, { detail }));
    }

    private _getSearchContactStream(): Observable<Contact[]> {
      return combineLatest(this._searchField$, this._searchTerm$, this._contactPreviewApi$).pipe(
        takeUntil(this._disconnect$),
        tap(([searchField]) => (searchField.loading = true)),
        switchMap(([searchField, searchTerm, contactPreviewApi]) => {
          return contactPreviewApi
            .find(searchTerm)
            .catch(err => [])
            .then(contactList => ({ contactList, searchField }));
        }),
        tap(({ searchField }) => (searchField.loading = false)),
        map(({ contactList }) => contactList),
      );
    }
  }
  return ContactPreviewBox;
};

const name = 'vce-contact-preview';
const searchDebounceTime = 200;
const minimumSearchTermLength = 4;
CustomElement(name)(createContactPreview(searchDebounceTime, minimumSearchTermLength));
