import { Observable } from 'rxjs';
import { Action, BLOCK_ENTERED_FROM_EMPTY_SPACE } from '../actions';
import { filter, debounceTime, switchMap, startWith } from 'rxjs/operators';

type KeepToolbarEffect = (actions$: Observable<Action>) => Observable<Action>;
export const keepToolbarEffect: KeepToolbarEffect = actions$ =>
  actions$.pipe(
    filter(action => action.type === BLOCK_ENTERED_FROM_EMPTY_SPACE),
    switchMap(action =>
      actions$.pipe(
        debounceTime(300),
        startWith(action),
      ),
    ),
  );
