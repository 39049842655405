import {
  DomBordererAction,
  DomBordererRecalculateAction,
  DomBordererCreateAction,
  DomBordererRemoveAction,
  DomBordererActionType,
} from './models';
import { Render } from './render';
import { forEach } from 'ramda';

const isRemove = (action: DomBordererAction) => action.actionType === DomBordererActionType.remove;
const isCreate = (action: DomBordererAction) => action.actionType === DomBordererActionType.create;
const isRecalculate = (action: DomBordererAction) => action.actionType === DomBordererActionType.recalculate;

export type ApplyActions = (render: Render) => (actions: DomBordererAction[]) => void;
export const applyActions: ApplyActions = ({
  createBorder,
  getBorder,
  getBorders,
  hasBorders,
  updateBorder,
  hideOutline,
  restoreOutline,
}) => actions => {
  actions
    .filter(isCreate)
    .map((action: DomBordererCreateAction) => createBorder(action.element, action.type))
    .forEach(border => border.ownerDocument.body.appendChild(border));

  actions.filter(isCreate).forEach((action: DomBordererCreateAction) => hideOutline(action.element));

  actions
    .filter(isRemove)
    .map((action: DomBordererRemoveAction) => getBorder(action.element, action.type))
    .filter(borderer => !!borderer)
    .forEach(borderer => borderer!.remove());

  actions
    .filter(isRemove)
    .filter((action: DomBordererRemoveAction) => !hasBorders(action.element))
    .forEach((action: DomBordererRemoveAction) => restoreOutline(action.element));

  actions
    .filter(isRecalculate)
    .map((action: DomBordererRecalculateAction) => getBorders(action.element))
    .forEach(forEach(updateBorder));
};
