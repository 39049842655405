import * as React from 'react';
import { State } from '../state';
import * as Actions from '../state/actions';
import * as Selectors from '../state/selectors';
import { connect } from 'react-redux';
import { EmptyState } from './empty-state';
import { VariableListProps, VariableList, VariableListActions } from './variable-list';

type AppProps = VariableListProps & VariableListActions;
export const App = (props: AppProps) =>
  props.editableVariables.length > 0 ? <VariableList {...props} /> : <EmptyState translations={props.translations} />;

const mapDispatch = {
  onInputChange: Actions.createChangeValue,
  onResetClick: Actions.createReset,
  onSearch: Actions.createSetSearch,
};

const mapState = (state: State): VariableListProps => ({
  translations: Selectors.getTranslations(state),
  editableVariables: Selectors.getSortedEditableVariablesSelector(state),
  useEditableImage: Selectors.getUseEditableImage(state),
  search: Selectors.getSearch(state),
  useSearch: Selectors.getUseSearch(state),
  showVariableIDString: Selectors.getshowVariableIDString(state),
});

export default connect<VariableListProps, VariableListActions, {}>(
  mapState,
  mapDispatch,
)(App);
