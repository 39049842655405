import { CustomElement } from '../../../../lib/custom-element-decorators';
import { Observable } from 'rxjs/Observable';
import { combineLatest } from 'rxjs/observable/combineLatest';
import { Callback, HTMLCustomElement } from '../../../../lib/index';
import { getComponentPlugins } from '../../../../lib/component-connections/index';
import { Memoize } from 'typescript-memoize';
import {
  CodeEditorToolbarContext,
  CodeEditorToolbarEvents,
  ToolbarItemEvents,
  CodeEditorToolbarItem,
} from '../../interface';
import { takeUntil } from 'rxjs/operators';

export type CodeEditorToolbar = HTMLElement & {
  setContext: (context: CodeEditorToolbarContext) => void;
};

export type CodeEditorToolbarConnectedEvent = {
  target: CodeEditorToolbar;
};

export const createCodeEditorToolbar = () => {
  class CodeEditorToolbarImplementation extends HTMLCustomElement {
    @Callback('disconnectedCallback') private _disconnect$: Observable<void>;
    @Callback('setContext') private _context$: Observable<CodeEditorToolbarContext>;
    private _toolbarContainer: HTMLElement;

    connectedCallback(): void {
      this._createItemsContainer();
      combineLatest(this._context$, this._connectedItems$)
        .pipe(takeUntil(this._disconnect$))
        .subscribe(([context, items]) => this._render(context, items));
      this.dispatchEvent(new CustomEvent(CodeEditorToolbarEvents.Connected, { bubbles: true }));
    }

    private _render(context, buttons): void {
      this._toolbarContainer.innerHTML = '';
      buttons
        .map(buttonContainer => buttonContainer.getItem(context))
        .forEach(button => this._toolbarContainer.appendChild(button));
    }

    private _createItemsContainer(): void {
      this._cleanupContainer('.code-editor-toolbar-container');
      const div = document.createElement('div');
      div.className = 'code-editor-toolbar-container';
      this._toolbarContainer = div;
      this.appendChild(div);
    }

    @Memoize()
    private get _connectedItems$(): Observable<CodeEditorToolbarItem[]> {
      return getComponentPlugins<CodeEditorToolbarItem>(
        this,
        this._disconnect$,
        ToolbarItemEvents.Connected,
        ToolbarItemEvents.Disconnected,
        ToolbarItemEvents.Updated,
      );
    }

    private _cleanupContainer(selector: string): void {
      const exitsContainer = this.querySelector(selector);
      if (exitsContainer) this.removeChild(exitsContainer);
    }
  }

  return CodeEditorToolbarImplementation;
};

const name = 'vce-code-editor-toolbar';
CustomElement(name)(createCodeEditorToolbar());
