import { map } from 'rxjs/operators';
import { Store } from 'redux';
import { VariablesEditorDialogAction, State } from '../..';
import { Observable } from 'rxjs/Observable';
import { EpicDependencies } from '../../dependencies.interface';
import { createSetStringEditable } from '../../actions';

export const setStringEditableFromApi = (
  _actions$: Observable<VariablesEditorDialogAction>,
  _store: Store<State>,
  dependencies: EpicDependencies,
): Observable<VariablesEditorDialogAction> => dependencies.stringEditable$.pipe(map(createSetStringEditable));
