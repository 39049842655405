import * as React from 'react';
import { ChangeEvent } from 'react';
import { CustomElement } from '../../../../lib/custom-element-decorators';
import { Observable } from 'rxjs/Observable';
import { takeUntil, map, startWith } from 'rxjs/operators';
import { Callback, ReactiveAttribute, attributeToBoolean } from '../../../../lib/reactive-decorators';
import { render } from 'react-dom';
import { combineLatest } from 'rxjs/observable/combineLatest';
import { SimpleSearchFieldEvents } from '../../interface';
import { HTMLCustomElement } from '../../../../lib';

export type SimpleSearchFieldTranslations = {
  placeholder: string;
};

const getDefaultTranslation = (): SimpleSearchFieldTranslations => ({
  placeholder: 'Start typing a search term',
});

type RenderProps = {
  value: string;
  loading: boolean;
  translations: SimpleSearchFieldTranslations;
};

export type SimpleSearchField = HTMLElement & {
  value: string;
  loading: boolean;
  translations: SimpleSearchFieldTranslations;
};

export const createSimpleSearchField = () => {
  class SimpleSearchFieldClass extends HTMLCustomElement implements SimpleSearchField {
    value: string;
    loading: boolean;
    translations: SimpleSearchFieldTranslations;

    @Callback('disconnectedCallback') private _disconnect$: Observable<void>;
    @ReactiveAttribute('value', 'value')
    private _value$: Observable<string>;
    @ReactiveAttribute('loading', 'loading', attributeToBoolean)
    private _loading$: Observable<boolean>;

    @ReactiveAttribute('translations', 'translations', JSON.parse)
    private _translations$: Observable<SimpleSearchFieldTranslations>;

    connectedCallback(): void {
      this._inputs$.pipe(takeUntil(this._disconnect$)).subscribe(props => render(this._render(props), this));
      this.dispatchEvent(new CustomEvent(SimpleSearchFieldEvents.Connected, { bubbles: true }));
    }

    disconnectedCallback(): void {
      this.dispatchEvent(new CustomEvent(SimpleSearchFieldEvents.Disconnected));
    }

    private _render(props: RenderProps): JSX.Element {
      return (
        <div className="e-field__postfix">
          <span className="e-field__postfix__cell">
            <input
              className="e-input"
              type="text"
              placeholder={props.translations.placeholder}
              value={props.value}
              onChange={event => this._onSearchFieldChange(event)}
            />
          </span>
          <span className="e-field__postfix__cell e-field__postfix__cell-short e-field__postfix__cell-shortsticky">
            <button className="e-btn e-btn-onlyicon" type="submit" onClick={() => this._onButtonClick(props.value)}>
              {props.loading ? <e-spinner data-size="small" /> : <e-icon icon="search" />}
            </button>
          </span>
        </div>
      );
    }

    private _onButtonClick(value: string): void {
      this._emitSearch(value);
    }

    private _onSearchFieldChange(event: ChangeEvent<HTMLInputElement>): void {
      this.value = event.target.value;
      this._emitSearch(this.value);
    }

    private get _inputs$(): Observable<RenderProps> {
      return combineLatest(
        this._value$.pipe(startWith('')),
        this._loading$.pipe(startWith(false)),
        this._translations$.pipe(startWith(getDefaultTranslation())),
      ).pipe(
        map(([value, loading, translations]) => ({
          value,
          loading,
          translations: { ...getDefaultTranslation(), ...translations },
        })),
      );
    }

    private _emitSearch(value: string): void {
      this.dispatchEvent(new CustomEvent('search', { detail: value }));
    }
  }
  return SimpleSearchFieldClass;
};

const name = 'vce-simple-search-field';
CustomElement(name)(createSimpleSearchField());
