import { CustomElement } from '../../../../lib/custom-element-decorators';
import { v4 } from 'uuid';
import { StringAttribute } from '../../../../lib/reactive-decorators';
import { fromEvent } from 'rxjs/observable/fromEvent';
import { filter, map as map$, first } from 'rxjs/operators';
import { tryCatch, F, prop } from 'ramda';
import { MediaDbSelection, IOpenMediaDB, MediaDbWindowDimensions } from '../../interface';
import { HTMLCustomElement } from '../../../../lib';

const isMediaDBEvent = validEvents => message => validEvents.indexOf(message.event) > -1;
const isCurrentDialogEvent = id => message => message.id === id;
const isValidJsonPostMessage = tryCatch(JSON.parse, F);

export function createOpenMediaDbComponent(
  global: Window,
  generateId: () => string,
  dimensions: MediaDbWindowDimensions,
  validEvents: string[],
): { new (): IOpenMediaDB } {
  class OpenMediaDB extends HTMLCustomElement implements IOpenMediaDB, IOpenMediaDB {
    @StringAttribute('campaign-id') campaignId: string;

    open(): Promise<MediaDbSelection> {
      const dialog = this._openMediaDBDialog();
      return fromEvent(global, 'message')
        .pipe(
          map$(prop('data')),
          filter(isValidJsonPostMessage),
          map$((message: string) => JSON.parse(message)),
          filter(isMediaDBEvent(validEvents)),
          filter(isCurrentDialogEvent(dialog)),
          map$(event => event.selected || null),
          first(),
        )
        .toPromise();
    }

    private _openMediaDBDialog(): string {
      const postMessageId = generateId();
      global.postMessage(
        JSON.stringify({ event: 'mediaDb:open', id: postMessageId, campaignId: this.campaignId }),
        '*',
      );
      if (global !== global.parent) {
        global.parent.postMessage(
          JSON.stringify({ event: 'mediaDb:open', id: postMessageId, campaignId: this.campaignId }),
          '*',
        );
      }
      return postMessageId;
    }
  }

  return OpenMediaDB;
}

const dimensions = {
  width: 1027,
  height: 650,
  posX: (window.screen.availWidth - 1027) / 2,
  posY: (window.screen.availHeight - 650) / 2,
};
const validEvents = ['mediaDb:insert', 'mediaDb:close'];
const component = createOpenMediaDbComponent(window, v4, dimensions, validEvents);
CustomElement('open-media-db')(component);
