import { HTMLCustomElement } from '../../../../lib/html-custom-element';
import { CustomElement } from '../../../../lib/custom-element-decorators';
import { ReactiveAttribute } from '../../../../lib/reactive-decorators';
import { Callback } from '../../../../lib/reactive-decorators';
import { Observable } from 'rxjs/Observable';
import { combineLatest } from 'rxjs/observable/combineLatest';
import { takeUntil } from 'rxjs/operators';

export const createEmptyState = () => {
  class EmptyState extends HTMLCustomElement {
    @ReactiveAttribute('label-title') _title: Observable<string>;
    @ReactiveAttribute('label-lead') _lead: Observable<string>;
    @ReactiveAttribute('label-learn-more') _learnMore: Observable<string>;
    @ReactiveAttribute('label-learn-more-link') _learnMoreLink: Observable<string>;
    @Callback('disconnectedCallback') private _disconnect$: Observable<void>;

    connectedCallback(): void {
      combineLatest(this._title, this._lead, this._learnMore, this._learnMoreLink)
        .pipe(takeUntil(this._disconnect$))
        .subscribe(([title, lead, learnMore, learnMoreLink]) => this._render(title, lead, learnMore, learnMoreLink));
    }

    private _render(title: string, lead: string, learnMore: string, learnMoreLink: string): void {
      this.innerHTML = `
        <div  class="e-emptystate e-emptystate-absolute cb-emptystate">
          <e-icon icon="e-blocks" size="middle" class="e-emptystate__icon"></e-icon>
          <div class="e-emptystate__content">
            <div class="e-emptystate__title">${title}</div>
            <div class="e-emptystate__lead">${lead}</div>
            <a href="${learnMoreLink}" class="e-emptystate__button e-btn e-btn-link">${learnMore}</a>
          </div>
        </div>
        `;
    }
  }
  return EmptyState;
};

export const name = 'vce-html-source-empty-state';
CustomElement(name)(createEmptyState());
