import { State } from '.';

export const CHANGE_VALUE = 'VARIABLES_EDITOR_CHANGE_VALUE';
type ChangeValue = {
  type: 'VARIABLES_EDITOR_CHANGE_VALUE';
  payload: {
    id: string;
    value: string;
  };
};
export const createChangeValue = (id: string, value: string): ChangeValue => ({
  type: CHANGE_VALUE,
  payload: { id, value },
});

export const RESET = 'VARIABLES_EDITOR_RESET';
type Reset = {
  type: 'VARIABLES_EDITOR_RESET';
  payload: {
    id: string;
  };
};
export const createReset = (id: string): Reset => ({
  type: RESET,
  payload: { id },
});

export const APPLY_STATE_FROM_INPUT = 'VARIABLES_EDITOR_APPLY_STATE_FROM_INPUT';
type ApplyStateFromInput = {
  type: 'VARIABLES_EDITOR_APPLY_STATE_FROM_INPUT';
  payload: {
    state: State;
  };
};
export const createApplyStateFromInput = (state: State): ApplyStateFromInput => ({
  type: APPLY_STATE_FROM_INPUT,
  payload: { state },
});

export const SET_USE_EDITABLE_IMAGE = 'VARIABLES_EDITOR_SET_USE_EDITABLE_IMAGE';
export type SetUseEditableImage = {
  type: 'VARIABLES_EDITOR_SET_USE_EDITABLE_IMAGE';
  payload: { useEditableImage: boolean };
};
export const createSetUseEditableImage = (useEditableImage: boolean): SetUseEditableImage => ({
  type: SET_USE_EDITABLE_IMAGE,
  payload: { useEditableImage },
});

export const SET_SEARCH = 'VARIABLES_EDITOR_SET_SEARCH';
export type SetSearch = {
  type: 'VARIABLES_EDITOR_SET_SEARCH';
  payload: { value: string };
};
export const createSetSearch = (search: string): SetSearch => ({
  type: SET_SEARCH,
  payload: { value: search },
});

export type VariablesEditorAction = ChangeValue | Reset | ApplyStateFromInput | SetUseEditableImage | SetSearch;
