import { CustomElement } from '../../../../lib/custom-element-decorators';
import { IVcePlugin } from '../../../../lib/vce-plugin.interface';
import { HTMLCustomElement } from '../../../../lib';
export interface VcePluginCleanPixelRatio extends HTMLElement, IVcePlugin {
  renderedCallback: (iframe: HTMLIFrameElement) => void;
}
export function createVcePluginCleanPixelRatio(): { new (): VcePluginCleanPixelRatio } {
  class VcePluginCleanPixelRatio extends HTMLCustomElement implements IVcePlugin {
    connectedCallback(): void {
      this.dispatchEvent(new CustomEvent('plugin.connected', { bubbles: true }));
    }

    disconnectedCallback(): void {
      this.dispatchEvent(new CustomEvent('plugin.disconnected'));
    }

    renderedCallback(iframe: HTMLIFrameElement): void {
      Array.from(iframe.contentWindow!.document.querySelectorAll('style')).forEach((element: HTMLElement) => {
        element.innerHTML = cleanPixelRatio(element.innerHTML);
      });
    }
  }
  return VcePluginCleanPixelRatio;
}

function cleanPixelRatio(style: string): string {
  const searchPixelRatio = /\s?and\s*\([^)]*-device-pixel-ratio:[^(]*\)/g;
  return style.replace(searchPixelRatio, '');
}

CustomElement('vce-plugin-clean-pixel-ratio')(createVcePluginCleanPixelRatio());
