import { fromEventPattern } from 'rxjs/observable/fromEventPattern';
import { Observable } from 'rxjs/Observable';

export const fromEventSafe = <T>(target: HTMLElement | Window, eventName: string): Observable<T> => {
  return fromEventPattern(
    (handler: any) => target.addEventListener(eventName, handler),
    (handler: any) => {
      if (target && target.removeEventListener) {
        target.removeEventListener(eventName, handler);
      }
    },
  );
};
