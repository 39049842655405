import { map } from 'rxjs/operators';
import { Store } from 'redux';
import { VariablesEditorDialogAction, State, createFillReservedIds } from '..';
import { Observable } from 'rxjs/Observable';
import { EpicDependencies } from '../dependencies.interface';

export const fillReservedIdsFromApi = (
  actions$: Observable<VariablesEditorDialogAction>,
  store: Store<State>,
  dependencies: EpicDependencies,
): Observable<VariablesEditorDialogAction> => dependencies.reservedIds$.pipe(map(createFillReservedIds));
