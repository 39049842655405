import { isNil } from 'ramda';
import { VcePluginBlockToolbarElement } from '../interface';

export type GetBLock = (
  element: HTMLElement | null,
  blocks: VcePluginBlockToolbarElement[],
) => VcePluginBlockToolbarElement | undefined;

export const getBlock: GetBLock = (element, blocks) => {
  if (element == null || isNil(element)) {
    return undefined;
  }
  const activeBlock = blocks.find(block => block.selector === element.getAttribute('e-block'));
  return !isNil(activeBlock) ? activeBlock : getBlock(element.parentElement, blocks);
};
