import { HTMLCustomElement } from '../../../../lib/html-custom-element';
import { CustomElement } from '../../../../lib/custom-element-decorators';
import { ReactiveAttribute, Callback } from '../../../../lib/reactive-decorators';
import { Observable } from 'rxjs/Observable';
import { render } from 'react-dom';
import * as React from 'react';
import { reactCustomElementWrapper } from '../../../../lib/react-custom-element-wrapper';
import { combineLatest } from 'rxjs/observable/combineLatest';
import { takeUntil } from 'rxjs/operators';
import { createAnalyticsEvent } from '../../lib/create-analytics-event';
const ESelect = reactCustomElementWrapper('e-select');
const ESelectOption = reactCustomElementWrapper('e-select-option');
const VceLanguageName = reactCustomElementWrapper('vce-language-name');

export const createLanguagesSelector = (): { new (): HTMLElement } => {
  class LanguagesSelector extends HTMLCustomElement {
    @ReactiveAttribute('available-languages', 'availableLanguages', JSON.parse)
    private _availableLanguages$: Observable<string[]>;
    @ReactiveAttribute('master', 'master')
    private _master$: Observable<string>;
    @ReactiveAttribute('selected', 'selected')
    private _selected$: Observable<string>;
    private _selected: string;

    @Callback('disconnectedCallback') private _disconnect$: Observable<void>;

    connectedCallback(): void {
      combineLatest(this._availableLanguages$, this._master$, this._selected$)
        .pipe(takeUntil(this._disconnect$))
        .subscribe(([availableLanguages, master, selected]) => {
          render(this._render(availableLanguages, master, selected), this);
          this._selected = selected;
        });
      this.dispatchEvent(new CustomEvent('connected', { bubbles: true }));
    }

    private _render(languages: string[], master: string, selected: string): JSX.Element {
      return (
        <ESelect on-change={this._emitChange.bind(this)}>
          {languages.map(language => (
            <ESelectOption key={language} id={language} value={language} selected={language === selected}>
              <VceLanguageName prop-ietfCode={language} />
              {this._getPostfix(language, master)}
            </ESelectOption>
          ))}
        </ESelect>
      );
    }

    private _getPostfix(language: string, master: string): string {
      return language === master ? ' - master' : '';
    }

    private _emitChange(event: any): void {
      this.dispatchEvent(
        createAnalyticsEvent('Changed_Languages', `ChangedLanguage_Select_${this._selected}_${event.detail.value}`),
      );
      this.dispatchEvent(
        new CustomEvent('language-change', {
          bubbles: true,
          detail: event.detail.value,
        }),
      );
      this._selected = event.detail.value;
    }
  }
  return LanguagesSelector;
};

export const languagesSelectorTagName = 'vce-languages-selector';
CustomElement(languagesSelectorTagName)(createLanguagesSelector());
