import { Logger } from './logger/logger';

export const HTMLCustomElement: { new (): HTMLElement } = class extends HTMLElement {
  logger: Logger;

  constructor() {
    super();
    this.init();
    //this.logUsage();
  }

  logUsage(): void {
    this.logger = new Logger(false);
    this.logger.setClientLogging(process.env.NODE_ENV !== 'development');
    this.logger.info('webComponentUsage-HTMLCustomElement-' + this.tagName, { tagName: this.tagName });
  }

  init(): void {}
} as any;
