import { CustomElement } from '../../../../lib/custom-element-decorators';
import { IVcePlugin } from '../../../../lib/vce-plugin.interface';
import { Callback, StringAttribute } from '../../../../lib/reactive-decorators';
import { fromEvent } from 'rxjs/observable/fromEvent';
import { Observable } from 'rxjs/Observable';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { HTMLCustomElement } from '../../../../lib/html-custom-element';

import './toolbar.tempstyle';

export interface ToolbarButton extends HTMLElement {
  getButton: (target: HTMLElement) => HTMLElement;
}

interface Positioner extends HTMLElement {
  open: (target: HTMLElement, iframe: HTMLIFrameElement) => void;
}

interface TargetWithIFrame {
  target: HTMLElement;
  iframe: HTMLIFrameElement;
}

export interface VcePluginToolbar extends HTMLElement, IVcePlugin {}

export function createVceToolbarPlugin(): { new (): VcePluginToolbar } {
  class VcePluginToolbar extends HTMLCustomElement implements VcePluginToolbar {
    @StringAttribute('selector') selector: string;
    @Callback('readyCallback') _ready$: Observable<HTMLIFrameElement>;
    @Callback('disconnectedCallback') _disconnect$: Observable<void>;
    private _positioner: Positioner;
    private _buttonContainer: HTMLDivElement;

    init(): void {
      this._createPositioner();
      this._targetClicksWithIFrame$ = this._targetClicksWithIFrame$.bind(this);
      this._show = this._show.bind(this);
    }

    connectedCallback(): void {
      this.appendChild(this._positioner);
      const targetClicks = this._ready$.pipe(
        switchMap(this._targetClicksWithIFrame$),
        takeUntil(this._disconnect$),
      );
      targetClicks.subscribe(this._show);
      this.dispatchEvent(new CustomEvent('plugin.connected', { bubbles: true }));
    }

    disconnectedCallback(): void {
      this.dispatchEvent(new CustomEvent('plugin.disconnected'));
    }

    private _createPositioner(): void {
      this._positioner = window.document.createElement('e-vce-positioner-editable') as Positioner;
      const middleContainer = window.document.createElement('div');
      middleContainer.className = 'e-vce-image_toolbar';
      this._buttonContainer = window.document.createElement('div');
      middleContainer.appendChild(this._buttonContainer);
      this._positioner.appendChild(middleContainer);
      this._positioner.className = 'e-buttongroup e-buttongroup-sticky';
    }

    private _show({ target, iframe }: TargetWithIFrame): void {
      this._buttonContainer.innerHTML = '';
      const buttons = this._appendButtons(target);
      buttons.forEach(element => this._buttonContainer.appendChild(element));
      if (buttons.length) {
        this._positioner.open(target, iframe);
      }
    }

    private _appendButtons(target: HTMLElement): HTMLElement[] {
      return Array.from(this.querySelectorAll('[toolbar-button]'))
        .map((component: ToolbarButton) => {
          return component.getButton(target);
        })
        .filter((component: ToolbarButton) => component !== undefined);
    }

    private _targetClicksWithIFrame$(iframe: HTMLIFrameElement): Observable<TargetWithIFrame> {
      return fromEvent(iframe.contentWindow!.document.querySelectorAll(this.selector), 'click').pipe(
        map((event: MouseEvent) => ({ target: event.target as HTMLElement, iframe })),
      );
    }
  }
  return VcePluginToolbar;
}

const name = 'vce-plugin-toolbar';
CustomElement(name)(createVceToolbarPlugin());
