import { CustomElement } from '../../../../lib/custom-element-decorators';
import { IVcePlugin } from '../../../../lib/vce-plugin.interface';
import { HTMLCustomElement } from '../../../../lib/index';
import { TransformToToken, transformToToken } from './transform-to-token';
import { findPlaceholderPositions } from '@emartech/personalization-twig-token';
import { compileTokenHtml } from '@emartech/content-blocks-token';
import { v2TokenDataToToken } from './token-data-to-token-v2';
import { EditableTextPlugin } from '..';
import { compileTokens, CompileTokenHtml } from './compile-tokens';
import { tokenDataToToken } from './token-data-to-token';

export interface VcePluginPersonalization extends HTMLElement, IVcePlugin, EditableTextPlugin {}
export function createVcePluginPersonlization(
  transformToToken: TransformToToken,
  compileTokenHtml: CompileTokenHtml,
): { new (): VcePluginPersonalization } {
  class VcePluginReplaceBrokenImageClass extends HTMLCustomElement
    implements VcePluginPersonalization, EditableTextPlugin {
    connectedCallback(): void {
      this.dispatchEvent(new CustomEvent('plugin.connected', { bubbles: true }));
    }

    disconnectedCallback(): void {
      this.dispatchEvent(new CustomEvent('plugin.disconnected'));
    }

    beforeRenderCallback(html: string): string {
      return transformToToken(html);
    }

    preUpdateCallback(html: string): string {
      return compileTokenHtml(html, []);
    }
  }
  return VcePluginReplaceBrokenImageClass;
}

CustomElement('vce-plugin-personalization')(
  createVcePluginPersonlization(
    transformToToken(v2TokenDataToToken, tokenDataToToken, findPlaceholderPositions),
    compileTokens(compileTokenHtml),
  ),
);
