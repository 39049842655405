import { CustomElement } from '../../../../lib/custom-element-decorators';
import { IVcePlugin } from '../../../../lib/vce-plugin.interface';
import { HTMLCustomElement } from '../../../../lib/html-custom-element';
import { Callback } from '../../../../lib';
import { Observable } from 'rxjs/Observable';
import { fromEvent } from 'rxjs/observable/fromEvent';
import { map, switchMap, takeUntil, pairwise } from 'rxjs/operators';
import { startWith } from 'rxjs/operators/startWith';
import { merge } from 'rxjs/observable/merge';
const hasEditable = (target: HTMLElement) => target.getAttribute('e-editable');
const isImageEvent = (target: HTMLElement) => target.tagName.toUpperCase() === 'IMG';
const isEditableImageClick = (target: HTMLElement) => hasEditable(target) && isImageEvent(target);
const emptyFirstEvent = { target: document.createElement('div') } as any;

export interface VcePluginBordererImageFocusStrategy extends HTMLElement, IVcePlugin {}
export function createVcePluginBordererImageFocusStrategy(
  global: Window,
): { new (): VcePluginBordererImageFocusStrategy } {
  class VcePluginImageFocusClass extends HTMLCustomElement implements VcePluginBordererImageFocusStrategy {
    @Callback('readyCallback') private _ready$: Observable<HTMLIFrameElement>;
    @Callback('disconnectedCallback') private _disconnect$: Observable<void>;

    init(): void {
      this._getClickPairs = this._getClickPairs.bind(this);
    }

    connectedCallback(): void {
      this._ready$
        .pipe(
          switchMap(this._getClickPairs),
          takeUntil(this._disconnect$),
        )
        .subscribe(([firstClickTarget, secondClickTarget]) => {
          if (isEditableImageClick(firstClickTarget)) this._emitBlur(firstClickTarget);
          if (isEditableImageClick(secondClickTarget)) this._emitFocus(secondClickTarget);
        });
      this.dispatchEvent(new CustomEvent('plugin.connected', { bubbles: true }));
    }

    disconnectedCallback(): void {
      this.dispatchEvent(new CustomEvent('plugin.disconnected'));
    }

    private _getClickPairs(iframe: HTMLIFrameElement): Observable<any> {
      return merge(
        fromEvent<Event>(iframe.contentWindow!.document.body, 'click'),
        fromEvent<Event>(global.document.body, 'click'),
      ).pipe(
        startWith<Event>(emptyFirstEvent),
        pairwise(),
        map(([firstClickEvent, secondClickEvent]) => [
          firstClickEvent.target as HTMLElement,
          secondClickEvent.target as HTMLElement,
        ]),
      );
    }

    private _emitBlur(target: HTMLElement): void {
      this.dispatchEvent(new CustomEvent('editable.blur', { detail: target, bubbles: true }));
    }

    private _emitFocus(target: HTMLElement): void {
      this.dispatchEvent(new CustomEvent('editable.focus', { detail: target, bubbles: true }));
    }
  }
  return VcePluginImageFocusClass;
}

const name = 'vce-plugin-borderer-image-focus-strategy';
CustomElement(name)(createVcePluginBordererImageFocusStrategy(window));
