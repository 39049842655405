import * as React from 'react';
import { Translations } from '../translations.interface';

type DisplayNameProps = {
  displayName: string;
  translations: Translations['displayName'];
  setDisplayName: (name: string) => void;
};
export const DisplayName = (props: DisplayNameProps): JSX.Element => (
  <div className="e-field" variable-editor-form-field="display_name">
    <label className="e-field__label">
      {props.translations.title}
      <e-tooltip type="helper" content={props.translations.helper} />
    </label>
    <input
      type="text"
      className="e-input"
      onChange={event => props.setDisplayName(event.target.value)}
      value={props.displayName || ''}
      placeholder={props.translations.placeholder}
    />
  </div>
);
