import * as React from 'react';
import classnames from 'classnames';
import { Translations } from '../translations.interface';

type IsOverridableProps = {
  isOverridable: boolean;
  overridableEnabled: boolean;
  uniqIdForRendering: string;
  translations: Translations['isOverridable'];
  setIsOverridable: (name: boolean) => void;
};
export const IsOverridable = (props: IsOverridableProps): JSX.Element => (
  <div variable-editor-form-field="is_overridable">
    <label className="e-field__label">
      {props.translations.title}
      <e-tooltip type="helper" content={props.translations.helper} />
    </label>
    {(() => {
      if (props.overridableEnabled) {
        return (
          <>
            <input
              type="checkbox"
              className="e-checkbox"
              id={'is-overridable-checkbox' + props.uniqIdForRendering}
              checked={props.isOverridable}
              onChange={event => props.setIsOverridable(event.target.checked)}
            />
            <label htmlFor={'is-overridable-checkbox' + props.uniqIdForRendering}>
              {props.translations.enabledState}
            </label>
          </>
        );
      } else {
        return (
          <div className={classnames(['text-color-shade', { 'e-hidden': props.overridableEnabled }])}>
            {props.translations.disabledState}
          </div>
        );
      }
    })()}
  </div>
);
