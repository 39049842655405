export const initialSate = {
  useEditableImage: false,
  variableConfigurations: [],
  variables: {},
  search: '',
  useSearch: false,
  showVariableIDString: false,
  translations: {
    resetButtonTooltip: 'Reset button',
    searchInputPlaceholder: 'Search',
    emptyStateTitle: 'No Settings to Show',
    emptyStateLead:
      'Style settings can be defined in the template as variables to provide more flexible campaign formatting options.',
    emptyStateLinkButton: 'Learn more',
    emptyStateLinkUrl: 'http://documentation.emarsys.com/',
    defaultValue: {
      image: {
        mediaDB: 'Media DB',
      },
    },
    validationErrors: {
      wrongIdFormat: 'This variable name is invalid. Please use only Latin letters (A-Z, a-z).',
      idAlreadyUsed: 'This variable name is already in use. Please try a new one.',
      wrongValue: 'This variable value is invalid. Please do not use curly brackets ({, }).',
      wrongImage: 'The image URL cannot be found.',
    },
    applyButton: 'Apply',
    cancelButton: 'Cancel',
  },
};
