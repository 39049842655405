import { TinyMCEEvent } from '../format-events/event.interface';
import { ElementWrapper } from '../utils';

export class TokenFontSelector {
  private _editor;
  private _wrapper: ElementWrapper;

  static create(editor): TokenFontSelector {
    return new TokenFontSelector(editor);
  }

  constructor(editor) {
    this._editor = editor;
    this._wrapper = ElementWrapper.create(editor);
  }

  wrapElement(element: HTMLSpanElement): TokenFontSelector {
    this._wrapper.wrap(element);
    return this;
  }

  toggleFormat(event: TinyMCEEvent): TokenFontSelector {
    this._editor.formatter.toggle(event.command.toLowerCase(), { value: event.value });
    return this;
  }

  restoreSelection(): TokenFontSelector {
    this._wrapper.restoreSelection();
    return this;
  }

  clearElementWrapper(): void {
    this._wrapper.clearWrapperElement({ forcedRemove: false });
  }
}
