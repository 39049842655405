import { State, ValidationResult } from '../state.interface';
import * as Actions from '../actions';
import { assocPath, isEmpty, pipe } from 'ramda';
import { validateDefaultValue, validateId } from '../validations';
import { VariableConfiguration } from '@emartech/vce-domain';
import { OpenType } from '../epics/lib/open-type';

export const openWithCopyReducer = (state: State, action: Actions.OpenWithCopy) =>
  pipe(
    assocPath<VariableConfiguration, State>(['variableConfiguration'], action.payload.variableConfiguration),
    assocPath<string, State>(
      ['variableConfiguration', 'display_name'],
      prefixDisplayName(action.payload.variableConfiguration.display_name),
    ),
    assocPath<string, State>(['variableConfiguration', 'id'], prefixId(action.payload.variableConfiguration.id)),
    assocPath<null, State>(['originalVariableConfiguration'], null),
    assocPath<ValidationResult, State>(
      ['validation', 'id'],
      validateId(
        prefixId(action.payload.variableConfiguration.id),
        state.reservedIds,
        undefined,
        state.translations.validationErrors,
      ),
    ),
    state =>
      state.useEditableImage
        ? { ...state }
        : assocPath<ValidationResult, State>(
            ['validation', 'default_value'],
            validateDefaultValue(
              action.payload.variableConfiguration.default_value,
              state.translations.validationErrors,
            ),
            state,
          ),
    assocPath<OpenType, State>(['openType'], OpenType.Copy),
  )(state);

const prefixId = (id: string): string => `copyof${id}`;
const prefixDisplayName = (displayName: string): string => {
  if (isEmpty(displayName)) return '';
  return `Copy of ${displayName}`;
};
