export interface DomTranslatorConfig {
  selector: string | null;
  key: string;
  attribute?: string;
  property?: string;
}
export type DomTranslatorSub = (translations: Object) => void;
export type DomTranslator = (configs: DomTranslatorConfig[], element: HTMLElement) => DomTranslatorSub;

export const domTranslator: DomTranslator = (configs, element) => {
  const mapped = configs.map(config =>
    Object.assign({}, config, {
      targets: config.selector ? Array.from(element.querySelectorAll(config.selector)) : [element],
    }),
  );
  return translations => {
    mapped.forEach(config => {
      config.targets.forEach(target => {
        if (config.property) {
          target[config.property] = translations[config.key];
        }
        if (config.attribute) {
          target.setAttribute(config.attribute, translations[config.key]);
        }
      });
    });
  };
};
