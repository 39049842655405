import { CustomElement, ReactiveAttribute } from '../../../../lib';
import {
  CodeEditorTwigValidatorEvents,
  CodeEditorTwigValidatorPluginValidationResult,
  CodeEditorTwigValidatorStatus,
  TwigValidatorClientStatus,
} from '../../interface';
import { Observable } from 'rxjs';
import { debounce, map, skip, switchMap, first } from 'rxjs/operators';
import { validateTwig } from '../../../../lib/validate-twig';
import { merge } from 'rxjs/observable/merge';
import { combineLatest } from 'rxjs/observable/combineLatest';
import { timer } from 'rxjs/observable/timer';

export const createCodeEditorTwigValidator = (
  validate: (code: string, validationUrl: string) => Promise<CodeEditorTwigValidatorPluginValidationResult>,
) => {
  class CodeEditorTwigValidator extends HTMLElement {
    @ReactiveAttribute('debounce-time', 'debounceTime')
    private _debounceTime$: Observable<string>;

    @ReactiveAttribute('validation-url', 'validationUrl')
    private _validationUrl$: Observable<string>;

    @ReactiveAttribute('code', 'code')
    private _code$: Observable<string>;

    private readonly _validate = validate;

    connectedCallback(): void {
      const codeAndConfig$ = combineLatest(
        this._code$,
        this._debounceTime$,
        this._validationUrl$,
        (code, debounceTime, validationUrl) => ({ code, debounceTime, validationUrl }),
      );

      const firstEvent$ = codeAndConfig$.pipe(first());
      const debouncedEvent$ = codeAndConfig$.pipe(
        skip(1),
        debounce(({ debounceTime }) => {
          return timer(parseInt(debounceTime));
        }),
      );

      codeAndConfig$.subscribe(() => this._dispatchValidationStartEvent());

      merge(firstEvent$, debouncedEvent$)
        .pipe(
          switchMap(({ code, validationUrl }) => this._validate(code, validationUrl)),
          map(CodeEditorTwigValidator._convertToEvent),
        )
        .subscribe(validationEvent => {
          this.dispatchEvent(validationEvent);
        });

      this.dispatchEvent(new CustomEvent(CodeEditorTwigValidatorEvents.Connected, { detail: {}, bubbles: true }));
    }

    _dispatchValidationStartEvent(): void {
      this.dispatchEvent(
        new CustomEvent(CodeEditorTwigValidatorEvents.ValidationEvent, {
          detail: { status: CodeEditorTwigValidatorStatus.Loading, errors: [] },
          bubbles: true,
        }),
      );
    }

    static _convertToEvent(validationResult: CodeEditorTwigValidatorPluginValidationResult): CustomEvent {
      return new CustomEvent(CodeEditorTwigValidatorEvents.ValidationEvent, {
        detail: {
          status: getValidatorStatus(validationResult.status),
          errors: validationResult.errors,
        },
        bubbles: true,
      });
    }
  }

  return CodeEditorTwigValidator;
};

const getValidatorStatus = (validationResultStatus: TwigValidatorClientStatus): CodeEditorTwigValidatorStatus => {
  const statusMap = {
    [TwigValidatorClientStatus.Success]: CodeEditorTwigValidatorStatus.Success,
    [TwigValidatorClientStatus.Unknown]: CodeEditorTwigValidatorStatus.Unknown,
    [TwigValidatorClientStatus.Fail]: CodeEditorTwigValidatorStatus.Fail,
  };

  return statusMap[validationResultStatus];
};

const name = 'vce-code-editor-twig-validator';
CustomElement(name)(createCodeEditorTwigValidator(validateTwig));
