import { CustomElement } from '../../../../lib/custom-element-decorators';
import { Variables } from '@emartech/vce-domain';
import { Translations, State, setUseEditableImageFromApi } from './state';
import * as React from 'react';
import { stateReducer, delegateChangesViaApi, applyChangesFromApi } from './state';
import App from './components/app';
import { createStore, applyMiddleware } from 'redux';
import { createEpicMiddleware, combineEpics } from 'redux-observable';
import { Provider, Store } from 'react-redux';
import { ReactiveAttribute, Callback, attributeToBoolean } from '../../../../lib';
import { Observable } from 'rxjs/Observable';
import { combineLatest } from 'rxjs/observable/combineLatest';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { render } from 'react-dom';
import { HTMLCustomElement } from '../../../../lib/html-custom-element';
import { mergeDeepRight } from 'ramda';

export const getDefaultTranslation = (): Translations => ({
  resetButtonTooltip: 'Reset button',
  searchInputPlaceholder: 'Search',
  emptyStateTitle: 'No Settings to Show',
  emptyStateLead:
    'Style settings can be defined in the template as variables to provide more flexible campaign formatting options.',
  emptyStateLinkButton: 'Learn more',
  emptyStateLinkUrl: 'https://help.emarsys.com/hc/en-us/articles/360003076334-Email-Style-Settings',
  defaultValue: {
    image: {
      mediaDB: 'Media DB',
    },
  },
  validationErrors: {
    wrongImage: 'The image URL cannot be found.',
    wrongIdFormat: 'This variable name is invalid. Please use only Latin letters (A-Z, a-z).',
    idAlreadyUsed: 'This variable name is already in use. Please try a new one.',
    wrongValue: 'This variable value is invalid. Please do not use curly brackets ({, }).',
  },
  applyButton: 'Apply',
  cancelButton: 'Cancel',
});

export const createVariablesEditor = () => {
  class VariablesEditor extends HTMLCustomElement {
    @Callback('disconnectedCallback') private _disconnect$: Observable<void>;
    @ReactiveAttribute('variables', 'variables', JSON.parse)
    private _variables$: Observable<Object>;

    @ReactiveAttribute('variable-configurations', 'variableConfigurations', JSON.parse)
    private _variableConfigurations$: Observable<Object>;

    @ReactiveAttribute('translations', 'translations', JSON.parse)
    private _translations$: Observable<Object>;

    @ReactiveAttribute('use-editable-image', 'useEditableImage', attributeToBoolean)
    private _useEditableImage$: Observable<boolean>;

    @ReactiveAttribute('use-search', 'useSearch')
    private _useSearch$: Observable<string>;

    @ReactiveAttribute('show-variableid-string', 'showVariableIDString')
    private _showVariableIDString$: Observable<boolean>;

    connectedCallback() {
      const epics = combineEpics(delegateChangesViaApi, applyChangesFromApi, setUseEditableImageFromApi);
      const dependencies = {
        inputChange$: this._inputs$,
        emitStateChange: (variables: Variables) => this._emitUpdate(variables),
        useEditableImage$: this._useEditableImage$,
      };
      const epicMiddleware = createEpicMiddleware(epics, { dependencies });
      const store = createStore(stateReducer, applyMiddleware(epicMiddleware));
      this._inputs$.pipe(takeUntil(this._disconnect$)).subscribe(() => render(this._render(store), this));
    }

    private _render(store: Store<State | undefined>): JSX.Element {
      return (
        <Provider store={store}>
          <App />
        </Provider>
      );
    }

    private get _inputs$(): Observable<any> {
      return combineLatest(
        this._variables$.pipe(startWith({})),
        this._variableConfigurations$.pipe(startWith([])),
        this._translations$.pipe(
          startWith(getDefaultTranslation()),
          map(mergeDeepRight<Translations>(getDefaultTranslation())),
        ),
        this._useEditableImage$.pipe(startWith(false)),
        this._useSearch$.pipe(startWith('')),
        this._showVariableIDString$.pipe(startWith(false)),
      ).pipe(
        map(([variables, variableConfigurations, translations, useEditableImage, useSearch, showVariableIDString,]) => ({
          variables,
          variableConfigurations,
          translations: { ...getDefaultTranslation(), ...(translations as {}) },
          useEditableImage,
          useSearch,
          search: this.getSearch(),
          showVariableIDString,
        })),
      );
    }

    private getSearch(): string {
      const searchInput = this.querySelector('#variable-search-input');
      if (searchInput) {
        return (searchInput as HTMLInputElement).value;
      } else {
        return '';
      }
    }

    private _emitUpdate(variables: Variables): void {
      this.dispatchEvent(new CustomEvent('update', { detail: variables }));
    }
  }
  return VariablesEditor;
};

export type VariablesEditor = any;

export const tagName = 'vce-variables-editor';
CustomElement(tagName)(createVariablesEditor());
