import { CustomElement } from '../../../../lib/custom-element-decorators';
import { IVcePlugin } from '../../../../lib/vce-plugin.interface';
import { HTMLCustomElement } from '../../../../lib';

export interface VcePluginCleanDeviceWidth extends HTMLElement, IVcePlugin {
  renderedCallback: (iframe: HTMLIFrameElement) => void;
}
export function createVcePluginCleanDeviceWidth(): { new (): VcePluginCleanDeviceWidth } {
  class VcePluginCleanDeviceWidth extends HTMLCustomElement implements IVcePlugin {
    connectedCallback(): void {
      this.dispatchEvent(new CustomEvent('plugin.connected', { bubbles: true }));
    }

    disconnectedCallback(): void {
      this.dispatchEvent(new CustomEvent('plugin.disconnected'));
    }

    renderedCallback(iframe: HTMLIFrameElement): void {
      Array.from(iframe.contentWindow!.document.querySelectorAll('style')).forEach((element: HTMLElement) => {
        element.innerHTML = cleanDeviceWidth(element.innerHTML);
      });
    }
  }
  return VcePluginCleanDeviceWidth;
}

function cleanDeviceWidth(style: string): string {
  return style.replace(/max-device-width/, 'max-width').replace(/min-device-width/, 'min-width');
}

CustomElement('vce-plugin-clean-device-width')(createVcePluginCleanDeviceWidth());
