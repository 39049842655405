import { TextEditorEvents, createTextEditorProviders } from '../../lib';
import { TextEditorContext, TextEditorProviders } from '../../models';
import { TinymceEditor } from '../../models/tinymce-editor/index';

export type CreateEditor = (context: TextEditorContext, editable: Element) => Promise<TinymceEditor>;
export type TinymceEditorInitializeHook = (editor: TinymceEditor) => void;

export class TextEditorsService {
  private _createEditor: CreateEditor;
  private _editorInitializeHook: TinymceEditorInitializeHook;

  constructor(createEditor: CreateEditor, editorInitializeHook: TinymceEditorInitializeHook) {
    this._createEditor = createEditor;
    this._editorInitializeHook = editorInitializeHook;
  }

  initialize(context: TextEditorContext): Promise<TextEditorProviders> {
    return this._initializeEditors(context)
      .then(editors => this._applyEditorHook(editors.filter(editor => !!editor)))
      .then(editors => this._initizalizeTextEditorEvents(context.editables, editors))
      .then(textEditorEvents => createTextEditorProviders(textEditorEvents));
  }

  private _initializeEditors(context: TextEditorContext): Promise<TinymceEditor[]> {
    const editorPromises = context.editables.map(editableText => this._createEditor(context, editableText));
    return Promise.all(editorPromises);
  }

  private _applyEditorHook(editors: TinymceEditor[]): TinymceEditor[] {
    editors.forEach(editor => this._editorInitializeHook(editor));
    return editors;
  }

  private _initizalizeTextEditorEvents(editableTexts: Element[], editors: TinymceEditor[]): TextEditorEvents[] {
    return editors.map((editor, index) => TextEditorEvents.create(editableTexts[index], editor as any));
  }
}
