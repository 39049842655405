import { transformTokenObjectToHtml } from '@emartech/content-blocks-token';
import { surroundPlaceholders } from '@emartech/personalization-twig-token';
import { PersonalizationState } from '../../../../lib/personalization.interface';

export type TokenDataToToken = (personalizationState: PersonalizationState) => string;
export const tokenDataToToken: TokenDataToToken = personalizationState =>
  transformTokenObjectToHtml({
    name: personalizationState.tokenName,
    type: 'personalization',
    content: { a: surroundPlaceholders(personalizationState.token.code, personalizationState) },
    template: '<%= a %>',
    meta: personalizationState,
    class: 'cbNonEditable',
    style: `
    background-color: #81c980;
    border-radius: .3em;
    box-shadow: 0 0 0 .2em #81c980;
    color: #fff;
  `,
  });
