import { TinymceEditor } from '../editable-text/models';
import { cleanUpBookmarks } from './bookmark-cleanup';
import { PersonalizationDialogV3ProxyOpenDialog } from '../../../personalization/interface';
import { transformTokenHtmlToObject } from '@emartech/content-blocks-token';

export type GetSetupPersonalizationButtonHookOptions = {
  tooltip: string;
  openDialog: PersonalizationDialogV3ProxyOpenDialog;
};

export type GetSetupV3PersonalizationButtonHook = (
  editor: TinymceEditor,
  options: GetSetupPersonalizationButtonHookOptions,
) => void;

export const getSetupV3PersonalizationButtonHook: GetSetupV3PersonalizationButtonHook = (editor, options) => {
  editor.addButton('personalization', {
    tooltip: options.tooltip,
    stateSelector: 'span[e-token="personalization"]',
    onclick: () => {
      cleanUpBookmarks(editor);

      const state =
        (isActive(editor) && transformTokenHtmlToObject(editor.selection.getNode().outerHTML, []).meta!['token']) ||
        null;

      const callback = token => {
        updateContent(editor, token);
      };

      options.openDialog({ callback, state });
    },
  });
};

function isActive(editor: TinymceEditor): boolean {
  const selectedNode = editor.selection.getNode();
  const isPersonalizationToken = selectedNode.getAttribute('e-token') === 'personalization';
  const nothingIsSelected = editor.selection.isCollapsed();
  return !nothingIsSelected && isPersonalizationToken;
}

function updateContent(editor: TinymceEditor, tokenData: string): void {
  editor.focus(false);
  editor.selection.moveToBookmark(editor.selection.getBookmark());
  editor.insertContent(tokenData);
}
