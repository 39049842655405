const tinymce = require('tinymce/tinymce');

export const patchForeColorPlugin = () => {
  tinymce.PluginManager.add('foreColorPatch', function(editor: any): void {
    const originalOnPanelOnClick = editor.buttons['forecolor'].panel.onclick;
    const originalButtonOnClick = editor.buttons['forecolor'].onclick;

    editor.buttons['forecolor'].panel.onclick = function(...args): void {
      editor.fire('BeforeExecCommand', {
        command: 'ForeColor',
        value: args[0].target.getAttribute('data-mce-color'),
      });

      originalOnPanelOnClick.apply(this, args);
    };

    editor.buttons['forecolor'].onclick = function(...args): void {
      editor.fire('BeforeExecCommand', {
        command: 'ForeColor',
        value: this._color,
      });

      originalButtonOnClick.apply(this, args);
    };
  });
};
