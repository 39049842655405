import { HTMLCustomElement } from '../../../../lib/html-custom-element';
import { CustomElement } from '../../../../lib/custom-element-decorators';
import { ConnectedViewChild, ReactiveAttribute, Callback } from '../../../../lib/reactive-decorators';
import { confirm } from '../../../../lib/confirm';
import { Observable } from 'rxjs/Observable';
import { takeUntil, switchMap, map, share, filter, withLatestFrom, startWith } from 'rxjs/operators';
import { combineLatest } from 'rxjs/observable/combineLatest';
import { Memoize } from 'typescript-memoize';
import { fromEvent } from 'rxjs/observable/fromEvent';
import { propEq, mergeDeepRight, dissoc, omit } from 'ramda';
import { LanguagesDataGridRow } from '../languages-list';
import { locales as everyLocales } from '../../lib/locales';
import {
  Locale,
  LanguagesDialog,
  LocalizedContents,
  LanguagesList,
  LanguagesTranslations,
  LanguagesChangeMasterDialog,
} from '../../interface';
import { Subject } from 'rxjs';
import { createAnalyticsEvent } from '../../lib/create-analytics-event';
import { cloneDeep } from 'lodash';

export const defaultTranslations: LanguagesTranslations = {
  deleteConfirm: {
    title: 'Are you sure?',
    message: 'Do you really want to delete this language version?',
    applyButton: 'Delete',
    cancelButton: 'Cancel',
  },
};

interface LanguagesListActionEvent extends CustomEvent {
  detail: {
    type: 'delete';
    data: LanguagesDataGridRow;
  };
}

interface LanguagesListAction {
  type: 'delete';
  key: string;
}

interface DeleteAction {
  localizedContents: LocalizedContents;
  key: string;
}

interface CopyAction {
  localizedContents: LocalizedContents;
  key: string;
  selectedLanguages: Locale[];
}

export const createLanguagesEditor = ({ confirmDialog = confirm, locales = everyLocales as Locale[] } = {}): {
  new (): HTMLElement;
} => {
  class LanguagesEditor extends HTMLCustomElement {
    @ReactiveAttribute('localized-contents', 'localizedContents', JSON.parse)
    private _localizedContents$: Observable<{ [locale: string]: any }>;
    @ReactiveAttribute('master', 'master')
    private _master$: Observable<string>;
    @ReactiveAttribute('selected', 'selected')
    private _selected$: Observable<string>;
    @ReactiveAttribute('translations', 'translations', JSON.parse)
    private _translations$: Observable<LanguagesTranslations>;

    @Callback('disconnectedCallback') private _disconnect$: Observable<void>;
    @ConnectedViewChild('[vce-languages-list]') private _languagesList$: Observable<LanguagesList>;
    @ConnectedViewChild('[vce-languages-dialog]') private _languagesDialog$: Observable<LanguagesDialog>;
    @ConnectedViewChild('[vce-languages-change-master-dialog]')
    private _languagesChangeMasterDialog$: Observable<LanguagesChangeMasterDialog>;
    private _changeMaster$: Subject<any> = new Subject();

    connectedCallback(): void {
      combineLatest(this._languagesList$, this._localizedContents$, this._master$, this._selected$)
        .pipe(takeUntil(this._disconnect$))
        .subscribe(([languagesList, localizedContents, master, selected]) => {
          languagesList.localizedContents = localizedContents;
          languagesList.master = master;
          languagesList.selected = selected;
        });
      combineLatest(this._languagesDialog$, this._availableLocales$).subscribe(
        ([dialog, availableLocales]) => (dialog.locales = availableLocales),
      );
      combineLatest(this._languagesChangeMasterDialog$, this._usedLocales$, this._master$).subscribe(
        ([dialog, usedLocales, master]) => {
          dialog.locales = usedLocales;
          dialog.master = master;
        },
      );
      this._changeMasterAction$.pipe(takeUntil(this._disconnect$)).subscribe(master => this._emitUpdateMaster(master));
      this._selectedDeleted$
        .pipe(
          takeUntil(this._disconnect$),
          withLatestFrom(this._master$),
        )
        .subscribe(([_, master]) => this._emitUpdateSelected(master));
      this._deleteAction$.pipe(takeUntil(this._disconnect$)).subscribe(deleteAction => {
        this._emitUpdate(dissoc(deleteAction.key, deleteAction.localizedContents));
        this.dispatchEvent(createAnalyticsEvent('Deleted_Language', `DeleteLanguage_${deleteAction.key}`));
      });
      this._copyAction$
        .pipe(
          takeUntil(this._disconnect$),
          withLatestFrom(this._master$),
        )
        .subscribe(([copyAction, master]) => {
          this._emitUpdate(this._copyLanguages(copyAction));
          this.dispatchEvent(
            createAnalyticsEvent('Copied_Language', this._getCopyActionAnalyticsEventLabel(copyAction, master)),
          );
        });
    }

    changeMaster(): void {
      this._changeMaster$.next('change');
    }

    private get _changeMasterAction$(): Observable<string> {
      return this._changeMaster$.pipe(
        withLatestFrom(this._languagesChangeMasterDialog$),
        switchMap(([_, dialog]) => dialog.change().apply.then(masterLocale => masterLocale.key)),
      );
    }

    @Memoize()
    private get _availableLocales$(): Observable<Locale[]> {
      return this._localizedContents$.pipe(
        map(localizedContents => {
          const reservedIds = Object.keys(localizedContents);
          return locales.filter(locale => !reservedIds.includes(locale.key));
        }),
      );
    }

    @Memoize()
    private get _usedLocales$(): Observable<Locale[]> {
      return this._localizedContents$.pipe(
        map(localizedContents => {
          const reservedIds = Object.keys(localizedContents);
          return locales.filter(locale => reservedIds.includes(locale.key));
        }),
      );
    }

    @Memoize()
    private get _actions$(): Observable<LanguagesListAction> {
      return this._languagesList$.pipe(
        switchMap(languagesList => fromEvent(languagesList, 'action')),
        map((event: LanguagesListActionEvent) => ({ type: event.detail.type, key: event.detail.data.key })),
        share(),
      );
    }

    @Memoize()
    private get _deleteAction$(): Observable<DeleteAction> {
      return this._actions$.pipe(
        filter(propEq('delete', 'type')),
        withLatestFrom(this._mergedTranslations$, this._localizedContents$),
        switchMap(([deleteAction, translations, localizedContents]) =>
          confirmDialog(
            translations.deleteConfirm.title,
            translations.deleteConfirm.message,
            translations.deleteConfirm.applyButton,
          ).then(isConfirmed => ({
            localizedContents,
            key: deleteAction.key,
            isConfirmed,
          })),
        ),
        filter(({ isConfirmed }) => isConfirmed),
        share(),
      );
    }

    @Memoize()
    private get _copyAction$(): Observable<CopyAction> {
      return this._actions$.pipe(
        filter(propEq('copy', 'type')),
        withLatestFrom(this._languagesDialog$, this._localizedContents$),
        switchMap(([copyAction, dialog, localizedContents]) => {
          const locale = locales.find(_ => _.key === copyAction.key)!;
          return dialog.copy(locale).apply.then(selectedLanguages => ({
            selectedLanguages,
            key: copyAction.key,
            localizedContents,
          }));
        }),
        share(),
      );
    }

    @Memoize()
    private get _selectedDeleted$(): Observable<any> {
      return this._deleteAction$.pipe(
        withLatestFrom(this._selected$),
        filter(([action, selected]) => selected === action.key),
      );
    }

    private get _mergedTranslations$(): Observable<LanguagesTranslations> {
      return this._translations$.pipe(
        startWith(defaultTranslations),
        map(mergeDeepRight(defaultTranslations)),
      );
    }

    private _getCopyActionAnalyticsEventLabel(copyAction: CopyAction, master: string): string {
      const isMaster = master === copyAction.key;
      const selectedLocaleKeys = copyAction.selectedLanguages.map(locale => locale.key);
      return `CopyLanguage_${copyAction.key}_${isMaster}_${selectedLocaleKeys.join('_')}`;
    }

    private _emitUpdate(localizedContents: LocalizedContents): void {
      this.dispatchEvent(new CustomEvent('update', { detail: localizedContents }));
    }

    private _emitUpdateSelected(selected: string): void {
      this.dispatchEvent(new CustomEvent('update-selected', { detail: selected }));
    }

    private _emitUpdateMaster(master: string): void {
      this.dispatchEvent(new CustomEvent('update-master', { detail: master }));
    }

    private _copyLanguages(copyAction: CopyAction): LocalizedContents {
      return {
        ...copyAction.localizedContents,
        ...copyAction.selectedLanguages.reduce(
          (contents, selectedLanguage) => ({
            ...contents,
            [selectedLanguage.key]: omit(['updated_at'], cloneDeep(copyAction.localizedContents[copyAction.key])),
          }),
          {},
        ),
      };
    }
  }
  return LanguagesEditor;
};

export const languagesEditorTagName = 'vce-languages-editor';
CustomElement(languagesEditorTagName)(createLanguagesEditor());
