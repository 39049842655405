import { CustomElement } from '../../../../lib/custom-element-decorators';
import { HTMLCustomElement, ReactiveAttribute } from '../../../../lib/index';
import {
  CodeEditorToolbarContext,
  CodeEditorToolbarButton,
  ToolbarItemEvents,
  CodeEditorToolbarButtonInputs,
} from '../../interface';
import { combineLatest } from 'rxjs/observable/combineLatest';
import { Callback } from '../../../../lib/reactive-decorators';
import { Observable } from 'rxjs/Observable';
import { takeUntil, take, skip, map } from 'rxjs/operators';

export const createCodeEditorToolbarButton = () => {
  class CodeEditorToolbarButtonImplementation extends HTMLCustomElement implements CodeEditorToolbarButton {
    @Callback('disconnectedCallback') private _disconnect$: Observable<void>;
    @ReactiveAttribute('label', 'label')
    private _label$: Observable<string>;
    @ReactiveAttribute('icon', 'icon')
    private _icon$: Observable<string>;
    private _inputs: CodeEditorToolbarButtonInputs;

    connectedCallback(): void {
      this._inputs$.subscribe(input => (this._inputs = input));
      this._inputs$.pipe(take(1)).subscribe(() => {
        this.dispatchEvent(new CustomEvent(ToolbarItemEvents.Connected, { bubbles: true }));
      });
      this._inputs$.pipe(skip(1)).subscribe(() => {
        this.dispatchEvent(new CustomEvent(ToolbarItemEvents.Updated, { bubbles: true }));
      });
    }

    disconnectedCallback(): void {
      this.dispatchEvent(new CustomEvent(ToolbarItemEvents.Disconnected));
    }

    getItem(_: CodeEditorToolbarContext): HTMLElement {
      const tooltip = document.createElement('e-tooltip');
      tooltip.setAttribute('content', this._inputs.label);
      const button = document.createElement('div');
      button.className = 'e-btn e-btn-onlyicon e-inputgroup__item';
      button.onclick = () => this.dispatchEvent(new CustomEvent(ToolbarItemEvents.Click, { bubbles: true }));
      const icon = document.createElement('e-icon');
      icon.setAttribute('icon', this._inputs.icon);
      button.appendChild(icon);
      tooltip.appendChild(button);
      return tooltip;
    }

    get _inputs$(): Observable<CodeEditorToolbarButtonInputs> {
      return combineLatest(this._label$, this._icon$).pipe(
        takeUntil(this._disconnect$),
        map(([label, icon]) => ({ label, icon })),
      );
    }
  }

  return CodeEditorToolbarButtonImplementation;
};

const name = 'vce-code-editor-toolbar-button';
CustomElement(name)(createCodeEditorToolbarButton());
