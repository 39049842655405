import { createOpenWithEdit, State, VariablesEditorDialogAction } from '../';
import { map } from 'rxjs/operators';
import { Store } from 'redux';
import { EpicDependencies } from '../dependencies.interface';
import { Observable } from 'rxjs/Observable';

export const fillEditDataFromApi = (
  actions$: Observable<VariablesEditorDialogAction>,
  store: Store<State>,
  dependencies: EpicDependencies,
): Observable<VariablesEditorDialogAction> => dependencies.edit$.pipe(map(createOpenWithEdit));
