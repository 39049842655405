export type CompileTokenHtml = (str: string, flippers: []) => string;
export type CompileTokensHtml = (str: string) => string;

export const compileTokens = (compileTokenHtml: CompileTokenHtml): CompileTokensHtml => html => {
  const div = document.createElement('div');
  div.innerHTML = html;
  const tokenSpans = div.querySelectorAll('span[e-token="personalization"]');
  Array.from(tokenSpans).forEach(tokenSpan => {
    const newNode = document.createTextNode(compileTokenHtml(tokenSpan.outerHTML, []));
    tokenSpan.parentElement!.replaceChild(newNode, tokenSpan);
  });
  return div.innerHTML;
};
