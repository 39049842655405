import { handleIE11 } from '../../handle-ie11';
import { sanitizePartialDocument } from '../../../../../../../lib/sanitize';

const tinymce = require('tinymce/tinymce');

interface ConfigOptions {
  window: Window;
  document: Document;
  target: Element;
  toolbar: string[];
}

export type GetTextEditorConfig = (
  { window, document, target, toolbar }: ConfigOptions,
) => {
  content_window: any;
  content_document: any;
  forced_root_block: boolean;
  paste_filter_drop: boolean;
  noneditable_noneditable_class: string;
  plugins: string[];
  font_formats: string;
  fontsize_formats: string;
  fixed_toolbar_container: string;
  extended_valid_elements: string;
  skin: boolean;
  inline: boolean;
  menubar: boolean;
  convert_urls: boolean;
  target: any;
  toolbar: Object;
  setup: Function;
  paste_as_text: boolean;
};

export const getTextEditorConfig = (
  fonts: string[],
  fontSizes: string[],
  plugins: string[],
  toolbarContainer: string = '#tiny-mce-toolbar-container',
): GetTextEditorConfig => ({ window, document, target, toolbar }) =>
  ({
    content_window: window,
    content_document: document,
    forced_root_block: false,
    paste_filter_drop: false,
    noneditable_noneditable_class: 'cbNonEditable',
    plugins,
    font_formats: fonts.join(';'),
    fontsize_formats: fontSizes.join(' '),
    fixed_toolbar_container: toolbarContainer,
    extended_valid_elements: '-span[*],-a[*]',
    skin: false,
    inline: true,
    menubar: false,
    convert_urls: false,
    target: target,
    toolbar,
    setup: editor => {
      tinymce.Env.ie === 11 ? handleIE11(editor) : undefined;
      editor.on('BeforeSetContent', object => {
        object.content = sanitizePartialDocument(object.content);
      });
      // SPAN FIX https://github.com/tinymce/tinymce/issues/4290 because TinyMCE hasn't fixed it yet
      const appendBogus = () => {
        let el = editor.getElement();
        if ('SPAN' === el.tagName && !el.querySelector('br[data-mce-bogus]')) {
          let bogus = document.createElement('br');
          bogus.setAttribute('data-mce-bogus', '1');
          el.append(bogus);
        }
      };
      editor.on('keydown', appendBogus);
    },
    paste_as_text: true,
  } as any);
