import { CustomElement } from '../../../../lib/custom-element-decorators';
import { Callback, ViewChild } from '../../../../lib/reactive-decorators';
import { fromEvent } from 'rxjs/observable/fromEvent';
import { IHtmlEditor } from '../../components/html-editor/index';
import { CodeEditorToolbarConnectedEvent } from '../../components/code-editor-toolbar/index';
import {
  CodeEditorToolbarEvents,
  CodeEditorTwigValidatorEvents,
  CodeEditorValidationStatusEvents,
  HtmlEditorEvents,
} from '../../interface';
import { combineLatest } from 'rxjs/observable/combineLatest';
import { merge } from 'rxjs/observable/merge';
import { Observable } from 'rxjs';
import { distinctUntilChanged, switchMap, takeUntil } from 'rxjs/operators';
import { equals, isNil } from 'ramda';
import { ReactiveAttribute, attributeToBoolean } from '../../../../lib/reactive-decorators';
import { HTMLCustomElement } from '../../../../lib/html-custom-element';

export interface ICodeEditor extends HTMLElement {
  singleLine: boolean;
  disabled: boolean;
}

export const createCodeEditor = () => {
  class CodeEditorImplementation extends HTMLCustomElement {
    @Callback('disconnectedCallback') private _disconnect$: Observable<void>;
    @ViewChild('[editor]') private _editor: IHtmlEditor;
    @ViewChild('[twig-validator]') private _twigValidator: any;
    @ViewChild('[validation-status-display]') private _validationStatusDisplay: any;
    @ReactiveAttribute('single-line', 'singleLine', attributeToBoolean)
    private _singleLine$: Observable<boolean>;
    @ReactiveAttribute('disabled', 'disabled', attributeToBoolean)
    private _disabled$: Observable<boolean>;

    connectedCallback(): void {
      fromEvent<CodeEditorToolbarConnectedEvent>(this, CodeEditorToolbarEvents.Connected)
        .pipe(takeUntil(this._disconnect$))
        .subscribe(({ target }) => target.setContext({ insertText: (text: string) => this._editor.insertText(text) }));

      this._editorAndTwigValidatorConnected$.subscribe(() => this._triggerValidation(this._editor.html));

      fromEvent(this, CodeEditorValidationStatusEvents.Connected)
        .pipe(
          switchMap(() => this.validationEvent$()),
          takeUntil(this._disconnect$),
        )
        .subscribe((e: CustomEvent) => {
          this._validationStatusDisplay.validationStatus = e.detail;
        });

      this._editorAndTwigValidatorConnected$
        .pipe(switchMap(() => fromEvent(this._editor, HtmlEditorEvents.Changed)))
        .subscribe((e: CustomEvent) => this._triggerValidation(e.detail));

      combineLatest(this._singleLine$, this._editorConnected$).subscribe(this.handleSingleLineChange.bind(this));

      const focusOrBlur$ = merge(fromEvent(this, 'focused.codemirror'), fromEvent(this, 'blurred.codemirror'));
      combineLatest(focusOrBlur$, this._singleLine$)
        .pipe(takeUntil(this._disconnect$))
        .subscribe(this.handleFocusChange.bind(this));

      combineLatest(this._disabled$, this._editorConnected$).subscribe(([disabled]) => {
        this._editor.disabled = disabled;
        this._editor.classList.toggle('e-input-disabled', disabled);
      });

      this._disabled$.subscribe((disabled: any) => {
        const disabledValueForAttribute = `${!!disabled}`;
        if (disabledValueForAttribute !== this.getAttribute('disabled')) {
          this.setAttribute('disabled', disabledValueForAttribute);
        }
      });
    }

    private handleSingleLineChange([singleLine]: [boolean]): void {
      this._editor.classList.toggle('e-input', singleLine);
      this._editor.classList.toggle('e-inputgroup__item', singleLine);
      this._editor.classList.toggle('e-inputgroup__item-fluid', singleLine);
      this._editor.classList.toggle('e-inputgroup__item-first', singleLine);
      this._editor.singleLine = singleLine;
    }

    private handleFocusChange([event, singleLine]: [CustomEvent, boolean]): void {
      if (!singleLine) {
        return;
      }

      if (event.type === 'focused.codemirror') {
        this._editor.classList.add('e-input-focus');
      } else {
        this._editor.classList.remove('e-input-focus');
      }
    }

    private validationEvent$(): Observable<any> {
      return fromEvent(this._twigValidator, CodeEditorTwigValidatorEvents.ValidationEvent).pipe(
        distinctUntilChanged((prev: CustomEvent, current: CustomEvent) => equals(prev.detail, current.detail)),
        takeUntil(this._disconnect$),
      );
    }

    private get _editorAndTwigValidatorConnected$(): Observable<any> {
      return combineLatest(this._editorConnected$, fromEvent(this, CodeEditorTwigValidatorEvents.Connected)).pipe(
        takeUntil(this._disconnect$),
      );
    }

    private get _editorConnected$(): Observable<any> {
      return fromEvent(this, HtmlEditorEvents.Connected).pipe(takeUntil(this._disconnect$));
    }

    private _triggerValidation(html: string | undefined): void {
      if (!isNil(html)) {
        this._twigValidator.code = html;
      }
    }
  }

  return CodeEditorImplementation;
};

const name = 'vce-code-editor';
CustomElement(name)(createCodeEditor());
