import { ValidationResult } from '..';
import { ValidationErrorTranslations } from '../../translations.interface';

export type ValidateDefaultValue = (value: string, translation: ValidationErrorTranslations) => ValidationResult;

export const validateDefaultValue: ValidateDefaultValue = (value, translation) => {
  const isInvalid = /[{}]/g.test(value);
  return {
    errorMessage: isInvalid ? translation.wrongValue : '',
    isInvalid,
  };
};
