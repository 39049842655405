import { Observable } from 'rxjs/Observable';
import {
  DomBordererType,
  DomBordererAction,
  DomBordererActionType,
  DomBordererCreateAction,
  DomBordererRemoveAction,
} from './models';
import { switchMap, map } from 'rxjs/operators';
import { fromEvent } from 'rxjs/observable/fromEvent';
import { merge } from 'rxjs/observable/merge';

export type OnHover = (iframe$: Observable<HTMLIFrameElement>, selector: string) => Observable<DomBordererAction[]>;
export const onHover: OnHover = (iframe$, selector) =>
  merge(
    editableEvents(iframe$, selector, 'mouseenter').pipe(
      map((hoverEvent: Event) => [
        {
          actionType: DomBordererActionType.create,
          element: hoverEvent.currentTarget,
          type: DomBordererType.hover,
        } as DomBordererCreateAction,
      ]),
    ),
    editableEvents(iframe$, selector, 'mouseleave').pipe(
      map((hoverEvent: Event) => [
        {
          actionType: DomBordererActionType.remove,
          element: hoverEvent.currentTarget,
          type: DomBordererType.hover,
        } as DomBordererRemoveAction,
      ]),
    ),
  );

function editableEvents(iframe$: Observable<HTMLIFrameElement>, selector: string, event: string): Observable<Event> {
  return iframe$.pipe(
    map(iframe => iframe.contentWindow!.document.body.querySelectorAll(selector)),
    switchMap(elements => fromEvent(elements, event)),
  );
}
