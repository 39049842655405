import { HTMLCustomElement } from '../../../../lib/html-custom-element';
import { CustomElement } from '../../../../lib/custom-element-decorators';
import { ReactiveAttribute, Callback } from '../../../../lib/reactive-decorators';
import { ViewChild } from '../../../../lib/reactive-decorators';
import { Observable } from 'rxjs/Observable';
import { fromEvent } from 'rxjs/observable/fromEvent';
import { takeUntil, map, sample, filter } from 'rxjs/operators';

export type UiColorPicker = HTMLElement & { value: string };
type UiColorInputChange = CustomEvent & {
  detail: {
    value: string;
  };
};

export const createColorInput = (): { new (): HTMLElement } => {
  class ColorInput extends HTMLCustomElement {
    @ReactiveAttribute('value', 'value')
    private _value$: Observable<string>;
    @Callback('disconnectedCallback') private _disconnect$: Observable<void>;
    @ViewChild('e-color-picker') private _colorPicker: UiColorPicker;

    connectedCallback(): void {
      this._createContent();

      this._value$.pipe(takeUntil(this._disconnect$)).subscribe(value => {
        this._colorPicker.value = value;
      });

      fromEvent<UiColorInputChange>(this._colorPicker, 'change')
        .pipe(
          sample(fromEvent(this._colorPicker, 'change')),
          filter(event => event.detail),
          map(event => event.detail.value),
          takeUntil(this._disconnect$),
        )
        .subscribe(color => {
          this._colorPicker.value = color;
          this.dispatchEvent(new CustomEvent('update', { detail: { value: color } }));
        });
    }

    private _createContent(): void {
      this.innerHTML = '';
      const colorPicker = window.document.createElement('e-color-picker');
      colorPicker.setAttribute('key', 'vce-color-input');
      this.appendChild(colorPicker);
    }
  }
  return ColorInput;
};

export const name = 'vce-color-input';
CustomElement(name)(createColorInput());
