import { HTMLCustomElement } from '../../../../lib/html-custom-element';
import { CustomElement } from '../../../../lib/custom-element-decorators';
import { ReactiveAttribute, Callback, attributeToBoolean, BooleanAttribute } from '../../../../lib/reactive-decorators';
import 'codemirror';
import 'codemirror/mode/htmlmixed/htmlmixed.js';
import 'codemirror/addon/display/placeholder.js';
import 'codemirror/addon/selection/active-line.js';
import 'codemirror/addon/display/autorefresh.js';
import 'codemirror/addon/lint/lint.js';
import 'codemirror/addon/lint/lint.css';
import { Observable } from 'rxjs/Observable';
import { merge } from 'rxjs/observable/merge';
import { fromEvent } from 'rxjs/observable/fromEvent';
import { first, switchMap, takeUntil, filter, withLatestFrom, map, pairwise, startWith, share } from 'rxjs/operators';
import * as codeMirrorFactory from 'codemirror';
import { Memoize } from 'typescript-memoize';
import { HtmlEditorStrategy, TokenDefinition, CodeMirrorComponent } from '../../interface';
import { transformToken } from './transform-token';
import 'codemirror-no-newlines';

type CODE_MIRROR_OPTIONS = {
  viewportMargin: Number;
  styleActiveLine: Boolean;
  placeholder: String;
  gutters: Array<String> | Array<Object>;
};

export const CODE_MIRROR_OPTIONS: CODE_MIRROR_OPTIONS = {
  viewportMargin: Infinity,
  styleActiveLine: true,
  placeholder: '',
  gutters: [],
};

export const CODE_MIRROR_LINT_GUTTER = 'CodeMirror-lint-markers';

export type CodeMirrorEvent = CustomEvent & {
  target: CodeMirrorComponent;
};

export const createCodeMirror = (codeMirrorFactory: any) => {
  class CodeMirror extends HTMLCustomElement {
    @ReactiveAttribute('mode', 'mode')
    private _mode$: Observable<string>;
    @ReactiveAttribute('disabled', 'disabled', attributeToBoolean)
    private _disabled$: Observable<boolean>;
    @ReactiveAttribute('placeholder', 'placeholder')
    private _placeholder$: Observable<string>;
    @ReactiveAttribute('line-wrapping', 'lineWrapping', attributeToBoolean)
    private _lineWrapping$: Observable<boolean>;
    @ReactiveAttribute('html', 'html')
    private _html$: Observable<string>;
    @ReactiveAttribute('auto-refresh', 'autoRefresh', attributeToBoolean)
    private _autoRefresh$: Observable<boolean>;
    @ReactiveAttribute('line-numbers', 'lineNumbers', attributeToBoolean)
    private _lineNumbers: Observable<boolean>;
    @ReactiveAttribute('single-line', 'singleLine', attributeToBoolean)
    private _singleLine$: Observable<boolean>;
    @ReactiveAttribute('lint', 'lint', attributeToBoolean)
    private _lint$: Observable<boolean>;
    @BooleanAttribute('disable-autofocus') _disableAutofocus: boolean = false;
    @Callback('disconnectedCallback') private _disconnect$: Observable<void>;
    private _codeMirror: CodeMirror.Editor | any;
    private _strategies: HtmlEditorStrategy[];

    init(): void {
      this._dispatchFocusedEvent = this._dispatchFocusedEvent.bind(this);
      this._dispatchBlurredEvent = this._dispatchBlurredEvent.bind(this);
    }

    connectedCallback(): void {
      this.dispatchEvent(new CustomEvent('connected.codemirror', { bubbles: true }));
      this._codeMirror = this._initCodeMirror();
      this._htmlInputChange$.pipe(takeUntil(this._disconnect$)).subscribe(html => {
        this._codeMirror.setValue(html);
        this._runTokenStrategies();
      });
      this._change$.pipe(takeUntil(this._disconnect$)).subscribe(event => this._emitUpdate(event));
      this._mode$.pipe(takeUntil(this._disconnect$)).subscribe(mode => this._codeMirror.setOption('mode', mode));
      this._placeholder$.pipe(takeUntil(this._disconnect$)).subscribe(placeholder => {
        this._codeMirror.setOption('placeholder', '');
        this._codeMirror.setOption('placeholder', placeholder);
      });
      this._lineWrapping$
        .pipe(takeUntil(this._disconnect$))
        .subscribe(lineWrapping => this._codeMirror.setOption('lineWrapping', lineWrapping));
      this._disabled$
        .pipe(takeUntil(this._disconnect$))
        .subscribe(disabled => this._codeMirror.setOption('readOnly', disabled));
      this._lineNumbers
        .pipe(takeUntil(this._disconnect$))
        .subscribe(lineNumbers => this._codeMirror.setOption('lineNumbers', lineNumbers));
      this._autoRefresh$
        .pipe(takeUntil(this._disconnect$))
        .subscribe(autoRefresh => this._codeMirror.setOption('autoRefresh', autoRefresh));
      this._singleLine$.pipe(takeUntil(this._disconnect$)).subscribe(singleLine => {
        this._codeMirror.setOption('noNewlines', singleLine);
        this._codeMirror.setOption('scrollbarStyle', singleLine ? 'null' : 'native');
      });
      this._lint$.pipe(takeUntil(this._disconnect$)).subscribe(lint => {
        this._codeMirror.setOption('lint', lint);
        const gutters: Array<String> = [...this._codeMirror.getOption('gutters')];
        const lintGutterIndex = gutters.indexOf(CODE_MIRROR_LINT_GUTTER);
        if (lint && lintGutterIndex === -1) {
          gutters.push(CODE_MIRROR_LINT_GUTTER);
          this._codeMirror.setOption('gutters', gutters);
        } else if (!lint && lintGutterIndex > -1) {
          gutters.splice(lintGutterIndex, 1);
          this._codeMirror.setOption('gutters', gutters);
        }
      });
      merge(this._paste$, this._drop$)
        .pipe(
          switchMap(() => this._codeMirrorChange$.pipe(first())),
          takeUntil(this._disconnect$),
        )
        .subscribe(() => this._runTokenStrategies());

      this._focus$.pipe(takeUntil(this._disconnect$)).subscribe(this._dispatchFocusedEvent);
      this._blur$.pipe(takeUntil(this._disconnect$)).subscribe(this._dispatchBlurredEvent);
    }

    disconnectedCallback(): void {
      this.dispatchEvent(new CustomEvent('disconnected.codemirror'));
    }

    setLintCallback(callback: Function): void {
      this._codeMirror.setOption('lint', {
        getAnnotations: callback,
        async: true,
      });
    }

    insertText(html: string): void {
      this._codeMirror['replaceSelection'](html);
      this._runTokenStrategies();
      this._codeMirror.focus();
      if (this._codeMirror.hasFocus()) {
        this._dispatchFocusedEvent();
      }
    }

    setStrategies(strategies: HtmlEditorStrategy[]): void {
      this._strategies = strategies;
      this._runTokenStrategies();
    }

    private _dispatchFocusedEvent(): void {
      this.dispatchEvent(new CustomEvent('focused.codemirror', { bubbles: true }));
    }

    private _dispatchBlurredEvent(): void {
      this.dispatchEvent(new CustomEvent('blurred.codemirror', { bubbles: true }));
    }

    private _runTokenStrategies(): void {
      if (!this._strategies) return;
      const html = this._codeMirror.getValue();
      this._applyTokens(this._getTokens(html), html);
    }

    private _getTokens(html: string): TokenDefinition[] {
      const tokens = this._strategies
        .filter(strategy => strategy.findTokens)
        .map(strategy => strategy.findTokens(html));
      return [].concat.apply([], tokens);
    }

    private _applyTokens(tokens: any[], html: string): void {
      let textMarkers = [] as any[];
      const insertText = index => (text: string): void => {
        this._codeMirror.setSelection(textMarkers[index].find().from, textMarkers[index].find().to);
        this.insertText(text);
      };
      tokens.map((token, index) => transformToken(html, token, insertText(index))).forEach(token => {
        const selection = this._codeMirror['markText'](token.from, token.to, token.options);
        textMarkers.push(selection);
      });
    }

    private get _htmlInputChange$(): Observable<string> {
      return this._html$.pipe(
        filter(html => html !== this._codeMirror.getValue()),
        startWith(''),
      );
    }

    private get _change$(): Observable<string> {
      return this._codeMirrorChange$.pipe(
        withLatestFrom(merge(this._previousChange$, this._html$.pipe(startWith('')))),
        filter(([codeMirrorValue, previousActionValue]) => codeMirrorValue !== previousActionValue),
        map(([codeMirrorValue, _]) => codeMirrorValue),
      );
    }

    private _initCodeMirror(): CodeMirror.Editor {
      const codeMirror = codeMirrorFactory(this, { ...CODE_MIRROR_OPTIONS });
      codeMirror.refresh();
      if (!this._disableAutofocus) {
        codeMirror.focus();
        if (codeMirror.hasFocus()) {
          this._dispatchFocusedEvent();
        }
      }
      return codeMirror;
    }

    private _emitUpdate(newContent: string): void {
      this.dispatchEvent(new CustomEvent('update', { detail: newContent, bubbles: true }));
    }

    private get _previousChange$(): Observable<string> {
      return this._codeMirrorChange$.pipe(
        pairwise(),
        map(([old, current]) => old),
      );
    }

    @Memoize()
    private get _codeMirrorChange$(): Observable<string> {
      return fromEvent<CodeMirror.Editor>(this._codeMirror, 'change').pipe(
        map(() => this._codeMirror.getValue()),
        share(),
      );
    }

    @Memoize()
    private get _paste$(): Observable<void | CodeMirror.Editor> {
      return fromEvent<CodeMirror.Editor>(this._codeMirror, 'paste').pipe(share());
    }

    @Memoize()
    private get _drop$(): Observable<void | CodeMirror.Editor> {
      return fromEvent<CodeMirror.Editor>(this._codeMirror, 'drop').pipe(share());
    }

    @Memoize()
    private get _focus$(): Observable<void | CodeMirror.Editor> {
      return fromEvent<CodeMirror.Editor>(this._codeMirror, 'focus').pipe(share());
    }

    @Memoize()
    private get _blur$(): Observable<void | CodeMirror.Editor> {
      return fromEvent<CodeMirror.Editor>(this._codeMirror, 'blur').pipe(share());
    }
  }

  return CodeMirror;
};

export const name = 'vce-codemirror';
CustomElement(name)(createCodeMirror(codeMirrorFactory));
