import { mergeMap, map, filter } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { ofType } from 'redux-observable';
import { Store } from 'redux';
import { EpicDependencies } from '../../dependencies.interface';
import { validateDefaultValue, validateEditableImage } from '../../validations';
import { of } from 'rxjs/observable/of';
import { fromPromise } from 'rxjs/observable/fromPromise';
import {
  VariablesEditorDialogAction,
  createSetDefaultValueValidation,
  OPEN_WITH_EDIT,
  OpenWithEdit,
} from '../../actions';
import { State } from '../../state.interface';

export const setDefaultValueValidationOnOpenWithEdit = (
  actions$: Observable<VariablesEditorDialogAction>,
  store: Store<State>,
  _dependencies: EpicDependencies,
): Observable<any> =>
  actions$.pipe(
    filter(() => store.getState().useEditableImage),
    ofType(OPEN_WITH_EDIT),
    mergeMap((action: OpenWithEdit) => {
      const state = store.getState();
      if (state.variableConfiguration.type === 'image') {
        return fromPromise(
          validateEditableImage(
            action.payload.variableConfiguration.default_value,
            state.translations.validationErrors,
          ),
        );
      }
      return of(
        validateDefaultValue(action.payload.variableConfiguration.default_value, state.translations.validationErrors),
      );
    }),
    map(({ isInvalid, errorMessage }) => createSetDefaultValueValidation(isInvalid, errorMessage)),
  );
