import { createReactiveProperty } from './lib/create-reactive-property';
import { Subject } from 'rxjs/Subject';
import { fromEvent } from 'rxjs/observable/fromEvent';
import { filter } from 'rxjs/operators';

export function ConnectedViewChild(childSelector: string): PropertyDecorator {
  return (target: Object, propertyKey: string): void => {
    const { subject } = createReactiveProperty({
      target: target.constructor.prototype,
      propertyKey,
      createObservable: () => new Subject(),
    });
    const oldCallback = target.constructor.prototype.connectedCallback || (() => {});
    target.constructor.prototype.connectedCallback = function(arg): void {
      oldCallback.call(this, arg);
      if (!!this.querySelector(childSelector)) {
        this[subject].next(this.querySelector(childSelector));
      } else {
        fromEvent<Event>(this, 'connected')
          .pipe(filter(event => this.querySelector(childSelector) === event.target))
          .subscribe(event => this[subject].next(event.target));
      }
    };
  };
}
