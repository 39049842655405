import { Observable } from 'rxjs';
import { withLatestFrom, map, share } from 'rxjs/operators';
import { getBlock } from './get-block';
import { Inputs, VcePluginBlockToolbarElement } from '../interface';
import { merge } from 'rxjs/observable/merge';

export type GetBlockUnderMouse$ = (inputs: Inputs) => Observable<VcePluginBlockToolbarElement | undefined>;

export const getBlockUnderMouse$: GetBlockUnderMouse$ = ({ mousemove$, blocks$, resize$, scroll$, window$ }) =>
  mousemove$.pipe(
    remindWhen(resize$, scroll$),
    withLatestFrom(window$),
    map(([mouseMoveEvent, window]) => window.document.elementFromPoint(mouseMoveEvent.clientX, mouseMoveEvent.clientY)),
    withLatestFrom(blocks$),
    map(([elementUnderMouse, blocks]: [HTMLElement, VcePluginBlockToolbarElement[]]) =>
      getBlock(elementUnderMouse, blocks),
    ),
  );

export function remindWhen<T>(...notifiers$: Observable<any>[]): (source: Observable<T>) => Observable<T> {
  return source$ => {
    const sharedSource$ = source$.pipe(share());
    return merge(
      sharedSource$,
      merge(...notifiers$).pipe(withLatestFrom(sharedSource$, (_notification, value) => value)),
    );
  };
}
