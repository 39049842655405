import { HTMLCustomElement } from '../../../../lib/html-custom-element';
import { CustomElement } from '../../../../lib/custom-element-decorators';
import { ReactiveAttribute, Callback } from '../../../../lib/reactive-decorators';
import { Observable } from 'rxjs/Observable';
import { render } from 'react-dom';
import * as React from 'react';
import { reactCustomElementWrapper } from '../../../../lib/react-custom-element-wrapper';
import { combineLatest } from 'rxjs/observable/combineLatest';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { pipe, keys, assoc } from 'ramda';
import { locales } from '../../lib/locales';
import { Locale, GetLocaleDisplayName } from '../../interface';
import { createGetLocaleDisplayName } from '../../lib/get-locale-display-name';
import { Translations } from './translations.interface';
import { Memoize } from 'typescript-memoize';
import { defaultTranslations } from './translations.default';
import { mergeDeepRight } from 'ramda';

const EDatagrid = reactCustomElementWrapper('e-datagrid');
const EDatagridColumn = reactCustomElementWrapper('e-datagrid-column');
const EDatagridItemAction = reactCustomElementWrapper('e-datagrid-item-action');

const selectedMarker =
  '<div style="background: #39aad3; width: 4px; height: 100%; position: absolute; top: 0; left: 0;"></div>';
const masterMarker = '<span class="text-color-shade"> - Master</span>';
const decorateSelectedName = selected => row =>
  selected === row.key ? assoc('name', `${row.name}${selectedMarker}`, row) : row;
const decorateMasterName = master => row =>
  master === row.key ? assoc('name', `${row.name}${masterMarker}`, row) : row;

type Content = { [locale: string]: any };
export type LanguagesDataGridRow = {
  key: string;
  name: string;
  protected: boolean;
};

export const createLanguagesList = (
  getLocaleName: GetLocaleDisplayName = createGetLocaleDisplayName(locales),
): { new (): HTMLElement } => {
  class LanguagesList extends HTMLCustomElement {
    @ReactiveAttribute('localized-contents', 'localizedContents', JSON.parse)
    private _localizedContents$: Observable<Content>;
    @ReactiveAttribute('master', 'master')
    private _master$: Observable<string>;
    @ReactiveAttribute('selected', 'selected')
    private _selected$: Observable<string>;
    @ReactiveAttribute('translations', 'translations', JSON.parse)
    private _translations$: Observable<Translations>;

    @Callback('disconnectedCallback') private _disconnect$: Observable<void>;

    connectedCallback(): void {
      combineLatest(this._localizedContents$, this._master$, this._selected$, this._getTranslations$)
        .pipe(takeUntil(this._disconnect$))
        .subscribe(([localizedContents, master, selected, translations]) => {
          return render(this._render(this._convert(localizedContents, master, selected), translations), this);
        });
      this.dispatchEvent(new CustomEvent('connected', { bubbles: true }));
    }

    @Memoize()
    private get _getTranslations$(): Observable<Translations> {
      return this._translations$.pipe(
        startWith(defaultTranslations),
        map(mergeDeepRight<Translations>(defaultTranslations)),
      );
    }

    private _render(localizedContents: any, translations: Translations): JSX.Element {
      return (
        <div className="e-stretch">
          <EDatagrid prop-data={localizedContents} hide-pagination hide-search sorting-key="name">
            <EDatagridColumn head={translations.columnName} content-key="name" width="50%" render-html />
            <EDatagridColumn head={translations.columnLanguageTag} content-key="key" width="50%" render-html />
            <EDatagridItemAction
              on-trigger={event => this._emitActionEvent(event)}
              tooltip={translations.tooltipActionCopy}
              icon="files-o"
              action="copy"
            />
            <EDatagridItemAction
              on-trigger={event => this._emitActionEvent(event)}
              tooltip={translations.tooltipActionDelete}
              icon="trash-o"
              action="delete"
              disabled-key="protected"
            />
          </EDatagrid>
        </div>
      );
    }

    private _emitActionEvent(event: any): void {
      this.dispatchEvent(
        new CustomEvent('action', {
          bubbles: true,
          detail: { type: event.target.getAttribute('action'), data: event.detail.data },
        }),
      );
    }

    private _convert(localizedContents: Content, master: string, selected: string): LanguagesDataGridRow[] {
      return keys(localizedContents).map(key =>
        pipe(
          this._convertLocalesToRows(master),
          decorateSelectedName(selected),
          decorateMasterName(master),
        )(key as any),
      );
    }

    private _convertLocalesToRows(master: string): (key: string) => Locale & { protected: boolean } {
      return key => ({ key, name: getLocaleName(key), protected: master === key });
    }
  }
  return LanguagesList;
};

export const languagesListagName = 'vce-languages-list';
CustomElement(languagesListagName)(createLanguagesList());
