import { CustomElement } from '../../../../lib/custom-element-decorators';
import { IVcePlugin } from '../../../../lib/vce-plugin.interface';
import { HTMLCustomElement } from '../../../../lib/html-custom-element';
import { Callback } from '../../../../lib';
import { Observable } from 'rxjs/Observable';
import { fromEvent } from 'rxjs/observable/fromEvent';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { merge } from 'rxjs/observable/merge';

export interface VcePluginImageLoad extends HTMLElement, IVcePlugin {}
export function createVcePluginImageLoad(global: Window): { new (): VcePluginImageLoad } {
  class VcePluginImageLoadClass extends HTMLCustomElement implements VcePluginImageLoad {
    @Callback('readyCallback') private _ready$: Observable<HTMLIFrameElement>;
    @Callback('disconnectedCallback') private _disconnect$: Observable<void>;

    connectedCallback(): void {
      this._ready$
        .pipe(
          map(iframe => iframe.contentWindow!.document.body.querySelectorAll('img')),
          switchMap(elements => merge(fromEvent(elements, 'load'), fromEvent(elements, 'error'))),
          takeUntil(this._disconnect$),
        )
        .subscribe(event => {
          this.dispatchEvent(new CustomEvent('editable.recalculate', { bubbles: true }));
        });
      this.dispatchEvent(new CustomEvent('plugin.connected', { bubbles: true }));
    }

    disconnectedCallback(): void {
      this.dispatchEvent(new CustomEvent('plugin.disconnected'));
    }
  }
  return VcePluginImageLoadClass;
}

const name = 'vce-plugin-borderer-image-load-strategy';
CustomElement(name)(createVcePluginImageLoad(window));
