import * as React from 'react';
import { EditableImageEditor } from '../variable-field/variable-field.editable-image';
import { ReactNode } from 'react-redux';
import { Translations } from '../../components/variables-editor/state/state.interface';
import { validateEditableImage } from '../../components/variable-editor-dialog/state/validations';
import { Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

export type EditableImagePopupTranslations = {
  defaultValue: Translations['defaultValue'];
  validationErrors: Translations['validationErrors'];
  cancelButton: string;
  applyButton: string;
};

export type EditableImagePopupProps = {
  value: string;
  isOpen: boolean;
  onApply: (value: string) => void;
  onCancel: () => void;
  translations: EditableImagePopupTranslations;
  popoverRef: any;
};

export type EditableImagePopupState = {
  value: string;
  isLoading: boolean;
  validation: {
    isInvalid: boolean;
    errorMessage: string;
  };
};

const initialState = {
  value: '',
  isLoading: false,
  validation: {
    isInvalid: false,
    errorMessage: '',
  },
};

export class EditableImagePopup extends React.Component<EditableImagePopupProps, EditableImagePopupState> {
  state = { ...initialState };
  private _value$: Subject<string> = new Subject();

  componentDidMount(): void {
    this._value$
      .pipe(
        tap(value => this.setState({ value })),
        switchMap(value => validateEditableImage(value, this.props.translations.validationErrors)),
      )
      .subscribe(validation => this.setState({ validation, isLoading: false }));
  }

  componentDidUpdate(props): void {
    if (props.isOpen !== this.props.isOpen) {
      this.setState({ ...initialState, value: this.props.value });
    }
  }

  render(): ReactNode {
    return (
      <div
        className="e-box e-box-simple e-popover"
        ref={this.props.popoverRef}
        style={{ minWidth: this.props.isOpen? '353px' : undefined, width: this.props.isOpen ? '353px' : undefined}}
      >
        <div className="e-field">
          <EditableImageEditor
            onChange={this._onChange}
            invalid={this.state.validation.isInvalid}
            translations={this.props.translations.defaultValue}
            value={this.state.value}
            hideThumbnailButton={true}
          />
          {this.state.validation.isInvalid && (
            <span className="e-field__message e-field__message-error">{this.state.validation.errorMessage}</span>
          )}
        </div>
        <div className="e-buttongroup">
          <button className="e-btn e-btn-narrow" onClick={this.props.onCancel}>
            {this.props.translations.cancelButton}
          </button>
          <button className="e-btn e-btn-secondary" onClick={this._onApply} disabled={this._isDisabled()}>
            {this.props.translations.applyButton}
          </button>
        </div>
      </div>
    );
  }

  private _onChange = (value: string): void => this._value$.next(value);

  private _onApply = (): void => {
    this.props.onApply(this.state.value);
  };

  private _isDisabled = (): boolean => {
    return this.state.value === this.props.value || this.state.isLoading || this.state.validation.isInvalid;
  };
}
