import { validContentChangeOf, validContinuousChangeOf } from '../valid-content-change';
import { TextEditorEventData } from '../../models';
import { Observable } from 'rxjs/Observable';
import { merge } from 'rxjs/observable/merge';
import { debounceTime, map, filter } from 'rxjs/operators';
import { TextEditor } from '../text-editor';
import { TinymceEditor } from '../../models/tinymce-editor/index';
import { eventFromPatternTinyMCE } from '../../../../../../lib/eventFromPatternTinyMCE';
import { fromEvent } from 'rxjs/observable/fromEvent';

export class TextEditorEvents {
  private _editable: Element;
  private _editor: TinymceEditor;

  static create(editable: Element, editor: TinymceEditor): TextEditorEvents {
    return new TextEditorEvents(editable, editor);
  }

  constructor(editable: Element, editor: TinymceEditor) {
    this._editable = editable;
    this._editor = editor;
  }

  get valueChange$(): Observable<TextEditorEventData> {
    return eventFromPatternTinyMCE(this._editor, 'change').pipe(
      filter(validContentChangeOf(this._editor)),
      map(() => this._editorEvents()),
    );
  }

  get focus$(): Observable<TextEditorEventData> {
    return eventFromPatternTinyMCE(this._editor, 'focus').pipe(map(event => this._editorEvents({ event })));
  }

  get continuousChange$(): Observable<TextEditorEventData> {
    return merge(
      eventFromPatternTinyMCE(this._editor, 'keyup'),
      eventFromPatternTinyMCE(this._editor, 'SetContent'),
      eventFromPatternTinyMCE(this._editor, 'BeforeExecCommand'),
    ).pipe(
      filter(validContinuousChangeOf(this._editor)),
      debounceTime(10),
      map(() => this._editorEvents()),
    );
  }

  get blur$(): Observable<TextEditorEventData> {
    return eventFromPatternTinyMCE(this._editor, 'blur').pipe(map(event => this._editorEvents({ event })));
  }

  get externalButtonClick$(): Observable<TextEditorEventData> {
    return eventFromPatternTinyMCE(this._editor, 'toolbar:external-button:click').pipe(
      map(({ data }) => this._editorEvents({ data })),
    );
  }

  get dragEnter$(): Observable<TextEditorEventData> {
    return fromEvent(this._editable, 'dragenter').pipe(map(() => this._editorEvents()));
  }

  get dragLeave$(): Observable<TextEditorEventData> {
    return fromEvent(this._editable, 'dragleave').pipe(map(() => this._editorEvents()));
  }

  get dragOver$(): Observable<TextEditorEventData> {
    return fromEvent(this._editable, 'dragover').pipe(map(event => this._editorEvents({ event })));
  }

  get drop$(): Observable<TextEditorEventData> {
    return fromEvent(this._editable, 'drop').pipe(map(event => this._editorEvents({ event })));
  }

  private _editorEvents({ data = undefined, event = undefined } = {} as any): TextEditorEventData {
    return {
      editable: this._editable,
      editor: TextEditor.create(this._editable, this._editor),
      event: event,
      data: data,
    } as any;
  }
}
