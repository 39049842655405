import { CustomElement } from '../../../../lib/custom-element-decorators';
import { IVcePlugin } from '../../../../lib/vce-plugin.interface';
import { Callback, HTMLCustomElement, ReactiveAttribute } from '../../../../lib';
import { Observable } from 'rxjs/Observable';
import { actions, Actions } from './actions';
import { applyActions, ApplyActions } from './apply-actions';
import { map, takeUntil } from 'rxjs/operators';
import { fromEvent } from 'rxjs/observable/fromEvent';
import * as render from './render';

export interface VcePluginBordererCore extends HTMLElement, IVcePlugin {}
export function createVcePluginBordererCore(
  actions: Actions,
  applyActions: ApplyActions,
  render: render.Render,
): { new (): VcePluginBordererCore } {
  class VcePluginBordererCoreClass extends HTMLCustomElement implements VcePluginBordererCore {
    @Callback('readyCallback') private _ready$: Observable<HTMLIFrameElement>;
    @Callback('disconnectedCallback') private _disconnect$: Observable<void>;
    @ReactiveAttribute('force', 'force')
    private _force$: Observable<string>;

    connectedCallback(): void {
      actions(
        this._force$.pipe(map(value => value === 'true')),
        fromEvent(this, 'editable.blur').pipe(map((event: CustomEvent) => event.detail)),
        fromEvent(this, 'editable.focus').pipe(map((event: CustomEvent) => event.detail)),
        this._ready$,
        fromEvent(this, 'editable.recalculate'),
        '[e-editable]',
      )
        .pipe(takeUntil(this._disconnect$))
        .subscribe(applyActions(render));
      this.dispatchEvent(new CustomEvent('plugin.connected', { bubbles: true }));
    }

    disconnectedCallback(): void {
      this.dispatchEvent(new CustomEvent('plugin.disconnected'));
    }
  }
  return VcePluginBordererCoreClass;
}

const name = 'vce-plugin-borderer-core';
CustomElement(name)(createVcePluginBordererCore(actions, applyActions, render));
