import { Translations } from './translations.interface';

export const defaultTranslations: Translations = {
  dialogTitleCreate: 'Create Variable',
  dialogTitleCopy: 'Copy Variable',
  dialogTitleEdit: 'Edit Variable',
  name: {
    title: 'Variable name',
    helper:
      'You can refer to the variable in the code with the name set here, using the following format: ‘{ variables.myVariable }’.<br>Variable names can only contain Latin letters (A-Z, a-z).',
    placeholder: 'Enter the name to be used in the code.',
  },
  displayName: {
    title: 'Display name',
    helper: 'Variables will be listed with their display name on the Style Settings tab to the editor of the campaign.',
    placeholder: 'Enter a describing name.',
  },
  types: {
    title: 'Variable type',
    color: 'Color',
    decimal: 'Number - Decimal',
    integer: 'Number - Integer',
    string: 'String',
    select: 'Select',
    image: 'Image',
  },
  isOverridable: {
    title: 'Editable',
    helper: 'Editable variables will be visible and editable for the editor of the campaign on the Style Settings tab.',
    enabledState: 'Editable by the editor of the campaign',
    disabledState: "You cannot set a 'String' type variable to editable.",
  },
  defaultValue: {
    title: 'Default value',
    placeholder: 'Enter a value',
    select: {
      defaultColumn: 'Default',
      nameColumn: 'Item Name',
      valueColumn: 'Item Value',
    },
    image: {
      mediaDB: 'Media DB',
    },
  },
  create: 'Create',
  createCopy: 'Create Copy',
  apply: 'Apply',
  cancel: 'Cancel',
  validationErrors: {
    wrongIdFormat: 'This variable name is invalid. Please use only Latin letters (A-Z, a-z).',
    idAlreadyUsed: 'This variable name is already in use. Please try a new one.',
    wrongValue: 'This variable value is invalid. Please do not use curly brackets ({, }).',
    wrongImage: 'The image URL cannot be found.',
  },
};
