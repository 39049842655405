import { Observable } from 'rxjs/Observable';
import { merge } from 'rxjs/observable/merge';
import { fromEvent } from 'rxjs/observable/fromEvent';
import { map, filter, first, takeUntil } from 'rxjs/operators';
import { HTMLCustomElement } from '../../../../lib/html-custom-element';
import { CustomElement } from '../../../../lib/custom-element-decorators';
import { ReactiveAttribute, Callback } from '../../../../lib/index';
import { ViewChild } from '../../../../lib/reactive-decorators';
import { render, domTranslatorConfig } from './render';
import { domTranslator, DomTranslatorSub } from './dom-translator';

export type DialogEvent = {
  apply: Promise<Link>;
};

export type DialogWithRemoveEvent = {
  apply: Promise<Link>;
  remove: Promise<void>;
};

export type LinkEditorDialog = HTMLElement & {
  open: (link: Link) => DialogEvent;
  openWithRemove: (link: Link) => DialogWithRemoveEvent;
  translations: Translations;
};

export type Link = {
  href: string;
  title: string;
};
export interface EDialog extends HTMLElement {
  open: () => void;
  close: () => void;
}

enum EDialogEventType {
  Close,
  Apply,
  Remove,
}

export type Translations = {
  headline: string;
  urlLabel: string;
  urlPlaceholder: string;
  titleLabel: string;
  titlePlaceholder: string;
  cancelLabel: string;
  applyLabel: string;
  removeLabel: string;
};

export const createLinkEditorDialog = (dialogTagname: string) => {
  class LinkEditorDialogClass extends HTMLCustomElement implements LinkEditorDialog {
    translations: Translations; // type placeholder
    @Callback('disconnectedCallback') private _disconnect$: Observable<void>;
    @ReactiveAttribute('translations', 'translations', JSON.parse)
    private _translations$: Observable<Translations>;
    @ViewChild('[dialog]') private _dialog: EDialog;
    private _apply$: Observable<Event>;
    private _remove$: Observable<Event>;
    private _remove: HTMLButtonElement;
    private _hrefInput: HTMLInputElement;
    private _titleInput: HTMLInputElement;

    private _translator: DomTranslatorSub;

    connectedCallback(): void {
      this.innerHTML = render(dialogTagname);
      this._translator = domTranslator(domTranslatorConfig, this._dialog);
      this._hrefInput = this.querySelector('.href') as HTMLInputElement;
      this._titleInput = this.querySelector('.title') as HTMLInputElement;
      this._apply$ = fromEvent(this.querySelector('.apply') as HTMLElement, 'click');
      this._remove = this.querySelector('.remove') as HTMLButtonElement;
      this._remove$ = fromEvent(this.querySelector('.remove') as HTMLElement, 'click');

      this._translations$.pipe(takeUntil(this._disconnect$)).subscribe(translations => this._translator(translations));
    }

    openWithRemove(link: Link): DialogWithRemoveEvent {
      this._showRemoveButton();
      this._setCurrentValue(link);
      this._dialog.open();
      this._onNextDialogEvent$.subscribe(() => this._dialog.close());
      return {
        remove: this._getEventForType(EDialogEventType.Remove).then(() => {}),
        apply: this._getEventForType(EDialogEventType.Apply).then(() => this._getCurrentValue()),
      };
    }

    open(link: Link): DialogEvent {
      this._hideRemoveButton();
      this._setCurrentValue(link);
      this._dialog.open();
      this._onNextDialogEvent$.subscribe(() => this._dialog.close());
      return {
        apply: this._getEventForType(EDialogEventType.Apply).then(() => this._getCurrentValue()),
      };
    }

    private get _onNextDialogEvent$(): Observable<EDialogEventType> {
      return merge(
        this._apply$.pipe(map(() => EDialogEventType.Apply)),
        this._remove$.pipe(map(() => EDialogEventType.Remove)),
        fromEvent(this._dialog, 'dialog.close').pipe(map(() => EDialogEventType.Close)),
      ).pipe(first());
    }

    private _getCurrentValue(): Link {
      return {
        title: this._titleInput.value,
        href: this._hrefInput.value,
      };
    }

    private _setCurrentValue(link: Link): void {
      this._titleInput.value = link.title;
      this._hrefInput.value = link.href;
    }

    private _getEventForType(type: EDialogEventType): Promise<LinkEditorDialog> {
      return new Promise(resolve => {
        this._onNextDialogEvent$
          .pipe(filter(event => event === type))
          .toPromise()
          .then(type => (type !== undefined ? resolve(this) : undefined));
      });
    }

    private _hideRemoveButton(): void {
      this._remove.style.display = 'none';
    }

    private _showRemoveButton(): void {
      this._remove.style.removeProperty('display');
    }
  }
  return LinkEditorDialogClass;
};

const name = 'vce-link-editor-dialog';
CustomElement(name)(createLinkEditorDialog('e-dialog'));
