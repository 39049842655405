import * as React from 'react';
import { OpenType } from '../state/epics/lib/open-type';
import { Translations } from '../translations.interface';

type FooterProps = {
  openType: OpenType;
  isSubmitable: boolean;
  translations: Translations;
  submitForm: () => void;
};
export const Footer = (props: FooterProps): JSX.Element => (
  <div className="e-dialog__footer">
    <div className="e-grid e-grid-small">
      <div className="e-cell e-cell-small">
        <button className="e-btn" data-action="close">
          {props.translations.cancel}
        </button>
      </div>
      <div className="e-cell e-cell-small">
        <button className="e-btn e-btn-primary" onClick={() => props.submitForm()} disabled={!props.isSubmitable}>
          {props.openType === OpenType.Create
            ? props.translations.create
            : props.openType === OpenType.Edit
              ? props.translations.apply
              : props.translations.createCopy}
        </button>
      </div>
    </div>
  </div>
);
