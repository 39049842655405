import * as React from 'react';
import { IntegerEditor } from './variable-field.integer';
import { ColorEditor } from './variable-field.color';
import { SelectEditor } from './variable-field.select';
import { SelectConfigurationEditor } from './select-configuration/index';
import { StringEditor } from './variable-field.string';
import { DecimalEditor } from './variable-field.decimal';
import { ImageEditor } from './variable-field.image';
import { EditableImageEditor } from './variable-field.editable-image';
import { EditableImageListItemEditor } from './variable-field.editable-image-list-item';

export const getVariableFieldDefaultValue = type => {
  switch (type) {
    case 'integer':
      return '0';
    case 'decimal':
      return '0';
    case 'color':
      return '#000000';
    case 'select':
      return '';
    case 'image':
      return '';
    default:
      return '';
  }
};

export type VariableFieldProps = {
  id: string;
  type: string;
  value: string;
  type_config: any;
  onChange: Function;
  useEditableImage?: boolean;
  invalid?: boolean;
  translations?: object;
};

export const VariableField = (props: VariableFieldProps) => {
  const Component = getVariableFieldByType(props.type, props.useEditableImage!);
  return <Component {...props} />;
};

export const VariableFieldForConfiguration = (props: VariableFieldProps) => {
  const Component = getVariableFieldByTypeForConfiguration(props.type, props.useEditableImage!);
  return <Component {...props} />;
};

function getVariableFieldByType(type: string, useEditableImage: boolean): any {
  switch (type) {
    case 'integer':
      return IntegerEditor;
    case 'image':
      return useEditableImage ? EditableImageListItemEditor : ImageEditor;
    case 'color':
      return ColorEditor;
    case 'select':
      return SelectEditor;
    case 'decimal':
      return DecimalEditor;
    default:
      return StringEditor;
  }
}

function getVariableFieldByTypeForConfiguration(type: string, useEditableImage: boolean): any {
  switch (type) {
    case 'integer':
      return IntegerEditor;
    case 'image':
      return useEditableImage ? EditableImageEditor : ImageEditor;
    case 'color':
      return ColorEditor;
    case 'select':
      return SelectConfigurationEditor;
    case 'decimal':
      return DecimalEditor;
    default:
      return StringEditor;
  }
}
