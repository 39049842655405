import { tap, map, withLatestFrom, ignoreElements } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { Store } from 'redux';
import { VariablesEditorDialogAction, State, SET_TYPE, SET_IS_OVERRIDABLE, CONFIRMED_SUBMIT_FORM } from '..';
import { Observable } from 'rxjs/Observable';
import { EpicDependencies } from '../dependencies.interface';
import { getOpenType, OpenType } from './lib/open-type';

export const trackEvents = (
  actions$: Observable<VariablesEditorDialogAction>,
  store: Store<State>,
  dependencies: EpicDependencies,
): Observable<any> =>
  actions$.pipe(
    ofType(SET_TYPE, SET_IS_OVERRIDABLE, CONFIRMED_SUBMIT_FORM),
    withLatestFrom(getOpenType(actions$)),
    map(([action, openType]) => mapActionToTrackAction(action, openType)),
    tap(trackAction => dependencies.trackEvent(trackAction)),
    ignoreElements(),
  );

function mapActionToTrackAction(action: VariablesEditorDialogAction, openType: OpenType): string {
  switch (action.type) {
    case SET_TYPE:
      return 'variableEditorDialogSetType';
    case SET_IS_OVERRIDABLE:
      return 'variableEditorDialogSetEditable';
    case CONFIRMED_SUBMIT_FORM:
      return `variableEditorDialog${openType}Variable`;
  }
  return '';
}
