import { Observable } from 'rxjs/Observable';
import { fromEvent } from 'rxjs/observable/fromEvent';
import { takeUntil, map } from 'rxjs/operators';
import { CustomElement } from '../../../../lib/custom-element-decorators';
import { HTMLCustomElement } from '../../../../lib/html-custom-element';
import { Link, LinkEditorDialog } from '../../components/link-editor-dialog';
import { ViewChild, JsonAttribute } from '../../../../lib/reactive-decorators';
import { safeGet } from '../image-properties/libs/safe-get';
import { VcePluginEditableTextCustomButton } from '../index';
import { Callback, ReactiveAttribute } from '../../../../lib/index';

export type TextOuterLinkEditorData = {
  [key: string]: Link;
};

export interface VcePluginTextOuterLinkEditorTranslations {
  'text-outer-link-editor-toolbar-button': string;
}
export interface VcePluginTextOuterLinkEditor extends HTMLElement {
  translations?: VcePluginTextOuterLinkEditorTranslations;
  idAttributeName?: string;
  linkData?: TextOuterLinkEditorData;
}

export function createVcePluginTextOuterLinkEditor(): { new (): VcePluginTextOuterLinkEditor } {
  class VcePluginTextOuterLinkEditorClass extends HTMLCustomElement implements VcePluginTextOuterLinkEditor {
    translations?: VcePluginTextOuterLinkEditorTranslations; // type placeholder
    idAttributeName?: string; // type placeholder

    @JsonAttribute('link-data') linkData?: TextOuterLinkEditorData;

    @ViewChild('vce-plugin-editable-text-custom-button') private _customButton?: VcePluginEditableTextCustomButton;
    @ViewChild('[link-editor-dialog]') private _dialog?: LinkEditorDialog;
    @Callback('disconnectedCallback') private _disconnect$: Observable<void>;
    @ReactiveAttribute('id-attribute-name', 'idAttributeName')
    private _idAttributeName$: Observable<string>;
    @ReactiveAttribute('translations', 'translations', JSON.parse)
    private _translations$: Observable<VcePluginTextOuterLinkEditorTranslations>;

    connectedCallback(): void {
      if (!this._customButton) {
        this._createCustomButton();
      }
      this._reflectPropertyToCustomButton(this._idAttributeName$, 'idAttributeName');
      this._reflectPropertyToCustomButton(
        this._translations$.pipe(
          map(translations => ({ tooltip: translations['text-outer-link-editor-toolbar-button'] })),
        ),
        'translations',
      );

      this._openDialogOnCustomButtonClick();
    }

    private _openDialogOnCustomButtonClick(): void {
      fromEvent(this._customButton!, 'vce-plugin-editable-text-custom-button.click')
        .pipe(takeUntil(this._disconnect$))
        .subscribe((event: CustomEvent) => {
          const dataId: string = event.detail.id;
          this._openDialog(dataId).then(linkData => this._emitChange(dataId, linkData));
        });
    }

    private _reflectPropertyToCustomButton<TKey extends keyof VcePluginEditableTextCustomButton>(
      source: Observable<VcePluginEditableTextCustomButton[TKey]>,
      targetKey: TKey,
    ): void {
      source.pipe(takeUntil(this._disconnect$)).subscribe(value => {
        this._customButton![targetKey] = value;
      });
    }

    private _createCustomButton(): void {
      const customButton = document.createElement(
        'vce-plugin-editable-text-custom-button',
      ) as VcePluginEditableTextCustomButton;
      customButton.setAttribute('external-plugin', 'external-plugin');
      customButton.buttonName = 'outerLinkEditor';
      this.appendChild(customButton);
    }

    private _openDialog(dataId: string): Promise<Link> {
      return this._dialog!.open(this._getLinkData(dataId)).apply;
    }

    private _emitChange(dataId: string, linkData: Link): void {
      this.dispatchEvent(new CustomEvent('change', { detail: { editableId: dataId, linkData } }));
    }

    private _getLinkData(dataId: string): Link {
      return (
        safeGet(() => this.linkData![dataId]) || {
          title: '',
          href: '',
        }
      );
    }
  }

  return VcePluginTextOuterLinkEditorClass;
}

const name = 'vce-plugin-text-outer-link-editor';
CustomElement(name)(createVcePluginTextOuterLinkEditor());
