export class Logger {
  private _debugMode = false;
  private _isClientLoggingEnabled = false;

  constructor(debugMode: boolean) {
    this._debugMode = debugMode;
  }

  setClientLogging(isEnabled: boolean): void {
    this._isClientLoggingEnabled = isEnabled;
  }

  error(action: string, error: Error): void {
    if (this._debugMode) {
      console.error(error);
    }
    if (this._isClientLoggingEnabled) {
      // Log to LaaS
    }
  }

  info(action: string, data): void {
    if (this._debugMode) {
      console.info(data);
    }
    if (this._isClientLoggingEnabled) {
      // Log to LaaS
    }
  }
}
