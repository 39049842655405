import { vsprintf } from 'sprintf-js';

export type Translate = (translations: Object) => (key: string, parameters?: any[]) => string;
export const translate: Translate = translations => (key, parameters) => {
  const translated = translations && key in translations ? translations[key] : key;
  try {
    return vsprintf(translated, parameters);
  } catch (e) {
    return translated.replace(/%s|%d/gi, '');
  }
};
