import { State, ValidationResults } from '../state.interface';
import * as Validations from '../validations';
import { createSelector } from 'reselect';
import { pathOr } from 'ramda';
import { Translations } from '../../translations.interface';

const getReservedIds = (state: State): string[] => state.reservedIds;
const getVariableConfigurationId = (state: State): string => state.variableConfiguration.id;
const getOriginalConfigurationId = (state: State): string =>
  pathOr(undefined, ['originalVariableConfiguration', 'id'], state);
const getVariableConfigurationDefaultValue = (state: State): string => state.variableConfiguration.default_value;
const getTranslations = (state: State): Translations => state.translations;
const getUseEditableImage = (state: State): boolean => state.useEditableImage;
const getValidation = (state: State): ValidationResults => state.validation;
const getStringEditable = (state: State): boolean => state.stringEditable;

export const isSubmitable = createSelector(
  [
    getReservedIds,
    getVariableConfigurationId,
    getVariableConfigurationDefaultValue,
    getOriginalConfigurationId,
    getTranslations,
    getUseEditableImage,
    getValidation,
    getStringEditable,
  ],
  (
    reservedIds,
    variableConfigurationId,
    variableConfigurationDefaultValue,
    originalVariableConfigurationId,
    translations,
    useEditableImage,
    validation,
  ) => {
    const isValidId = !Validations.validateId(
      variableConfigurationId,
      reservedIds,
      originalVariableConfigurationId,
      translations.validationErrors,
    ).isInvalid;
    const isValidDefaultValue = useEditableImage
      ? !validation.default_value.isInvalid
      : !Validations.validateDefaultValue(variableConfigurationDefaultValue, translations.validationErrors).isInvalid;
    return isValidId && isValidDefaultValue;
  },
);
