import { HTMLCustomElement } from '../../../../lib/html-custom-element';
import { CustomElement } from '../../../../lib/custom-element-decorators';
import { ReactiveAttribute, Callback, StringAttribute } from '../../../../lib/reactive-decorators';
import { Observable } from 'rxjs/Observable';
import { takeUntil } from 'rxjs/operators';

export interface PreviewJavascript extends HTMLElement {}

export const createPreviewJavascript = (): { new (): HTMLElement } => {
  class PreviewJavascript extends HTMLCustomElement {
    private iframe: HTMLIFrameElement;
    private _iframeWidth?: string;
    private _extraClass?: string;

    @ReactiveAttribute('content') private _content$: Observable<string>;

    @Callback('disconnectedCallback') private _disconnect$: Observable<void>;

    @StringAttribute('iframe-width')
    set iframeWidth(value: string | undefined) {
      this._iframeWidth = value;
      if (this.iframe) this._setIframeWidth();
    }

    @StringAttribute('extra-class')
    set extraClass(value: string | undefined) {
      this._extraClass = value;
      if (this.iframe) this._setExtraClass();
    }

    connectedCallback(): void {
      this._createIframe();
      this._content$.pipe(takeUntil(this._disconnect$)).subscribe(content => {
        this.iframe.setAttribute('srcdoc', content);
      });
    }

    private _createIframe(): void {
      this.iframe = document.createElement('iframe') as HTMLIFrameElement;
      this.iframe.style.overflowX = 'hidden';
      this.iframe.setAttribute('sandbox', 'allow-forms allow-scripts');
      this._setIframeWidth();
      this._setExtraClass();
      this.appendChild(this.iframe);
    }

    private _setIframeWidth(): void {
      this.iframe!.style.width = this._iframeWidth === undefined ? '100%' : this._iframeWidth;
    }

    private _setExtraClass(): void {
      this.iframe!.className = this._extraClass || '';
    }
  }
  return PreviewJavascript;
};

export const name = 'vce-preview-javascript';
CustomElement(name)(createPreviewJavascript());
