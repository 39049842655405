import * as React from 'react';
import { reactCustomElementWrapper } from '../../../../lib/react-custom-element-wrapper';

export type ImageEditorProp = {
  value: string;
  onChange: (value: string) => void;
};

const ImageSelectorComponent = reactCustomElementWrapper('vce-image-input');

export const ImageEditor = ({ onChange, value }: ImageEditorProp): any => (
  <ImageSelectorComponent value={value} on-update={event => onChange(event.detail.value)} />
);
