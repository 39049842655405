import { TinymceEditor } from '../editable-text/models/tinymce-editor/index';

export interface GetSetupHookOptions {
  buttonName: string;
  tooltip: string;
  onClickCallback: (element: Element) => void;
}

export type GetSetupHook = (editor: TinymceEditor, options: GetSetupHookOptions) => void;

export const getSetupHook: GetSetupHook = (editor, options) => {
  editor.addButton(options.buttonName, {
    tooltip: options.tooltip,
    stateSelector: false,
    onclick: () => options.onClickCallback(editor.getElement()),
  });
};
