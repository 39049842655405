import { map } from 'rxjs/operators';
import { merge } from 'rxjs/observable/merge';
import { Observable } from 'rxjs/Observable';
import * as Actions from '../../actions';
import { ofType } from 'redux-observable';
import { VariablesEditorDialogAction } from '../../actions';

export enum OpenType {
  Create = 'Create',
  Copy = 'Copy',
  Edit = 'Edit',
}

export const getOpenType = (actions$: Observable<VariablesEditorDialogAction>): Observable<OpenType> => {
  return merge(
    actions$.pipe(
      ofType(Actions.OPEN_WITH_CREATE),
      map(() => OpenType.Create),
    ),
    actions$.pipe(
      ofType(Actions.OPEN_WITH_COPY),
      map(() => OpenType.Copy),
    ),
    actions$.pipe(
      ofType(Actions.OPEN_WITH_EDIT),
      map(() => OpenType.Edit),
    ),
  );
};
