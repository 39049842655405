import { CustomElement } from '../../../../lib/custom-element-decorators';
import {
  HtmlEditorStrategy,
  EditorPluginEvents,
  TokenDefinition,
  TokenPosition,
  TokenizePatternEvents,
  InsertText,
} from '../../interface';
import { HTMLCustomElement, StringAttribute } from '../../../../lib';
import { match } from 'ramda';

export type HtmlEditorTokenizePatternPlugin = HTMLElement & {
  getStrategy(): HtmlEditorStrategy;
};

export const createHtmlEditorTokenizePatternPlugin = () => {
  class HtmlEditorTokenizePatternPluginImplementation extends HTMLCustomElement
    implements HtmlEditorTokenizePatternPlugin {
    @StringAttribute('pattern') pattern;

    connectedCallback(): void {
      this.dispatchEvent(new CustomEvent(EditorPluginEvents.Connected, { bubbles: true }));
    }

    disconnectedCallback(): void {
      this.dispatchEvent(new CustomEvent(EditorPluginEvents.Disconnected));
    }

    getStrategy(): HtmlEditorStrategy {
      return {
        findTokens: (html: string) => {
          const pattern = new RegExp(this.pattern, 'g');
          return match(pattern, html).reduce(
            (acc, item: string) => {
              const itemPosition = this._getPosition(item, acc.html);
              return {
                html: acc.html.slice(itemPosition.to),
                tokens: acc.tokens.concat(this._createToken(item, this._addPadding(itemPosition, acc.padding))),
                padding: acc.padding + itemPosition.to,
              };
            },
            { html, tokens: [], padding: 0 },
          ).tokens;
        },
      };
    }

    private _addPadding(position: TokenPosition, padding: number): TokenPosition {
      return {
        from: position.from + padding,
        to: position.to + padding,
      };
    }

    private _getPosition(item: string, html: string): TokenPosition {
      return {
        from: html.indexOf(item),
        to: html.indexOf(item) + item.length,
      };
    }

    private _createToken(tokenName: string, position: TokenPosition): TokenDefinition {
      return {
        tokenName,
        position,
        type: 'pattern',
        tokenData: undefined,
        onClick: ({ insertText }) => this._emitClick(tokenName, insertText),
      };
    }

    private _emitClick(tokenText: string, insertText: InsertText): void {
      this.dispatchEvent(
        new CustomEvent(TokenizePatternEvents.Click, {
          bubbles: true,
          detail: {
            tokenText,
            insertText,
          },
        }),
      );
    }
  }

  return HtmlEditorTokenizePatternPluginImplementation;
};

const name = 'vce-html-editor-tokenize-pattern-plugin';
CustomElement(name)(createHtmlEditorTokenizePatternPlugin());
