import { setTypeReducer } from './set-type.reducer';
import { setIdReducer } from './set-id.reducer';
import { setDefaultValueReducer } from './set-default-value.reducer';
import { setReservedIdsReducer } from './set-reserved-ids.reducer';
import { setDisplayNameReducer } from './set-display-name.reducer';
import { setIsOverridableReducer } from './set-is-overridable.reducer';
import { openWithEditReducer } from './open-with-edit.reducer';
import { openWithCopyReducer } from './open-with-copy.reducer';
import { openWithCreateReducer } from './open-with-create.reducer';
import { State, VariablesEditorDialogAction, createInitialState } from '../';
import * as Actions from '../actions';
import { setTranslations } from './set-translations';
import { setDefaultValueValidationReducer } from './set-default-value-validation.reducer';
import { setUseEditableImageReducer } from './set-use-editable-image.reducer';
import { setStringEditableReducer } from './set-use-string-editable.reducer';

export const stateReducer = (state: State = createInitialState(), action: VariablesEditorDialogAction): State => {
  switch (action.type) {
    case Actions.OPEN_WITH_EDIT:
      return openWithEditReducer(state, action);
    case Actions.OPEN_WITH_COPY:
      return openWithCopyReducer(state, action);
    case Actions.OPEN_WITH_CREATE:
      return openWithCreateReducer(state, action);
    case Actions.SET_RESERVED_IDS:
      return setReservedIdsReducer(state, action);
    case Actions.SET_DISPLAY_NAME:
      return setDisplayNameReducer(state, action);
    case Actions.SET_ID:
      return setIdReducer(state, action);
    case Actions.SET_TYPE:
      return setTypeReducer(state, action);
    case Actions.SET_DEFAULT_VALUE:
      return setDefaultValueReducer(state, action);
    case Actions.SET_IS_OVERRIDABLE:
      return setIsOverridableReducer(state, action);
    case Actions.SET_TRANSLATIONS:
      return setTranslations(state, action);
    case Actions.SET_DEFAULT_VALUE_VALIDATION:
      return setDefaultValueValidationReducer(state, action);
    case Actions.SET_USE_EDITABLE_IMAGE:
      return setUseEditableImageReducer(state, action);
    case Actions.SET_STRING_EDITABLE:
      return setStringEditableReducer(state, action);
    default:
      return state;
  }
};
