import { CustomElement } from '../../../../lib/custom-element-decorators';
import { IVcePlugin } from '../../../../lib/vce-plugin.interface';
import { Callback, HTMLCustomElement } from '../../../../lib';
import { Observable } from 'rxjs/Observable';
import { fromEvent } from 'rxjs/observable/fromEvent';
import { switchMap, map, takeUntil } from 'rxjs/operators';

export interface VcePluginPreventLinkClick extends HTMLElement, IVcePlugin {}
export function createVcePreventLinkClickPlugin(): { new (): VcePluginPreventLinkClick } {
  class VcePluginPreventLinkClick extends HTMLCustomElement implements IVcePlugin {
    @Callback('readyCallback') private _ready$: Observable<HTMLIFrameElement>;
    @Callback('disconnectedCallback') private _disconnect$: Observable<void>;

    connectedCallback(): void {
      this.dispatchEvent(new CustomEvent('plugin.connected', { bubbles: true }));
      this._ready$
        .pipe(
          map(iframe => iframe.contentWindow!.document),
          switchMap(document => fromEvent<Event>(document.querySelectorAll('a'), 'click')),
          takeUntil(this._disconnect$),
        )
        .subscribe(event => {
          event.preventDefault();
          event.stopPropagation();
        });
    }

    disconnectedCallback(): void {
      this.dispatchEvent(new CustomEvent('plugin.disconnected'));
    }
  }
  return VcePluginPreventLinkClick;
}

export const tagName = 'vce-plugin-prevent-link-click';
CustomElement(tagName)(createVcePreventLinkClickPlugin());
