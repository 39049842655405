import { State } from './state.interface';
import { defaultTranslations } from '../translations.default';
import { OpenType } from './epics/lib/open-type';

export const createInitialState = (): State => ({
  reservedIds: [],
  openType: OpenType.Create,
  variableConfiguration: {
    id: '',
    display_name: '',
    type: 'string',
    default_value: '',
    is_overridable: false,
  },
  originalVariableConfiguration: null,
  validation: {
    id: { isInvalid: false, errorMessage: '' },
    default_value: { isInvalid: false, errorMessage: '' },
  },
  translations: defaultTranslations,
  useEditableImage: false,
  stringEditable: false,
});
