const style = `
  .e-vce-image_toolbar {
      background: #fff;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
      display: block;
      padding: 5px
  }
  .e-vce-image_toolbar .e-btn {
      border-color: transparent
  }
  .e-vce-image_toolbar .e-btn:hover,
  .e-vce-image_toolbar .e-btn:active,
  .e-vce-image_toolbar .e-btn:focus {
      background-color: #6597cf;
      box-shadow: none;
      color: #fff
  }
  .e-vce-image_toolbar .e-btn:hover svg,
  .e-vce-image_toolbar .e-btn:active svg,
  .e-vce-image_toolbar .e-btn:focus svg {
      fill: #fff
  }
`;

document.head.insertAdjacentHTML('beforeend', `<style>${style}</style>`);
