import { TinymceEditor } from '../../models/tinymce-editor';

const tinymce = require('tinymce/tinymce');

export const cleanUpBookmarks = (editor: TinymceEditor) => {
  Array.from(editor.dom.select('*'))
    .filter(element => tinymce.dom.BookmarkManager.isBookmarkNode(element))
    .forEach(element => editor.dom.remove(element as any, false));
  delete (<any>editor).currentBookmark;
};
