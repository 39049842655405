import { HTMLCustomElement } from '../../../../lib/html-custom-element';
import { CustomElement } from '../../../../lib/custom-element-decorators';
import { BooleanAttribute, Callback, ReactiveAttribute, attributeToBoolean } from '../../../../lib/reactive-decorators';
import { name as codeMirrorTagName } from './codemirror.component';
import { CodeMirrorComponent, HtmlEditorEvents, HtmlEditorStrategy } from '../../interface';
import { name as emptyStateTagName } from './empty-state.component';
import { Observable } from 'rxjs/Observable';
import { filter, map, takeUntil, startWith } from 'rxjs/operators';
import { getComponentPlugins } from '../../../../lib/component-connections';
import { combineLatest } from 'rxjs/observable/combineLatest';
import { debounceTime } from 'rxjs/operators/debounceTime';
import { merge } from 'rxjs/observable/merge';
import { defaultTo, isNil } from 'ramda';
import { fromEvent } from 'rxjs/observable/fromEvent';

type Translations = {
  title: string;
  lead: string;
  learnMore: string;
  learnMoreLink: string;
};

type HtmlEditorPlugin = HTMLElement & {
  getStrategy: () => HtmlEditorStrategy;
};

export interface IHtmlEditor extends HTMLElement {
  insertText: (html: string) => void;
  translations: Translations;
  mode: string;
  placeholder: string;
  lineWrapping: boolean;
  disabled: boolean;
  html: string | undefined;
  lineNumbers: string;
  autoRefresh: string;
  singleLine: boolean;
}

export const createHtmlEditor = () => {
  class HtmlEditor extends HTMLCustomElement {
    @ReactiveAttribute('translations', 'translations', JSON.parse)
    private _translations$: Observable<Translations>;
    @ReactiveAttribute('mode', 'mode')
    private _mode$: Observable<string>;
    @ReactiveAttribute('disabled', 'disabled', attributeToBoolean)
    private _disabled$: Observable<boolean>;
    @ReactiveAttribute('placeholder', 'placeholder')
    private _placeholder$: Observable<string>;
    @ReactiveAttribute('line-wrapping', 'lineWrapping', attributeToBoolean)
    private _lineWrapping$: Observable<boolean>;
    @ReactiveAttribute('auto-refresh', 'autoRefresh', attributeToBoolean)
    private _autoRefresh$: Observable<boolean>;
    @ReactiveAttribute('line-numbers', 'lineNumbers', attributeToBoolean)
    private _lineNumbers: Observable<boolean>;
    @ReactiveAttribute('html', 'html')
    private _html$: Observable<string>;
    @ReactiveAttribute('single-line', 'singleLine', attributeToBoolean)
    private _singleLine$: Observable<boolean>;
    @ReactiveAttribute('lint', 'lint', attributeToBoolean)
    private _lint$: Observable<boolean>;
    @BooleanAttribute('disable-autofocus') _disableAutofocus: boolean = false;
    @Callback('disconnectedCallback') private _disconnect$: Observable<void>;

    private _codeMirror: any;
    private _emptyState: HTMLElement;
    private _value: string;

    init(): void {
      this._updateTranslations = this._updateTranslations.bind(this);
      this._toggleEmptyState = this._toggleEmptyState.bind(this);
    }

    connectedCallback(): void {
      this._handlePlugins();
      this._createCodeMirror();
      this._createEmptyState();
      this._html$
        .pipe(
          takeUntil(this._disconnect$),
          map(defaultTo('')),
        )
        .subscribe((value: string) => {
          this._codeMirror.setAttribute('html', value);
        });
      merge(this._codeMirrorChange$, this._html$)
        .pipe(
          takeUntil(this._disconnect$),
          map(defaultTo('')),
          filter(value => value !== this._value),
        )
        .subscribe((value: string) => {
          this._handleChange(value);
        });
      this._mode$.pipe(takeUntil(this._disconnect$)).subscribe(mode => this._codeMirror.setAttribute('mode', mode));
      this._placeholder$
        .pipe(takeUntil(this._disconnect$))
        .subscribe(placeholder => this._codeMirror.setAttribute('placeholder', placeholder));
      this._lineWrapping$
        .pipe(takeUntil(this._disconnect$))
        .subscribe(lineWrapping => this._codeMirror.setAttribute('line-wrapping', lineWrapping));
      this._singleLine$
        .pipe(takeUntil(this._disconnect$))
        .subscribe(singleLine => this._codeMirror.setAttribute('single-line', singleLine));
      combineLatest([this._singleLine$.pipe(filter(x => x)), this._mode$.pipe(startWith('null'))])
        .pipe(takeUntil(this._disconnect$))
        .subscribe(([_, mode]) => {
          this._codeMirror.setAttribute('mode', !mode ? 'null' : mode);
        });
      this._lineNumbers
        .pipe(takeUntil(this._disconnect$))
        .subscribe(lineNumbers => this._codeMirror.setAttribute('line-numbers', lineNumbers));
      this._lint$.pipe(takeUntil(this._disconnect$)).subscribe(lint => this._codeMirror.setAttribute('lint', lint));
      this._autoRefresh$
        .pipe(takeUntil(this._disconnect$))
        .subscribe(autoRefresh => this._codeMirror.setAttribute('auto-refresh', autoRefresh));
      this._disabled$
        .pipe(takeUntil(this._disconnect$))
        .subscribe(disabled => this._codeMirror.setAttribute('disabled', disabled));
      this._translations$.pipe(takeUntil(this._disconnect$)).subscribe(this._updateTranslations);
      this.dispatchEvent(new CustomEvent(HtmlEditorEvents.Connected, { bubbles: true }));
    }

    insertText(html: string): void {
      this._codeMirror.insertText(html);
      if (html === '' && isNil(this._value)) {
        this._handleChange(html);
      }
    }

    setLintCallback(callback: Function): void {
      this._codeMirror.setLintCallback(callback);
    }

    private get _codeMirrorChange$(): Observable<string> {
      return fromEvent(this._codeMirror, 'update').pipe(map((event: any) => event.detail));
    }

    private _handleChange(value: string): void {
      this._value = value;
      this._toggleEmptyState(value);
      this.dispatchEvent(new CustomEvent(HtmlEditorEvents.Changed, { detail: value }));
    }

    private _handlePlugins(): void {
      const plugins$ = getComponentPlugins<HtmlEditorPlugin>(
        this,
        this._disconnect$,
        'connected.html-editor-plugin',
        'disconnected.html-editor-plugin',
        'updated.html-editor-plugin',
      );
      const codemirrors$ = getComponentPlugins<CodeMirrorComponent>(
        this,
        this._disconnect$,
        'connected.codemirror',
        'disconnected.codemirror',
        'updated.codemirror',
      );
      combineLatest(plugins$, codemirrors$)
        .pipe(debounceTime(20))
        .subscribe(([plugins, codemirrors]) => {
          const strategies = plugins.map(plugin => plugin.getStrategy());
          codemirrors.forEach(codemirror => codemirror.setStrategies(strategies));
        });
    }

    private _createCodeMirror(): void {
      this._cleanupContainer(codeMirrorTagName);
      this._codeMirror = window.document.createElement(codeMirrorTagName);
      this._codeMirror.setAttribute('disable-autofocus', this._disableAutofocus);
      this.appendChild(this._codeMirror);
    }

    private _createEmptyState(): void {
      this._cleanupContainer(emptyStateTagName);
      this._emptyState = window.document.createElement(emptyStateTagName);
      this.appendChild(this._emptyState);
      this._emptyState.style.display = 'block';
    }

    private _toggleEmptyState(value: string): void {
      if (value) {
        this._emptyState.style.display = 'none';
      } else {
        this._emptyState.style.display = 'block';
      }
    }

    private _updateTranslations(translations: Translations): void {
      this._emptyState.setAttribute('label-title', translations.title);
      this._emptyState.setAttribute('label-lead', translations.lead);
      this._emptyState.setAttribute('label-learn-more', translations.learnMore);
      this._emptyState.setAttribute('label-learn-more-link', translations.learnMoreLink);
    }

    private _cleanupContainer(selector: string): void {
      const exitsContainer = this.querySelector(selector);
      if (exitsContainer) this.removeChild(exitsContainer);
    }
  }
  return HtmlEditor;
};

const name = 'vce-html-editor';
CustomElement(name)(createHtmlEditor());
