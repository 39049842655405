import { addObservedAttributes } from './lib/add-observed-attribute';

export const attributeToBoolean = (value: any) =>
  value !== undefined && value !== null && value !== false && value !== 'false';

export const attributeToInteger = (value: any) => parseInt(value);

export interface Parse<K> {
  (value: string): K;
}
export function Attribute<TValue>(attrName: string, parser: Parse<TValue>): PropertyDecorator {
  return (target: Object, propertyKey: string | symbol): void => {
    const oldAttributeChangedCallback = target.constructor.prototype['attributeChangedCallback'] || (() => {});
    addObservedAttributes(target, attrName);
    target.constructor.prototype['attributeChangedCallback'] = function(
      attr: string,
      oldValue: any,
      newValue: any,
    ): void {
      if (attr === attrName) {
        try {
          this[propertyKey] = parser(newValue);
        } catch (error) {
          console.warn(
            `${target.constructor.name}[${attrName}->${propertyKey.toString()}] json parse failed`,
            newValue,
          );
        }
      }
      return oldAttributeChangedCallback.call(this, attr, oldValue, newValue);
    };
  };
}

export const StringAttribute = (attrName: string): PropertyDecorator => Attribute<string>(attrName, s => s);

export const JsonAttribute = (attrName: string): PropertyDecorator => Attribute(attrName, JSON.parse);

export const BooleanAttribute = (attrName: string): PropertyDecorator => Attribute(attrName, attributeToBoolean);

export const IntegerAttribute = (attrName: string): PropertyDecorator => Attribute(attrName, attributeToInteger);
