import { VariableConfiguration } from '@emartech/vce-domain';
import { HTMLCustomElement } from '../../../../lib/html-custom-element';
import { CustomElement } from '../../../../lib/custom-element-decorators';
import { ReactiveAttribute, Callback } from '../../../../lib/reactive-decorators';
import { ConnectedViewChild } from '../../../../lib/reactive-decorators';
import { confirm } from '../../../../lib/confirm';
import { Observable } from 'rxjs/Observable';
import { takeUntil, switchMap, map, share, filter, withLatestFrom, startWith } from 'rxjs/operators';
import { fromEvent } from 'rxjs/observable/fromEvent';
import { propEq, complement, update, filter as RFilter, mergeDeepRight, append } from 'ramda';
import { ReturnData as DialogReturnData } from '../variable-editor-dialog';
import { merge } from 'rxjs/observable/merge';
import { Memoize } from 'typescript-memoize';
import { combineLatest } from 'rxjs/observable/combineLatest';

interface VariablesListActionEvent extends CustomEvent {
  detail: {
    type: 'delete' | 'edit';
    data: VariableConfiguration;
  };
}

interface VariablesList extends HTMLElement {
  variableConfigurations: VariableConfiguration[];
}

interface VariablesListAction {
  type: 'delete' | 'edit';
  id: string;
}

interface VariableEditorDialog extends HTMLElement {
  create(): DialogReturnData;
  edit(variableConfiguration: VariableConfiguration): DialogReturnData;
  copy(variableConfiguration: VariableConfiguration): DialogReturnData;
}

type Translations = {
  deleteConfirm: {
    title: string;
    message: string;
    applyButton: string;
  };
};

const defaultTranslations: Translations = {
  deleteConfirm: {
    title: 'Are you sure?',
    message: 'Do you really want to delete this variable?',
    applyButton: 'Delete',
  },
};

export const createVariableConfigurationsEditor = (): { new (): HTMLElement } => {
  class VariableConfigurationsEditor extends HTMLCustomElement {
    @ReactiveAttribute('variable-configurations', 'variableConfigurations', JSON.parse)
    private _variableConfigurations$: Observable<any[]>;
    @ReactiveAttribute('translations', 'translations', JSON.parse)
    private _translations$: Observable<Translations>;

    @Callback('disconnectedCallback') private _disconnect$: Observable<void>;
    @ConnectedViewChild('[vce-variables-list]') private _variablesList$: Observable<VariablesList>;
    @ConnectedViewChild('[vce-variable-editor-dialog]')
    private _variableEditorDialog$: Observable<VariableEditorDialog>;

    connectedCallback(): void {
      combineLatest(this._variablesList$, this._variableConfigurations$)
        .pipe(takeUntil(this._disconnect$))
        .subscribe(
          ([variablesList, variableConfigurations]) => (variablesList.variableConfigurations = variableConfigurations),
        );
      merge(this._deleteAction$, this._copyAction$, this._editAction$)
        .pipe(takeUntil(this._disconnect$))
        .subscribe(variableConfigurations => this._emitUpdate(variableConfigurations));
    }

    @Memoize()
    private get _actions$(): Observable<VariablesListAction> {
      return this._variablesList$.pipe(
        switchMap(variablesList => fromEvent(variablesList, 'action')),
        map((event: VariablesListActionEvent) => ({ type: event.detail.type, id: event.detail.data.id })),
        share(),
      );
    }

    private get _deleteAction$(): Observable<VariableConfiguration[]> {
      return this._actions$.pipe(
        filter(propEq('delete', 'type')),
        withLatestFrom(this._mergedTranslations$),
        switchMap(([_, { deleteConfirm }]) =>
          confirm(deleteConfirm.title, deleteConfirm.message, deleteConfirm.applyButton),
        ),
        filter(isConfirmed => isConfirmed),
        withLatestFrom(this._variableConfigurations$, this._actions$),
        map(([_, variableConfigurations, { id }]) => RFilter(complement(propEq(id, 'id')), variableConfigurations)),
      );
    }

    private get _copyAction$(): Observable<VariableConfiguration[]> {
      return this._actions$.pipe(
        filter(propEq('copy', 'type')),
        withLatestFrom(this._variableConfigurations$, this._variableEditorDialog$),
        switchMap(([{ id }, variableConfigurations, variableEditorDialog]) => {
          const variableConfiguration = variableConfigurations.find(propEq(id, 'id'));
          return variableEditorDialog
            .copy(variableConfiguration)
            .apply.then(variableConfiguration => ({ variableConfiguration, variableConfigurations }));
        }),
        map(({ variableConfiguration, variableConfigurations }) => {
          return append(variableConfiguration, variableConfigurations);
        }),
      );
    }

    private get _editAction$(): Observable<VariableConfiguration[]> {
      return this._actions$.pipe(
        filter(propEq('edit', 'type')),
        withLatestFrom(this._variableConfigurations$, this._variableEditorDialog$),
        switchMap(([{ id }, variableConfigurations, variableEditorDialog]) => {
          const variableConfiguration = variableConfigurations.find(propEq(id, 'id'));
          return variableEditorDialog
            .edit(variableConfiguration)
            .apply.then(variableConfiguration => ({ id, variableConfiguration, variableConfigurations }));
        }),
        map(({ id, variableConfiguration, variableConfigurations }) => {
          const index = variableConfigurations.findIndex(propEq(id, 'id'));
          return update(index, variableConfiguration, variableConfigurations);
        }),
      );
    }

    private get _mergedTranslations$(): Observable<Translations> {
      return this._translations$.pipe(
        startWith(defaultTranslations),
        map(mergeDeepRight(defaultTranslations)),
      );
    }

    private _emitUpdate(variableConfigurations: VariableConfiguration[]): void {
      this.dispatchEvent(new CustomEvent('update', { detail: variableConfigurations }));
    }
  }
  return VariableConfigurationsEditor;
};

export const variableConfigurationsEditorTagName = 'vce-variable-configurations-editor';
CustomElement(variableConfigurationsEditorTagName)(createVariableConfigurationsEditor());
