import { CustomElement } from '../../../../lib/custom-element-decorators';
import { ContactPreviewApi, Contact } from '../../interface';
export { Storage } from './contact-preview-fake-content-api';

export const createContactPreviewPersonalizationApi = (storage: Storage) => {
  class ContactPreviewPersonalizationApiClass extends HTMLElement implements ContactPreviewApi {
    async find(term: string): Promise<Contact[]> {
      await new Promise(resolve => setTimeout(resolve, 1000));
      if (term === 'error') {
        throw new Error(`error for ${term}`);
      }

      return [
        { id: Date.now().toString(), email: term + '.hu', firstName: 'John', lastName: 'Doe' },
        { id: Date.now().toString(), email: term + '.com', firstName: 'Jane', lastName: 'Doe' },
        { id: Date.now().toString(), email: term + '.uk', firstName: 'Jimmy', lastName: 'Doe' },
      ];
    }

    render(html: any, contact: Contact): Promise<any> {
      if (contact.email === 'error@emarsys.com') {
        return Promise.reject(new Error('322'));
      }
      return Promise.resolve(`${html} ${JSON.stringify(contact)}`);
    }

    getStoredContacts(): Contact[] {
      try {
        const stored = storage.getItem('vce-contact-preview-box__recentContacts');
        return (stored && JSON.parse(stored)) || [];
      } catch (err) {
        return [];
      }
    }

    setStoredContacts(contacts: Contact[]): void {
      storage.setItem('vce-contact-preview-box__recentContacts', JSON.stringify(contacts));
    }
  }
  return ContactPreviewPersonalizationApiClass;
};

const name = 'vce-contact-preview-fake-api';
CustomElement(name)(createContactPreviewPersonalizationApi(localStorage));
