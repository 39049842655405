export function safeGet<T>(fn: () => T): T | undefined {
  try {
    return fn();
  } catch (e) {
    if (e instanceof TypeError) {
      return undefined;
    }
    throw e;
  }
}
