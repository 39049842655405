export type CodeMirrorLine = {
  text: string;
  startCharacter: number;
  endCharacter: number;
  index: number;
};

export type InsertText = (s: string) => void;

export type HtmlEditorPluginOnClickContext = {
  insertText: InsertText;
};

export type TokenDefinition = {
  tokenName: string;
  tokenData: any;
  position: TokenPosition;
  type: string;
  onClick?: (context: HtmlEditorPluginOnClickContext) => void;
};
export type TokenPosition = {
  from: number;
  to: number;
};
export type FindTokens = (html: string) => TokenDefinition[];
export type HtmlEditorStrategy = { findTokens: FindTokens };

export interface CodeMirrorComponent extends HTMLElement {
  html: string;
  insertText: InsertText;
  setStrategies: (strategies: HtmlEditorStrategy[]) => void;
}

export type CodeMirrorTokenPosition = {
  line: number;
  ch: number;
};

export type CodeMirrorTokenDefinition = {
  from: CodeMirrorTokenPosition;
  to: CodeMirrorTokenPosition;
  options: {
    replacedWith: HTMLElement;
  };
};

export type CodeMirror = {
  getLine: (index: number) => string;
  lineCount: () => number;
};

export type CodeEditorToolbarContext = { insertText: InsertText };

export type CodeEditorToolbarButton = {
  getItem(context: CodeEditorToolbarContext): HTMLElement;
} & HTMLElement;

export type CodeEditorToolbarButtonInputs = { label: string; icon: string };

export type CodeEditorToolbarItem = HTMLElement & {
  getItem(context: CodeEditorToolbarContext): HTMLElement;
};

export type CodeEditorToolbarItemInputs = { label: string; icon: string; textToInsert: string };

export type CodeEditorToolbarPersonalization = {
  getItem(context: CodeEditorToolbarContext): HTMLElement;
} & HTMLElement;

export type CodeEditorToolbarPersonalizationInputs = { label: string; icon: string };

export type CodeEditorToolbarPersonalizationPrompt = {
  getItem(context: CodeEditorToolbarContext): HTMLElement;
} & HTMLElement;

export type CodeEditorToolbarPersonalizationPromptInputs = { label: string; icon: string };

export type CodeEditorTwigValidatorPluginValidationStatus = {
  status: CodeEditorTwigValidatorStatus;
  errors: string[];
};

export type CodeEditorTwigValidatorPluginValidationResult = {
  status: TwigValidatorClientStatus;
  errors: string[];
};

export enum ToolbarItemEvents {
  Click = 'click.code-editor-toolbar-item',
  Connected = 'connected.code-editor-toolbar-item',
  Disconnected = 'disconnected.code-editor-toolbar-item',
  Updated = 'updated.code-editor-toolbar-item',
}

export enum TokenizePatternEvents {
  Click = 'click.html-editor-tokenize-pattern-plugin',
}

export enum EditorPluginEvents {
  Connected = 'connected.html-editor-plugin',
  Disconnected = 'disconnected.html-editor-plugin',
}

export enum CodeEditorToolbarEvents {
  Connected = 'connected.code-editor-toolbar',
}

export enum CodeEditorTwigValidatorEvents {
  Connected = 'connected.code-editor-twig-validator',
  Disconnected = 'disconnected.code-editor-twig-validator',
  ValidationEvent = 'validation-event.code-editor-twig-validator',
}

export enum CodeEditorValidationStatusEvents {
  Connected = 'connected.code-editor-error-display',
  Disconnected = 'disconnected.code-editor-error-display',
}

export enum CodeEditorTwigValidatorStatus {
  Success = 'success.twig-validator-status',
  Fail = 'fail.twig-validator-status',
  Loading = 'loading.twig-validator-status',
  Unknown = 'unknown.twig-validator-status',
}

export enum HtmlEditorEvents {
  Connected = 'connected.html-editor',
  Changed = 'changed.html-editor',
}

export enum TwigValidatorClientStatus {
  Success = 'success.twig-validator-client-status',
  Fail = 'fail.twig-validator-client-status',
  Unknown = 'unknown.twig-validator-client-status',
}
