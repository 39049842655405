import * as React from 'react';
import { reactCustomElementWrapper } from '../../../../lib/react-custom-element-wrapper';

export type IntegerEditorProp = {
  value: string;
  onChange: (value: string) => void;
};

const IntegerComponent = reactCustomElementWrapper('vce-number-input');

export const IntegerEditor = ({ onChange, value }: IntegerEditorProp): any => (
  <IntegerComponent value={value} integer step="1" on-update={event => onChange(event.detail.value)} />
);
