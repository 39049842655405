import { fromEvent } from 'rxjs/observable/fromEvent';
import { first, takeUntil, map, scan, startWith, mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { merge } from 'rxjs/observable/merge';

type ConnectionEvent<T> = {
  type: 'connected' | 'disconnected' | 'updated';
  item: T;
};

export const connections = <T>(
  connect$: Observable<T>,
  disconnect$: Observable<T>,
  update$: Observable<T>,
): Observable<T[]> => {
  return merge(
    connect$.pipe(map(event => ({ type: 'connected', item: event }))),
    disconnect$.pipe(map(event => ({ type: 'disconnected', item: event }))),
    update$.pipe(map(event => ({ type: 'updated', item: event }))),
  ).pipe(
    scan((items: T[], connectionEvent: ConnectionEvent<T>) => {
      switch (connectionEvent.type) {
        case 'connected': {
          return items.concat(connectionEvent.item);
        }
        case 'disconnected': {
          return items.filter(connectionTarget => connectionTarget !== connectionEvent.item);
        }
        case 'updated': {
          return items;
        }
      }
    }, []),
    startWith([]),
  );
};

type PluginEvent<T> = CustomEvent & {
  target: T;
};
export const getComponentPlugins = <T extends HTMLElement>(
  component: HTMLElement,
  componentDisconnect$: Observable<void>,
  connectEventName: string,
  disconnectEventName: string,
  updatedEventName: string,
): Observable<T[]> => {
  const pluginConnect$ = fromEvent<PluginEvent<T>>(component, connectEventName).pipe(map(e => e.target!));
  const pluginDisconnect$ = pluginConnect$.pipe(
    mergeMap(connectedEvent =>
      fromEvent<PluginEvent<T>>(connectedEvent, disconnectEventName).pipe(
        first(),
        map(e => e.target),
      ),
    ),
  );
  const pluginUpdate$ = fromEvent<PluginEvent<T>>(component, updatedEventName).pipe(map(e => e.target!));
  return connections<T>(pluginConnect$, pluginDisconnect$, pluginUpdate$).pipe(takeUntil(componentDisconnect$));
};

export const getComponentPlugin = <T extends HTMLElement>(
  component: HTMLElement,
  componentDisconnect$: Observable<void>,
  connectEventName: string,
  disconnectEventName: string,
  updatedEventName: string,
): Observable<T> => {
  return getComponentPlugins<T>(
    component,
    componentDisconnect$,
    connectEventName,
    disconnectEventName,
    updatedEventName,
  ).pipe(map(items => items[0]));
};
