import { State, VariablesEditorDialogAction, createConfirmedSubmitForm } from '..';
import * as Actions from '..';
import { map, switchMap, filter } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { ofType } from 'redux-observable';
import { Store } from 'redux';
import { EpicDependencies } from '../dependencies.interface';

const validationMessagesForFields = {
  id: 'If this variable is already in use, changing its name may break your layout.',
  type: 'If this variable is already in use, changing its type may break your layout.',
  is_overridable:
    'If this variable is already in use, by setting it to ‘Not editable’, you will lose all previous values.',
};

export const confirm = (
  actions$: Observable<VariablesEditorDialogAction>,
  store: Store<State>,
  dependencies: EpicDependencies,
): Observable<any> =>
  actions$.pipe(
    ofType<Actions.Confirm>(Actions.CONFIRM),
    switchMap(action => {
      return dependencies.confirm('Are you sure?', getConfirmText(action.payload.changedFieldNames));
    }),
    filter(isConfirmed => isConfirmed),
    map(() => createConfirmedSubmitForm()),
  );

function getConfirmText(changedFields: string[]): string {
  if (changedFields.length > 1) {
    return 'If this variable is already in use, changing it may break your layout.';
  } else {
    return validationMessagesForFields[changedFields[0]];
  }
}
