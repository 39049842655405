import { CustomElement } from '../../../../../lib/custom-element-decorators';
import { HTMLCustomElement, Callback, ReactiveAttribute } from '../../../../../lib';
import { Observable } from 'rxjs/Observable';
import { take, skip, map, takeUntil } from 'rxjs/operators';
import { combineLatest } from 'rxjs/observable/combineLatest';
import { BlockToolbarItemEvents, CodeEditorToolbarButtonInputs } from '../interface';

export const createVcePluginBlockToolbarButton = () => {
  class VcePluginBlockToolbarButtonClass extends HTMLCustomElement {
    @Callback('readyCallback') _ready$: Observable<HTMLIFrameElement>;
    @ReactiveAttribute('label', 'label')
    private _label$: Observable<string>;
    @ReactiveAttribute('icon', 'icon')
    private _icon$: Observable<string>;

    private _inputs: CodeEditorToolbarButtonInputs;
    @Callback('disconnectedCallback') private _disconnect$: Observable<void>;

    connectedCallback(): void {
      this._inputs$.subscribe(input => (this._inputs = input));
      this._inputs$.pipe(take(1)).subscribe(() => {
        this.dispatchEvent(new CustomEvent(BlockToolbarItemEvents.Connected, { bubbles: true }));
      });
      this._inputs$.pipe(skip(1)).subscribe(() => {
        this.dispatchEvent(new CustomEvent(BlockToolbarItemEvents.Updated, { bubbles: true }));
      });
    }

    disconnectedCallback(): void {
      this.dispatchEvent(new CustomEvent(BlockToolbarItemEvents.Disconnected));
    }

    getItem(): HTMLElement {
      const tooltip = document.createElement('e-tooltip');
      tooltip.setAttribute('content', this._inputs.label);
      const button = document.createElement('div');
      button.className = 'e-btn e-btn-on_overlay e-btn-onlyicon e-btn-borderless';
      button.onclick = () => this.dispatchEvent(new CustomEvent(BlockToolbarItemEvents.Click, { bubbles: true }));
      const icon = document.createElement('e-icon');
      icon.setAttribute('icon', this._inputs.icon);
      button.appendChild(icon);
      tooltip.appendChild(button);
      return tooltip;
    }

    private get _inputs$(): Observable<CodeEditorToolbarButtonInputs> {
      return combineLatest(this._label$, this._icon$).pipe(
        takeUntil(this._disconnect$),
        map(([label, icon]) => ({ label, icon })),
      );
    }
  }

  return VcePluginBlockToolbarButtonClass;
};

const name = 'vce-plugin-block-toolbar-button';
CustomElement(name)(createVcePluginBlockToolbarButton());
