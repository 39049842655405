import { createReactiveProperty } from './lib/create-reactive-property';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { addObservedAttributes } from './lib/add-observed-attribute';
export function ReactiveAttribute(attrName: string, setterName?: string, parser = a => a): PropertyDecorator {
  return (target: Object, propertyKey: string): void => {
    const oldAttributeChangedCallback = target.constructor.prototype['attributeChangedCallback'] || (() => {});
    addObservedAttributes(target, attrName);
    const { subject } = createReactiveProperty({
      target: target.constructor.prototype,
      propertyKey,
      createObservable: () => new ReplaySubject(1),
    });
    if (setterName) {
      Object.defineProperty(target.constructor.prototype, setterName, {
        set: function(value): void {
          this[subject].next(value);
        },
        get: function(): any {
          let val;
          this[subject].subscribe(_val => (val = _val)).unsubscribe();
          return val;
        },
      });
    }
    target.constructor.prototype['attributeChangedCallback'] = function(
      attr: string,
      oldValue: any,
      newValue: any,
    ): void {
      if (attr === attrName && this[propertyKey]) {
        try {
          let parsed = parser(newValue);
          this[subject].next(parsed);
        } catch (error) {
          console.warn(`${target.constructor.name}[${attrName}->${propertyKey}] parse failed`, newValue);
        }
      }
      return oldAttributeChangedCallback.call(this, attr, oldValue, newValue);
    };
  };
}
