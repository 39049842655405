import * as React from 'react';
import { State, ValidationResults } from '../state';
import * as Actions from '../state/actions';
import * as Selectors from '../state/selectors';
import { connect } from 'react-redux';
import { v4 } from 'uuid';
import { VariableConfiguration } from '@emartech/vce-domain';
import { Footer } from './footer';
import { Name } from './name';
import { DisplayName } from './display-name';
import { DefaultValue } from './default-value';
import { IsOverridable } from './is-overridable';
import { Type } from './type';
import { Translations } from '../translations.interface';
import { OpenType } from '../state/epics/lib/open-type';

export type FormActions = {
  submitForm: () => void;
  setDisplayName: (name: string) => void;
  setId: (name: string) => void;
  setType: (name: string) => void;
  setDefaultValue: (name: string, typeConfig: any) => void;
  setIsOverridable: (name: boolean) => void;
};

type FormProps = {
  variableConfiguration: VariableConfiguration;
  validation: ValidationResults;
  isSubmitable: boolean;
  openType: OpenType;
  uniqIdForRendering: string;
  overridableEnabled: boolean;
  translations: Translations;
  useEditableImage: boolean;
};

export const FormComponent = (props: FormProps & FormActions): JSX.Element => (
  <div>
    <Name
      setId={props.setId}
      validation={props.validation.id}
      name={props.variableConfiguration.id}
      translations={props.translations.name}
    />
    <DisplayName
      setDisplayName={props.setDisplayName}
      displayName={props.variableConfiguration.display_name || ''}
      translations={props.translations.displayName}
    />
    <Type type={props.variableConfiguration.type} setType={props.setType} translations={props.translations.types} />
    <DefaultValue
      variableConfiguration={props.variableConfiguration}
      setDefaultValue={props.setDefaultValue}
      validation={props.validation.default_value}
      translations={props.translations.defaultValue}
      useEditableImage={props.useEditableImage}
    />
    <IsOverridable
      isOverridable={props.variableConfiguration.is_overridable}
      overridableEnabled={props.overridableEnabled}
      uniqIdForRendering={props.uniqIdForRendering}
      setIsOverridable={props.setIsOverridable}
      translations={props.translations.isOverridable}
    />
    <Footer
      submitForm={props.submitForm}
      openType={props.openType}
      isSubmitable={props.isSubmitable}
      translations={props.translations}
    />
  </div>
);

const mapState = (state: State): FormProps => {
  return {
    variableConfiguration: state.variableConfiguration,
    validation: state.validation,
    isSubmitable: Selectors.isSubmitable(state),
    openType: state.openType,
    uniqIdForRendering: v4(),
    overridableEnabled: Selectors.overridableEnabled(state),
    translations: state.translations,
    useEditableImage: state.useEditableImage,
  };
};

const mapDispatch = {
  setDisplayName: Actions.createSetDisplayName,
  setId: Actions.createSetId,
  setType: Actions.createSetType,
  setDefaultValue: Actions.createSetDefaultValue,
  setIsOverridable: Actions.createSetIsOverridable,
  submitForm: Actions.createSubmitForm,
};

export default connect<FormProps, FormActions>(
  mapState,
  mapDispatch,
)(FormComponent);
