export type Contact = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
};

export type ContactElementEventDetail = {
  type: 'add' | 'select' | 'remove';
  contact: Contact;
};

export type SetContactListEventDetail = {
  type: 'set';
  contactList: Contact[];
};

export type ContactEventDetail = SetContactListEventDetail | ContactElementEventDetail;

export type HTMLRenderEvent = {
  type: string;
  detail: object | Error;
};

export type ContactPreviewApi = HTMLElement & {
  find(term: string): Promise<Contact[]>;
  render(content: object, contact: Contact): Promise<object>;
  getStoredContacts(): Contact[];
  setStoredContacts(contacts: Contact[]): void;
};

export enum SimpleSearchFieldEvents {
  Connected = 'connected.simple-search-field',
  Disconnected = 'disconnected.simple-search-field',
  Updated = 'updated.simple-search-field',
}

export enum SearchResultsEvents {
  Connected = 'connected.search-results',
  Disconnected = 'disconnected.search-results',
  Updated = 'updated.search-results',
}

export enum RecentContactsEvents {
  Connected = 'connected.recent-contacts',
  Disconnected = 'disconnected.recent-contacts',
  Updated = 'updated.recent-contacts',
}

export enum ApiEvents {
  Connected = 'connected.api-events',
  Disconnected = 'disconnected.api-events',
  Updated = 'updated.api-events',
}
