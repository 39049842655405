import { getWindowYPosition } from './';

export type KeepWindowPosition = {
  (window: Window, callback: Function): void;
};

export const keepWindowPosition: KeepWindowPosition = (window: Window, callback: Function): void => {
  const windowYPos = getWindowYPosition(window);
  callback();
  window.scrollTo(0, windowYPos);
};
