import { cleanUpBookmarks } from '../bookmark-cleanup';
import { head } from 'ramda';
import { TinymceEditor } from '../../models/tinymce-editor/index';

type BookmarkRole = '_start' | '_end';

export const handleIE11 = (editor: TinymceEditor) => {
  editor.once('focus', () => (editor.editorManager.focusedEditor = null));
  editor.once('focusout', () => (editor.editorManager.focusedEditor = editor));

  editor.on(
    'mousedown',
    event => {
      if (editor.dom.hasClass(event.target, 'cbNonEditable')) {
        cleanUpBookmarks(editor);
        const id = editor.dom.uniqueId();
        const target = (editor.$ as any)(event.target);
        target.before(createBookmarkElement(id, '_start'));
        target.after(createBookmarkElement(id, '_end'));
        editor.selection.moveToBookmark({ id, keep: 1 });
      }
    },
    true,
  );

  editor.on(
    'blur',
    event => {
      if (editor.selection.getNode().classList.contains('cbNonEditable')) {
        event.stopImmediatePropagation();
        event.stopPropagation();
        event.preventDefault();
      }
    },
    true,
  );

  editor.on(
    'keydown',
    event => {
      if (editor.selection.getNode().classList.contains('cbNonEditable')) {
        const range = (editor.selection.getRng as any)().cloneRange();
        range.setStartAfter(head<Element>((editor.$ as any)(editor.selection.getNode()).prev()));
        range.setEndBefore(head<Element>((editor.$ as any)(editor.selection.getNode()).next()));
        editor.selection.setRng(range);
        cleanUpBookmarks(editor);
      }
    },
    true,
  );
};

function createBookmarkElement(id: string, role: BookmarkRole): string {
  return `<span data-mce-type="bookmark" id="${id + role}" style="overflow:hidden;line-height:0px">&#xFEFF;</span>`;
}
