import { CustomElement } from '../../../../lib/custom-element-decorators';
import { HTMLCustomElement, ReactiveAttribute, Callback, ViewChild } from '../../../../lib';
import { Observable } from 'rxjs/Observable';
import { takeUntil } from 'rxjs/operators';
import { VcePluginBordererCore } from '../index';

export interface VcePluginBorderer extends HTMLElement {}
export function createVcePluginBorderer(): { new (): VcePluginBorderer } {
  class VcePluginBordererClass extends HTMLCustomElement implements VcePluginBorderer {
    @Callback('disconnectedCallback') private _disconnect$: Observable<void>;
    @ReactiveAttribute('force', 'force')
    private _force$: Observable<string>;
    @ViewChild('vce-plugin-borderer-core') private _$bordererCore: VcePluginBordererCore | null;

    connectedCallback(): void {
      this.innerHTML = `
        <vce-plugin-borderer-core>
          <vce-plugin-borderer-image-focus-strategy></vce-plugin-borderer-image-focus-strategy>
          <vce-plugin-borderer-image-load-strategy></vce-plugin-borderer-image-load-strategy>
          <vce-plugin-borderer-editable-text-strategy></vce-plugin-borderer-editable-text-strategy>
          <vce-plugin-borderer-window-resize-strategy></vce-plugin-borderer-window-resize-strategy>
        </vce-plugin-borderer-core>
      `;
      this._force$.pipe(takeUntil(this._disconnect$)).subscribe(force => {
        if (this._$bordererCore) {
          this._$bordererCore.setAttribute('force', force);
        }
      });
    }
  }
  return VcePluginBordererClass;
}

const name = 'vce-plugin-borderer';
CustomElement(name)(createVcePluginBorderer());
