import { createElementFromHtmlContent } from '../create-element-from-html-content';

export function insertNodeBefore(document: Document, selector: string, content: string): void {
  const insertBeforeElement = document.querySelector(selector);
  insertBeforeElement!.parentElement!.insertBefore(
    createElementFromHtmlContent(document, content),
    insertBeforeElement,
  );
}

export function insertNodeInContainer(document: Document, container: string, content: string): void {
  document.querySelector(container)!.appendChild(createElementFromHtmlContent(document, content));
}

export function setContainerHtml(document: Document, container: string, content: string): void {
  document.querySelectorAll(container).forEach(element => {
    element.innerHTML = content;
  });
}

export function deleteNode(document: Document, selector: string): void {
  Array.from(document.querySelectorAll(selector)).forEach(node => {
    node.parentNode!.removeChild(node);
  });
}

export function updateNode(document: Document, selector: string, content: string): void {
  const target = document.querySelector(selector)!;
  target.parentElement!.replaceChild(createElementFromHtmlContent(document, content), target);
}

export function reorderNodesInContainer(document: Document, containerSelector: string, order: string[]): void {
  const container = document.querySelector(containerSelector)!;
  order.forEach(selector => container.appendChild(container.querySelector(selector)!));
}
