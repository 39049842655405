import { CustomElement } from '../../../../lib/custom-element-decorators';
import { HTMLCustomElement, Callback } from '../../../../lib';
import { Observable } from 'rxjs/Observable';
import { createCalculated } from './calculated';
import { StateType, VcePluginBlockToolbarElement, BlockToolbarElementEvents } from './interface';
import { createInputs } from './create-inputs';
import { createActions } from './actions';
import { createState } from './state';
import Popper from 'popper.js';
import { withLatestFrom } from 'rxjs/operators';
import { getComponentPlugins } from '../../../../lib/component-connections';
export interface VcePluginBlockToolbar extends HTMLElement {}
export function createVcePluginBlockToolbar(): { new (): VcePluginBlockToolbar } {
  class VcePluginBlockToolbarClass extends HTMLCustomElement implements VcePluginBlockToolbar {
    @Callback('readyCallback') _ready$: Observable<HTMLIFrameElement>;
    @Callback('disconnectedCallback') _disconnect$: Observable<void>;

    connectedCallback(): void {
      let lastPopper: any = null;
      createState(
        createActions(createCalculated(createInputs(this.ownerDocument!.defaultView!, this._ready$, this._blocks$))),
      )
        .pipe(withLatestFrom(this._ready$))
        .subscribe(([state, iframe]) => {
          if (lastPopper) {
            lastPopper.destroy();
            lastPopper = null;
          }

          if (state.type === StateType.on) {
            const element = this._createToolbarElement(state.block);
            // <e-vce-borderer-element style="left: 2px; top: 460px; will-change: top, left; position: absolute; display: block; z-index: 600;"><div style="pointer-events: none; border-top: 3px solid rgba(68,68,68,.8); width: 560px; height: 0; left: 0; top: 0; position: absolute"></div><div style="pointer-events: none; border-bottom: 3px solid rgba(68,68,68,.8); width: 560px; height: 0; left: 0; top: 379px; position: absolute"></div><div style="pointer-events: none; border-left: 3px solid rgba(68,68,68,.8); width: 0; height: 376px; left: 0; top: 3px; position: absolute"></div><div style="pointer-events: none; border-right: 3px solid rgba(68,68,68,.8); width: 0; height: 376px; left: 557px; top: 3px; position: absolute"></div><div data-coverelement="" style="position: absolute; left: 0px; top: -60px; width: 560px; height: 60px;"></div><div style="position: absolute; left: 100px; top: -60px; width: 323px; height: 60px;"></div></e-vce-borderer-element>
            this.appendChild(element);
            lastPopper = this._createPopper(element, iframe, state.block.selector);
          }
        });
      this.dispatchEvent(new CustomEvent('plugin.connected', { bubbles: true }));
    }

    disconnectedCallback(): void {
      if (this.querySelector('#toolbar')) {
        this.querySelector('#toolbar')!.remove();
      }
    }

    private _createPopper(element: HTMLElement, iframe: HTMLIFrameElement, selector: string): Popper {
      return new Popper(iframe.contentWindow!.document.querySelector(`[e-block="${selector}"]`)!, element, {
        placement: 'top-end',
        removeOnDestroy: true,
        positionFixed: true,
        modifiers: {
          preventOverflow: {
            boundariesElement: 'viewport',
            escapeWithReference: true,
            padding: 0,
          },
          flip: {
            enabled: false,
          },
          applyIframeStyle: {
            enabled: true,
            fn: this._calculatePositionInIframe(iframe, element),
            order: 800,
          },
          computeStyle: {
            gpuAcceleration: false,
          },
        },
      });
    }
    private _calculatePositionInIframe(iframe, popperElement): any {
      return data => {
        const {
          top: iframeTop,
          left: iframeLeft,
          height: iframeHeight,
          width: iframeWidth,
        } = iframe.getBoundingClientRect();
        const { height: popperHeight, width: popperWidth } = popperElement.getBoundingClientRect();
        const leftBoundary = iframeLeft + iframeWidth - popperWidth;
        const bottomBoundary = iframeTop + iframeHeight - popperHeight;
        data.offsets.popper.top += iframeTop;
        data.offsets.popper.left += iframeLeft;

        if (data.offsets.popper.top < iframeTop - data.offsets.popper.height) {
          data.offsets.popper.top = iframeTop - data.offsets.popper.height;
        }
        if (data.offsets.popper.top > bottomBoundary) {
          data.offsets.popper.top = bottomBoundary;
        }
        if (data.offsets.popper.left > leftBoundary) {
          data.offsets.popper.left = leftBoundary;
        }
        return data;
      };
    }

    private get _blocks$(): Observable<VcePluginBlockToolbarElement[]> {
      return getComponentPlugins<VcePluginBlockToolbarElement>(
        this,
        this._disconnect$,
        BlockToolbarElementEvents.Connected,
        BlockToolbarElementEvents.Disconnected,
        BlockToolbarElementEvents.Updated,
      );
    }

    private _createToolbarElement(blockElement: VcePluginBlockToolbarElement): HTMLElement {
      const element = document.createElement('div');
      element.classList.add('block-toolbar');
      element.style.zIndex = '500';
      element.id = 'toolbar';
      element.appendChild(blockElement.getItem());
      return element;
    }
  }

  return VcePluginBlockToolbarClass;
}

const name = 'vce-plugin-block-toolbar';
CustomElement(name)(createVcePluginBlockToolbar());
