import { CustomElement } from '../../../../lib/custom-element-decorators';
import { IVcePlugin } from '../../../../lib/vce-plugin.interface';
import { HTMLCustomElement } from '../../../../lib/html-custom-element';
import { Callback } from '../../../../lib';
import { Observable } from 'rxjs/Observable';
import { fromEvent } from 'rxjs/observable/fromEvent';
import { takeUntil, switchMap } from 'rxjs/operators';
import { merge } from 'rxjs/observable/merge';

export interface VcePluginWindowResize extends HTMLElement, IVcePlugin {}
export function createVcePluginWindowResize(global: Window): { new (): VcePluginWindowResize } {
  class VcePluginImageLoadClass extends HTMLCustomElement implements VcePluginWindowResize {
    @Callback('readyCallback') private _ready$: Observable<HTMLIFrameElement>;
    @Callback('disconnectedCallback') private _disconnect$: Observable<void>;

    connectedCallback(): void {
      this._ready$
        .pipe(
          switchMap(iframe => merge(fromEvent(global, 'resize'), fromEvent(iframe.contentWindow!, 'resize'))),
          takeUntil(this._disconnect$),
        )
        .subscribe(event => {
          this.dispatchEvent(new CustomEvent('editable.recalculate', { bubbles: true }));
        });
      this.dispatchEvent(new CustomEvent('plugin.connected', { bubbles: true }));
    }

    disconnectedCallback(): void {
      this.dispatchEvent(new CustomEvent('plugin.disconnected'));
    }
  }
  return VcePluginImageLoadClass;
}

const name = 'vce-plugin-borderer-window-resize-strategy';
CustomElement(name)(createVcePluginWindowResize(window));
