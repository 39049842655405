import { VcePluginBlockToolbarElement } from '../interface';

export const BLOKS_LEAVED = '[]Blocks Leaved';
export const BLOCK_SWITCHED = '[]Block Switched';
export const BLOCK_FORCED = '[]TBlock Forced';
export const BLOCK_ENTERED_FROM_EMPTY_SPACE = '[]Block Entered From Empty Space';
export const TOOLBAR_ENTERED_FROM_BLOCK = '[]Toolbar Entered From Block';

export class BlocksLeavedAction {
  public readonly type = BLOKS_LEAVED;
}

export class BlockSwitchedAction {
  public readonly type = BLOCK_SWITCHED;
  public readonly payload: { block: VcePluginBlockToolbarElement };
  constructor(payload: { block: VcePluginBlockToolbarElement }) {
    this.payload = payload;
  }
}

export class BlockForcedAction {
  public readonly type = BLOCK_FORCED;
  public readonly payload: { block: VcePluginBlockToolbarElement };
  constructor(payload: { block: VcePluginBlockToolbarElement }) {
    this.payload = payload;
  }
}

export class BlockEnteredFromEmptySpaceAction {
  public readonly type = BLOCK_ENTERED_FROM_EMPTY_SPACE;
  public readonly payload: { block: VcePluginBlockToolbarElement };
  constructor(payload: { block: VcePluginBlockToolbarElement }) {
    this.payload = payload;
  }
}

export class ToolbarEnteredFromBLockAction {
  public readonly type = TOOLBAR_ENTERED_FROM_BLOCK;
}

export type Action =
  | BlocksLeavedAction
  | BlockSwitchedAction
  | BlockForcedAction
  | ToolbarEnteredFromBLockAction
  | BlockEnteredFromEmptySpaceAction;
