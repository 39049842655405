import { Subscription } from 'rxjs/Subscription';
import { fromEvent } from 'rxjs/observable/fromEvent';

export interface ReplaceBrokenImage {
  (opts: { placeholder: string }, document: Document): Subscription;
}
export const replaceBrokenImage: ReplaceBrokenImage = (opts: { placeholder: string }, document: Document) => {
  Array.from(document.querySelectorAll('img')).forEach(
    (element: HTMLImageElement): void => {
      if (element.complete) {
        if (element.naturalWidth === 0 || element.naturalHeight === 0) {
          setPlaceholder(element, opts.placeholder);
        }
      }
    },
  );

  return fromEvent(document.querySelectorAll('img'), 'error').subscribe(($event: ErrorEvent) => {
    const element = $event.target as HTMLImageElement;
    if (element.hasAttribute('data-broken-src')) {
      element.src = ''; // inf. loop prevention
      return;
    }
    setPlaceholder(element, opts.placeholder);
  });
};

function setPlaceholder(element: HTMLImageElement, placeholder: string): void {
  element.setAttribute('data-broken-src', element.src);
  element.src = placeholder;
}
