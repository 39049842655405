import * as React from 'react';
import { CustomElement } from '../../../../lib/custom-element-decorators';
import { Observable } from 'rxjs/Observable';
import { takeUntil, map, startWith } from 'rxjs/operators';
import { Callback, ReactiveAttribute } from '../../../../lib/reactive-decorators';
import { render } from 'react-dom';
import { combineLatest } from 'rxjs/observable/combineLatest';
import { Contact, ContactEventDetail, SearchResultsEvents } from '../../interface';
import { HTMLCustomElement } from '../../../../lib';

export type SearchResults = HTMLElement & {
  contacts: Contact[];
};

type RenderProps = {
  contacts: Contact[];
  selectedContact?: string;
};

export const createSearchResults = () => {
  class SearchResultsClass extends HTMLCustomElement implements SearchResults {
    contacts: Contact[];

    @Callback('disconnectedCallback') private _disconnect$: Observable<void>;
    @ReactiveAttribute('contacts', 'contacts', JSON.parse)
    private _contacts$: Observable<Contact[]>;

    connectedCallback(): void {
      this._inputs$.pipe(takeUntil(this._disconnect$)).subscribe(props => render(this._render(props), this));
      this.dispatchEvent(new CustomEvent(SearchResultsEvents.Connected, { bubbles: true }));
    }

    disconnectedCallback(): void {
      this.dispatchEvent(new CustomEvent(SearchResultsEvents.Disconnected));
    }

    private _render(props: RenderProps): JSX.Element {
      return (
        <table className="e-table e-table-overview" data-e-version="2">
          <tbody>
            {props.contacts.map((contact, index) => (
              <tr className="e-table__row" key={contact.email}>
                <td className="e-clickable" onClick={event => this._onContactSelect(contact)}>
                  {contact.email}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    }

    private get _inputs$(): Observable<RenderProps> {
      return combineLatest(this._contacts$.pipe(startWith([]))).pipe(
        map(([contacts]) => ({
          contacts,
        })),
      );
    }

    private _onContactSelect(contact: Contact): void {
      this._emitSelect(contact);
    }

    private _emitSelect(contact: Contact): void {
      const type = 'add';
      const detail: ContactEventDetail = { type, contact };
      this.dispatchEvent(new CustomEvent(type, { detail }));
    }
  }

  return SearchResultsClass;
};

const name = 'vce-search-results';
CustomElement(name)(createSearchResults());
