import { ValidationErrorTranslations } from '../../../translations.interface';
import { ValidationResult } from '../../state.interface';
import { ValidateDefaultValue } from '../validate-default-value';
import { ValidateImageUrl } from '../validate-image-url';

export type ValidateEditableImageStrategy = {
  validateDefaultValue: ValidateDefaultValue;
  validateImageUrl: ValidateImageUrl;
};

export type ValidateEditableImage = (
  imageUrl: string,
  translations: ValidationErrorTranslations,
) => Promise<ValidationResult>;

export const createValidateEditableImage = (strategy: ValidateEditableImageStrategy): ValidateEditableImage => async (
  imageUrl,
  translations,
) => {
  const defaultValueValidationResult = strategy.validateDefaultValue(imageUrl, translations);
  if (defaultValueValidationResult.isInvalid) {
    return defaultValueValidationResult;
  }
  return strategy.validateImageUrl(imageUrl, translations);
};
