import * as React from 'react';
import classnames from 'classnames';

export type ResetButtonProps = {
  tooltip: string;
  onClick: () => void;
};
export const ResetButton = ({ tooltip, onClick }: ResetButtonProps): any => (
  <e-tooltip class={'variables-editor-reset-tooltip'} content={tooltip}>
    <button className={classnames(['e-btn', 'e-btn-onlyicon', 'variables-editor-reset'])} onClick={() => onClick()}>
      <e-icon size="small" icon="reset-block" />
    </button>
  </e-tooltip>
);
