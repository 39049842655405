import { fromEventSafe as fromEvent, CustomElement, HTMLCustomElement } from '../../../../lib/index';
import { sanitizeDocument } from '../../../../lib/sanitize/index';
import { VceIFramesContainer, vceIFramesContainerName } from '../iframes-container/iframes-container.component';
import { VceIframeCustomEvent, VceIframe } from '../iframe/iframe.component';
import { Observable } from 'rxjs/Observable';
import { takeUntil, map, filter, mergeMap, withLatestFrom } from 'rxjs/operators';
import { merge } from 'rxjs/observable/merge';
import { IVcePlugin } from '../../../../lib/vce-plugin.interface';
import { Callback, StringAttribute, BooleanAttribute, ReactiveAttribute } from '../../../../lib/reactive-decorators';
import { getComponentPlugins } from '../../../../lib/component-connections';

type VcePluginCustomEvent = CustomEvent & {
  target: IVcePlugin;
};

interface ConnectEventWithIframe {
  iframe: HTMLIFrameElement;
  plugin: IVcePlugin;
}

type ReorderNodesInContainer = { selector: string; order: string[] };
type InsertNodeBefore = { selector: string; content: string };
type InsertNodeInContainer = { selector: string; content: string };
type UpdateNode = { selector: string; content: string };
type DeleteNode = { selector: string };
type SetContainerHtml = { selector: string; content: string };

export interface Preview extends HTMLElement {
  iframeWidth: string;
  extraClass: string;
  disableSanitize: boolean;
  reload(): void;
  deleteNode(args: DeleteNode): void;
  updateNode(args: UpdateNode): void;
  insertNodeInContainer(args: InsertNodeInContainer): void;
  insertNodeBefore(args: InsertNodeBefore): void;
  setContainerHtml(args: SetContainerHtml): void;
  reorderNodesInContainer(args: ReorderNodesInContainer): void;
}

export function createVcePreview(iframesContainerName): { new (): Preview } {
  class VcePreview extends HTMLCustomElement {
    @Callback('disconnectedCallback') _disconnect$: Observable<void>;
    @Callback('reload') _reload$: Observable<void>;
    @Callback('deleteNode') _deleteNodeCalled$: Observable<DeleteNode>;
    @Callback('updateNode') _updateNodeCalled$: Observable<UpdateNode>;
    @Callback('insertNodeInContainer') _insertNodeInContainerCalled$: Observable<InsertNodeInContainer>;
    @Callback('insertNodeBefore') _insertNodeBeforeCalled$: Observable<InsertNodeBefore>;
    @Callback('setContainerHtml') _setContainerHtmlCalled$: Observable<SetContainerHtml>;
    @Callback('reorderNodesInContainer') _reorderNodesInContainerCalled$: Observable<ReorderNodesInContainer>;
    @BooleanAttribute('disable-sanitize') disableSanitize: boolean;
    @ReactiveAttribute('content', 'content')
    private _content$: Observable<string>;
    @StringAttribute('iframe-width')
    set iframeWidth(value: string) {
      this._iframeWidth = value;
      this._setIFrameWidth();
    }
    @StringAttribute('extra-class')
    set extraClass(value: string) {
      this._extraClass = value;
      this._setExtraClass();
    }
    private _iframeWidth?: string;
    private _extraClass?: string;
    private _iframesComponent?: VceIFramesContainer;

    connectedCallback(): void {
      this._iframesComponent = document.createElement(iframesContainerName) as VceIFramesContainer;
      this._onIframeChangeCallReadyCallbacks();
      this._onIframeCreateCallRenderedCallbacks();
      this._onConnectionsAfterIframeLoadReload();
      this._onInputChangeUpdateContent();
      this._setIFrameWidth();
      this._setExtraClass();
      this._onDomChangeReloadPlugins();
      this.appendChild(this._iframesComponent);

      this._setContainerHtmlCalled$
        .pipe(withLatestFrom(this._pluginsWithBeforeRenderCallback$, this._vceIframe$))
        .subscribe(([args, plugins, iframe]) =>
          iframe.setContainerHtml(args.selector, this._callThroughBeforeRenderCallbacks(plugins, args.content)),
        );
    }

    disconnectedCallback(): void {}
    reload(): void {}
    deleteNode(args: DeleteNode): void {}
    updateNode(args: UpdateNode): void {}
    insertNodeInContainer(args: InsertNodeInContainer): void {}
    insertNodeBefore(args: InsertNodeBefore): void {}
    setContainerHtml(args: SetContainerHtml): void {}
    reorderNodesInContainer(args: ReorderNodesInContainer): void {}

    private _onDomChangeReloadPlugins(): void {
      merge(
        this._reload$,
        this._deleteNode$,
        this._updateNode$,
        this._insertNodeInContainer$,
        this._insertNodeBefore$,
        this._reorderNodeInContainer$,
      )
        .pipe(
          takeUntil(this._disconnect$),
          withLatestFrom(this._plugins$, this._iframeChange$),
        )
        .subscribe(([_, plugins, iframe]) => plugins.forEach(plugin => this._reloadPlugin({ plugin, iframe })));
    }

    private get _deleteNode$(): Observable<void> {
      return this._deleteNodeCalled$.pipe(
        withLatestFrom(this._vceIframe$),
        map(([deleteArgs, iframe]) => iframe.deleteNode(deleteArgs.selector)),
      );
    }

    private get _updateNode$(): Observable<void> {
      return this._updateNodeCalled$.pipe(
        withLatestFrom(this._pluginsWithBeforeRenderCallback$, this._vceIframe$),
        map(([args, plugins, iframe]) =>
          iframe.updateNode(args.selector, this._callThroughBeforeRenderCallbacks(plugins, args.content)),
        ),
      );
    }

    private get _insertNodeInContainer$(): Observable<void> {
      return this._insertNodeInContainerCalled$.pipe(
        withLatestFrom(this._pluginsWithBeforeRenderCallback$, this._vceIframe$),
        map(([args, plugins, iframe]) =>
          iframe.insertNodeInContainer(args.selector, this._callThroughBeforeRenderCallbacks(plugins, args.content)),
        ),
      );
    }

    private get _insertNodeBefore$(): Observable<void> {
      return this._insertNodeBeforeCalled$.pipe(
        withLatestFrom(this._pluginsWithBeforeRenderCallback$, this._vceIframe$),
        map(([args, plugins, iframe]) =>
          iframe.insertNodeBefore(args.selector, this._callThroughBeforeRenderCallbacks(plugins, args.content)),
        ),
      );
    }

    private get _reorderNodeInContainer$(): Observable<void> {
      return this._reorderNodesInContainerCalled$.pipe(
        withLatestFrom(this._vceIframe$),
        map(([args, iframe]) => iframe.reorderNodesInContainer(args.selector, args.order)),
      );
    }

    private _callThroughBeforeRenderCallbacks(plugins: IVcePlugin[], initialContent: string): string {
      return plugins.reduce((content, plugin) => plugin.beforeRenderCallback!(content), initialContent);
    }

    private _onConnectionsAfterIframeLoadReload(): void {
      this._connectionsAfterIframeLoad$.pipe(takeUntil(this._disconnect$)).subscribe(this._reloadPlugin.bind(this));
    }

    private _onIframeChangeCallReadyCallbacks(): void {
      this._connectionsWith(this._iframeChange$)
        .pipe(
          filter(({ plugin }) => !!plugin.readyCallback),
          takeUntil(this._disconnect$),
        )
        .subscribe(({ iframe, plugin }) => plugin.readyCallback!(iframe));
    }

    private _onIframeCreateCallRenderedCallbacks(): void {
      this._connectionsWith(this._iframeCreate$)
        .pipe(
          filter(({ plugin }) => !!plugin.renderedCallback),
          takeUntil(this._disconnect$),
        )
        .subscribe(({ iframe, plugin }) => plugin.renderedCallback!(iframe));
    }

    private _reloadPlugin({ plugin, iframe }: ConnectEventWithIframe): void {
      if (plugin.renderedCallback) plugin.renderedCallback(iframe);
      if (plugin.readyCallback) plugin.readyCallback(iframe);
    }

    private _connectionsWith(trigger: Observable<HTMLIFrameElement>): Observable<ConnectEventWithIframe> {
      return this._pluginConnect$.pipe(
        mergeMap((connectEvent: VcePluginCustomEvent) =>
          trigger.pipe(
            takeUntil(fromEvent(connectEvent.target, 'plugin.disconnected')),
            map(iframe => ({
              iframe,
              plugin: connectEvent.target,
            })),
          ),
        ),
      );
    }

    private get _connectionsAfterIframeLoad$(): Observable<ConnectEventWithIframe> {
      return this._pluginConnect$.pipe(
        withLatestFrom(this._iframeChange$),
        map(([connectEvent, iframe]) => ({
          iframe,
          plugin: connectEvent.target,
        })),
      );
    }

    private get _vceIframe$(): Observable<VceIframe> {
      return fromEvent(this._iframesComponent!, 'iframe.change').pipe(
        map((event: VceIframeCustomEvent) => event.detail),
      );
    }

    private get _iframeChange$(): Observable<HTMLIFrameElement> {
      return fromEvent(this._iframesComponent!, 'iframe.change').pipe(
        map((event: VceIframeCustomEvent) => event.detail.iframeElement!),
      );
    }

    private get _iframeCreate$(): Observable<HTMLIFrameElement> {
      return fromEvent(this._iframesComponent!, 'iframe.create').pipe(
        map((event: VceIframeCustomEvent) => event.detail.iframeElement!),
      );
    }

    private get _pluginConnect$(): Observable<VcePluginCustomEvent> {
      return fromEvent(this, 'plugin.connected');
    }

    private _onInputChangeUpdateContent(): void {
      this._content$
        .pipe(
          map(value => (this.disableSanitize ? value : sanitizeDocument(value))),
          withLatestFrom(this._pluginsWithBeforeRenderCallback$),
          map(([value, plugins]) => this._callThroughBeforeRenderCallbacks(plugins, value)),
          takeUntil(this._disconnect$),
        )
        .subscribe(content => {
          if (this._iframesComponent && content) {
            this._iframesComponent.setAttribute('content', content);
          }
        });
    }

    private _setIFrameWidth(): void {
      if (this._iframesComponent) {
        this._iframesComponent.setAttribute('iframe-width', this._iframeWidth!);
      }
    }

    private _setExtraClass(): void {
      if (this._iframesComponent) {
        this._iframesComponent.setAttribute('extra-class', this._extraClass!);
      }
    }

    private get _plugins$(): Observable<IVcePlugin[]> {
      return getComponentPlugins<IVcePlugin>(
        this,
        this._disconnect$,
        'plugin.connected',
        'plugin.disconnected',
        'plugin.updated',
      );
    }

    private get _pluginsWithBeforeRenderCallback$(): Observable<IVcePlugin[]> {
      return this._plugins$.pipe(map(plugins => plugins.filter(plugin => plugin.beforeRenderCallback)));
    }
  }

  return VcePreview;
}

CustomElement('vce-preview')(createVcePreview(vceIFramesContainerName));
