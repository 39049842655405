import { CodeMirrorTokenDefinition, InsertText } from '../../interface';
import { TokenDefinition } from '../../interface';
import { multiline } from './lib/multiline';

export const transformToken = (html, token: TokenDefinition, insertText: InsertText): CodeMirrorTokenDefinition => {
  const { from, to } = multiline(html, token);
  return {
    from,
    to,
    options: {
      replacedWith: createTokenElement(token.tokenName, insertText, token.onClick),
    },
  };
};

export const createTokenElement = (tokenName: string, insertText: InsertText, onClick?: Function): HTMLElement => {
  const token = document.createElement('span');
  token.className = 'e-label e-label-primary';
  token.innerText = tokenName;
  if (onClick) {
    token.onclick = () => onClick({ insertText }) as any;
    token.style.cursor = 'pointer';
  }
  return token;
};
