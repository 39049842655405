import { TextEditorContext } from '../../models';
import { TinymceEditor } from '../../models/tinymce-editor';
import { GetTextEditorConfig } from './config';
import { CreateEditor } from '../../services/index';
import { head } from 'ramda';

const tinymce = require('tinymce/tinymce');
require('tinymce/themes/modern/theme');
require('tinymce/plugins/paste/plugin');
require('tinymce/plugins/textcolor/plugin');
require('tinymce/plugins/colorpicker/plugin');
require('tinymce/plugins/noneditable/plugin');

import './patches';

export { getTextEditorConfig } from './config';
export * from './plugins';

export function createEditorFactory(getTextEditorConfig: GetTextEditorConfig, toolbar: string[]): CreateEditor {
  return (context: TextEditorContext, editable: Element): Promise<TinymceEditor> => {
    return tinymce
      .init(
        getTextEditorConfig({
          target: editable,
          window: context.previewWindow,
          document: context.previewDocument,
          toolbar,
        }),
      )
      .then(editors => head<TinymceEditor>(editors));
  };
}
