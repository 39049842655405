import { CustomElement } from '../../../../lib/custom-element-decorators';
import { HTMLCustomElement, ReactiveAttribute } from '../../../../lib/index';
import { combineLatest } from 'rxjs/observable/combineLatest';
import { Observable } from 'rxjs/Observable';
import { takeUntil, take, skip, map } from 'rxjs/operators';
import { Callback } from '../../../../lib/reactive-decorators';
import {
  CodeEditorToolbarContext,
  ToolbarItemEvents,
  CodeEditorToolbarItem,
  CodeEditorToolbarItemInputs,
} from '../../interface';

export const createCodeEditorToolbarItem = () => {
  class CodeEditorToolbarItemImplementation extends HTMLCustomElement implements CodeEditorToolbarItem {
    @Callback('disconnectedCallback') private _disconnect$: Observable<void>;
    @ReactiveAttribute('text-to-insert', 'textToInsert')
    private _textToInsert$: Observable<string>;
    @ReactiveAttribute('label', 'label')
    private _label$: Observable<string>;
    @ReactiveAttribute('icon', 'icon')
    private _icon$: Observable<string>;
    private _inputs: CodeEditorToolbarItemInputs;

    connectedCallback(): void {
      this._inputs$.subscribe(input => (this._inputs = input));
      this._inputs$.pipe(take(1)).subscribe(() => {
        this.dispatchEvent(new CustomEvent(ToolbarItemEvents.Connected, { bubbles: true }));
      });
      this._inputs$.pipe(skip(1)).subscribe(() => {
        this.dispatchEvent(new CustomEvent(ToolbarItemEvents.Updated, { bubbles: true }));
      });
    }

    disconnectedCallback(): void {
      this.dispatchEvent(new CustomEvent(ToolbarItemEvents.Disconnected));
    }

    getItem(context: CodeEditorToolbarContext): HTMLElement {
      const tooltip = document.createElement('e-tooltip');
      tooltip.setAttribute('content', this._inputs.label);
      const button = document.createElement('div');
      button.className = 'e-btn e-btn-onlyicon e-inputgroup__item';
      button.onclick = () => context.insertText(this._inputs.textToInsert);
      const icon = document.createElement('e-icon');
      icon.setAttribute('icon', this._inputs.icon);
      button.appendChild(icon);
      tooltip.appendChild(button);
      return tooltip;
    }

    get _inputs$(): Observable<CodeEditorToolbarItemInputs> {
      return combineLatest(this._label$, this._icon$, this._textToInsert$).pipe(
        takeUntil(this._disconnect$),
        map(([label, icon, textToInsert]) => ({ label, icon, textToInsert })),
      );
    }
  }

  return CodeEditorToolbarItemImplementation;
};

const name = 'vce-code-editor-toolbar-item';
CustomElement(name)(createCodeEditorToolbarItem());
