import { Observable } from 'rxjs/Observable';
import { map, share } from 'rxjs/operators';
import { fromEvent } from 'rxjs/observable/fromEvent';
import { from } from 'rxjs/observable/from';
import { merge } from 'rxjs/observable/merge';

export function appendBody(iframe: HTMLIFrameElement, htmlfragment: string): Observable<string> {
  const document = iframe.contentWindow!.document;
  document.open();
  document.write(htmlfragment);
  document.close();
  const readyState$ = fromEvent<Event>(document, 'readystatechange').pipe(
    map((e: Event) => (e.target as Document).readyState),
    share(),
  );
  if (document.readyState === 'complete') {
    return from(['interactive', 'complete']);
  }
  if (document.readyState === 'interactive') {
    return merge(from(['interactive']), readyState$);
  }
  return readyState$;
}
