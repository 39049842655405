import { isEmpty } from 'ramda';
import { ValidationErrorTranslations } from '../../../translations.interface';
import { ValidationResult } from '../../state.interface';

export type ValidateImageUrl = (value: string, translation: ValidationErrorTranslations) => Promise<ValidationResult>;
export const validateImageUrl: ValidateImageUrl = (imageUrl, translations) => {
  if (isEmpty(imageUrl)) {
    return Promise.resolve({ isInvalid: false, errorMessage: '' });
  }
  return new Promise(resolve => {
    const image = new Image();
    image.addEventListener('load', () => resolve({ isInvalid: false, errorMessage: '' }));
    image.addEventListener('error', () => resolve({ isInvalid: true, errorMessage: translations.wrongImage }));
    image.src = imageUrl;
  });
};
