import { fromEvent } from 'rxjs/observable/fromEvent';
import { mapTo, map, switchMap } from 'rxjs/operators';
import { CreateInputs } from '../interface';
import { merge } from 'rxjs/observable/merge';

export const createInputs: CreateInputs = (window, iframe$, blocks$) => ({
  mouseleave$: iframe$.pipe(
    switchMap(iframe => fromEvent<MouseEvent>(iframe, 'mouseout')),
    mapTo(undefined),
  ),
  windowMouseenter$: merge(...getEveryWindow(window).map(_ => fromEvent<MouseEvent>(_, 'mouseover'))),
  mouseenter$: iframe$.pipe(
    switchMap(iframe => fromEvent(iframe.contentWindow!, 'mouseover')),
    mapTo(undefined),
  ),
  mousemove$: iframe$.pipe(switchMap(iframe => fromEvent<MouseEvent>(iframe.contentWindow!.document, 'mousemove'))),
  scroll$: iframe$.pipe(
    switchMap(iframe => fromEvent(iframe.contentWindow!, 'scroll')),
    mapTo(undefined),
  ),
  resize$: iframe$.pipe(
    switchMap(iframe => fromEvent(iframe.contentWindow!, 'resize')),
    mapTo(undefined),
  ),
  window$: iframe$.pipe(map(iframe => iframe.contentWindow!)),
  blocks$,
});

function getEveryWindow(window: Window): Window[] {
  return getEveryWindowRec(window, []);
}

function getEveryWindowRec(window: Window, collected: Window[]): Window[] {
  if (window.parent === window) return [...collected, window];
  return getEveryWindowRec(window.parent, [...collected, window]);
}
