import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { isNil } from 'ramda';

type GetToolbarHover = (mousemove: Observable<MouseEvent>) => Observable<any>;
export const getToolbarHover: GetToolbarHover = mousemove =>
  mousemove.pipe(filter(event => isToolbar(event.target as HTMLElement)));

function isToolbar(element: Element | null): boolean {
  if (element == null || isNil(element)) {
    return false;
  }
  return element.id === 'toolbar' ? true : isToolbar(element.parentElement);
}
