import { ImageProperties } from './image-properties.interface';

export function parseTemplateImageProperties(template: string, editableId: string): ImageProperties {
  const doc = new DOMParser().parseFromString(template, 'text/html');
  const $editable = doc.querySelector(`[e-editable="${editableId}"]`) as HTMLElement | undefined;

  const width = $editable && $editable.hasAttribute('width') ? $editable.getAttribute('width')! : undefined;
  const height = $editable && $editable.hasAttribute('height') ? $editable.getAttribute('height')! : undefined;
  const style = $editable && $editable.hasAttribute('style') ? $editable.getAttribute('style')! : undefined;
  const src = $editable && $editable.hasAttribute('src') ? $editable.getAttribute('src')! : undefined;
  const alt = $editable && $editable.hasAttribute('alt') ? $editable.getAttribute('alt')! : undefined;

  return {
    hide: false,
    inherit: undefined,
    attributes: {
      width,
      height,
      style,
      src,
      alt,
    },
  };
}
