import { HTMLCustomElement, CustomElement, Callback } from '../../../../lib';
import { Observable } from 'rxjs/Observable';
import { map, share, scan, switchMap, takeUntil } from 'rxjs/operators';
import { merge } from 'rxjs/observable/merge';
import { TinymceEditor } from '../editable-text/models/tinymce-editor';
import { uniq } from 'ramda';
import { debounceBufferTime } from '../../../../lib';
import { Memoize } from 'typescript-memoize';
import { EditorLifeCycleEventType, EditorLifeCycleEvent } from './model';
import { eventFromPatternTinyMCE } from '../../../../lib/eventFromPatternTinyMCE';

const tinymce = require('tinymce/tinymce');
const handleTinyEvent = (source: Observable<TinymceEditor>): Observable<TinymceEditor[]> =>
  source.pipe(
    debounceBufferTime(),
    map(editors => uniq(editors)),
  );

export interface BordererEditableTextStrategy extends HTMLElement {}
export function createBordererEditableTextStrategy(tinymce: {
  editors: TinymceEditor[];
}): { new (): BordererEditableTextStrategy } {
  class BordererEditableTextStrategyClass extends HTMLCustomElement implements BordererEditableTextStrategy {
    @Callback('disconnectedCallback') private _disconnect$: Observable<void>;

    connectedCallback(): void {
      merge(
        this._getEditorsEvents(['NodeChange', 'KeyDown'], 'editable.recalculate'),
        this._getEditorsEvents(['Blur'], 'editable.blur'),
        this._getEditorsEvents(['Focus'], 'editable.focus'),
      )
        .pipe(takeUntil(this._disconnect$))
        .subscribe(({ name, editors }) => {
          editors.forEach(editor =>
            this.dispatchEvent(new CustomEvent(name, { detail: editor.getElement() as HTMLElement, bubbles: true })),
          );
        });
    }

    private _getEditorsEvents(tinyMceEventNames: string[], eventName: string): Observable<any> {
      return handleTinyEvent(merge(...tinyMceEventNames.map(eventName => this._getEditorsEvent(eventName)))).pipe(
        map(editors => ({ name: eventName, editors })),
      );
    }

    private _getEditorsEvent(eventName: string): Observable<TinymceEditor> {
      return this._editors$.pipe(
        switchMap(editors =>
          merge(...editors.map(editor => eventFromPatternTinyMCE(editor, eventName).pipe(map(() => editor)))),
        ),
      );
    }

    @Memoize()
    private get _editors$(): Observable<TinymceEditor[]> {
      return merge(this._editorAdd$, this._editorRemove$).pipe(
        scan((state: TinymceEditor[], event: EditorLifeCycleEvent) => {
          switch (event.type) {
            case EditorLifeCycleEventType.Add:
              return state.concat(event.editor);
            case EditorLifeCycleEventType.Remove:
              return state.filter(editor => editor !== event.editor);
          }
        }, tinymce.editors || []),
        debounceBufferTime(),
        map(arr => arr[arr.length - 1]),
        share(),
      );
    }

    private get _editorAdd$(): Observable<EditorLifeCycleEvent> {
      return eventFromPatternTinyMCE(tinymce, 'AddEditor').pipe(
        map(event => ({ editor: event.editor, type: EditorLifeCycleEventType.Add })),
      );
    }

    private get _editorRemove$(): Observable<EditorLifeCycleEvent> {
      return eventFromPatternTinyMCE(tinymce, 'RemoveEditor').pipe(
        map(event => ({ editor: event.editor, type: EditorLifeCycleEventType.Remove })),
      );
    }
  }
  return BordererEditableTextStrategyClass;
}

const name = 'vce-plugin-borderer-editable-text-strategy';
CustomElement(name)(createBordererEditableTextStrategy(tinymce));
