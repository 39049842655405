import { HTMLCustomElement } from '../../../../lib/html-custom-element';
import { CustomElement } from '../../../../lib/custom-element-decorators';
import { render } from 'react-dom';
import * as React from 'react';
import { ReactiveAttribute, Callback, attributeToBoolean } from '../../../../lib';
import { Observable } from 'rxjs';
import { takeUntil, startWith } from 'rxjs/operators';
import { IOpenMediaDB } from '../../../preview/interface';
import { fromPromise } from 'rxjs/observable/fromPromise';
import classnames from 'classnames';
import { combineLatest } from 'rxjs/observable/combineLatest';
import { Translations } from '../variable-editor-dialog/translations.interface';

export const createEditableImageInput = (): { new (): HTMLElement } => {
  class EditableImageInput extends HTMLCustomElement {
    @ReactiveAttribute('value', 'value')
    private _value$: Observable<string>;
    @ReactiveAttribute('invalid', 'invalid', attributeToBoolean)
    private _invalid$: Observable<boolean>;
    @ReactiveAttribute('translations', 'translations', JSON.parse)
    private _translations$: Observable<Translations['defaultValue']>;
    @Callback('disconnectedCallback') private _disconnect$: Observable<void>;
    private _mediaDb: IOpenMediaDB;
    @ReactiveAttribute('hide-thumbnail-button', 'hideThumbnailButton', attributeToBoolean)
    private _hideThumbnailButton$: Observable<boolean>;

    connectedCallback(): void {
      combineLatest(
        this._value$,
        this._invalid$,
        this._translations$,
        this._hideThumbnailButton$.pipe(startWith(false)),
      )
        .pipe(takeUntil(this._disconnect$))
        .subscribe(([value, invalid, translations, hideThumbnailButton]) =>
          render(this._render(value, invalid, translations, hideThumbnailButton), this),
        );
    }

    private _openMediaDb(): void {
      fromPromise(this._mediaDb.open())
        .pipe(takeUntil(this._disconnect$))
        .subscribe(selected => this._dispatchUpdate(selected.basePath + selected.relativePath));
    }

    private _dispatchUpdate(value: string): void {
      this.dispatchEvent(new CustomEvent('update', { detail: { value } }));
    }

    private _render(
      value: string,
      invalid: boolean,
      translations: Translations['defaultValue'],
      hideThumbnailButton: boolean,
    ): JSX.Element {
      const img = document.createElement('img');
      // Let the broswer escape the value to prevent xss
      img.setAttribute('src', value);
      img.setAttribute('style', 'max-width:200px;display:block;margin:5px 0;');
      return (
        <div>
          <div className="e-grid e-grid-xsmall">
            <div className="e-cell e-cell-xsmall e-cell-auto e-inputgroup">
              {!hideThumbnailButton && (
                <e-tooltip disabled={!value} content={`${img.outerHTML}`} placement="top-start">
                  <button className="e-btn e-btn-onlyicon e-inputgroup__item e-inputgroup__item-first">
                    <e-icon icon="picture-o" />
                  </button>
                </e-tooltip>
              )}
              <input
                className={classnames([
                  'e-input',
                  {
                    'e-input-error': invalid,
                    'e-inputgroup__item e-inputgroup__item-fluid e-inputgroup__item-last': !hideThumbnailButton,
                  },
                ])}
                onChange={event => this._dispatchUpdate(event.target.value)}
                value={value}
              />
            </div>

            <div className="e-cell e-cell-xsmall">
              <button className="e-btn media-db" onClick={this._openMediaDb.bind(this)}>
                {translations.image.mediaDB}
              </button>
            </div>
          </div>
          <open-media-db ref={mediaDb => (this._mediaDb = mediaDb)} />
        </div>
      );
    }
  }
  return EditableImageInput;
};

const name = 'vce-editable-image-input';
CustomElement(name)(createEditableImageInput());
