import { TinymceEditor } from '../../models/tinymce-editor';

const tinymce = require('tinymce/tinymce');

export const isUIElement = (editor: TinymceEditor, element: Element) => {
  const _isUIElement = (editor: TinymceEditor, elm: Element) => {
    var customSelector = editor ? editor.settings.custom_ui_selector : '';
    var parent = tinymce.DOM.getParent(elm, function(elm) {
      return (
        tinymce.FocusManager.isEditorUIElement(elm) || (customSelector ? editor.dom.is(elm, customSelector) : false)
      );
    });
    return parent !== null;
  };

  return _isUIElement(editor, element);
};
