import * as React from 'react';
import { dissoc } from 'ramda';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { of } from 'rxjs/observable/of';
import { merge } from 'rxjs/observable/merge';
import { combineLatest } from 'rxjs/observable/combineLatest';
import { fromEvent } from 'rxjs/observable/fromEvent';
import { switchMap, map, takeUntil, startWith } from 'rxjs/operators';
import { Callback } from './reactive-decorators';

class ReactCustomElementWrapper extends React.Component<any> {
  private _element$ = new Subject<HTMLElement>();
  @Callback('componentWillUnmount') private _componentWillUnmount$: Observable<boolean>;
  @Callback('componentWillReceiveProps') private _componentWillReceiveProps$: Observable<{ [key: string]: any }>;

  componentWillMount(): void {
    combineLatest(this._element$, this._componentWillReceiveProps$.pipe(startWith(this.props)))
      .pipe(
        takeUntil(this._componentWillUnmount$),
        switchMap(([element, props]) => {
          if (!element) {
            return of();
          }
          return merge(
            ...Object.keys(props)
              .filter(key => key.startsWith('on-'))
              .map(key => {
                const eventName = key.slice(3);
                return fromEvent(element, eventName).pipe(map((event: any) => ({ event, handler: props[key] })));
              }),
          );
        }),
      )
      .subscribe(({ event, handler }) => {
        handler(event);
      });

    combineLatest(this._element$, this._componentWillReceiveProps$.pipe(startWith(this.props)))
      .pipe(takeUntil(this._componentWillUnmount$))
      .subscribe(([element, props]) => {
        Object.keys(props)
          .filter(key => key.startsWith('prop-'))
          .forEach(key => {
            const propName = key.slice(5);
            element[propName] = props[key];
          });
      });
  }

  refElement = element => this._element$.next(element);

  render(): React.DOMElement<any, any> {
    const { tag, ...props } = this.props;

    const propsWithoutCustom = Object.keys(props)
      .filter(key => key.startsWith('on-') || key.startsWith('prop-'))
      .reduce((prevProps, key) => dissoc(key, prevProps), props);

    return React.createElement(tag as string, { ref: this.refElement, ...propsWithoutCustom });
  }
}

export const reactCustomElementWrapper = (tag: string) => props => <ReactCustomElementWrapper tag={tag} {...props} />;
