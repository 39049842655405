import { Store, applyMiddleware, createStore, compose } from 'redux';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import * as Epics from './epics';
import { State, stateReducer } from './';
import { EpicDependencies } from './dependencies.interface';
import { validateEditableImage } from './validations';

export const createDialogStore = (dependencies: EpicDependencies): Store<State> => {
  const epics = combineEpics(
    Epics.fillEditDataFromApi,
    Epics.fillCopyDataFromApi,
    Epics.fillNewDataFromApi,
    Epics.confirmSubmit,
    Epics.emitStateChangeOnConfirmedSubmit,
    Epics.fillReservedIdsFromApi,
    Epics.setUseEditableImageFromApi,
    Epics.setStringEditableFromApi,
    Epics.trackEvents,
    Epics.confirm,
    Epics.setTranslations,
    Epics.createSetDefaultValueValidationOnChange(validateEditableImage),
    Epics.setDefaultValueValidationOnOpenWithEdit,
  );
  const epicMiddleware = createEpicMiddleware(epics, { dependencies });
  const composeEnhancers = window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] || compose;
  return createStore(stateReducer, composeEnhancers(applyMiddleware(epicMiddleware))) as any;
};
