import { State, VariablesEditorAction, CHANGE_VALUE, RESET, APPLY_STATE_FROM_INPUT, SET_SEARCH, initialSate } from '.';
import { assocPath, dissocPath } from 'ramda';

export const stateReducer = (state: State = initialSate, action: VariablesEditorAction): State => {
  switch (action.type) {
    case CHANGE_VALUE:
      return assocPath(['variables', action.payload.id], action.payload.value, state);
    case RESET:
      return dissocPath(['variables', action.payload.id], state);
    case APPLY_STATE_FROM_INPUT:
      return action.payload.state;
    case SET_SEARCH:
      return assocPath(['search'], action.payload.value, state);
    default:
      return state;
  }
};
