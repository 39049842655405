import { propOr, isNil, prop, pipe, complement } from 'ramda';
import { TinymceEditor } from '../../models/tinymce-editor/index';

const isNilContent = pipe(
  prop('content'),
  isNil,
);
const isNotNil = complement(isNil);
const contentOf = propOr<string>('', 'content') as (level: Object) => string;
const startContentOf = propOr<string>('', 'startContent') as (level: Object) => string;

export const validContentChangeOf = (editor: TinymceEditor) => {
  return ({ lastLevel, level }) => {
    if (isNilContent(level)) return false;
    return getSanitizedContent(editor, contentOf(lastLevel)) !== getSanitizedContent(editor, contentOf(level));
  };
};

export const validContinuousChangeOf = (editor: TinymceEditor) => {
  return event => {
    const currentContent = editor.getContent({ format: 'raw' });
    if (event.type && event.type.toLowerCase() === 'beforeexeccommand') return true;
    return isNotNil(currentContent) ? startContentOf(event.target) !== currentContent : false;
  };
};

function getSanitizedContent(editor: TinymceEditor, content: string): string {
  const contentNode = editor.dom.create('div', {}, content) as HTMLElement;
  return (editor.serializer.serialize(contentNode, { getInner: true, no_events: true }) as any) as string;
}
