import { State, VariablesEditorDialogAction } from '..';
import * as Actions from '..';
import { scan, map, withLatestFrom, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { ofType } from 'redux-observable';
import { Store } from 'redux';
import { EpicDependencies } from '../dependencies.interface';
import { uniq } from 'ramda';
import { getOpenType, OpenType } from './lib/open-type';

export const confirmSubmit = (
  actions$: Observable<VariablesEditorDialogAction>,
  store: Store<State>,
  dependencies: EpicDependencies,
): Observable<VariablesEditorDialogAction> =>
  actions$.pipe(
    ofType(Actions.SUBMIT_FORM),
    withLatestFrom(getChangedDestructiveFields(actions$).pipe(startWith([])), getOpenType(actions$)),
    map(
      ([_, changedFields, openType]) =>
        changedFields.length > 0 && openType === OpenType.Edit
          ? Actions.createConfirm(changedFields)
          : Actions.createConfirmedSubmitForm(),
    ),
  );

function getChangedDestructiveFields(actions$: Observable<VariablesEditorDialogAction>): Observable<string[]> {
  return actions$.pipe(
    ofType(Actions.SET_ID, Actions.SET_TYPE, Actions.SET_IS_OVERRIDABLE),
    scan((changedFields: string[], currentAction: VariablesEditorDialogAction) => {
      return uniq(changedFields.concat([getFieldName(currentAction.type)!]));
    }, []),
  );
}

function getFieldName(actionType: string): string | undefined {
  switch (actionType) {
    case Actions.SET_TYPE:
      return 'type';
    case Actions.SET_ID:
      return 'id';
    case Actions.SET_IS_OVERRIDABLE:
      return 'is_overridable';
  }
}
