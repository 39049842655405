import { createSetTranslations, State, SetTranslations } from '../';
import { map } from 'rxjs/operators';
import { Store } from 'redux';
import { EpicDependencies } from '../dependencies.interface';
import { Observable } from 'rxjs/Observable';

export const setTranslations = (
  actions$: Observable<SetTranslations>,
  store: Store<State>,
  dependencies: EpicDependencies,
): Observable<SetTranslations> => dependencies.translations$.pipe(map(createSetTranslations));
