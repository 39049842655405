import { surroundPlaceholders } from '@emartech/personalization-twig-token';
import { CustomElement } from '../../../../lib/custom-element-decorators';
import { HTMLCustomElement, ReactiveAttribute } from '../../../../lib/index';
import { V2PersonalizationState, PersonalizationApi } from '../../../../lib/personalization.interface';
import {
  CodeEditorToolbarContext,
  ToolbarItemEvents,
  CodeEditorToolbarPersonalization,
  CodeEditorToolbarPersonalizationInputs,
} from '../../interface';
import { combineLatest } from 'rxjs/observable/combineLatest';
import { Callback } from '../../../../lib/reactive-decorators';
import { Observable } from 'rxjs/Observable';
import { takeUntil, take, skip, map, startWith } from 'rxjs/operators';

export const createCodeEditorToolbarPersonalization = (global: { personalization: PersonalizationApi }) => {
  class CodeEditorToolbarPersonalizationImplementation extends HTMLCustomElement
    implements CodeEditorToolbarPersonalization {
    @Callback('disconnectedCallback') private _disconnect$: Observable<void>;
    @ReactiveAttribute('label', 'label')
    private _label$: Observable<string>;
    @ReactiveAttribute('icon', 'icon')
    private _icon$: Observable<string>;
    private _inputs: CodeEditorToolbarPersonalizationInputs;

    connectedCallback(): void {
      this._inputs$.subscribe(input => (this._inputs = input));
      this._inputs$.pipe(take(1)).subscribe(() => {
        this.dispatchEvent(new CustomEvent(ToolbarItemEvents.Connected, { bubbles: true }));
      });
      this._inputs$.pipe(skip(1)).subscribe(() => {
        this.dispatchEvent(new CustomEvent(ToolbarItemEvents.Updated, { bubbles: true }));
      });
    }

    disconnectedCallback(): void {
      this.dispatchEvent(new CustomEvent(ToolbarItemEvents.Disconnected));
    }

    getItem(context: CodeEditorToolbarContext): HTMLElement {
      const tooltip = this._createTooltip();
      const icon = this._createIcon(this._inputs.icon);
      const button = this._createButton();

      button.onclick = () => this._openDialog(context);
      button.appendChild(icon);
      tooltip.appendChild(button);
      return tooltip;
    }

    private _createTooltip(): HTMLElement {
      const tooltip = document.createElement('e-tooltip');
      tooltip.setAttribute('content', this._inputs.label);
      return tooltip;
    }

    private _createButton(): HTMLElement {
      const button = document.createElement('div');
      button.className = 'e-btn e-btn-onlyicon e-inputgroup__item';
      return button;
    }

    private _createIcon(iconName: string): HTMLElement {
      const icon = document.createElement('e-icon');
      icon.setAttribute('icon', iconName);
      return icon;
    }

    private _openDialog(context: CodeEditorToolbarContext): void {
      global.personalization.openDialog({
        state: JSON.stringify({ tokenName: '', preview: '' }),
        insertTemplate: (state: V2PersonalizationState) => {
          context.insertText(surroundPlaceholders(state.preview, state));
        },
      });
    }

    get _inputs$(): Observable<CodeEditorToolbarPersonalizationInputs> {
      return combineLatest(this._label$, this._icon$.pipe(startWith('e-profile'))).pipe(
        takeUntil(this._disconnect$),
        map(([label, icon]) => ({ label, icon })),
      );
    }
  }

  return CodeEditorToolbarPersonalizationImplementation;
};

const name = 'vce-code-editor-toolbar-personalization';
CustomElement(name)(createCodeEditorToolbarPersonalization(window as any));
