import { VariableConfiguration } from '@emartech/vce-domain';
import { HTMLCustomElement } from '../../../../lib/html-custom-element';
import { CustomElement } from '../../../../lib/custom-element-decorators';
import { ReactiveAttribute, Callback, attributeToBoolean } from '../../../../lib/reactive-decorators';
import { Observable } from 'rxjs/Observable';
import { combineLatest } from 'rxjs/observable/combineLatest';
import { startWith, takeUntil, map } from 'rxjs/operators';
import { mergeDeepRight } from 'ramda';
import * as escape from 'lodash.escape';
import { Translations } from './translations.interface';
import * as React from 'react';
import { render } from 'react-dom';
import { reactCustomElementWrapper } from '../../../../lib/react-custom-element-wrapper';
const EDatagrid = reactCustomElementWrapper('e-datagrid');
const EDatagridColumn = reactCustomElementWrapper('e-datagrid-column');
const EDatagridItemAction = reactCustomElementWrapper('e-datagrid-item-action');

type VariableConfigurationDataGridRow = VariableConfiguration & {
  name: string;
  value: string;
  editable: string;
};

const defaultTranslations: Translations = {
  columnName: 'Name',
  columnDefaultValue: 'Value',
  columnEditable: 'Editable',
  tooltipActionEdit: 'Edit',
  tooltipActionCopy: 'Copy',
  tooltipActionDelete: 'Delete',
  types: {
    string: 'String',
    color: 'Color',
    integer: 'Number - Integer',
    decimal: 'Number - Decimal',
    select: 'Selectable',
    image: 'Image',
  },
};

export const createVariablesList = (): { new (): HTMLElement } => {
  class VariablesList extends HTMLCustomElement {
    @ReactiveAttribute('variable-configurations', 'variableConfigurations', JSON.parse)
    private _variableConfigurations$: Observable<any[]>;

    @ReactiveAttribute('translations', 'translations', JSON.parse)
    private _translations$: Observable<Translations>;

    @ReactiveAttribute('show-copy-action', 'showCopyAction', attributeToBoolean)
    private _showCopyAction$: Observable<boolean>;

    @ReactiveAttribute('show-search', 'showSearch', attributeToBoolean)
    private _showSearch$: Observable<boolean>;

    @Callback('disconnectedCallback') private _disconnect$: Observable<void>;

    connectedCallback(): void {
      this.dispatchEvent(new CustomEvent('connected', { bubbles: true }));
      this._inputs$
        .pipe(takeUntil(this._disconnect$))
        .subscribe(({ translations, variableConfigurations, showCopyAction, showSearch }) => {
          render(this._render(translations, variableConfigurations, showCopyAction, showSearch), this);
        });
    }

    private get _inputs$(): Observable<{
      translations: Translations;
      variableConfigurations: VariableConfigurationDataGridRow[];
      showCopyAction: boolean;
      showSearch: boolean;
    }> {
      return combineLatest(
        this._translations$.pipe(startWith(defaultTranslations)),
        this._variableConfigurations$,
        this._showCopyAction$.pipe(startWith(false)),
        this._showSearch$.pipe(startWith(false)),
      ).pipe(
        map(([translations, variableConfigurations, showCopyAction, showSearch]) => {
          const mergedTranslations = mergeDeepRight(defaultTranslations, translations);
          return {
            translations: mergedTranslations,
            variableConfigurations: this._convert(variableConfigurations, mergedTranslations),
            showCopyAction,
            showSearch,
          };
        }),
      );
    }

    private _render(
      translations: Translations,
      variableConfigurations: VariableConfigurationDataGridRow[],
      showCopyAction: boolean,
      showSearch: boolean,
    ): JSX.Element {
      return (
        <div className="e-stretch">
          <EDatagrid
            prop-data={variableConfigurations}
            hide-pagination="hide-pagination"
            hide-search={!showSearch}
            sorting-key="name"
          >
            <EDatagridColumn head={translations.columnName} content-key="name" render-html="render-html" width="50%" />
            <EDatagridColumn
              head={translations.columnDefaultValue}
              content-key="value"
              render-html="render-html"
              width="50%"
            />
            <EDatagridColumn
              head={translations.columnEditable}
              content-key="editable"
              render-html="render-html"
              width="50%"
            />
            <EDatagridItemAction
              on-trigger={event => this._emitActionEvent(event)}
              icon="pencil"
              tooltip={translations.tooltipActionEdit}
              action="edit"
            />
            {showCopyAction && (
              <EDatagridItemAction
                on-trigger={event => this._emitActionEvent(event)}
                icon="copy-block"
                tooltip={translations.tooltipActionCopy}
                action="copy"
              />
            )}
            <EDatagridItemAction
              on-trigger={event => this._emitActionEvent(event)}
              icon="trash-o"
              tooltip={translations.tooltipActionDelete}
              action="delete"
            />
          </EDatagrid>
        </div>
      );
    }

    private _emitActionEvent(event: any): void {
      this.dispatchEvent(
        new CustomEvent('action', {
          bubbles: true,
          detail: { type: event.target.getAttribute('action'), data: event.detail.data },
        }),
      );
    }

    private _convert(
      configurations: VariableConfiguration[],
      translations: Translations,
    ): VariableConfigurationDataGridRow[] {
      return configurations.map(config =>
        Object.assign({}, config, {
          name: `${config.id}<div class="text-size-small text-color-shade">${escape(
            config.display_name ? config.display_name : config.id,
          )}</div>`,
          value: `${escape(config.default_value)}<div class="text-size-small text-color-shade">${
            translations.types[config.type]
          }</div>`,
          editable: config.is_overridable
            ? '<e-icon icon="check" size="small" color="success"></e-icon>'
            : '<e-icon icon="ban" size="small" color="disabled"></e-icon>',
        }),
      );
    }
  }
  return VariablesList;
};

export const variablesListagName = 'vce-variables-list';
CustomElement(variablesListagName)(createVariablesList());
