export const render = (dialogTagName: string) => `
  <${dialogTagName} dialog class="link-editor-dialog mce-prevent-blur" headline="">
    <div class="e-field">
      <label for="input-url" class="e-field__label"></label>
      <div class="e-field__postfix">
        <input id="input-url" type="text" class="e-input href" placeholder="">
      </div>
    </div>
    <div class="e-field">
      <label for="input-title" class="e-field__label"></label>
      <div class="e-field__postfix">
        <input id="input-title" type="text" class="e-input title" placeholder="">
      </div>
    </div>
    <div class="e-dialog__footer">
      <div class="e-flex e-flex-between">
        <div class="e-buttongroup e-flex__item">
          <button class="e-btn close_btn close" data-action="close"></button>
          <button class="e-btn e-btn-primary apply" style="margin-left: 10px"></button>
        </div>
        <button class="e-btn e-btn-narrow e-flex__item remove"></button>
      </div>
    </div>
  </${dialogTagName}>
`;

export const domTranslatorConfig = [
  {
    selector: null,
    key: 'headline',
    attribute: 'headline',
  },
  {
    selector: `[for="input-url"]`,
    key: 'urlLabel',
    property: 'innerHTML',
  },
  {
    selector: `#input-url`,
    key: 'urlPlaceholder',
    attribute: 'placeholder',
  },
  {
    selector: `[for="input-title"]`,
    key: 'titleLabel',
    property: 'innerHTML',
  },
  {
    selector: `#input-title`,
    key: 'titlePlaceholder',
    attribute: 'placeholder',
  },
  {
    selector: `.e-btn.close`,
    key: 'cancelLabel',
    property: 'innerHTML',
  },
  {
    selector: `.e-btn.apply`,
    key: 'applyLabel',
    property: 'innerHTML',
  },
  {
    selector: `.e-btn.remove`,
    key: 'removeLabel',
    property: 'innerHTML',
  },
];
